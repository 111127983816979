export const pageRoutes = {
  home: "home",
  assistant: "assistant",
  investorRelations: "investor",
  contacts: "contacts",
  investors: "investors",
  investorPortalSettings: "settings",
  documentCollections: "document-collections",
  documentActivity: "document-activity",
  fundraisings: "fundraisings",
  messages: "messages",
  attachments: "attachments",
  activity: "activity",
  investorEmails: "emails",
  investorPortalDevTools: "dev-tools",
  companyReports: "company-reports",
  reportTemplates: "report-templates",
  reports: "reports",
  reportLevels: "report-levels",
  settings: "settings",
  settingsCompanyGeneral: "company-general",
  settingsMembers: "members",
  settingsIntegrations: "integrations",
  integration: "integration",
  apiGateway: "api-gateway",
  dataBackup: "data-backup",
  passthrough: "passthrough",
  snowflake: "snowflake",
  salesforce: "salesforce",
  hubspot: "hubspot",
  organizationReports: "organization-reports",
  funds: "funds",
  files: "files",
  expenseManagement: "expenses",
  invoices: "invoices",
  vendors: "vendors",
  portfolio: "portfolio",
  fundInvestments: "fund-investments",
  portfolioCompanies: "portfolio-companies",
  importData: "import-data",
  importCsvData: "csv-import",
  importDetails: "details",
  importReview: "review",
  requests: "requests",
  workflows: "workflows",
  approvals: "approvals",
  dataModel: "data-model",
  orgObjects: "org-objects",
  layout: "layout",
  records: "records",
  smartViews: "smart-views",
  apiReports: "api-reports",
  organizationApiReports: "organization-api-reports",
};
