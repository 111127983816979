import SubjectIcon from "@mui/icons-material/SubjectRounded";
import { DataImportTarget } from "../../../../../api/types/dataImportTypes";
import TimeSeriesIcon from "../../../../../icons/TimeSeriesIcon";

interface Props {
  target: DataImportTarget;
}

const ImportCsvTargetIcon = ({ target }: Props) => {
  if (target === "PlatformObjects") {
    return <SubjectIcon />;
  }
  if (target === "TimeSeriesFields") {
    return <TimeSeriesIcon />;
  }
  return null;
};

export default ImportCsvTargetIcon;
