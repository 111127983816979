import CheckIcon from "@mui/icons-material/CheckRounded";
import CloseIcon from "@mui/icons-material/CloseRounded";
import { Button, Stack } from "@mui/material";
import { useCallback, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import DataLoadingFailed from "../../../../../shared/components/DataLoadingFailed";
import InlineLoader from "../../../../../shared/components/inlineLoader/InlineLoader";
import useFetch from "../../../../../shared/hooks/useFetch";
import { defined } from "../../../../../shared/utilities/typeHelper";
import approvalsApi from "../../../../api/mock/approvalsMockApi";
import { useClientContext } from "../../../../context/ClientContext";
import { pageRoutes } from "../../../../routes";
import GeneralPageHeader from "../../../common/GeneralPageHeader";
import FullHeightFlexPage from "../../../common/layout/FullHeightFlexPage";
import ScrollableColumns from "../../../common/layout/ScrollableColumns";
import StretchedScrollableContent from "../../../common/layout/StretchedScrollableContent";
import ApprovalConfirmationDialog from "../dialogs/ApprovalConfirmationDialog";
import RejectionConfirmationDialog from "../dialogs/RejectionConfirmationDialog";
import ApprovalDetailsSection from "./ApprovalDetailsSection";
import ApprovalDetailsTitle from "./ApprovalDetailsTitle";
import ApprovalPreviewSection from "./ApprovalPreviewSection";

const ApprovalDetailsPage = () => {
  const { id } = useParams();
  const { state: navigationState } = useLocation();
  const { clientCode, updateMenuBadges } = useClientContext();

  const [showApproveConfirmation, setShowApproveConfirmation] = useState(false);
  const [showRejectConfirmation, setShowRejectConfirmation] = useState(false);

  const getApprovalDetails = useCallback(() => approvalsApi.getApprovalById(defined(id)), [id]);

  const [approvalRequest, fetchError, { isFetching, fetch: fetchApprovalDetails }] = useFetch(getApprovalDetails);
  if (fetchError) {
    return <DataLoadingFailed title="Loading change approval details failed" />;
  }

  if (isFetching || approvalRequest === undefined) {
    return <InlineLoader />;
  }

  const handleApproveClick = () => {
    setShowApproveConfirmation(true);
  };

  const handleRejectClick = async () => {
    setShowRejectConfirmation(true);
  };

  const handleStatusUpdated = async () => {
    await fetchApprovalDetails();
    updateMenuBadges();
  };

  return (
    <>
      <FullHeightFlexPage>
        <GeneralPageHeader
          TitleComponent={<ApprovalDetailsTitle approvalRequest={approvalRequest} />}
          showDefaultBackButtonTo={`/${clientCode}/${pageRoutes.workflows}/${pageRoutes.approvals}?tab=${navigationState?.tab ?? ""}&view=${navigationState?.view ?? ""}`}
        >
          {approvalRequest.status === "Pending" && approvalRequest.allowUpdateForCurrentUser && (
            <Stack direction="row" alignItems="center" spacing={1}>
              <Button variant="outlined" color="success" startIcon={<CheckIcon />} onClick={handleApproveClick}>
                Approve
              </Button>
              <Button variant="outlined" color="error" startIcon={<CloseIcon />} onClick={handleRejectClick}>
                Reject
              </Button>
            </Stack>
          )}
        </GeneralPageHeader>
        <StretchedScrollableContent>
          <ScrollableColumns layout={[{ xs: true }, { width: "45rem" }]}>
            <ApprovalDetailsSection approvalRequest={approvalRequest} />
            <ApprovalPreviewSection entityIds={approvalRequest.payments.map(({ entityId }) => entityId)} />
          </ScrollableColumns>
        </StretchedScrollableContent>
      </FullHeightFlexPage>
      <ApprovalConfirmationDialog
        requests={[approvalRequest]}
        open={showApproveConfirmation}
        onClose={() => setShowApproveConfirmation(false)}
        onConfirmed={handleStatusUpdated}
      />
      <RejectionConfirmationDialog
        requests={[approvalRequest]}
        open={showRejectConfirmation}
        onClose={() => setShowRejectConfirmation(false)}
        onConfirmed={handleStatusUpdated}
      />
    </>
  );
};

export default ApprovalDetailsPage;
