import { Grid } from "@mui/material";
import useFetch from "../../../../../shared/hooks/useFetch";
import { logError } from "../../../../../shared/logging";
import adminApi from "../../../../api/adminApi";
import { useClientContext } from "../../../../context/ClientContext";
import SaltEdgeIcon from "../../../../icons/SaltEdgeIcon";
import CanoeLogo from "../../../../images/canoe.png";
import CascataLogo from "../../../../images/cascata.png";
import FloatRatesLogo from "../../../../images/floatrates.png";
import HmrcLogo from "../../../../images/hmrc.png";
import HubspotLogo from "../../../../images/hubspot.png";
import MxLogo from "../../../../images/mx.png";
import PassthroughLogo from "../../../../images/passthrough.png";
import PlaidLogo from "../../../../images/plaid.png";
import QashqadeLogo from "../../../../images/qashqade.png";
import SalesforceLogo from "../../../../images/salesforce.png";
import SnowflakeLogo from "../../../../images/snowflake.png";
import TwelveDataLogo from "../../../../images/twelvedata.png";
import YodleeLogo from "../../../../images/yodlee.png";
import { pageRoutes } from "../../../../routes";
import IntegrationsCard from "./IntegrationsCard";

const IntegrationsDashboard = () => {
  const {
    clientCode,
    hasAnyPermission,
    reportingPortalApp,
    salesforceApp,
    yodleeApp,
    saltEdgeApp,
    plaidApp,
    mxApp,
    passthroughApp,
    qashqadeApp,
  } = useClientContext();
  const [fundAccountingApps, fetchError] = useFetch(adminApi.getFundAccountingAppsStatus);

  if (fetchError) {
    logError(fetchError, "[IntegrationsDashboard], getFundAccountingAppsStatus");
  }

  const hmrcApp = fundAccountingApps?.applications?.find((app) => app.appCode === "HMRC");
  const twelveDataApp = fundAccountingApps?.applications?.find((app) => app.appCode === "TwelveData");
  const floatRatesApp = fundAccountingApps?.applications?.find((app) => app.appCode === "FloatRates");

  const hasAccessToClientIntegrations = hasAnyPermission(["ViewClientIntegrations", "ManageClientIntegrations"]);

  return (
    <Grid container spacing={3}>
      <Grid item>
        <IntegrationsCard
          title="Snowflake"
          text="A single, global platform that powers the Data Cloud. Snowflake is uniquely designed to connect businesses globally, across any type or scale of data."
          logoSrc={SnowflakeLogo}
          appStatus={reportingPortalApp?.status}
          moreInfoLink="https://www.snowflake.com"
          configurationRoute={
            hasAccessToClientIntegrations
              ? `/${clientCode}/${pageRoutes.integration}/${pageRoutes.snowflake}`
              : undefined
          }
        />
      </Grid>
      <Grid item>
        <IntegrationsCard
          title="Salesforce"
          text="Salesforce is a robust CRM platform designed to help businesses manage and analyze customer interactions and data efficiently."
          logoSrc={SalesforceLogo}
          appStatus={salesforceApp?.status}
          moreInfoLink="https://www.salesforce.com"
          configurationRoute={`/${clientCode}/${pageRoutes.integration}/${pageRoutes.salesforce}`}
        />
      </Grid>
      <Grid item>
        <IntegrationsCard
          title="Hubspot"
          text="HubSpot offers an all-in-one inbound marketing, sales, and customer service platform."
          logoSrc={HubspotLogo}
          appStatus="Announced"
          moreInfoLink="https://www.hubspot.com"
        />
      </Grid>
      <Grid item>
        <IntegrationsCard
          title="Passthrough"
          text="Intelligent sub docs and KYC/AML for private markets."
          logoSrc={PassthroughLogo}
          appStatus={passthroughApp?.status}
          moreInfoLink="https://passthrough.com"
          configurationRoute={
            hasAccessToClientIntegrations
              ? `/${clientCode}/${pageRoutes.integration}/${pageRoutes.passthrough}`
              : undefined
          }
        />
      </Grid>
      <Grid item>
        <IntegrationsCard
          title="MX"
          text="MX is a fast-growing fintech provider, partnering with financial institutions and banking providers to provide consolidated data."
          logoSrc={MxLogo}
          appStatus={mxApp?.status}
          moreInfoLink="https://www.mx.com"
        />
      </Grid>
      <Grid item>
        <IntegrationsCard
          title="Yodlee"
          text="Yodlee provides account aggregation services, consolidating information from multiple sources."
          logoSrc={YodleeLogo}
          appStatus={yodleeApp?.status}
          moreInfoLink="https://www.yodlee.com"
        />
      </Grid>
      <Grid item>
        <IntegrationsCard
          title="Salt Edge"
          text="Connect to thousands of banks, neobanks, and EMIs across the world with Salt Edge."
          Icon={SaltEdgeIcon}
          appStatus={saltEdgeApp?.status}
          moreInfoLink="https://www.saltedge.com"
        />
      </Grid>
      <Grid item>
        <IntegrationsCard
          title="Plaid"
          text="Plaid enables seamless and secure access to financial data, allowing to link bank accounts, verify balances, and initiate transactions."
          logoSrc={PlaidLogo}
          appStatus={plaidApp?.status}
          moreInfoLink="https://plaid.com"
        />
      </Grid>
      <Grid item>
        <IntegrationsCard
          title="qashqade"
          text="qashqade is an enterprise product suite designed to streamline the calculation, allocation, tracking and reporting processes for private markets."
          logoSrc={QashqadeLogo}
          appStatus={qashqadeApp?.status}
          moreInfoLink="https://www.qashqade.com/?utm_source=partner&utm_medium=website&utm_campaign=entrilia"
        />
      </Grid>
      <Grid item>
        <IntegrationsCard
          title="Cascata"
          text="Automated distribution waterfall and management solutions for the private equity industry."
          logoSrc={CascataLogo}
          appStatus="Announced"
          moreInfoLink="https://www.cascata-solutions.com"
        />
      </Grid>
      <Grid item>
        <IntegrationsCard
          title="Canoe"
          text="Guiding the alts industry forward with smarter data management."
          logoSrc={CanoeLogo}
          appStatus="Announced"
          moreInfoLink="https://canoeintelligence.com"
        />
      </Grid>
      <Grid item>
        <IntegrationsCard
          title="HMRC"
          text="Submit your VAT returns directly to HMRC for seamless tax compliance."
          logoSrc={HmrcLogo}
          appStatus={hmrcApp?.status ?? "Hidden"}
          moreInfoLink="https://www.gov.uk/government/organisations/hm-revenue-customs"
          configurationLink={hmrcApp?.managementUrl}
        />
      </Grid>
      <Grid item>
        <IntegrationsCard
          title="Twelve Data"
          text="Access real-time and historical financial market data for better investment insights."
          logoSrc={TwelveDataLogo}
          appStatus={twelveDataApp?.status ?? "Hidden"}
          moreInfoLink="https://twelvedata.com"
          configurationLink={twelveDataApp?.managementUrl}
        />
      </Grid>
      <Grid item>
        <IntegrationsCard
          title="Float Rates"
          text="Get accurate and up-to-date foreign exchange rates for simplified currency conversions."
          logoSrc={FloatRatesLogo}
          appStatus={floatRatesApp?.status ?? "Hidden"}
          moreInfoLink="https://www.floatrates.com"
          configurationLink={floatRatesApp?.managementUrl}
        />
      </Grid>
    </Grid>
  );
};

export default IntegrationsDashboard;
