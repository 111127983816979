import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { withErrorHandling } from "../../../../../shared/api/axiosHelper";
import DialogCloseButton from "../../../../../shared/components/DialogeCloseButton";
import { useNotificationContext } from "../../../../../shared/contexts/NotificationContext";
import { logError } from "../../../../../shared/logging";
import adminApi from "../../../../api/adminApi";
import MemberDialogIcon from "../../../common/MemberDialogIcon";
import NavigateToInvitationEmailSettings from "./NavigateToInvitationEmailSettings";

interface Props {
  open: boolean;
  onClose: () => void;
  onSubmit: (contactIds: string[]) => void;
  contactIds: string[];
}

const sendInvitationToContacts = withErrorHandling(adminApi.sendInvitationToContacts);

const InviteContactsDialog = ({ open, onClose, onSubmit, contactIds }: Props) => {
  const { sendNotification, sendNotificationError } = useNotificationContext();
  const [isSending, setSending] = useState(false);

  const handleSubmit = async () => {
    if (contactIds.length === 0) {
      return;
    }

    setSending(true);
    const [invitedContacts, error] = await sendInvitationToContacts(contactIds);
    setSending(false);

    if (error) {
      logError(error, "[InviteContactsDialog]");
      sendNotificationError("Failed to send invitation");
      return;
    }

    sendNotification(
      `Invitation has been sent to ${invitedContacts.length} ${invitedContacts.length === 1 ? "contact" : " contacts"}.`
    );

    onSubmit(invitedContacts);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>Send Invitation</DialogTitle>
      <DialogCloseButton onClick={onClose} />
      <DialogContent>
        <Stack alignItems="center" spacing={2}>
          <MemberDialogIcon />
          <Typography color="text.secondary" textAlign="center">
            You're about to send an invitation to <strong>{contactIds.length}</strong>{" "}
            {contactIds.length > 1 ? "contacts" : "contact"}.
          </Typography>
          <NavigateToInvitationEmailSettings />
        </Stack>
      </DialogContent>
      <DialogActions sx={{ py: 2, px: 3, columnGap: 1 }}>
        <Button variant="text" color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton variant="contained" color="primary" loading={isSending} onClick={handleSubmit}>
          Send
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default InviteContactsDialog;
