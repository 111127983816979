import { useMemo } from "react";
import { useCustomDataGridHeaderToolbar } from "../../../../common/grid/CustomDataGridHeaderToolbar";
import { deepmerge } from "@mui/utils";

export const useCheckedGridSx = () => {
  const { getGridSx } = useCustomDataGridHeaderToolbar();

  const checkedGridSx = useMemo(
    () => ({
      ".MuiDataGrid-columnHeaders": {
        ".MuiCheckbox-root": {
          display: "none",
          "&.Mui-checked": {
            display: "inherit",
          },
          "&.MuiCheckbox-indeterminate": {
            display: "inherit",
          },
        },
        ":hover .MuiCheckbox-root": {
          display: "inherit",
        },
      },
      ".MuiDataGrid-row": {
        ".MuiDataGrid-cellCheckbox .MuiCheckbox-root": {
          display: "none",
          "&.Mui-checked": {
            display: "inherit",
          },
          "&.MuiCheckbox-indeterminate": {
            display: "inherit",
          },
        },

        ":hover .MuiDataGrid-cellCheckbox .MuiCheckbox-root": {
          display: "inherit",
        },
      },
    }),
    []
  );

  return (showToolbar: boolean) => {
    return deepmerge(getGridSx(showToolbar), checkedGridSx, { clone: true });
  };
};
