import { Typography } from "@mui/material";
import { GridCellModes, useGridApiRef } from "@mui/x-data-grid-premium";
import CenteredWrapper from "../../../../../../../shared/components/CenteredWrapper";
import DataGrid from "../../../../../../../shared/components/grid/DataGrid";
import { GlAccount, InvoiceDeal } from "../../../../../../api/adminApi";
import { InvoiceDetailsConfidenceWarning, InvoiceDetailsLine, InvoiceDetailsLineError } from "../invoiceDetailsState";
import InvoiceLinesGridFooter from "./InvoiceLinesGridFooter";
import { getInvoiceLinesColumns } from "./invoiceLinesGridProvider";

export interface InvoiceDetailsLineWithError extends InvoiceDetailsLine {
  accountCategoryError?: string;
  amountError?: string;
  descriptionError?: string;
}

interface Props {
  lines: InvoiceDetailsLine[];
  lineErrors?: Record<string, InvoiceDetailsLineError>;
  confidenceWarnings: InvoiceDetailsConfidenceWarning;
  glAccounts: GlAccount[];
  deals: InvoiceDeal[];
  disabled?: boolean;
  onLineChange: (line: InvoiceDetailsLine) => void;
  onLineDelete: (lineId: number) => void;
  onLineAdd: () => void;
}

const InvoiceLinesGrid = ({
  lines,
  lineErrors,
  onLineChange,
  onLineAdd,
  onLineDelete,
  glAccounts,
  confidenceWarnings,
  disabled,
  deals,
}: Props) => {
  const apiRef = useGridApiRef();

  return (
    <DataGrid<InvoiceDetailsLine>
      apiRef={apiRef}
      columns={getInvoiceLinesColumns(glAccounts, lineErrors, confidenceWarnings, deals, disabled)}
      getRowId={(row) => row.id}
      rowHeight={40}
      columnHeaderHeight={36}
      rows={lines}
      autoHeight
      disableColumnFilter
      disableColumnSelector
      disableColumnMenu
      disableColumnReorder
      disableRowSelectionOnClick
      showCellVerticalBorder
      showColumnVerticalBorder
      pinnedColumns={{ left: ["warning", "description"] }}
      noScrollableContainer
      hideFooter={disabled}
      processRowUpdate={(newRow) => {
        onLineChange(newRow);
        return newRow;
      }}
      onCellClick={(params) => {
        if (params.isEditable && params.cellMode === GridCellModes.View) {
          apiRef.current.startCellEditMode({ id: params.id, field: params.field });
          return;
        }
        if (params.field === "actions" && lines.length > 1) {
          onLineDelete(params.row.id);
          return;
        }
      }}
      sx={(theme) => ({
        border: 1,
        borderColor: theme.palette.divider,
        ".grid-cell-editable:hover": {
          outline: `1px solid ${theme.palette.text.primary}`,
        },
        ".grid-cell-warning": {
          borderRight: `1px solid ${theme.palette.divider}`,
        },
        ".grid-cell-warning svg:hover": {
          cursor: "pointer",
        },
        ".grid-cell-no-right-border": {
          borderRight: "none",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "transparent !important",
        },
        " .MuiDataGrid-cell.MuiDataGrid-cell--editing": {
          boxShadow: "none",
          p: 0,
        },
        " .MuiDataGrid-cell.MuiDataGrid-cell.grid-cell-editable": {
          "&.grid-cell-error": {
            outline: `1px solid ${theme.palette.error.main}`,
          },
          "&.grid-cell-low-confident": {
            outline: `1px solid ${theme.palette.warning.light}`,
          },
        },
        " .grid-cell-editable": {
          borderRight: `1px solid ${theme.palette.divider}`,
          px: 1.75,
          outlineOffset: "-1px",
        },
        " .MuiDataGrid-columnHeader": {
          py: 0.75,
          px: 2,
        },
      })}
      slots={{
        footer: InvoiceLinesGridFooter,
        noRowsOverlay: NoRows,
      }}
      slotProps={{
        footer: { onLineAdd, disabled: lines.length >= 80 },
      }}
    />
  );
};

export default InvoiceLinesGrid;

const NoRows = () => (
  <CenteredWrapper>
    <Typography color="text.secondary">No lines yet</Typography>
  </CenteredWrapper>
);
