import { Stack, Tooltip, Typography } from "@mui/material";
import { DocumentCollectionDeliveryProgress } from "../../../../api/adminApi";

import DocumentIcon from "@mui/icons-material/DescriptionOutlined";
import MailIcon from "@mui/icons-material/MailOutlined";

interface Props {
  documentsCount: number;
  deliveryProgress: DocumentCollectionDeliveryProgress;
}

const DeliveryProgress = ({ documentsCount, deliveryProgress }: Props) => {
  const { emailsSent, emailsNotDelivered } = deliveryProgress;

  return (
    <Stack direction="row" spacing={2.5}>
      <Tooltip title="All Documents" arrow>
        <Stack direction="row" spacing={1} alignItems="center">
          <DocumentIcon color="secondary" />
          <Typography>{documentsCount}</Typography>
        </Stack>
      </Tooltip>
      <Tooltip title={emailsSent ? "All Emails / Not Delivered Emails" : "No Emails"} arrow>
        <Stack direction="row" spacing={1} alignItems="center">
          <MailIcon color="secondary" />
          {emailsSent ? (
            <Stack direction="row">
              <Typography>{emailsSent}&nbsp;/&nbsp;</Typography>
              <Typography color={emailsNotDelivered > 0 ? "error" : "text.primary"}>{emailsNotDelivered}</Typography>
            </Stack>
          ) : (
            <Typography>-</Typography>
          )}
        </Stack>
      </Tooltip>
    </Stack>
  );
};

export default DeliveryProgress;
