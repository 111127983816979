import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import DragIndicatorIcon from "@mui/icons-material/DragIndicatorRounded";
import { IconButton, ListItem, ListItemIcon, ListItemSecondaryAction } from "@mui/material";
import TypographyTooltipEllipsis from "../../../../../../../shared/components/TypographyTooltipEllipsis";
import { TimeSeriesPeriodTableField } from "../../../../../../api/types/timeSeriesTypes";
import HiddenIcon from "../../../../../../icons/HiddenIcon";
import VisibleIcon from "../../../../../../icons/VisibleIcon";
import TimeSeriesFieldTypeIcon from "../TimeSeriesFieldTypeIcon";

interface Props {
  field: TimeSeriesPeriodTableField;
  onChangeVisibility: (fieldId: string, isVisible: boolean) => void;
  onRequestEdit: (field: TimeSeriesPeriodTableField) => void;
}

const TimeSeriesFieldListItem = ({ field, onChangeVisibility, onRequestEdit }: Props) => {
  const handleChangeVisibilityClick = () => {
    onChangeVisibility(field.id, !field.isVisible);
  };

  const handleEditClick = () => {
    onRequestEdit(field);
  };

  return (
    <ListItem draggable>
      <ListItemIcon sx={{ minWidth: "unset", mr: 0.75 }}>
        <DragIndicatorIcon sx={{ cursor: "grab" }} />
        <TimeSeriesFieldTypeIcon fieldType={field.type} />
      </ListItemIcon>
      <TypographyTooltipEllipsis text={field.name} typographySx={{ pr: 4 }} />
      <ListItemSecondaryAction>
        <IconButton edge="end" onClick={handleChangeVisibilityClick}>
          {field.isVisible ? <VisibleIcon /> : <HiddenIcon />}
        </IconButton>
        <IconButton edge="end" onClick={handleEditClick} sx={{ ml: 1 }}>
          <ChevronRightRoundedIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default TimeSeriesFieldListItem;
