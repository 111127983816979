import SaveIcon from "@mui/icons-material/FileDownloadOutlined";
import { Box, Button, Stack, Typography } from "@mui/material";
import { ApiResponse } from "../../../../shared/api/types";
import { logError } from "../../../../shared/logging";
import { downloadFileFromUrl } from "../../../../shared/services/downloadFile";
import { FileDownloadInfo } from "../../../api/adminApi";
import { useLocalization } from "../../../hooks/useLocalization";
import DocumentIcon from "../../../icons/DocumentIcon";

interface Props {
  getFileDownloadInfoCallback: () => Promise<ApiResponse<FileDownloadInfo>>;
  fileIdentifier?: string;
}

const NotPossibleDocumentPreview = ({ getFileDownloadInfoCallback, fileIdentifier }: Props) => {
  const locale = useLocalization();
  const page = locale.pages.cant_preview_file;

  const handleDownloadClick = async () => {
    const resp = await getFileDownloadInfoCallback();
    if (resp.data !== undefined) {
      downloadFileFromUrl(resp.data.downloadUrl);
    } else {
      logError(
        `Could not receive download URL for document: ${fileIdentifier}. Error: ${resp.error?.message}`,
        "NotPossibleDocumentPreview"
      );
    }
  };

  return (
    <Box display="flex" alignItems="center" justifyContent="center" flexGrow={1} width="100%" height="100%">
      <Stack alignItems="center" spacing={2}>
        <DocumentIcon sx={{ width: 80, height: 80 }} />
        <Stack alignItems="center">
          <Typography color="text.secondary" textAlign="center">
            {page.subtitle}
          </Typography>
        </Stack>
        <Button color="primary" variant="outlined" onClick={handleDownloadClick} startIcon={<SaveIcon />}>
          {page.downloadButton}
        </Button>
      </Stack>
    </Box>
  );
};

export default NotPossibleDocumentPreview;
