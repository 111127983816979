import { Typography } from "@mui/material";
import { createContext, PropsWithChildren, useCallback, useContext, useState } from "react";
import DataLoadingFailed from "../../../../../shared/components/DataLoadingFailed";
import { useNotificationContext } from "../../../../../shared/contexts/NotificationContext";
import useFetch from "../../../../../shared/hooks/useFetch";
import DeleteDocumentIcon from "../../../../../shared/icons/DeleteDocumentIcon";
import { logError } from "../../../../../shared/logging";
import { stringToBase64 } from "../../../../../shared/utilities/stringHelper";
import { defined } from "../../../../../shared/utilities/typeHelper";
import adminApi, { Category, ConsentsDetails, UserConsent, UserConsentContract } from "../../../../api/adminApi";
import { useClientContext } from "../../../../context/ClientContext";
import useConfirmationDialog from "../../../../hooks/useConfirmationDialog";
import ConfirmOperationDialog from "../../../common/ConfirmOperationDialog";
import ConsentPreviewDialog from "./ConsentPreviewDialog";

export interface ConsentEditable extends UserConsent, ConsentsDetails {
  isNew: boolean;
  editedContent: string | undefined;
}

export type SaveStatus = "Ready" | "Saving" | "Saved";

interface ContextValue {
  title: string;
  canEdit: boolean;
  categories: Category[];
  isLoading: boolean;
  consents: UserConsent[];
  handleNewConsent: () => void;
  editableConsent: ConsentEditable | undefined;
  updateEditableConsent: (consentUpdate: Partial<ConsentEditable>) => void;
  removeEditableConsent: () => void;
  handleConsentDelete: (consent: UserConsent) => void;
  showConsentPreview: () => void;
  handleConsentSave: (updateVersion: boolean) => void;
  saveState: SaveStatus;
  setSaveState: (state: SaveStatus) => void;
  isSaveDisabled: boolean;
  handleEditConsent: (consent: UserConsent) => void;
}

const ConsentsContext = createContext<ContextValue | undefined>(undefined);

export const ConsentsContextProvider = ({ children }: PropsWithChildren) => {
  const { clientTitle, branding, hasPermissions } = useClientContext();
  const canEdit = hasPermissions(["ManageInvestorPortalSettings"]);
  const [consents, setConsents] = useState<UserConsent[]>([]);
  const [editableConsent, setEditableConsent] = useState<ConsentEditable>();
  const [saveState, setSaveState] = useState<SaveStatus>("Ready");
  const [isUpdating, setIsUpdating] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const { sendNotification, sendNotificationError } = useNotificationContext();

  const { isOpenConfirmationDialog, confirmDialogData, setConfirmDialogData, closeConfirmationDialog } =
    useConfirmationDialog<UserConsent>();

  const [consentsResp, error, { isFetching }] = useFetch(adminApi.getIrConsentsData, (data) => {
    setConsents(data.consents);
  });

  if (error) {
    logError(error.message, "EmailsPage");
  }

  const handleConfirmedDeleteConsent = useCallback(
    async (consent: UserConsent) => {
      setIsUpdating(true);
      try {
        const response = await adminApi.deleteIrConsent(consent);
        if (response.success) {
          setConsents(consents.filter((c) => c.name !== consent.name));
        } else {
          sendNotificationError("Failed to delete consent");
        }
      } catch (error) {
        logError(error, "ConsentsContext");
        sendNotificationError("Failed to delete consent");
      } finally {
        setIsUpdating(false);
      }
      setConfirmDialogData(undefined);
    },
    [consents, setConfirmDialogData, sendNotificationError]
  );

  const editConsent = useCallback(
    async (consent: UserConsent) => {
      setIsUpdating(true);
      try {
        const response = await adminApi.getIrConsentDetails(consent.name);
        if (response.success) {
          setEditableConsent({
            ...consent,
            ...response.data,
            isNew: false,
            editedContent: undefined,
          });
        } else {
          sendNotificationError("Failed to load consent details");
        }
      } catch (error) {
        logError(error, "ConsentsContext");
        sendNotificationError("Failed to load consent details");
      } finally {
        setIsUpdating(false);
      }
    },
    [sendNotificationError]
  );

  const createConsentContract = (consent: ConsentEditable) => {
    const consentContract: UserConsentContract = {
      name: consent.name,
      categoryId: consent.categoryId,
      activeFrom: consent.activeFrom,
      lastModified: consent.lastModified,
    };

    if (consent.editedContent) {
      consentContract.contentEncoded = stringToBase64(consent.editedContent);
    }

    return consentContract;
  };

  const handleConsentSave = useCallback(
    async (updateVersion: boolean) => {
      if (editableConsent) {
        setSaveState("Saving");
        setIsUpdating(true);
        try {
          const consentContract = createConsentContract(editableConsent);
          const request = editableConsent.isNew
            ? adminApi.createIrConsent(consentContract)
            : adminApi.updateIrConsent(consentContract, updateVersion);

          const response = await request;

          if (response.success) {
            sendNotification("Consent saved");
            if (editableConsent.isNew) {
              setConsents([...consents, response.data]);
            } else {
              setConsents(consents.map((c) => (c.name === editableConsent.name ? response.data : c)));
            }

            setEditableConsent((prev) => ({
              ...prev,
              ...response.data,
              isNew: false,
              editedContent: undefined,
            }));

            setSaveState("Saved");
          } else {
            sendNotificationError("Failed to save consent");
            setSaveState("Ready");
          }
        } catch (error) {
          logError(error, "ConsentsContext");
          sendNotificationError("Failed to save consent");
          setSaveState("Ready");
        } finally {
          setIsUpdating(false);
        }
      }
    },
    [consents, editableConsent, sendNotification, sendNotificationError]
  );

  const categories = consentsResp ? consentsResp.categories : [];
  const consentPreviewLogoUrl = consentsResp ? consentsResp.companyLogoUrl || branding.logoMarkUrl : undefined;
  const isLoading = isFetching || isUpdating;
  const isSaveDisabled =
    isLoading ||
    saveState !== "Ready" ||
    editableConsent?.activeFrom === null ||
    !editableConsent?.editedContent ||
    editableConsent?.editedContent === editableConsent?.content;

  return (
    <ConsentsContext.Provider
      value={{
        title: clientTitle,
        categories,
        isLoading,
        consents,
        canEdit,
        handleNewConsent: () => setEditableConsent({ name: "", isNew: true, editedContent: undefined }),
        handleEditConsent: (consent) => {
          if (isLoading) {
            return;
          }
          editConsent(consent);
        },
        editableConsent,
        updateEditableConsent: (consentUpdate) => {
          setEditableConsent((prev) => (prev ? { ...prev, ...consentUpdate } : undefined));
          setSaveState("Ready");
        },
        removeEditableConsent: () => {
          setEditableConsent(undefined);
          setSaveState("Ready");
        },
        handleConsentDelete: (consent) => {
          if (isLoading) {
            return;
          }

          setConfirmDialogData({
            title: "Delete this consent?",
            description: (
              <Typography>
                Are you sure to want delete <b>{consent.name}</b> ?
              </Typography>
            ),
            ids: consent,
            confirmCallback: () => handleConfirmedDeleteConsent(consent),
            confirmButtonColor: "error",
            confirmButtonLabel: "Delete",
            icon: <DeleteDocumentIcon />,
          });
        },
        showConsentPreview: () => setShowPreview(true),
        handleConsentSave,
        saveState,
        setSaveState,
        isSaveDisabled,
      }}
    >
      {error && <DataLoadingFailed title="Loading user consents failed" />}
      {!error && children}
      {showPreview && editableConsent && editableConsent.editedContent && (
        <ConsentPreviewDialog
          open={!!showPreview}
          onClose={() => setShowPreview(false)}
          clientTitle={clientTitle}
          logoUrl={consentPreviewLogoUrl}
          consentName={editableConsent.name}
          consentContent={editableConsent.editedContent}
        />
      )}
      <ConfirmOperationDialog
        open={isOpenConfirmationDialog}
        onClose={closeConfirmationDialog}
        onConfirm={() => confirmDialogData?.confirmCallback?.(confirmDialogData.ids)}
        data={confirmDialogData}
        isLoading={isLoading}
      />
    </ConsentsContext.Provider>
  );
};

export const useConsentsContext = () => {
  const consentsContext = useContext(ConsentsContext);
  return defined(consentsContext);
};
