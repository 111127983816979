import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { withErrorHandling } from "../../../../../shared/api/axiosHelper";
import DialogCloseButton from "../../../../../shared/components/DialogeCloseButton";
import { useNotificationContext } from "../../../../../shared/contexts/NotificationContext";
import DocumentSuccessIcon from "../../../../../shared/icons/DocumentSuccess";
import approvalsMockApi, { UpdateApprovalStatusRequest } from "../../../../api/mock/approvalsMockApi";
import { ApprovalRequest } from "../../../../api/mock/approvalsTypes";
import { useUserContext } from "../../../../context/UserContext";

interface Props {
  requests: ApprovalRequest[];
  open: boolean;
  onClose: () => void;
  onConfirmed: () => void;
}

const updateApprovalStatus = withErrorHandling(approvalsMockApi.updateApprovalStatus);

const ApprovalConfirmationDialog = ({ requests, open, onClose, onConfirmed }: Props) => {
  const { userName } = useUserContext();
  const { sendNotification, sendNotificationError } = useNotificationContext();
  const [loading, setLoading] = useState(false);

  const single = requests.length === 1;
  const title = single ? "Approve request?" : `Approve ${requests.length} requests?`;

  const handleApproveClick = async () => {
    setLoading(true);
    const payload = requests
      .map(({ id, currentPendingStepId }) => ({
        approvalId: id,
        approvalStepId: currentPendingStepId,
        status: "Approved",
      }))
      .filter((update) => Boolean(update.approvalStepId)) as UpdateApprovalStatusRequest[];

    const [, error] = await updateApprovalStatus(userName, payload);
    setLoading(false);

    if (error) {
      sendNotificationError(single ? "Failed to approve the request" : "Failed to approve the requests");
      return;
    }

    onClose();
    sendNotification(single ? "The request was approved" : `${requests.length} requests were approved`);
    onConfirmed();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>Approve</DialogTitle>
      <DialogCloseButton onClick={onClose} />

      <DialogContent>
        <Stack alignItems="center" spacing={2}>
          <DocumentSuccessIcon sx={{ width: 80, height: 80 }} />
          <Stack alignItems="center" spacing={0.5}>
            <Typography variant="h6">{title}</Typography>
            <Typography color="text.secondary" textAlign="center">
              The changes will be applied to the system immediately after approval.
            </Typography>
          </Stack>
        </Stack>
      </DialogContent>

      <DialogActions sx={{ py: 2, px: 3, columnGap: 1 }}>
        <Button variant="text" color="secondary" autoFocus onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton variant="contained" loading={loading} onClick={handleApproveClick}>
          Approve
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default ApprovalConfirmationDialog;
