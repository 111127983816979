import { useState } from "react";
import { Divider, Stack } from "@mui/material";
import deepEqual from "fast-deep-equal";
import DataLoadingFailed from "../../../../../shared/components/DataLoadingFailed";
import InlineLoader from "../../../../../shared/components/inlineLoader/InlineLoader";
import useFetch from "../../../../../shared/hooks/useFetch";
import { logError } from "../../../../../shared/logging";
import adminApi from "../../../../api/adminApi";
import { useClientContext } from "../../../../context/ClientContext";
import InvitationEmailEdit, { InvitationEmailSettings } from "./InvitationEmailEdit";
import SendTestInvitationEmail from "./SendTestInvitationEmail";

const InvitationEmailSection = () => {
  const [, portalSettingsError, { isFetching }] = useFetch(adminApi.getInvestorPortalSettings, (data) => {
    const initialSettings = {
      subject: data.settings.contactInvitationEmailSubject,
      messageText: data.settings.contactInvitationEmailMessageText,
    };

    setSavedSettings(initialSettings);
    setCurrentSettings(initialSettings);
  });

  const { hasPermissions } = useClientContext();

  const [savedSettings, setSavedSettings] = useState<InvitationEmailSettings>();
  const [currentSettings, setCurrentSettings] = useState<InvitationEmailSettings>();

  const handleSettingsChange = (settings: InvitationEmailSettings) => setCurrentSettings(settings);
  const handleSettingsSave = () => setSavedSettings(currentSettings);
  const handleSettingsReset = () => setCurrentSettings(savedSettings);

  if (portalSettingsError) {
    logError(portalSettingsError, "getInvestorPortalSettings");
    return <DataLoadingFailed title="Failed to load settings" />;
  }

  if (isFetching || !currentSettings) {
    return <InlineLoader />;
  }

  const canEditSettings = hasPermissions(["ManageInvestorPortalSettings"]);
  const isDirty = !deepEqual(savedSettings, currentSettings);

  return (
    <Stack spacing={3} px={1} pb={4}>
      <InvitationEmailEdit
        settings={currentSettings}
        disableSave={!isDirty || !canEditSettings}
        onChange={handleSettingsChange}
        onSave={handleSettingsSave}
        onReset={handleSettingsReset}
      />
      {canEditSettings && (
        <>
          <Divider />
          <SendTestInvitationEmail disabled={isDirty} />
        </>
      )}
    </Stack>
  );
};

export default InvitationEmailSection;
