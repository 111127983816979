import CircleIcon from "@mui/icons-material/Circle";
import { Box, Theme } from "@mui/material";
import { DocumentCollectionPublishStatus } from "../../../api/adminApi";

interface Props {
  status: DocumentCollectionPublishStatus;
}

const getStatusColorAndText = (status: DocumentCollectionPublishStatus) => {
  switch (status) {
    case "Live": {
      return { statusText: "Live", statusColor: (theme: Theme) => theme.palette.success.main };
    }
    case "PartiallyLive": {
      return { statusText: "Live (Partially)", statusColor: (theme: Theme) => theme.palette.success.main };
    }
    default: {
      return { statusText: "Preview", statusColor: (theme: Theme) => theme.palette.info.main };
    }
  }
};

const DocumentCollectionStatus = ({ status }: Props) => {
  const { statusText, statusColor } = getStatusColorAndText(status);

  return (
    <Box display="flex" alignItems="center">
      <CircleIcon
        sx={(theme) => ({
          color: statusColor(theme),
          mr: theme.spacing(1),
          fontSize: "0.5rem",
        })}
      />
      {statusText}
    </Box>
  );
};

export default DocumentCollectionStatus;
