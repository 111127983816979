import DownloadIcon from "@mui/icons-material/SaveAltRounded";
import { Button, Divider, Stack, Typography } from "@mui/material";
import { FileInfo } from "../../../../api/adminApi";
import { useFilesContext } from "../FilesContext";
import { getMultipleFilesInformationAttributes } from "../fileDetailsHelper";
import FileAttributesSection from "./FileAttributesSection";

const SectionDivider = () => <Divider sx={{ borderStyle: "dotted" }} />;

interface Props {
  files: FileInfo[];
}

const MultipleFilesDetailsPanel = ({ files }: Props) => {
  const { onDownloadMultipleFiles } = useFilesContext();

  return (
    <>
      <Stack spacing={2} width="100%">
        <Typography variant="subtitle1">{`${files.length} files selected`}</Typography>
        <Button
          variant="outlined"
          color="secondary"
          fullWidth
          startIcon={<DownloadIcon />}
          onClick={() => onDownloadMultipleFiles(files.map((file) => file.catalogueId))}
        >
          {`Download ${files.length} files`}
        </Button>
      </Stack>
      <SectionDivider />
      <FileAttributesSection title="Information" attributes={getMultipleFilesInformationAttributes(files)} />
    </>
  );
};

export default MultipleFilesDetailsPanel;
