import { useCallback } from "react";
import DataLoadingFailed from "../../../../../../shared/components/DataLoadingFailed";
import InlineLoader from "../../../../../../shared/components/inlineLoader/InlineLoader";
import useFetch from "../../../../../../shared/hooks/useFetch";
import { logError } from "../../../../../../shared/logging";
import adminApi, { Category } from "../../../../../api/adminApi";
import ContactCommunicationMatrix from "./ContactCommunicationMatrix";

interface Props {
  contactId: string;
  categories: Category[];
}

const ContactCommunicationMatrixTab = ({ contactId, categories }: Props) => {
  const fetchInvestors = useCallback(
    () => adminApi.searchInvestors({ fieldIds: [], includeContacts: true, includeFunds: true }),
    []
  );
  const fetchContactDetails = useCallback(() => adminApi.getContactDetails(contactId), [contactId]);

  const [investorsResp, investorsFetchError] = useFetch(fetchInvestors);
  const [contactDetails, contactDetailsFetchError] = useFetch(fetchContactDetails);

  if (investorsFetchError) {
    logError(investorsFetchError, "ContactCommunicationMatrixTab");
    return <DataLoadingFailed bgColor="none" />;
  }

  if (contactDetailsFetchError) {
    logError(contactDetailsFetchError, "ContactCommunicationMatrixTab");
    return <DataLoadingFailed bgColor="none" />;
  }

  if (investorsResp === undefined || contactDetails === undefined) {
    return <InlineLoader />;
  }

  return (
    <ContactCommunicationMatrix
      categories={categories}
      contactDetails={contactDetails}
      investors={investorsResp.items}
    />
  );
};

export default ContactCommunicationMatrixTab;
