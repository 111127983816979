import CheckIcon from "@mui/icons-material/CheckRounded";
import LanguageIcon from "@mui/icons-material/LanguageRounded";
import { FundraisingStatus } from "../../../../../shared/api/fundraisingTypes";
import LanguageOffIcon from "../../../../icons/LanguageOffIcon";
import VisibleIcon from "../../../../icons/VisibleIcon";
import { FundraisingStatusTransition } from "./fundraisingsPageTypes";

const statusTransitions: FundraisingStatusTransition[] = [
  {
    from: "Draft",
    to: "Preview",
    requiresAdditionalConfirmation: false,
    label: "Publish to Preview",
    dialogActionLabel: "Publish",
    detailLines: [
      "You are about to publish the fundraising page in Preview mode.",
      "The page won't be available to investors yet, but you can inspect the portal as an investor contact.",
    ],
    Icon: VisibleIcon,
  },
  {
    from: "Preview",
    to: "Live",
    requiresAdditionalConfirmation: true,
    label: "Publish to Live",
    dialogActionLabel: "Publish",
    detailLines: [
      "You are about to make fundraising Live and available for investors.",
      "Note, that related contacts may receive invitation emails after this action, if they have not been registered on the portal yet.",
    ],
    Icon: LanguageIcon,
  },
  {
    from: "Live",
    to: "Preview",
    requiresAdditionalConfirmation: false,
    label: "Unpublish to Preview",
    dialogActionLabel: "Unpublish",
    detailLines: ["The content will be returned in “Preview” and will not be available for viewing by users."],
    Icon: LanguageOffIcon,
  },
  {
    from: "Live",
    to: "Completed",
    requiresAdditionalConfirmation: true,
    label: "Mark as Completed",
    dialogActionLabel: "Complete",
    detailLines: [
      "You are about to finalize and complete the fundraising.",
      "This action will close the fundraising and users will no longer have access to it.",
    ],
    Icon: CheckIcon,
  },
  {
    from: "Completed",
    to: "Live",
    requiresAdditionalConfirmation: true,
    label: "Publish to Live",
    dialogActionLabel: "Publish",
    detailLines: [
      "You are about to make fundraising Live and available for investors.",
      "Note, that related contacts may receive invitation emails after this action, if they have not been registered on the portal yet.",
    ],
    Icon: LanguageIcon,
  },
];

export const getAvailableStatusTransitions = (from: FundraisingStatus): FundraisingStatusTransition[] =>
  statusTransitions.filter((transition) => transition.from === from);
