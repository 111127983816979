import AddIcon from "@mui/icons-material/Add";
import {
  Button,
  Divider,
  FilledInputProps,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { SelectInputProps } from "@mui/material/Select/SelectInput";
import CloseIconButton from "../../../../../../../shared/components/CloseIconButton";
import { formatDate, formatDateShort } from "../../../../../../../shared/utilities/dateUtils";
import { NumberFormatFactor } from "../../../../../../../shared/utilities/formatters";
import { DateFormat } from "../../../../../../api/types/timeSeriesTypes";
import { useClientContext } from "../../../../../../context/ClientContext";
import { FieldEditForm } from "./timeSeriesFieldEditForm";

const supportedDecimalPrecisions = [0, 1, 2, 3, 4, 5];

const getDecimalPrecisionLabel = (precision: number) => (precision === 0 ? "1" : `1.${"0".repeat(precision)}`);

const supportedNumberFormatFactors: (NumberFormatFactor | "")[] = ["", "Thousands", "Millions", "Billions"];

const getNumberFormatFactorLabel = (factor: NumberFormatFactor | "") => {
  switch (factor) {
    case "":
      return "None";
    case "Thousands":
      return "Thousands";
    case "Millions":
      return "Millions";
    case "Billions":
      return "Billions";
    default:
      return factor;
  }
};

const supportedDateFormats: DateFormat[] = ["Short", "Long"];

const getDateFormatLabel = (dateFormat: DateFormat) => {
  const exampleDate = new Date(new Date().getFullYear(), 3, 29);
  switch (dateFormat) {
    case "Short":
      return `Short: (${formatDateShort(exampleDate)})`;
    case "Long":
      return `Long: (${formatDate(exampleDate)})`;
    default:
      return dateFormat;
  }
};

interface Props {
  fieldEditForm: FieldEditForm;
  onFormChange: (newForm: FieldEditForm) => void;
}

const TimeSeriesFieldConfigEditor = ({ fieldEditForm, onFormChange }: Props) => {
  const { dictionaries } = useClientContext();

  const handlePrecisionChange: SelectInputProps<number>["onChange"] = (e) => {
    const precision = Number(e.target.value);
    onFormChange({ ...fieldEditForm, precision });
  };

  const handleNumberFormatFactorChange: SelectInputProps<NumberFormatFactor>["onChange"] = (e) => {
    const factor = e.target.value ? (e.target.value as NumberFormatFactor) : undefined;
    onFormChange({ ...fieldEditForm, factor });
  };

  const handleDateFormatChange: SelectInputProps<DateFormat>["onChange"] = (e) => {
    const dateFormat = e.target.value as DateFormat;
    onFormChange({ ...fieldEditForm, dateFormat });
  };

  const handleCurrencyCodeChange: SelectInputProps<string>["onChange"] = (e) => {
    const currencyCode = e.target.value;
    onFormChange({ ...fieldEditForm, currencyCode });
  };

  const handleEditValueOption =
    (index: number): FilledInputProps["onChange"] =>
    (e) => {
      const newOptionValue = e.target.value;
      const newValueOptions = fieldEditForm.valueOptions ? [...fieldEditForm.valueOptions] : [];
      newValueOptions[index] = newOptionValue;
      onFormChange({ ...fieldEditForm, valueOptions: newValueOptions });
    };

  const handleAddValueOption = () => {
    const newValueOptions = fieldEditForm.valueOptions ? [...fieldEditForm.valueOptions, ""] : [""];
    onFormChange({ ...fieldEditForm, valueOptions: newValueOptions });
  };

  const handleRemoveValueOption = (index: number) => () => {
    const newValueOptions = fieldEditForm.valueOptions ? [...fieldEditForm.valueOptions] : [];
    newValueOptions.splice(index, 1);
    onFormChange({ ...fieldEditForm, valueOptions: newValueOptions });
  };

  return (
    <>
      {["Money", "Number", "Percent", "Date"].includes(fieldEditForm.fieldType) && (
        <>
          <Divider flexItem sx={{ borderStyle: "dashed" }} />
          <Typography variant="subtitle2">Formatting</Typography>
          {["Money", "Number", "Percent"].includes(fieldEditForm.fieldType) && (
            <Stack direction="row" alignItems="center" spacing={2}>
              <Typography color="textSecondary" sx={{ width: "6rem" }}>
                Precision
              </Typography>
              <Select
                fullWidth
                variant="outlined"
                value={fieldEditForm.precision ?? 1}
                onChange={handlePrecisionChange}
                renderValue={(precision) => getDecimalPrecisionLabel(precision)}
              >
                {supportedDecimalPrecisions.map((precision) => (
                  <MenuItem key={`${precision}`} value={precision}>
                    <ListItemText>{getDecimalPrecisionLabel(precision)}</ListItemText>
                  </MenuItem>
                ))}
              </Select>
            </Stack>
          )}
          {["Money", "Number"].includes(fieldEditForm.fieldType) && (
            <Stack direction="row" alignItems="center" spacing={2}>
              <Typography color="textSecondary" sx={{ width: "6rem" }}>
                Factor
              </Typography>
              <Select
                fullWidth
                variant="outlined"
                value={fieldEditForm.factor ?? ""}
                onChange={handleNumberFormatFactorChange}
                renderValue={(factor) => getNumberFormatFactorLabel(factor)}
                displayEmpty
              >
                {supportedNumberFormatFactors.map((factorOrEmpty) => (
                  <MenuItem key={`${factorOrEmpty}`} value={factorOrEmpty}>
                    <ListItemText>{getNumberFormatFactorLabel(factorOrEmpty)}</ListItemText>
                  </MenuItem>
                ))}
              </Select>
            </Stack>
          )}
          {fieldEditForm.fieldType === "Money" && (
            <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
              <Typography color="textSecondary" sx={{ width: "6rem" }}>
                Currency
              </Typography>
              <Select
                fullWidth
                variant="outlined"
                value={fieldEditForm.currencyCode ?? "USD"}
                onChange={handleCurrencyCodeChange}
                renderValue={(currencyCode) => currencyCode}
              >
                {dictionaries.currencies.map(({ code }) => (
                  <MenuItem key={code} value={code}>
                    <ListItemText>{code}</ListItemText>
                  </MenuItem>
                ))}
              </Select>
            </Stack>
          )}
          {fieldEditForm.fieldType === "Date" && (
            <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
              <Typography color="textSecondary" sx={{ width: "6rem" }}>
                Format
              </Typography>
              <Select
                fullWidth
                variant="outlined"
                value={fieldEditForm.dateFormat ?? "Short"}
                onChange={handleDateFormatChange}
                renderValue={(dateFormat) => getDateFormatLabel(dateFormat)}
              >
                {supportedDateFormats.map((dateFormat) => (
                  <MenuItem key={dateFormat} value={dateFormat}>
                    <ListItemText>{getDateFormatLabel(dateFormat)}</ListItemText>
                  </MenuItem>
                ))}
              </Select>
            </Stack>
          )}
        </>
      )}

      {["Select"].includes(fieldEditForm.fieldType) && (
        <>
          <Divider flexItem sx={{ borderStyle: "dashed" }} />
          <Typography variant="subtitle2">Options</Typography>
          <Stack spacing={1} mt={2} alignItems="flex-start">
            {(fieldEditForm.valueOptions ?? []).map((option, index) => (
              <Stack width="100%" direction="row" spacing={1} key={index}>
                <TextField value={option} fullWidth onChange={handleEditValueOption(index)} />
                <CloseIconButton onClick={handleRemoveValueOption(index)} />
              </Stack>
            ))}
            {fieldEditForm.errors.valueOptions && (
              <Typography color="error">{fieldEditForm.errors.valueOptions}</Typography>
            )}
            <Button variant="text" color="secondary" onClick={handleAddValueOption} startIcon={<AddIcon />}>
              Add Option
            </Button>
          </Stack>
        </>
      )}
    </>
  );
};

export default TimeSeriesFieldConfigEditor;
