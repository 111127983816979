import {
  DocumentEventCategoryType,
  DocumentViews,
  SearchDocumentViewsRequest,
} from "../../../../api/types/documentActivityTypes";
import { FilterState } from "../../../common/filters/filterState";
import {
  DateFilter,
  MultiSelectFilter,
  NumberFilter,
  TableFilter,
  TableFilterDefinition,
  TextFilter,
} from "../../../common/filters/filterTypes";

export const filterDefinitions: TableFilterDefinition<DocumentViews>[] = [
  {
    id: "userName",
    name: "Contact Name",
    type: "text",
    operatorOptions: ["contains"],
  },
  {
    id: "userEmail",
    name: "Contact Email",
    type: "text",
    operatorOptions: ["contains"],
  },
  {
    id: "fileName",
    name: "Document Name",
    type: "text",
    operatorOptions: ["contains"],
  },
  {
    id: "investorIds",
    name: "Investor",
    type: "multi_select",
    operatorOptions: ["one_of"],
    asyncOptions: true,
  },
  {
    id: "fundIds",
    name: "Fund",
    type: "multi_select",
    operatorOptions: ["one_of"],
    asyncOptions: true,
  },
  {
    id: "categoryType",
    name: "Category Type",
    type: "multi_select",
    operatorOptions: ["one_of"],
    predefinedOptions: [
      { value: "InvestorPortal", label: "Investor Portal" },
      { value: "Fundraising", label: "Fundraising" },
    ],
  },
  {
    id: "categoryIds",
    name: "Category",
    type: "multi_select",
    operatorOptions: ["one_of"],
    asyncOptions: true,
  },
  {
    id: "viewedAt",
    name: "Viewed at",
    type: "date",
    operatorOptions: ["range"],
  },
  {
    id: "completionPercentage",
    name: "Total Completion %",
    type: "number",
    operatorOptions: ["between", "greater_than", "less_than"],
  },
];

const getUserNameFilterValue = (visibleFilters: TableFilter<DocumentViews>[]) => {
  const filter = visibleFilters.find((f) => f.id === "userName") as TextFilter<DocumentViews> | undefined;
  return filter?.value?.operator === "contains" ? filter.value.text : undefined;
};

const getUserEmailFilterValue = (visibleFilters: TableFilter<DocumentViews>[]) => {
  const filter = visibleFilters.find((f) => f.id === "userEmail") as TextFilter<DocumentViews> | undefined;
  return filter?.value?.operator === "contains" ? filter.value.text : undefined;
};

const getFileNameFilterValue = (visibleFilters: TableFilter<DocumentViews>[]) => {
  const filter = visibleFilters.find((f) => f.id === "fileName") as TextFilter<DocumentViews> | undefined;
  return filter?.value?.operator === "contains" ? filter.value.text : undefined;
};

const getCategoryTypeFilterValue = (visibleFilters: TableFilter<DocumentViews>[]) => {
  const filter = visibleFilters.find((f) => f.id === "categoryType") as MultiSelectFilter<DocumentViews> | undefined;
  const selectedValues =
    filter?.value?.operator === "one_of" ? (filter.value.selectedValues as DocumentEventCategoryType[]) : undefined;
  return selectedValues?.length ? selectedValues : undefined;
};

const getCategoryIdsFilterValue = (visibleFilters: TableFilter<DocumentViews>[]) => {
  const filter = visibleFilters.find((f) => f.id === "categoryIds") as MultiSelectFilter<DocumentViews> | undefined;
  const selectedValues = filter?.value?.operator === "one_of" ? filter.value.selectedValues : undefined;
  return selectedValues?.length ? selectedValues : undefined;
};

const getFundIdsFilterValue = (visibleFilters: TableFilter<DocumentViews>[]) => {
  const filter = visibleFilters.find((f) => f.id === "fundIds") as MultiSelectFilter<DocumentViews> | undefined;
  const selectedValues = filter?.value?.operator === "one_of" ? filter.value.selectedValues : undefined;
  return selectedValues?.length ? selectedValues : undefined;
};

const getInvestorIdsFilterValue = (visibleFilters: TableFilter<DocumentViews>[]) => {
  const filter = visibleFilters.find((f) => f.id === "investorIds") as MultiSelectFilter<DocumentViews> | undefined;
  const selectedValues = filter?.value?.operator === "one_of" ? filter.value.selectedValues : undefined;
  return selectedValues?.length ? selectedValues : undefined;
};

const getViewedAtFilterValues = (visibleFilters: TableFilter<DocumentViews>[]) => {
  const filter = visibleFilters.find((f) => f.id === "viewedAt") as DateFilter<DocumentViews> | undefined;
  if (filter?.value?.operator !== "range") {
    return {};
  }

  const { range } = filter.value;
  return {
    viewedFrom: range?.[0] || undefined,
    viewedTo: range?.[1] || undefined,
  };
};

const getCompletionPercentageFilterValues = (visibleFilters: TableFilter<DocumentViews>[]) => {
  const filter = visibleFilters.find((f) => f.id === "completionPercentage") as NumberFilter<DocumentViews> | undefined;

  let completionPercentageFrom: number | undefined = undefined;
  let completionPercentageTo: number | undefined = undefined;

  if (filter?.value?.operator === "between") {
    completionPercentageFrom = filter.value.number;
    completionPercentageTo = filter.value.secondNumber;
  } else if (filter?.value?.operator === "greater_than") {
    completionPercentageFrom = filter.value.number;
  } else if (filter?.value?.operator === "less_than") {
    completionPercentageTo = filter.value.number;
  }

  completionPercentageFrom = toValidIntegerPercentage(completionPercentageFrom);
  completionPercentageTo = toValidIntegerPercentage(completionPercentageTo);

  if (
    completionPercentageFrom !== undefined &&
    completionPercentageTo !== undefined &&
    completionPercentageFrom > completionPercentageTo
  ) {
    completionPercentageFrom = completionPercentageTo;
  }

  return { completionPercentageFrom, completionPercentageTo };
};

const toValidIntegerPercentage = (number: number | undefined) =>
  number !== undefined && !Number.isNaN(number) ? Math.min(Math.max(Math.round(number), 0), 100) : undefined;

export const getRequestFilters = (filterState: FilterState<DocumentViews>): Partial<SearchDocumentViewsRequest> => {
  const { viewedFrom, viewedTo } = getViewedAtFilterValues(filterState.visibleFilters);

  const { completionPercentageFrom, completionPercentageTo } = getCompletionPercentageFilterValues(
    filterState.visibleFilters
  );

  return {
    userName: getUserNameFilterValue(filterState.visibleFilters),
    userEmail: getUserEmailFilterValue(filterState.visibleFilters),
    fileName: getFileNameFilterValue(filterState.visibleFilters),
    documentEventCategoryTypes: getCategoryTypeFilterValue(filterState.visibleFilters),
    categoryIds: getCategoryIdsFilterValue(filterState.visibleFilters),
    fundIds: getFundIdsFilterValue(filterState.visibleFilters),
    investorIds: getInvestorIdsFilterValue(filterState.visibleFilters),
    viewedFrom,
    viewedTo,
    completionPercentageFrom,
    completionPercentageTo,
  };
};
