import { Autocomplete, ListItem, TextField, Typography } from "@mui/material";
import { InvoiceEntry } from "../../../../../api/adminApi";
import InvoiceControlWithWarning from "./InvoiceControlWithWarning";

interface Props {
  value?: InvoiceEntry;
  options: InvoiceEntry[];
  onChange: (entry?: InvoiceEntry) => void;
  placeholder?: string;
  label?: string;
  validationError?: string;
  disabled?: boolean;
}

const InvoiceAutocomplete = ({ value, options, onChange, placeholder, validationError, label, disabled }: Props) => {
  return (
    <InvoiceControlWithWarning validationError={validationError}>
      <Autocomplete
        fullWidth
        options={options}
        value={value || null}
        onChange={(_, value) => onChange(value || undefined)}
        getOptionLabel={({ caption }) => caption}
        isOptionEqualToValue={(option, value) => option.id === value?.id}
        forcePopupIcon={!validationError}
        renderInput={(inputProps) => (
          <TextField {...inputProps} placeholder={placeholder} label={label} error={!!validationError} />
        )}
        renderOption={(props, option) => (
          <ListItem {...props} key={`${option.caption}_${option.id}`}>
            <Typography>{option.caption}</Typography>
          </ListItem>
        )}
        disabled={disabled}
      />
    </InvoiceControlWithWarning>
  );
};

export default InvoiceAutocomplete;
