import { Divider, Stack, Typography } from "@mui/material";
import { parseISO } from "date-fns";
import { numberComparerDescBy } from "../../../../../../shared/utilities/arrayHelper";
import { convertISODate } from "../../../../../../shared/utilities/dateUtils";
import { TimeSeriesField, TimeSeriesValue } from "../../../../../api/types/timeSeriesTypes";
import TimeSeriesFieldValueText from "./TimeSeriesFieldValueText";

interface Props {
  field: TimeSeriesField;
  fieldValues: TimeSeriesValue[];
}

const TimeSeriesFieldDetails = ({ field, fieldValues }: Props) => {
  return (
    <Stack
      overflow="auto"
      height="100%"
      py={2}
      px={2.5}
      sx={(t) => ({ width: t.spacing(36) })}
      spacing={2}
      alignItems="normal"
      justifyContent="normal"
    >
      <Typography variant="subtitle1">{field.name}</Typography>
      <Divider />
      <Stack spacing={2}>
        {fieldValues
          .filter((dv) => dv.value !== undefined)
          .sort(numberComparerDescBy((dv) => parseISO(dv.date).valueOf()))
          .map(({ date, value }, index) => (
            <Stack key={index} direction="row" alignItems="center">
              <Typography variant="caption" color="text.secondary" sx={{ width: "6rem" }}>
                {convertISODate(date)}
              </Typography>
              <TimeSeriesFieldValueText fieldType={field.type} fieldConfig={field.configuration} fieldValue={value} />
            </Stack>
          ))}
      </Stack>
    </Stack>
  );
};

export default TimeSeriesFieldDetails;
