import AddIcon from "@mui/icons-material/AddRounded";
import DeleteIcon from "@mui/icons-material/DeleteOutlineRounded";
import EditIcon from "@mui/icons-material/EditOutlined";
import {
  Card,
  CardContent,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import BadgeDetached from "../../../../../shared/components/BadgeDetached";
import { stringComparerBy } from "../../../../../shared/utilities/arrayHelper";
import { DataCatalogueEntityType, FileTag } from "../../../../api/adminApi";
import { getEntityTitle } from "../../../common/files/fileDetailsHelper";

interface HeadRowProps {
  readOnly: boolean;
  entityType: DataCatalogueEntityType;
  count: number;
  onAddClick: () => void;
}

const HeadRow = ({ readOnly, entityType, count, onAddClick }: HeadRowProps) => (
  <TableRow>
    <TableCell colSpan={2}>
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography variant="subtitle2">{getEntityTitle(entityType)}</Typography>
        {count > 0 && <BadgeDetached color="secondary" badgeContent={count} />}
        {!readOnly && (
          <IconButton color="secondary" onClick={onAddClick}>
            <AddIcon />
          </IconButton>
        )}
      </Stack>
    </TableCell>
  </TableRow>
);

interface BodyRowProps {
  readOnly: boolean;
  tag: FileTag;
  onEditClick: (tag: FileTag) => void;
  onDeleteClick: (tag: FileTag) => void;
}

const BodyRow = ({ readOnly, tag, onEditClick, onDeleteClick }: BodyRowProps) => (
  <TableRow
    hover
    sx={{
      "&.MuiTableRow-root": {
        "&:last-child": {
          td: {
            borderBottom: 0,
          },
        },
      },
    }}
  >
    <TableCell>
      <Typography>{tag.name}</Typography>
    </TableCell>
    {!readOnly && (
      <TableCell className="table-row-actions" width="96">
        <Stack direction="row" spacing={1}>
          <Tooltip title="Edit" arrow>
            <IconButton onClick={() => onEditClick(tag)}>
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete" arrow>
            <IconButton color="error" onClick={() => onDeleteClick(tag)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Stack>
      </TableCell>
    )}
  </TableRow>
);

interface Props {
  readOnly: boolean;
  entityType: DataCatalogueEntityType;
  tags: FileTag[];
  onAddClick: () => void;
  onEditClick: (tag: FileTag) => void;
  onDeleteClick: (tag: FileTag) => void;
}

const FileTagGroup = ({ readOnly, entityType, tags, onAddClick, onEditClick, onDeleteClick }: Props) => {
  return (
    <Card variant="outlined">
      <CardContent sx={{ px: 0, pt: 0, "&.MuiCardContent-root": { "&:last-child": { pb: 0 } } }}>
        <TableContainer>
          <Table
            sx={{
              ".MuiTableRow-root": {
                "&:hover": {
                  ".MuiTableCell-root.table-row-actions": {
                    button: { visibility: readOnly ? "hidden" : "visible" },
                  },
                },
              },
              ".MuiTableCell-root.table-row-actions": {
                button: { visibility: "hidden" },
              },
            }}
          >
            <TableHead>
              <HeadRow readOnly={readOnly} entityType={entityType} count={tags.length} onAddClick={onAddClick} />
            </TableHead>
            <TableBody>
              {tags.sort(stringComparerBy((t) => t.name)).map((tag) => (
                <BodyRow
                  key={tag.catalogueId}
                  readOnly={readOnly}
                  tag={tag}
                  onEditClick={onEditClick}
                  onDeleteClick={onDeleteClick}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
};

export default FileTagGroup;
