import { PropsWithChildren, createContext, useContext } from "react";
import { defined } from "../../../../../shared/utilities/typeHelper";
import { FundraisingStatusTransition } from "./fundraisingsPageTypes";

interface Props {
  hasEditPermissions: boolean;
  onCreate: () => void;
  onRename: (id: string, name: string) => void;
  onStatusChange: (id: string, name: string, statusTransition: FundraisingStatusTransition) => void;
  onDelete: (id: string, name: string) => void;
}

const FundraisingsPageContext = createContext<Props | undefined>(undefined);

export const FundraisingsPageContextProvider = ({
  hasEditPermissions,
  onCreate,
  onRename,
  onStatusChange,
  onDelete,
  children,
}: PropsWithChildren<Props>) => (
  <FundraisingsPageContext.Provider value={{ hasEditPermissions, onCreate, onRename, onStatusChange, onDelete }}>
    {children}
  </FundraisingsPageContext.Provider>
);

export const useFundraisingsPageContext = () =>
  defined(useContext(FundraisingsPageContext), "Attempt to use FundraisingsPageContext without provider");
