import { Box, BoxProps } from "@mui/material";
import { PropsWithChildren } from "react";
import useOverflowDetection from "../hooks/useOverflowDetection";
import useToggleState from "../hooks/useToggleState";
import ShowMoreLessButton from "./ShowMoreLessButton";

interface Props {
  maxHeight: number;
  containerProps?: BoxProps;
  expandButtonText?: string;
  collapseButtonText?: string;
}

const ExpandableBox = ({
  maxHeight,
  containerProps,
  expandButtonText,
  collapseButtonText,
  children,
}: PropsWithChildren<Props>) => {
  const { hasOverflow, containerRef } = useOverflowDetection([maxHeight, children]);
  const [expanded, toggleExpanded] = useToggleState(false);

  const { sx, ...props } = containerProps ?? {};

  return (
    <>
      <Box
        {...props}
        ref={containerRef}
        sx={
          expanded
            ? { ...sx }
            : {
                maxHeight,
                overflow: "hidden",
                maskImage: hasOverflow ? "linear-gradient(0deg, transparent, black 35%)" : "none",
                ...sx,
              }
        }
      >
        {children}
      </Box>

      {hasOverflow && (
        <ShowMoreLessButton
          expanded={expanded}
          onClick={toggleExpanded}
          expandButtonText={expandButtonText}
          collapseButtonText={collapseButtonText}
        />
      )}
    </>
  );
};

export default ExpandableBox;
