import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { Button, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { Investor } from "../../../../../api/adminApi";
import SimpleDialog from "../../../../common/SimpleDialog";
import MultiselectAutocomplete from "../../common/MultiselectAutocomplete";

interface Props {
  open: boolean;
  options: Investor[];
  title: string;
  description: string;
  onClose: () => void;
  onCreateNew: () => void;
  onSelected: (items: Investor[]) => void;
}

const AssignInvestorsDialog = ({ open, options, title, description, onClose, onCreateNew, onSelected }: Props) => {
  const [selectedInvestors, setSelected] = useState<Investor[]>([]);

  const handleSubmit = () => {
    setSelected([]);
    onSelected(selectedInvestors);
  };

  const handleClose = () => {
    setSelected([]);
    onClose();
  };

  return (
    <SimpleDialog
      dialogProps={{ maxWidth: "md", fullWidth: true }}
      title={title}
      open={open}
      onClose={handleClose}
      onSubmit={handleSubmit}
      submitButtonDisabled={selectedInvestors.length === 0}
      submitButtonText="Add Investor(s)"
    >
      <Stack spacing={2}>
        <Typography color="text.secondary">{description}</Typography>
        <MultiselectAutocomplete
          onClose={handleClose}
          options={options}
          values={selectedInvestors}
          onSelected={setSelected}
          getOptionLabel={(option: Investor) => option.title}
          getOptionValue={(option: Investor) => option.id}
          noOptionsText="No investors"
          addNewItem={{
            onClick: onCreateNew,
            element: (
              <Button sx={{ ml: 2, my: 1 }} startIcon={<AddRoundedIcon />} variant="text">
                New Investor
              </Button>
            ),
          }}
        />
      </Stack>
    </SimpleDialog>
  );
};

export default AssignInvestorsDialog;
