import ArrowRightAltRoundedIcon from "@mui/icons-material/ArrowRightAltRounded";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Typography, useTheme } from "@mui/material";
import { Stack } from "@mui/system";
import { useState } from "react";
import TypographyTooltipEllipsis from "../../../../../shared/components/TypographyTooltipEllipsis";
import { defined } from "../../../../../shared/utilities/typeHelper";
import { PortalRole } from "../../../../api/adminApi";
import EmailIcon from "../../../../icons/EmailIcon";
import SimpleDialog from "../../../common/SimpleDialog";
import PortalRoleSelector from "./PortalRoleSelector";
import { ContactPermissionsChange } from "./matrixColumnDefinitions";

interface Props {
  open: boolean;
  changes: ContactPermissionsChange[];
  onSubmit: () => void;
  onClose: () => void;
}

const SendInvitationsConfirmationDialog = ({ open, onSubmit, onClose, changes }: Props) => {
  const changesCount = changes.length;
  return (
    <SimpleDialog
      title={"Invitation Email"}
      submitButtonText={"Confirm"}
      open={open}
      onClose={onClose}
      onSubmit={onSubmit}
    >
      <Stack spacing={2} alignItems={"center"}>
        <EmailIcon sx={{ height: 54, width: 64 }} />
        <Typography variant="h6">You are about to set the Investor Portal Role:</Typography>
        {changesCount === 1 && <SingleContactChange {...defined(changes[0])} />}
        {changesCount > 1 && <MultipleContactChanges changes={changes} />}
      </Stack>
    </SimpleDialog>
  );
};

export default SendInvitationsConfirmationDialog;

const RoleChange = ({ original, modified }: { original?: PortalRole[]; modified: PortalRole[] }) => {
  return (
    <Stack direction={"row"} alignItems={"center"} spacing={0.6}>
      <PortalRoleSelector readonly roles={original} />
      <ArrowRightAltRoundedIcon color="secondary" />
      <PortalRoleSelector readonly roles={modified} />
    </Stack>
  );
};

const SingleContactChange = ({ contact, original, modified }: ContactPermissionsChange) => {
  const theme = useTheme();
  return (
    <Stack spacing={2} alignItems={"center"}>
      <RoleChange original={original?.roles} modified={modified.roles} />
      <Typography color="text.secondary" textAlign="center">
        Upon making this change,
        <b style={{ color: theme.palette.text.primary }}>{` ${contact.name} <${contact.email}>`}</b> may receive an
        invitation email either immediately (if related investors have already published documents on the portal) or at
        a later time when such documents are published.
      </Typography>
    </Stack>
  );
};

const MultipleContactChanges = ({ changes }: { changes: ContactPermissionsChange[] }) => {
  const theme = useTheme();
  const [expanded, setExpanded] = useState<boolean>(false);
  return (
    <Box>
      <Typography color="text.secondary" mb={3}>
        Upon making this change, <b style={{ color: theme.palette.text.primary }}>{changes.length} contacts</b> may
        receive an invitation email either immediately (if related investors have already published documents on the
        portal) or at a later time when such documents are published.
      </Typography>
      <Accordion
        onChange={(_, newExpanded) => setExpanded(newExpanded)}
        expanded={expanded}
        disableGutters
        elevation={0}
        sx={{
          width: "100%",
          border: 1,
          borderColor: "divider",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreRoundedIcon />}
          sx={{
            "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
              transform: "rotate(180deg)",
            },
            borderBottom: expanded ? 1 : 0,
            borderColor: "divider",
          }}
        >
          <Typography variant="subtitle2">Details</Typography>
        </AccordionSummary>
        <AccordionDetails sx={(t) => ({ maxHeight: t.spacing(20), p: 0, overflow: "auto" })}>
          {changes.map((change, index) => (
            <Grid
              columnGap={2}
              container
              alignItems={"center"}
              key={index}
              sx={{
                p: 2,
                "&:not(:last-child)": {
                  borderBottom: 1,
                  borderColor: "divider",
                },
              }}
            >
              <Grid item xs={4}>
                <TypographyTooltipEllipsis text={change.contact.name} />
              </Grid>
              <Grid item xs={4}>
                <Typography color="text.secondary">{change.contact.email}</Typography>
              </Grid>
            </Grid>
          ))}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};
