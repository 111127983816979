import { useState } from "react";
import HubspotLogo from "../../../images/hubspot.png";
import CrmSetupInfo from "./CrmSetupInfo";
import CrmActions from "./CrmActions";
import CrmManagement from "./CrmManagement";
import crmConnectorsApi, { CrmSyncSettings } from "../../../api/crmConnectorsApi";
import InlineLoader from "../../../../shared/components/inlineLoader/InlineLoader";
import useFetch from "../../../../shared/hooks/useFetch";
import TestConnectionInfo, { TestConnectionState } from "./TestConnectionInfo";
import { useNavigate, useParams } from "react-router-dom";
import { CrmConnectorContextProvider } from "./CrmConectorContext";
import { logError } from "../../../../shared/logging";
import DataLoadingFailed from "../../../../shared/components/DataLoadingFailed";
import IntegrationHeader from "../integration/IntegrationHeader";
import { pageRoutes } from "../../../routes";

const promotionText =
  "Our APIs are designed to enable teams of any shape or size to build robust integrations that help them customize and get the most value out of HubSpot.";

const HubspotConnectorPage = () => {
  const { client } = useParams();
  const navigate = useNavigate();

  const [connectionInfo] = useFetch(crmConnectorsApi.hubspot.getConnection);
  const [connectorSettings, fetchError, { setData: setConnectorSettings }] = useFetch(
    crmConnectorsApi.hubspot.getConnectorSettings
  );
  const [ourObjects] = useFetch(crmConnectorsApi.hubspot.getOurObjects);
  const [crmObjects] = useFetch(crmConnectorsApi.hubspot.getCrmObjects);

  const [testConnectionState, setTestConnectionState] = useState<TestConnectionState>({ status: "hidden" });

  if (fetchError) {
    logError(fetchError.message || "fetch connectorSettings failed", "HubspotConnectorPage");
    return <DataLoadingFailed title="Loading connector settings failed" />;
  }

  if (
    connectionInfo === undefined ||
    connectorSettings === undefined ||
    ourObjects === undefined ||
    crmObjects === undefined
  ) {
    return <InlineLoader />;
  }

  const handleDelete = () => navigate(`/${client}/${pageRoutes.settings}/${pageRoutes.settingsIntegrations}`);

  // Not implemented
  const handleReAuthorize = () => undefined;

  const handleTestConnection = async () => {
    setTestConnectionState({ status: "loading" });

    const { data: testConnectionResult, success, error } = await crmConnectorsApi.salesforce.testConnection();
    if (success) {
      setTestConnectionState(
        testConnectionResult.success
          ? { status: "success" }
          : { status: "error", errorMessage: testConnectionResult.message }
      );
    } else {
      setTestConnectionState({ status: "error", errorMessage: error?.message });
    }
  };

  const handleCloseTestConnectionInfo = () => setTestConnectionState({ status: "hidden" });

  const handleDataSyncToggle = async (enabled: boolean) => {
    await crmConnectorsApi.hubspot.postDataSyncEnabled(enabled);
  };

  const handleSyncSettingsUpdate = (syncSettings: CrmSyncSettings) =>
    setConnectorSettings({ ...connectorSettings, syncSettings });

  return (
    <CrmConnectorContextProvider
      crmName="Hubspot"
      crmApiName="hubspot"
      logoSrc={HubspotLogo}
      connectionInfo={connectionInfo}
      connectorSettings={connectorSettings}
      ourObjects={ourObjects}
      crmObjects={crmObjects}
      onSyncSettingsUpdate={handleSyncSettingsUpdate}
    >
      <IntegrationHeader title="Hubspot" logoSrc={HubspotLogo} text={promotionText} appStatus="Announced" />
      <CrmSetupInfo />
      <CrmActions
        onDelete={handleDelete}
        onReAuthorize={handleReAuthorize}
        onTestConnection={handleTestConnection}
        onDataSyncToggle={handleDataSyncToggle}
      />
      <TestConnectionInfo state={testConnectionState} onClose={handleCloseTestConnectionInfo} />
      <CrmManagement />
    </CrmConnectorContextProvider>
  );
};

export default HubspotConnectorPage;
