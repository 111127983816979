import CancelIcon from "@mui/icons-material/CancelRounded";
import CheckIcon from "@mui/icons-material/CheckCircleRounded";
import CircleIcon from "@mui/icons-material/RadioButtonUncheckedRounded";
import { Alert, Box, Step, StepContent, StepLabel, Stepper, Typography } from "@mui/material";
import { convertISODateTime } from "../../../../../shared/utilities/dateUtils";
import { ApprovalRequest, ApprovalRequestStep } from "../../../../api/mock/approvalsTypes";

interface Props {
  approvalRequest: ApprovalRequest;
}

const PendingStepIcon = () => <CircleIcon color="secondary" />;
const ApprovedStepIcon = () => <CheckIcon color="success" />;
const RejectedStepIcon = () => <CancelIcon color="error" />;

const ApprovalStepLabel = ({ step, index }: { step: ApprovalRequestStep; index: number }) => {
  if (step.status === "Approved") {
    return (
      <StepLabel
        StepIconComponent={ApprovedStepIcon}
        optional={
          <Typography color="text.secondary">
            Approved by <strong>{step.updatedByUserName}</strong> at{" "}
            <strong>{convertISODateTime(step.updatedAt ?? "")}</strong>
          </Typography>
        }
      >
        Approval {index + 1}
      </StepLabel>
    );
  }

  if (step.status === "Rejected") {
    return (
      <StepLabel
        StepIconComponent={RejectedStepIcon}
        error
        optional={
          <Typography color="text.secondary">
            Rejected by <strong>{step.updatedByUserName}</strong> at{" "}
            <strong>{convertISODateTime(step.updatedAt ?? "")}</strong>
          </Typography>
        }
      >
        Approval {index + 1}
      </StepLabel>
    );
  }

  if (step.status === "Pending") {
    return (
      <StepLabel StepIconComponent={PendingStepIcon} optional="Pending approval">
        Approval {index + 1}
      </StepLabel>
    );
  }

  return null;
};

const ApprovalStepContent = ({ step }: { step: ApprovalRequestStep }) => {
  if (step.status === "Rejected" && step.comment) {
    return (
      <StepContent>
        <Alert severity="error" icon={false} sx={{ width: "18rem" }}>
          {step.comment}
        </Alert>
      </StepContent>
    );
  }

  return null;
};

const getActiveStepIndex = (approvalRequest: ApprovalRequest) => {
  if (approvalRequest.status === "Approved") {
    return approvalRequest.steps.length - 1;
  }

  if (approvalRequest.status === "Rejected") {
    return approvalRequest.steps.findIndex((step) => step.status === "Rejected");
  }

  if (approvalRequest.currentPendingStepId) {
    return approvalRequest.steps.findIndex((step) => step.id === approvalRequest.currentPendingStepId);
  }

  return 0;
};

const ApprovalProgressSection = ({ approvalRequest }: Props) => {
  return (
    <Box>
      <Typography variant="subtitle1" gutterBottom>
        Progress
      </Typography>
      <Stepper orientation="vertical" activeStep={getActiveStepIndex(approvalRequest)}>
        {approvalRequest.steps.map((step, index) => (
          <Step key={step.id}>
            <ApprovalStepLabel step={step} index={index} />
            <ApprovalStepContent step={step} />
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default ApprovalProgressSection;
