import { TabPanel } from "@mui/lab";
import { useState } from "react";
import { autoFormatCamelCase } from "../../../../../../shared/utilities/stringHelper";
import { defined } from "../../../../../../shared/utilities/typeHelper";
import { DataImportInfo, DataImportMetadata } from "../../../../../api/types/dataImportTypes";
import PageTabs from "../../../../common/PageTabs";
import DataImportChangesList from "./changes-list/DataImportChangesList";

interface Props {
  dataImport: DataImportInfo;
  dataImportMetadata: DataImportMetadata;
}

const ReviewImport = ({ dataImport, dataImportMetadata }: Props) => {
  const [selectedTab, setSelectedTab] = useState(dataImport.files[0]?.sourceFileDataCatalogueId || "");

  const { id, state, entriliaProductAreas, files } = dataImport;

  if (files.length === 1) {
    const file = defined(files[0]);
    return (
      <DataImportChangesList
        dataImportId={id}
        dataImportState={state}
        productAreas={entriliaProductAreas}
        fileDataCatalogueId={file.sourceFileDataCatalogueId}
        importFieldDefinitions={dataImportMetadata.importFieldDefinitions[file.sourceFileDataCatalogueId] ?? []}
      />
    );
  }

  const tabLabels = files.map(
    ({ importObjectTypeTarget, importTypeTarget }) =>
      `${autoFormatCamelCase(importObjectTypeTarget)} - ${autoFormatCamelCase(importTypeTarget)}`
  );

  return (
    <PageTabs
      value={selectedTab}
      onChange={setSelectedTab}
      tabs={files.map(({ sourceFileDataCatalogueId }) => sourceFileDataCatalogueId)}
      labels={tabLabels}
    >
      {files.map((file) => (
        <TabPanel key={file.sourceFileDataCatalogueId} value={file.sourceFileDataCatalogueId} sx={{ height: "100%" }}>
          <DataImportChangesList
            dataImportId={id}
            dataImportState={state}
            productAreas={entriliaProductAreas}
            fileDataCatalogueId={file.sourceFileDataCatalogueId}
            importFieldDefinitions={dataImportMetadata.importFieldDefinitions[file.sourceFileDataCatalogueId] ?? []}
          />
        </TabPanel>
      ))}
    </PageTabs>
  );
};

export default ReviewImport;
