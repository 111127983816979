import { Grid } from "@mui/material";
import { useClientContext } from "../../../../context/ClientContext";
import ApiGatewayIcon from "../../../../icons/ApiGatewayIcon";
import DataBackupIcon from "../../../../icons/DataBackupIcon";
import FundAccountingIcon from "../../../../icons/FundAccountingIcon";
import InvestorPortalIcon from "../../../../icons/InvestorPortalIcon";
import { pageRoutes } from "../../../../routes";
import IntegrationsCard from "./IntegrationsCard";

const AppsDashboard = () => {
  const { clientCode, hasAnyPermission, businessCentralApp, investorPortalApp, apiGatewayApp, dataBackupApp } =
    useClientContext();

  const canConfigureInvestorPortal = hasAnyPermission(["ViewInvestorPortalSettings", "ManageInvestorPortalSettings"]);
  const canConfigureApiGateway = hasAnyPermission(["ViewClientIntegrations", "ManageClientIntegrations"]);

  return (
    <Grid container spacing={3}>
      <Grid item>
        <IntegrationsCard
          title="Fund Operations"
          Icon={FundAccountingIcon}
          appStatus={businessCentralApp?.status}
          text="Powerful dual-sided accounting, with intuitive event-driven automations tailored for private capital and other alternative asset funds."
          showLearnMore
          cardId="integrations-card--fund-operations"
        />
      </Grid>
      <Grid item>
        <IntegrationsCard
          title="Investor Portal"
          Icon={InvestorPortalIcon}
          appStatus={investorPortalApp?.status}
          text="Modern tools for messaging and connectivity with your investors."
          configurationRoute={
            canConfigureInvestorPortal
              ? `/${clientCode}/${pageRoutes.investorRelations}/${pageRoutes.investorPortalSettings}`
              : undefined
          }
          showLearnMore
          cardId="integrations-card--investor-portal"
        />
      </Grid>
      <Grid item>
        <IntegrationsCard
          title="API Gateway"
          Icon={ApiGatewayIcon}
          appStatus={apiGatewayApp?.status}
          text="Enabling a 360 degree view of your data throughout your firm, while eliminating manual, high-risk data transfer methods."
          configurationRoute={
            canConfigureApiGateway ? `/${clientCode}/${pageRoutes.integration}/${pageRoutes.apiGateway}` : undefined
          }
          showLearnMore
          cardId="integrations-card--api-gateway"
        />
      </Grid>
      <Grid item>
        <IntegrationsCard
          title="Data Backup"
          Icon={DataBackupIcon}
          appStatus={dataBackupApp?.status}
          text="Configure daily backups of your financial data to Amazon S3 for added risk mitigation and regulatory compliance."
          configurationRoute={
            canConfigureApiGateway ? `/${clientCode}/${pageRoutes.integration}/${pageRoutes.dataBackup}` : undefined
          }
          showLearnMore
          cardId="integrations-card--data-backup"
        />
      </Grid>
    </Grid>
  );
};

export default AppsDashboard;
