import DocumentIcon from "@mui/icons-material/AttachFileRounded";
import { Badge, Box, Divider, ListItemIcon, ListItemText, MenuItem, MenuList } from "@mui/material";
import objectHash from "object-hash";
import { useCallback, useState } from "react";
import DataLoadingFailed from "../../../../../shared/components/DataLoadingFailed";
import useFetch from "../../../../../shared/hooks/useFetch";
import { logError } from "../../../../../shared/logging";
import approvalsMockApi from "../../../../api/mock/approvalsMockApi";
import { useClientContext } from "../../../../context/ClientContext";
import NoteIcon from "../../../../icons/NoteIcon";
import { NotesContextProvider } from "../../../notes/NotesContext";
import ApprovalFilesPreview from "./ApprovalFilesPreview";
import ApprovalNotesSection from "./ApprovalNotesSection";

interface Props {
  entityIds: string[];
}

type PreviewType = "Documents" | "Notes";

const ApprovalPreviewSection = ({ entityIds }: Props) => {
  const { hasAnyPermission } = useClientContext();
  const hasPermissionsToManageNotes = hasAnyPermission(["ManageInvestorRelationsNotes"]);

  const [selectedPreview, setSelectedPreview] = useState<PreviewType>("Documents");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getApprovalFiles = useCallback(() => approvalsMockApi.getApprovalFiles(entityIds), [objectHash(entityIds)]);
  const [files, error, { isFetching }] = useFetch(getApprovalFiles);

  if (error) {
    logError(error, "[ApprovalPreviewSection]");
    return <DataLoadingFailed title="Failed to fetch approval files" />;
  }

  const firstEntityId = entityIds[0];

  return (
    <Box height="100%" width="100%" display="flex">
      <Divider orientation="vertical" />
      <Box width="100%" flexGrow={1}>
        {selectedPreview === "Documents" && <ApprovalFilesPreview loading={isFetching} files={files ?? []} />}
        {selectedPreview === "Notes" && firstEntityId && (
          <NotesContextProvider
            entityType="Fund"
            entityId={firstEntityId}
            hasPermissionsToManageNotes={hasPermissionsToManageNotes}
          >
            <ApprovalNotesSection />
          </NotesContextProvider>
        )}
      </Box>
      <Divider orientation="vertical" flexItem />
      <MenuList
        sx={{
          py: 2.5,
          px: 1,
          "& .MuiMenuItem-root": {
            flexDirection: "column",
            alignItems: "center",
          },
          "& .Mui-selected": { bgcolor: "transparent" },
        }}
      >
        <MenuItem selected={selectedPreview === "Documents"} onClick={() => setSelectedPreview("Documents")}>
          <Badge badgeContent={files?.length} color={selectedPreview === "Documents" ? "primary" : "secondary"}>
            <ListItemIcon>
              <DocumentIcon color={selectedPreview === "Documents" ? "primary" : "secondary"} />
            </ListItemIcon>
          </Badge>
          <ListItemText>Documents</ListItemText>
        </MenuItem>
        <MenuItem selected={selectedPreview === "Notes"} onClick={() => setSelectedPreview("Notes")} disableGutters>
          <ListItemIcon>
            <NoteIcon color={selectedPreview === "Notes" ? "primary" : "secondary"} />
          </ListItemIcon>
          <ListItemText>Notes</ListItemText>
        </MenuItem>
      </MenuList>
    </Box>
  );
};

export default ApprovalPreviewSection;
