import FilesDropArea from "../../../../../../shared/components/FilesDropArea";
import { formatFileSize } from "../../../../../../shared/utilities/fileHelper";
import { fileToUploadValidator } from "../../../../../../shared/utilities/validators";
import { useImportCsvDataContext } from "../ImportCsvDataContext";
import { setFileUploadError, startFileUploadAction } from "../importCsvDataPageState";

const maxFileSize = 25 * 1024 * 1024;
const acceptedFileExtensions = [".csv"];
const subtitle = `Maximum file size: ${formatFileSize(maxFileSize)}. Supported format: csv.`;

const fileValidator = fileToUploadValidator({ maxFileSize, acceptedFileExtensions });

interface Props {
  targetOptionKey: string;
  onUploadStart: (targetOptionKey: string, file: File) => void;
}

const ImportSourceFileUploadArea = ({ targetOptionKey, onUploadStart }: Props) => {
  const { setPageState } = useImportCsvDataContext();

  const handleFilesAdd = (files: FileList) => {
    const file = files[0];
    if (!file) {
      return;
    }

    const validationResult = fileValidator(file);
    if (validationResult.isValid) {
      setPageState(startFileUploadAction(targetOptionKey, file.name, file.size));
      onUploadStart(targetOptionKey, file);
    } else {
      setPageState(setFileUploadError(targetOptionKey, validationResult.error));
    }
  };

  return (
    <FilesDropArea acceptedFileExtensions={acceptedFileExtensions} subtitle={subtitle} onFilesAdd={handleFilesAdd} />
  );
};

export default ImportSourceFileUploadArea;
