import CheckIcon from "@mui/icons-material/Check";
import SendIcon from "@mui/icons-material/SendRounded";
import { LoadingButton } from "@mui/lab";
import { Stack, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { getErrorMessage } from "../../../../../shared/api/axiosHelper";
import { useNotificationContext } from "../../../../../shared/contexts/NotificationContext";
import { logError } from "../../../../../shared/logging";
import { wait } from "../../../../../shared/utilities/promiseHelper";
import { combineValidators, emailValidator, requiredValidator } from "../../../../../shared/utilities/validators";
import adminApi from "../../../../api/adminApi";
import { useUserContext } from "../../../../context/UserContext";

interface Props {
  disabled?: boolean;
}

type ButtonState = "ready" | "sending" | "sent";

const cooldownDelayMs = 2000;

const buttonTexts: Record<ButtonState, string> = {
  ready: "Send Email",
  sent: "Email Sent",
  sending: "Sending",
};

const validateEmail = combineValidators(requiredValidator, emailValidator);

const SendTestInvitationEmail = ({ disabled }: Props) => {
  const { sendNotificationError } = useNotificationContext();

  const { name, email } = useUserContext();
  const [state, setState] = useState<ButtonState>("ready");
  const [testEmailAddress, setTestEmailAddress] = useState(email);

  const handleButtonClick = async () => {
    if (state !== "ready") {
      return;
    }

    try {
      setState("sending");

      const resp = await adminApi.sendTestInvitationEmail({
        recipientName: name,
        recipientEmail: testEmailAddress,
      });

      if (resp.success) {
        setState("sent");
        await wait(cooldownDelayMs);
      } else {
        logError(resp.error?.message, "SendTestInvitationEmail");
        sendNotificationError(`Failed to send invitation email: ${resp.error?.message}`);
      }
    } catch (error) {
      logError(error, "SendTestInvitationEmail");
      sendNotificationError(`Failed to send invitation email: ${getErrorMessage(error)}`);
    } finally {
      setState("ready");
    }
  };

  const emailAddressValidationResult = validateEmail(testEmailAddress);

  return (
    <Stack spacing={2} maxWidth="32rem">
      <Stack spacing={1}>
        <Typography variant="subtitle1">Test Email</Typography>
        <Typography color="text.secondary">Test email will be sent to</Typography>
      </Stack>
      <TextField
        variant="outlined"
        label="Email"
        value={testEmailAddress}
        onChange={({ target }) => setTestEmailAddress(target.value)}
      />
      <LoadingButton
        variant="outlined"
        color="primary"
        loading={state === "sending"}
        startIcon={state === "sent" ? <CheckIcon /> : undefined}
        endIcon={state === "ready" ? <SendIcon /> : undefined}
        disabled={!!disabled || state === "sending" || !emailAddressValidationResult.isValid}
        onClick={handleButtonClick}
        sx={{
          width: "7.5rem",
          cursor: state === "ready" ? "pointer" : "default",
        }}
      >
        {buttonTexts[state]}
      </LoadingButton>
    </Stack>
  );
};

export default SendTestInvitationEmail;
