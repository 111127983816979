import { LoadingButton } from "@mui/lab";
import { Alert, Autocomplete, Box, Button, Stack, TextField, Typography } from "@mui/material";
import objectHash from "object-hash";
import { useCallback, useState } from "react";
import { createApiResponse, withErrorHandling } from "../../../../../../shared/api/axiosHelper";
import DataLoadingFailed from "../../../../../../shared/components/DataLoadingFailed";
import SearchField from "../../../../../../shared/components/inputs/SearchField";
import { useNotificationContext } from "../../../../../../shared/contexts/NotificationContext";
import useFetch from "../../../../../../shared/hooks/useFetch";
import { logError } from "../../../../../../shared/logging";
import { arraysHaveSameItems } from "../../../../../../shared/utilities/arrayHelper";
import adminApi from "../../../../../api/adminApi";
import { FundraisingAccessItem } from "../../../../../api/types/fundraisingTypes";
import RecordCounter from "../../../../common/filters/RecordCounter";
import FundraisingAccessGrid from "./FundraisingAccessGrid";
import { applySearchFilter } from "./fundraisingAccessGridDataProvider";
import { useFundraisingDetailsPageContext } from "./FundraisingDetailsPageContext";

const updateFundraising = withErrorHandling(adminApi.updateFundraising);

const FundraisingAccessConfig = () => {
  const { sendNotification, sendNotificationError } = useNotificationContext();
  const { categories, isContentEditable, fundraising, fundOptions, onUpdated, onConfirmSave } =
    useFundraisingDetailsPageContext();

  const [selectedFundIds, setSelectedFundIds] = useState<string[]>(fundraising.funds.map((f) => f.id));
  const [isSaving, setSaving] = useState(false);
  const [filteredRows, setFilteredRows] = useState<FundraisingAccessItem[]>([]);

  const getAccessRows = useCallback(
    () =>
      categories.length > 0 ? adminApi.getFundraisingAccess(selectedFundIds) : Promise.resolve(createApiResponse([])),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [objectHash(selectedFundIds), categories.length]
  );

  const [rows, fetchError, { isFetching }] = useFetch(getAccessRows, (data) => setFilteredRows(data));

  const handleSearch = (searchValue: string) => {
    if (rows !== undefined) {
      setFilteredRows(applySearchFilter(searchValue, rows));
    }
  };

  const save = async () => {
    setSaving(true);
    const [resp, error] = await updateFundraising(fundraising.id, { fundIds: selectedFundIds });
    setSaving(false);

    if (error) {
      logError(error, "[FundraisingAccessConfig]");
      sendNotificationError("Could not update fundraising");
    } else {
      sendNotification(`Fundraising "${fundraising.name}" updated successfully`);
      onUpdated(resp);
    }
  };

  const handleSave = () => onConfirmSave(save);

  const handleReset = () => {
    const initialFundIds = fundraising.funds.map((f) => f.id);
    setSelectedFundIds(initialFundIds);
  };

  const isEdited = !arraysHaveSameItems(
    selectedFundIds,
    fundraising.funds.map((f) => f.id)
  );

  const isValid = fundraising.status === "Draft" || selectedFundIds.length > 0;
  const showValidationError = isEdited && !isValid;

  return (
    <Stack spacing={2.5} flex={1}>
      {categories.length === 0 && (
        <Alert severity="error">Access assignment is not possible: no Fundraising access categories defined</Alert>
      )}
      <Box>
        <Stack py={2} spacing={0.5}>
          <Typography variant="subtitle1">Access</Typography>
          <Typography color="text.secondary">
            Select one or more funds to target investor contacts. The list of contacts is dynamic, including those who
            match your fund selection now and in the future.
          </Typography>
        </Stack>
        <Autocomplete
          multiple
          openOnFocus
          disableCloseOnSelect
          fullWidth
          filterSelectedOptions
          disabled={!isContentEditable || categories.length === 0}
          options={fundOptions}
          isOptionEqualToValue={(opt, val) => opt.value === val.value}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Funds"
              error={showValidationError}
              helperText={showValidationError ? "At least one fund must be selected" : ""}
            />
          )}
          value={selectedFundIds.map((id) => ({
            label: fundOptions.find((o) => o.value === id)?.label || id,
            value: id,
          }))}
          onChange={(_, options) => setSelectedFundIds(options.map((o) => o.value))}
          sx={(t) => ({
            ".MuiInputBase-input": { color: t.palette.secondary.main },
            ".MuiChip-root": {
              "&.Mui-disabled": {
                opacity: 1,
              },
            },
          })}
        />
        {isContentEditable && (
          <Stack direction="row" spacing={1} pt={2}>
            <LoadingButton variant="contained" loading={isSaving} onClick={handleSave} disabled={!isEdited || !isValid}>
              Save
            </LoadingButton>
            <Button variant="text" color="secondary" onClick={handleReset} disabled={!isEdited}>
              Cancel
            </Button>
          </Stack>
        )}
      </Box>
      <Box flex={1}>
        <Stack py={2} spacing={0.5}>
          <Typography variant="subtitle1">Investors/Contacts</Typography>
          <Typography color="text.secondary">
            The list of investor contacts who can access the fundraising is based on the selected funds, portal role,
            and document access category. This dynamic list updates with any changes in access permissions or contact
            roles.
          </Typography>
        </Stack>
        <Box mb={2} display="flex" alignItems="center" justifyContent="space-between">
          <RecordCounter records={filteredRows.length} total={(rows ?? []).length} hide={isFetching} />
          <SearchField onSearch={handleSearch} debounceTimeMs={300} disabled={isFetching} />
        </Box>
        {!fetchError && <FundraisingAccessGrid rows={filteredRows} isLoading={isFetching} />}
        {fetchError && <DataLoadingFailed bgColor="none" />}
      </Box>
    </Stack>
  );
};

export default FundraisingAccessConfig;
