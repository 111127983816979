import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import { IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { GridAutoGeneratedGroupNode, GridDataGroupNode, GridLeafNode } from "@mui/x-data-grid-premium";
import { useEffect, useMemo, useState } from "react";
import { withErrorHandling } from "../../../../../../shared/api/axiosHelper";
import { ApiResponse } from "../../../../../../shared/api/types";
import TypographyTooltipEllipsis from "../../../../../../shared/components/TypographyTooltipEllipsis";
import { logError } from "../../../../../../shared/logging";
import { combineComparers, stringComparerBy } from "../../../../../../shared/utilities/arrayHelper";
import cloneDeep from "../../../../../../shared/utilities/cloneDeep";
import adminApi, {
  Category,
  Contact,
  ContactWithFieldValues,
  CreateContactData,
  FundPermissionsResponse,
  Investor,
  InvestorContact,
  UpdateInvestorCommunicationMatrixRequest,
} from "../../../../../api/adminApi";
import NavigateByLink from "../../../../common/NavigateByLink";
import CommunicationMatrix from "../../common/CommunicationMatrix";
import ContactCreateDialog from "../../common/ContactCreateDialog";
import { CommunicationMatrixModel, getModelId } from "../../common/matrixColumnDefinitions";
import {
  cloneFundModelWithoutChildren,
  createFundModels,
  createFundModelsForEmptyInvestorsContacts,
  createFundPermissionModelsAddedContacts,
  getContactChangesWhereInvitationWouldBeSent,
  getFundMatrixTreeDataPath,
} from "../../common/matrixHelper";
import InvestorCreateDialog from "../../investors/InvestorCreateDialog";
import AssignInvestorContactDialog from "../../investors/communication-matrix/AssignInvestorContactDialog";

interface Props {
  permissionsResponse: FundPermissionsResponse;
  categories: Category[];
  contacts: ContactWithFieldValues[];
  investors: Investor[];
}

const sortModelFunc = combineComparers<CommunicationMatrixModel>(
  stringComparerBy((m) => m.investorName),
  stringComparerBy((m) => m.contactName)
);

const getInvestorDetails = withErrorHandling(adminApi.getInvestorDetails);
const getContactDetails = withErrorHandling(adminApi.getContactDetails);
const updateMatrixFundCommunicationMatrix = withErrorHandling(adminApi.updateFundCommunicationMatrix);

const FundCommunicationMatrix = ({ permissionsResponse, categories, contacts, investors }: Props) => {
  const { fundId, fundName, permissions } = permissionsResponse;
  const originalModels: CommunicationMatrixModel[] = useMemo(
    () => createFundModels(permissions, fundName),
    [fundName, permissions]
  );

  const [initialModels, setInitialModels] = useState<CommunicationMatrixModel[]>(cloneDeep(originalModels));
  const [models, setModels] = useState<CommunicationMatrixModel[]>(cloneDeep(originalModels));
  const [contactsList, setContactsList] = useState<Contact[]>([...contacts]);
  const [investorsList, setInvestorsList] = useState<Investor[]>(investors);
  const [showInvestorCreateDialog, setShowInvestorCreateDialog] = useState(false);
  const [showAssignContactDialog, setShowAssignContactDialog] = useState(false);
  const [showContactCreateDialog, setShowContactCreateDialog] = useState(false);
  const [selectedInvestorModel, setSelectedInvestorModel] = useState<CommunicationMatrixModel | undefined>();

  const contactsForOptions = useMemo(() => {
    return contactsList
      .filter(
        (contact) =>
          !models.some(
            (model) => model.investorName === selectedInvestorModel?.investorName && model.contactId === contact.id
          ) //exclude already added
      )
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [contactsList, models, selectedInvestorModel?.investorName]);

  const getChangesWhereInvitationWouldBeSent = (
    initialModels: CommunicationMatrixModel[],
    modifiedModels: CommunicationMatrixModel[]
  ) => getContactChangesWhereInvitationWouldBeSent(contacts, initialModels, modifiedModels);

  const handleContactAdd = (node: GridDataGroupNode | GridAutoGeneratedGroupNode | GridLeafNode) => {
    const model =
      node.type === "group"
        ? models.find((m) => (node.children as string[]).includes(getModelId(m)))
        : models.find((m) => m.investorName === node.groupingKey);

    setSelectedInvestorModel(model);
  };

  useEffect(() => {
    if (selectedInvestorModel) {
      setShowAssignContactDialog(true);
    }
  }, [selectedInvestorModel]);

  const createModelsForAddedContacts = (contacts: InvestorContact[]) => {
    if (selectedInvestorModel) {
      setModels((currentModels) => [
        ...currentModels,
        ...createFundPermissionModelsAddedContacts(selectedInvestorModel, contacts),
      ]);
    }

    setSelectedInvestorModel(undefined);
  };

  const updateCommunicationMatrix = async (
    payload: UpdateInvestorCommunicationMatrixRequest
  ): Promise<ApiResponse<void | CommunicationMatrixModel[]>> => {
    const [updatedPermissions, error] = await updateMatrixFundCommunicationMatrix(fundId, payload);
    return {
      data: updatedPermissions && createFundModels(updatedPermissions.permissions, fundName),
      error,
      success: !error,
    };
  };

  const handleInvestorCreated = async (investorId: string, investorName: string) => {
    setModels((currentModels) => [
      ...currentModels,
      ...createFundModelsForEmptyInvestorsContacts([{ id: investorId, name: investorName }], fundName),
    ]);

    const [investor, error] = await getInvestorDetails(investorId);
    if (investor && !error) {
      setInvestorsList([...investorsList, investor]);
    } else {
      logError(error, "Investor Communication Matrix - ContactCreateDialog");
    }

    setShowInvestorCreateDialog(false);
  };

  const handleContactsSelected = (selectedContacts: Contact[]) => {
    const investorContacts: InvestorContact[] = selectedContacts.map((contact) => ({
      contactId: contact.id,
      name: contact.name,
      email: contact.email,
    }));

    createModelsForAddedContacts(investorContacts);
    setShowAssignContactDialog(false);
  };

  const handleContactCreated = async (contactId: string, contactData: CreateContactData) => {
    createModelsForAddedContacts([{ contactId, ...contactData }]);
    setShowContactCreateDialog(false);

    const [contactDetails, error] = await getContactDetails(contactId);
    if (contactDetails && !error) {
      setContactsList([...contactsList, contactDetails.contact]);
    } else {
      logError(error, "Investor Communication Matrix - ContactCreateDialog");
    }
  };

  return (
    <Stack spacing={2} sx={{ height: "100%" }}>
      <CommunicationMatrix
        entityType="Fund"
        noItemsMessage={"This fund has not been added to any investor."}
        renderGroupCellElement={(value, node) => (
          <GroupCell
            value={value as string}
            groupingKey={node.groupingKey ? (node.groupingKey as string) : undefined}
            onContactAdd={() => handleContactAdd(node)}
          />
        )}
        categories={categories}
        initialModels={initialModels}
        setInitialModels={setInitialModels}
        models={models}
        setModels={setModels}
        getChangesWhereInvitationWouldBeSent={getChangesWhereInvitationWouldBeSent}
        sortModelFunc={sortModelFunc}
        getTreeDataPath={getFundMatrixTreeDataPath}
        cloneModelWithoutChildren={cloneFundModelWithoutChildren}
        renderGroupedRowCell={(model: CommunicationMatrixModel) => (
          <Stack pl={8}>
            <TypographyTooltipEllipsis text={model.contactName} />
            {model.contactEmail && (
              <Typography variant="caption" color="textSecondary">
                {model.contactEmail}
              </Typography>
            )}
            {!model.contactEmail && (
              <NavigateByLink title="Add Email" href={`../../contacts/${model.contactId}/main`} openInNewTab showIcon />
            )}
          </Stack>
        )}
        updateInvestorCommunicationMatrix={updateCommunicationMatrix}
        groupRowActionsDisabled
      />
      <InvestorCreateDialog
        open={showInvestorCreateDialog}
        onClose={() => setShowInvestorCreateDialog(false)}
        investors={investors}
        onCreated={handleInvestorCreated}
      />
      <AssignInvestorContactDialog
        open={showAssignContactDialog && selectedInvestorModel !== undefined}
        onClose={() => setShowAssignContactDialog(false)}
        title={`Add contact(s) to ${selectedInvestorModel?.investorName}`}
        description={"Select the contacts you wish to associate with this fund."}
        onCreateNew={() => {
          setShowAssignContactDialog(false);
          setShowContactCreateDialog(true);
        }}
        options={contactsForOptions}
        onContactsSelected={handleContactsSelected}
      />
      <ContactCreateDialog
        open={showContactCreateDialog && selectedInvestorModel !== undefined}
        onClose={() => setShowContactCreateDialog(false)}
        onNewContactCreated={handleContactCreated}
        contacts={contacts}
      />
    </Stack>
  );
};

export default FundCommunicationMatrix;

interface GroupCellProps {
  value: string;
  groupingKey: string | undefined;
  onContactAdd: () => void;
}

const GroupCell = ({ value, groupingKey, onContactAdd }: GroupCellProps) => (
  <Stack direction="row" spacing={1} alignItems="center" width="100%">
    <TypographyTooltipEllipsis
      text={value || groupingKey || ""}
      typographyProps={{ variant: "subtitle1" }}
      typographySx={{ pl: !value ? 5 : 0 }}
    />
    <Tooltip title="Add Contact" arrow>
      <IconButton onClick={onContactAdd}>
        <PersonAddAltIcon />
      </IconButton>
    </Tooltip>
  </Stack>
);
