import AddIcon from "@mui/icons-material/Add";
import { Button, Divider, List, Stack, Typography } from "@mui/material";
import deepEqual from "fast-deep-equal";
import { useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import CloseIconButton from "../../../../shared/components/CloseIconButton";
import DragAndDropList from "../DragAndDropList";
import { useFilterContext } from "../filters/FilterContext";
import AddColumnSelect from "./AddColumnSelect";
import ColumnSelectionItem from "./ColumnSelectionItem";
import { TableColumnDefinition } from "./columnTypes";

interface Props {
  onClose: () => void;
}

interface ColumnsState {
  visible: TableColumnDefinition[];
  available: TableColumnDefinition[];
}

const ColumnSelectionEditor = ({ onClose }: Props) => {
  const { filterState, dispatchFilters } = useFilterContext();

  const [columns, setColumns] = useState<ColumnsState>({
    visible: filterState.visibleColumns,
    available: filterState.availableColumns,
  });

  const [isAddingColumn, setAddingColumn] = useState(false);

  const handleApply = () => {
    onClose();
    setTimeout(
      () =>
        dispatchFilters({
          type: "update_columns",
          visibleColumns: columns.visible,
          availableColumns: columns.available,
        }),
      100
    );
  };

  const handleCancel = () => {
    onClose();
    setTimeout(() => setColumns({ visible: filterState.visibleColumns, available: filterState.availableColumns }), 100);
  };

  const handleItemDrop = (dropIndex: number, draggedColumn: TableColumnDefinition | undefined) => {
    if (!draggedColumn) {
      return;
    }

    setColumns(({ visible, available }) => {
      const newVisible = visible.filter((col) => col.id !== draggedColumn.id);
      const insertIndex = dropIndex < 0 ? newVisible.length : dropIndex;
      newVisible.splice(insertIndex, 0, draggedColumn);
      return { visible: newVisible, available };
    });
  };

  const handleHideColumn = (columnId: string) => () => {
    setColumns(({ visible, available }) => {
      const columnToHide = visible.find((col) => col.id === columnId);
      if (!columnToHide) {
        return { visible, available };
      }

      return {
        visible: visible.filter((col) => col.id !== columnId),
        available: [...available, columnToHide],
      };
    });
  };

  const handleAddColumn = (columnId: string) => {
    setAddingColumn(false);
    setColumns(({ visible, available }) => {
      const columnToAdd = available.find((col) => col.id === columnId);
      if (!columnToAdd) {
        return { visible, available };
      }

      return {
        visible: [...visible, columnToAdd],
        available: available.filter((col) => col.id !== columnId),
      };
    });
  };

  const isDirty = !deepEqual(columns.visible, filterState.visibleColumns);

  return (
    <Stack sx={(t) => ({ width: t.spacing(52.5), maxHeight: "80vh" })} overflow="hidden" alignItems="flex-start">
      <Stack px={2.5} py={2} direction="row" justifyContent="space-between" alignItems="center" width="100%">
        <Typography variant="h6">Columns</Typography>
        <CloseIconButton onClick={handleCancel} />
      </Stack>
      <Divider flexItem />
      <List sx={{ width: "100%", px: 0.5, py: 1, overflow: "auto" }}>
        <DndProvider backend={HTML5Backend}>
          <DragAndDropList
            type="table_column"
            items={columns.visible}
            onDrop={handleItemDrop}
            renderItem={(column) => (
              <ColumnSelectionItem
                columnTitle={column.title}
                onHide={handleHideColumn(column.id)}
                disabled={isAddingColumn}
              />
            )}
            options={{ notDraggable: () => isAddingColumn }}
          />
        </DndProvider>
        {isAddingColumn && (
          <AddColumnSelect items={columns.available} onAdd={handleAddColumn} onCancel={() => setAddingColumn(false)} />
        )}
        {columns.available.length > 0 && (
          <Button
            variant="text"
            startIcon={<AddIcon />}
            onClick={() => setAddingColumn(true)}
            sx={{ ml: 2 }}
            disabled={isAddingColumn}
          >
            Add Column
          </Button>
        )}
      </List>
      <Divider flexItem />
      <Stack px={2} py={1.5} direction="row" justifyContent="flex-end">
        <Stack spacing={1.5} direction="row">
          <Button variant="text" color="secondary" onClick={handleCancel}>
            Cancel
          </Button>
          <Button variant="contained" onClick={handleApply} disabled={!isDirty}>
            Apply
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ColumnSelectionEditor;
