import { Typography } from "@mui/material";
import { useGridApiRef, useKeepGroupedColumnsHidden } from "@mui/x-data-grid-premium";
import CenteredWrapper from "../../../../../../shared/components/CenteredWrapper";
import DataGrid from "../../../../../../shared/components/grid/DataGrid";
import InlineLoader from "../../../../../../shared/components/inlineLoader/InlineLoader";
import { FundraisingAccessItem } from "../../../../../api/types/fundraisingTypes";
import { columnDefinitions, groupingColumnDefinition } from "./fundraisingAccessGridDataProvider";

interface Props {
  rows: FundraisingAccessItem[];
  isLoading: boolean;
}

const FundraisingAccessGrid = ({ rows, isLoading }: Props) => {
  const gridApiRef = useGridApiRef();

  const gridInitialState = useKeepGroupedColumnsHidden({
    apiRef: gridApiRef,
    initialState: {
      rowGrouping: {
        model: ["investorName"],
      },
      columns: {
        columnVisibilityModel: {
          contactName: false,
        },
      },
    },
  });

  return (
    <DataGrid<FundraisingAccessItem>
      apiRef={gridApiRef}
      columns={columnDefinitions}
      groupingColDef={groupingColumnDefinition}
      rows={rows}
      getRowId={(row) => `${row.investorId}_${row.contactId}`}
      rowHeight={52}
      columnHeaderHeight={36}
      loading={isLoading}
      initialState={gridInitialState}
      isGroupExpandedByDefault={() => true}
      hideFooter
      disableColumnFilter
      disableColumnPinning
      disableColumnSelector
      disableColumnSorting
      disableColumnMenu
      disableColumnReorder
      disableRowSelectionOnClick
      disableMultipleRowSelection
      slots={{
        loadingOverlay: () => <InlineLoader />,
        noRowsOverlay: () => (
          <CenteredWrapper>
            <Typography color="text.secondary">No users</Typography>
          </CenteredWrapper>
        ),
      }}
    />
  );
};

export default FundraisingAccessGrid;
