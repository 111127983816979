import { NumberFormatFactor } from "../../../shared/utilities/formatters";

export interface TimeSeriesPeriodTableData {
  fields: TimeSeriesPeriodTableField[];
  values: TimeSeriesValue[];
  periodsPerView: Record<TimeSeriesPeriodView, NamedTimePeriod[]>;
  rowsPerView: Record<TimeSeriesPeriodView, TimeSeriesPeriodTableRow[]>;
}

export interface TimeSeriesPeriodTableField extends TimeSeriesField {
  isVisible: boolean;
}

export interface TimeSeriesField {
  id: string;
  name: string;
  type: TimeSeriesFieldType;
  configuration: TimeSeriesFieldConfiguration;
}

export const allTimeSeriesFieldTypes = ["Number", "Money", "Percent", "Text", "Date", "Select"] as const;
export type TimeSeriesFieldType = (typeof allTimeSeriesFieldTypes)[number];

export interface NamedTimePeriod {
  name: string;
  startDate: string;
  endDate: string;
}

export interface TimeSeriesFieldConfiguration {
  precision?: number;
  numberFormatFactor?: NumberFormatFactor;
  dateFormat?: DateFormat;
  currencyCode?: string;
  valueOptions?: string[];
}

export interface TimeSeriesValue {
  fieldId: string;
  date: string;
  value: TimeSeriesFieldValue;
}

export type TimeSeriesFieldValue = string | number | undefined;

export const allTimeSeriesPeriodViews = ["Annual", "Quarterly", "Monthly"] as const;
export type TimeSeriesPeriodView = (typeof allTimeSeriesPeriodViews)[number];

export interface TimeSeriesPeriodTableRow {
  id: string;
  name: string;
  fieldType: TimeSeriesFieldType;
  fieldConfiguration: TimeSeriesFieldConfiguration;
  periodValues: PeriodNameToValue;
}

type PeriodNameToValue = Record<string, TimeSeriesFieldValue>;

export type DateFormat = "Short" | "Long";

export interface TimeSeriesPeriodTableLayout {
  orderedFields: TimeSeriesPeriodTableFieldConfiguration[];
}

interface TimeSeriesPeriodTableFieldConfiguration {
  fieldId: string;
  isVisible: boolean;
}

export interface CreateTimeSeriesFieldRequest {
  name: string;
  type: TimeSeriesFieldType;
  configuration: TimeSeriesFieldConfiguration;
}

export interface UpdateTimeSeriesFieldRequest {
  name?: string;
  configuration?: TimeSeriesFieldConfiguration;
}
