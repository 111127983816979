import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { Alert, Button, Stack, Typography } from "@mui/material";
import { useMemo, useState } from "react";
import TypographyTooltipEllipsis from "../../../../../../shared/components/TypographyTooltipEllipsis";
import { logError } from "../../../../../../shared/logging";
import { combineComparers, stringComparerBy } from "../../../../../../shared/utilities/arrayHelper";
import cloneDeep from "../../../../../../shared/utilities/cloneDeep";
import adminApi, {
  Category,
  Contact,
  ContactWithFieldValues,
  CreateContactData,
  Investor,
  InvestorContact,
  InvestorContactPermissions,
} from "../../../../../api/adminApi";
import NavigateByLink from "../../../../common/NavigateByLink";
import CommunicationMatrix from "../../common/CommunicationMatrix";
import ContactCreateDialog from "../../common/ContactCreateDialog";
import { CommunicationMatrixModel, getId, getModelId } from "../../common/matrixColumnDefinitions";
import {
  cloneInvestorModelWithoutChildren,
  createPermissionModelsForContacts,
  getContactChangesWhereInvitationWouldBeSent,
  getInvestorMatrixTreeDataPath,
  investorGroupingKeySeparator,
} from "../../common/matrixHelper";
import AssignInvestorContactDialog from "./AssignInvestorContactDialog";

interface Props {
  investor: Investor;
  permissions: InvestorContactPermissions[];
  categories: Category[];
  contacts: ContactWithFieldValues[];
}

const sortModelFunc = combineComparers<CommunicationMatrixModel>(
  stringComparerBy((m) => m.contactName),
  stringComparerBy((m) => m.fundName)
);

const InvestorCommunicationMatrix = ({ permissions, categories, investor, contacts }: Props) => {
  const originalModels: CommunicationMatrixModel[] = useMemo(() => {
    const available: CommunicationMatrixModel[] = createPermissionModelsForContacts(investor, investor.contacts);
    return available.map((model) => {
      const permissionsForModel =
        permissions.find((p) => getId(investor.title, p.fundInvestorId, p.contactId) === getModelId(model)) ?? [];
      return { ...model, ...permissionsForModel };
    });
  }, [investor, permissions]);

  const [contactsList, setContactsList] = useState<Contact[]>([...contacts]);
  const [initialModels, setInitialModels] = useState<CommunicationMatrixModel[]>(cloneDeep(originalModels));
  const [models, setModels] = useState<CommunicationMatrixModel[]>(cloneDeep(originalModels));
  const [showAssignContactDialog, setShowAssignContactDialog] = useState(false);
  const [showContactCreateDialog, setShowContactCreateDialog] = useState(false);

  const contactsForOptions = useMemo(() => {
    return contacts
      .filter(
        (c) => !c.investors.some((i) => i.id === investor.id) && !models.some((model) => model.contactId === c.id) //exclude already added
      )
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [contacts, investor.id, models]);

  const createModelsForAddedContacts = (addedContacts: InvestorContact[]) => {
    setModels((currentModels) => [...currentModels, ...createPermissionModelsForContacts(investor, addedContacts)]);
  };

  return (
    <Stack spacing={2} sx={{ height: "100%" }}>
      {investor.funds.length === 0 && (
        <Alert severity="warning">
          Investor does not have any fund. Please add a commitment to this investor in Fund Ops
        </Alert>
      )}
      <CommunicationMatrix
        entityType="Investor"
        noItemsMessage={"This investor doesn't have any contacts yet."}
        renderGroupCellElement={(value: unknown) => <GroupContactCell value={value as string} />}
        renderGridToolbarComponent={(isLoading: boolean) => (
          <Button
            startIcon={<AddRoundedIcon />}
            disabled={isLoading || investor.funds.length === 0}
            onClick={() => setShowAssignContactDialog(true)}
            variant="contained"
            color="primary"
          >
            Add Contact
          </Button>
        )}
        categories={categories}
        initialModels={initialModels}
        setInitialModels={setInitialModels}
        models={models}
        setModels={setModels}
        getChangesWhereInvitationWouldBeSent={(
          initialModels: CommunicationMatrixModel[],
          modifiedModels: CommunicationMatrixModel[]
        ) => getContactChangesWhereInvitationWouldBeSent(contactsList, initialModels, modifiedModels)}
        sortModelFunc={sortModelFunc}
        getTreeDataPath={getInvestorMatrixTreeDataPath}
        cloneModelWithoutChildren={cloneInvestorModelWithoutChildren}
        renderGroupedRowCell={(model: CommunicationMatrixModel) => (
          <TypographyTooltipEllipsis text={model.fundName} typographySx={{ ml: 8 }} />
        )}
        childRowActionsDisabled
        updateInvestorCommunicationMatrix={adminApi.updateInvestorCommunicationMatrix}
        excludedColumns={["isPrimary"]}
      />
      <AssignInvestorContactDialog
        open={showAssignContactDialog}
        onClose={() => setShowAssignContactDialog(false)}
        title={`Add contact(s) to ${investor.title}`}
        description={"Select the contacts you wish to associate with this investor."}
        onCreateNew={() => {
          setShowAssignContactDialog(false);
          setShowContactCreateDialog(true);
        }}
        options={contactsForOptions}
        onContactsSelected={(selectedContacts: Contact[]) => {
          const investorContacts: InvestorContact[] = selectedContacts.map((contact) => ({
            contactId: contact.id,
            name: contact.name,
            email: contact.email,
          }));
          createModelsForAddedContacts(investorContacts);
          setShowAssignContactDialog(false);
        }}
      />
      <ContactCreateDialog
        open={showContactCreateDialog}
        onClose={() => setShowContactCreateDialog(false)}
        onNewContactCreated={async (contactId: string, contactData: CreateContactData) => {
          createModelsForAddedContacts([{ contactId, ...contactData }]);
          setShowContactCreateDialog(false);
          try {
            const contactDetailsResponse = await adminApi.getContactDetails(contactId);
            if (contactDetailsResponse && contactDetailsResponse.data && contactDetailsResponse.data) {
              setContactsList([...contactsList, contactDetailsResponse.data.contact]);
            }
          } catch (e) {
            logError(e, "Investor Communication Matrix - ContactCreateDialog");
          }
        }}
        contacts={contacts}
      />
    </Stack>
  );
};

export default InvestorCommunicationMatrix;

const GroupContactCell = ({ value }: { value: string }) => {
  const [contactName, contactId, contactEmail] = value.split(investorGroupingKeySeparator);
  return (
    <Stack>
      <Typography variant="subtitle1">{contactName}</Typography>
      {contactEmail && (
        <TypographyTooltipEllipsis
          text={contactEmail}
          typographyProps={{ variant: "caption" }}
          typographySx={{
            color: "text.secondary",
          }}
        />
      )}
      {!contactEmail && (
        <Stack direction={"row"} alignItems="center" spacing={0.5}>
          <Typography color={"text.secondary"} variant="caption">
            To start assigning accesses, please provide an email address for this contact.
            <br /> Then refresh the page.
          </Typography>
          <NavigateByLink title={"Add Email"} href={`../../contacts/${contactId}/main`} openInNewTab showIcon />
        </Stack>
      )}
    </Stack>
  );
};
