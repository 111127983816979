import { Avatar, Stack, SvgIconProps, Typography } from "@mui/material";
import { FC } from "react";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

interface CardTitleProps {
  title: string;
  Icon?: FC<SvgIconProps>;
  logoSrc?: string;
  showLearnMore?: boolean;
}

const CardTitle = ({ title, Icon, logoSrc, showLearnMore }: CardTitleProps) => (
  <Stack direction="row" spacing={0.5} alignItems="center">
    <Stack direction="row" spacing={2} alignItems="center">
      {Icon && <Icon sx={{ width: 48, height: 48 }} />}
      {logoSrc && <Avatar src={logoSrc} variant="square" alt="logo" sx={{ width: 48, height: 48 }} />}
      <Typography variant="subtitle1">{title}</Typography>
    </Stack>
    {showLearnMore && <HelpOutlineIcon className="learn-more" color="secondary" />}
  </Stack>
);

export default CardTitle;
