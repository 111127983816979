import { Parser } from "@json2csv/plainjs";
import { TimeSeriesPeriodTableRow } from "../../../../../api/types/timeSeriesTypes";

type TimeSeriesPeriodTableCsvModel = Record<string, string | number | undefined>;

const createCsvParser = (periodNames: string[]): Parser<TimeSeriesPeriodTableCsvModel> =>
  new Parser<TimeSeriesPeriodTableCsvModel>({
    fields: [
      {
        label: "Name",
        value: "name",
      },
      ...periodNames.map((periodName) => ({ label: periodName, value: periodName })),
    ],
  });

export const createCsvForTimeSeriesPeriodTable = (periodNames: string[], rows: TimeSeriesPeriodTableRow[]): string => {
  const csvParser = createCsvParser(periodNames);
  const csvModels = rows.map((row) => ({ name: row.name, ...row.periodValues }));
  return csvParser.parse(csvModels);
};
