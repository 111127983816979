import { Container } from "@mui/system";
import { useCallback } from "react";
import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom";
import { withErrorHandling } from "../../../../shared/api/axiosHelper";
import DataLoadingFailed from "../../../../shared/components/DataLoadingFailed";
import InlineLoader from "../../../../shared/components/inlineLoader/InlineLoader";
import { useNotificationContext } from "../../../../shared/contexts/NotificationContext";
import useFetch from "../../../../shared/hooks/useFetch";
import { logError } from "../../../../shared/logging";
import adminApi from "../../../api/adminApi";
import { FieldIdToValueMap } from "../../../api/types/objectTypes";
import { useClientContext } from "../../../context/ClientContext";
import EntityTitle from "../../common/EntityTitle";
import GeneralPageHeader from "../../common/GeneralPageHeader";
import FullHeightFlexPage from "../../common/layout/FullHeightFlexPage";
import StretchedScrollableContent from "../../common/layout/StretchedScrollableContent";
import EntitySection from "../EntitySection";
import FieldValuesManager from "../FieldValuesManager";

const createObject = withErrorHandling(adminApi.createObject);

const NewObjectDetailsPage = () => {
  const { type } = useParams();
  const { hasAnyPermission } = useClientContext();
  const navigate = useNavigate();
  const { sendNotification, sendNotificationError } = useNotificationContext();
  const { pathname } = useLocation();

  const getObjectDefinition = useCallback(() => adminApi.getObjectDefinition(type ?? ""), [type]);
  const [objectDefinitionWithPermissions, fetchDefinitionError] = useFetch(getObjectDefinition);

  if (fetchDefinitionError) {
    logError(fetchDefinitionError, "[NewProductObjectDetailsPage]");
    return <DataLoadingFailed />;
  }

  if (!objectDefinitionWithPermissions) {
    return <InlineLoader />;
  }

  if (!type) {
    return <Navigate to=".." />;
  }

  const { objectClassDefinition, objectPermissions } = objectDefinitionWithPermissions;

  const hasPermissionsToManageObjectData = hasAnyPermission(objectPermissions.objectDataWritePermissions);

  const handleUpdate = async (fieldValues: FieldIdToValueMap) => {
    const [object, error] = await createObject(type, { fieldValues });
    if (error) {
      logError(error, "[NewProductObjectDetailsPage]");
      sendNotificationError("Failed to create object");
      return false;
    }

    sendNotification("Object created successfully");

    const objectDetailsPath = pathname.replace(/(.*)\/new$/, `$1/${object.id}`);
    setTimeout(() => navigate(objectDetailsPath));

    return true;
  };

  return (
    <FullHeightFlexPage>
      <GeneralPageHeader
        TitleComponent={<EntityTitle title="New Record" typeName={objectClassDefinition.objectName} />}
        showDefaultBackButtonTo=".."
      />
      <StretchedScrollableContent>
        <Container maxWidth="lg" disableGutters sx={{ height: "100%", px: 3, pt: 2.5 }}>
          <EntitySection>
            <FieldValuesManager
              objectDefinition={objectClassDefinition}
              fieldValues={{}}
              saveFieldValues={handleUpdate}
              hasPermissionsToManageFields={hasPermissionsToManageObjectData}
            />
          </EntitySection>
        </Container>
      </StretchedScrollableContent>
    </FullHeightFlexPage>
  );
};

export default NewObjectDetailsPage;
