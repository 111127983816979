import { Stack, Typography } from "@mui/material";
import UserAvatar from "../../../../../../../shared/components/UserAvatar";
import { convertISODateTime } from "../../../../../../../shared/utilities/dateUtils";
import { MessageDeliveryInfo } from "../../../../../../api/adminApi";

interface Props {
  deliveryInfo: MessageDeliveryInfo;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}

const getViewStatusText = (deliveryInfo: MessageDeliveryInfo) => {
  if (deliveryInfo.isRead) {
    return deliveryInfo.readDate ? `Viewed - ${convertISODateTime(deliveryInfo.readDate)}` : "Viewed";
  }

  return "Not viewed";
};

const ContactDeliveryInfo = ({ deliveryInfo, onClick }: Props) => {
  const { recipient } = deliveryInfo;

  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={1.5}
      sx={(theme) => ({ cursor: "pointer", "&:hover": { "& .recipientName": { color: theme.palette.primary.main } } })}
      onClick={onClick}
    >
      <UserAvatar size="medium" userName={recipient.name} />
      <Stack spacing={0.5}>
        <Stack direction="row" alignItems="baseline" spacing={0.5}>
          <Typography variant="subtitle2" className="recipientName">
            {recipient.name}&nbsp;
          </Typography>
          {!recipient.isRegistered && (
            <Typography variant="caption" color="error">
              Not registered
            </Typography>
          )}
        </Stack>
        <Typography color="text.secondary">{getViewStatusText(deliveryInfo)}</Typography>
      </Stack>
    </Stack>
  );
};

export default ContactDeliveryInfo;
