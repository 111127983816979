import { GridEventListener, GridRowSelectionModel, useGridApiRef } from "@mui/x-data-grid-premium";
import { useEffect, useMemo } from "react";
import DataGrid from "../../../../../../shared/components/grid/DataGrid";
import {
  getVisibleRowIds,
  selectNexRow,
  selectPreviousRow,
} from "../../../../../../shared/utilities/dataGridApiHelper";
import { DocumentInfo } from "../../../../../api/adminApi";
import { InvestorLevelRowModel, getInvestorLevelColumns, getInvestorLevelRows } from "./documentsTableDataProvider";

interface Props {
  documents: DocumentInfo[];
  showPublishingStatus: boolean;
  selection: GridRowSelectionModel | undefined;
  onSelectionChange: (selectionModel: GridRowSelectionModel) => void;
}

const DocumentsTable = ({ documents, selection, showPublishingStatus, onSelectionChange }: Props) => {
  const apiRef = useGridApiRef();

  const rows = useMemo(() => getInvestorLevelRows(documents), [documents]);

  useEffect(() => {
    const firstRowId = getVisibleRowIds(apiRef)[0];
    if (firstRowId) {
      setTimeout(() => {
        onSelectionChange([firstRowId]);
        apiRef.current.setCellFocus(firstRowId, "investorTitle");
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRowClick: GridEventListener<"rowClick"> = ({ id }) => {
    onSelectionChange([id]);
  };

  const handleCellKeyDown: GridEventListener<"cellKeyDown"> = (_, e) => {
    if (e.code !== "ArrowUp" && e.code !== "ArrowDown") {
      return;
    }

    if (e.code === "ArrowDown") {
      selectNexRow(apiRef);
    } else if (e.code === "ArrowUp") {
      selectPreviousRow(apiRef);
    }

    e.stopPropagation();
    e.preventDefault();
  };

  return (
    <DataGrid<InvestorLevelRowModel>
      apiRef={apiRef}
      columns={getInvestorLevelColumns()}
      rows={rows}
      rowHeight={40}
      rowSelectionModel={selection}
      onRowSelectionModelChange={onSelectionChange}
      onCellKeyDown={handleCellKeyDown}
      onRowClick={handleRowClick}
      checkboxSelection
      disableRowSelectionOnClick
      hideFooter
      disableColumnFilter
      disableColumnSelector
      disableColumnMenu
      columnVisibilityModel={{
        publishingStatus: showPublishingStatus,
      }}
    />
  );
};

export default DocumentsTable;
