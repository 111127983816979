import {
  Autocomplete,
  Checkbox,
  Divider,
  ListItem,
  ListItemText,
  Popper,
  PopperProps,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, {
  HTMLAttributes,
  PropsWithChildren,
  createContext,
  forwardRef,
  useContext,
  useEffect,
  useState,
} from "react";
import useDebounce from "../../../../../shared/hooks/useDebounce";

interface AddNewItemProps {
  element: JSX.Element;
  onClick: () => void;
}

interface Props<T> {
  options: T[];
  values: T[];
  onClose: () => void;
  onSelected: (items: T[]) => void;
  getOptionLabel: (option: T) => string;
  getOptionValue: (option: T) => string;
  getOptionDescription?: (option: T) => string;
  addNewItem?: AddNewItemProps;
  noOptionsText?: string;
}

interface ContextValue {
  addNewItem?: AddNewItemProps;
}
const MultiselectAutocompleteContext = createContext<ContextValue | undefined>(undefined);
export const MultiselectAutocompleteContextProvider = ({ children, ...other }: PropsWithChildren<ContextValue>) => {
  return <MultiselectAutocompleteContext.Provider value={other}>{children}</MultiselectAutocompleteContext.Provider>;
};

const PopperComponent = forwardRef<HTMLDivElement, PopperProps>((props, ref) => (
  <Popper ref={ref} {...props} sx={{ ".MuiAutocomplete-noOptions": { p: 0 } }} />
));

const createNewItemButtonId = "create-new-item-button";
const chunkSize = 20;

const ListboxComponent = forwardRef<HTMLDivElement, HTMLAttributes<HTMLElement>>((props, ref) => {
  const { children, ...other } = props;
  const context = useContext(MultiselectAutocompleteContext);
  const itemData: React.ReactElement[] = [];
  (children as React.ReactElement[]).forEach((item: React.ReactElement & { children?: React.ReactElement[] }) => {
    itemData.push(item);
    itemData.push(...(item.children || []));
  });
  const [loadedItems, setLoadedItems] = useState<React.ReactElement[]>(itemData.slice(0, chunkSize));

  useEffect(() => {
    setLoadedItems(itemData.slice(0, chunkSize));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [children]);

  const loadMore = () => {
    const loadedCount = loadedItems.length;
    if (itemData.length !== 0 && loadedCount < itemData.length) {
      const end = loadedCount + chunkSize;
      const newItems = itemData.slice(loadedCount, end >= itemData.length ? itemData.length : end);
      setLoadedItems([...loadedItems, ...newItems]);
    }
  };

  return (
    <>
      <div onScroll={loadMore} ref={ref} {...other} role={"listbox"} style={{ minHeight: "16rem" }}>
        {loadedItems}
      </div>
      {context && context.addNewItem && <AddNewItem {...context.addNewItem} />}
    </>
  );
});

const AddNewItem = ({ element, onClick }: AddNewItemProps) => {
  return (
    <>
      <Divider />
      {React.cloneElement(element, { onClick: onClick, id: createNewItemButtonId })}
    </>
  );
};

const MultiselectAutocomplete = <T,>({
  options,
  values,
  noOptionsText,
  addNewItem,
  onClose,
  onSelected,
  getOptionLabel,
  getOptionValue,
  getOptionDescription,
}: Props<T>) => {
  const [filtered, setFiltered] = useState<T[]>(options);

  const handleNewItemAdd = () => {
    addNewItem?.onClick();
    onClose();
  };

  const handleClose = () => {
    onClose();
  };

  const onInputChange = useDebounce((value: string) => {
    if (value) {
      const valueLowerCase = value.toLowerCase();
      setFiltered(
        options.filter(
          (o) =>
            (getOptionLabel(o) || "").toLowerCase().includes(valueLowerCase) ||
            (getOptionValue(o) || "").toLowerCase().includes(valueLowerCase) ||
            (getOptionDescription && (getOptionDescription(o) || "").toLowerCase().includes(valueLowerCase))
        )
      );
    } else {
      setFiltered(options);
    }
  }, 300);

  return (
    <MultiselectAutocompleteContextProvider addNewItem={addNewItem}>
      <Autocomplete
        multiple
        fullWidth
        sx={(t) => ({
          ".MuiInputBase-input": { color: t.palette.secondary.main },
        })}
        options={options}
        filterOptions={() => filtered}
        disableCloseOnSelect
        getOptionLabel={getOptionLabel}
        isOptionEqualToValue={(option, item) => option && item && getOptionValue(option) === getOptionValue(item)}
        value={values}
        onChange={(event, selectedOptions) => {
          event.stopPropagation();
          onSelected(selectedOptions);
        }}
        onInputChange={(event, value) => {
          if (event.type === "change") {
            onInputChange(value);
          }
        }}
        onClose={(event) => {
          if (
            //close on create new
            addNewItem &&
            "relatedTarget" in event &&
            event.relatedTarget &&
            (event.relatedTarget as HTMLElement).id === createNewItemButtonId
          ) {
            handleClose();
            handleNewItemAdd();
          } else {
            setFiltered(options);
          }
        }}
        renderOption={(props, option, { selected, index }) => (
          <ListItem {...props} key={index} value={getOptionValue(option)} dense>
            <Checkbox checked={selected} />
            <ListItemText
              primary={
                <Stack spacing={0.2}>
                  <Typography variant="subtitle2">{getOptionLabel(option)}</Typography>
                  {getOptionDescription && (
                    <Typography variant="caption" color={"text.secondary"}>
                      {getOptionDescription(option)}
                    </Typography>
                  )}
                </Stack>
              }
            />
          </ListItem>
        )}
        ListboxComponent={ListboxComponent}
        PopperComponent={PopperComponent}
        renderTags={(items) => items.map((v) => getOptionLabel(v)).join(", ")}
        renderInput={(params) => <TextField {...params} />}
        noOptionsText={
          <>
            <Typography m={1}>{noOptionsText ?? "No items"}</Typography>
            {addNewItem && <AddNewItem {...addNewItem} />}
          </>
        }
      />
    </MultiselectAutocompleteContextProvider>
  );
};

export default MultiselectAutocomplete;
