import { Dispatch, PropsWithChildren, createContext, useContext } from "react";
import { defined } from "../../../../shared/utilities/typeHelper";
import { DataCatalogueEntityType, FileInfo } from "../../../api/adminApi";
import { FilesAction, FilesState } from "./filesState";

interface Props {
  filesState: FilesState;
  dispatchFiles: Dispatch<FilesAction>;
  hasPermissionsToManage: boolean;
  entityInfo: EntityInfo | undefined;
  onDownloadFile: (row: FileInfo) => void;
  onDownloadMultipleFiles: (catalogueIds: string[]) => void;
  onPreviewFile: (row: FileInfo) => void;
  onDeleteFile: (row: FileInfo) => void;
  onDeleteMultipleFiles: (catalogueIds: string[]) => void;
}

export interface EntityInfo {
  entityType: DataCatalogueEntityType;
  entityId: string;
  entityName: string;
  objectType?: string;
}

type ContextValue = Props;

const FilesContext = createContext<ContextValue | undefined>(undefined);

export const FilesContextProvider = ({
  filesState,
  dispatchFiles,
  hasPermissionsToManage,
  entityInfo,
  onDownloadFile,
  onDownloadMultipleFiles,
  onPreviewFile,
  onDeleteFile,
  onDeleteMultipleFiles,
  children,
}: PropsWithChildren<Props>) => {
  return (
    <FilesContext.Provider
      value={{
        filesState,
        dispatchFiles,
        hasPermissionsToManage,
        entityInfo,
        onDownloadFile,
        onDownloadMultipleFiles,
        onPreviewFile,
        onDeleteFile,
        onDeleteMultipleFiles,
      }}
    >
      {children}
    </FilesContext.Provider>
  );
};

export const useFilesContext = () => defined(useContext(FilesContext), "Attempt to use FilesContext without provider");
