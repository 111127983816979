import DateIcon from "@mui/icons-material/CalendarTodayRounded";
import NumberIcon from "@mui/icons-material/NumbersRounded";
import PercentIcon from "@mui/icons-material/PercentRounded";
import TitleIcon from "@mui/icons-material/TitleRounded";
import { TimeSeriesFieldType } from "../../../../../api/types/timeSeriesTypes";
import MoneyFieldTypeIcon from "../../../../../icons/fieldTypes/MoneyFieldTypeIcon";
import SelectFieldTypeIcon from "../../../../../icons/fieldTypes/SelectFieldTypeIcon";

interface Props {
  fieldType: TimeSeriesFieldType;
}

const TimeSeriesFieldTypeIcon = ({ fieldType }: Props) => {
  const iconStyle = { color: "secondary.light", height: 20, width: 20 };

  switch (fieldType) {
    case "Text":
      return <TitleIcon sx={{ ...iconStyle }} />;
    case "Number":
      return <NumberIcon sx={{ ...iconStyle }} />;
    case "Date":
      return <DateIcon sx={{ ...iconStyle }} />;
    case "Money":
      return <MoneyFieldTypeIcon sx={{ ...iconStyle }} />;
    case "Percent":
      return <PercentIcon sx={{ ...iconStyle }} />;
    case "Select":
      return <SelectFieldTypeIcon sx={{ ...iconStyle }} />;
    default:
      return null;
  }
};

export default TimeSeriesFieldTypeIcon;
