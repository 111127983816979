import { Box, Divider } from "@mui/material";
import { useCallback, useState } from "react";
import DataLoadingFailed from "../../../../shared/components/DataLoadingFailed";
import InlineLoader from "../../../../shared/components/inlineLoader/InlineLoader";
import useFetch from "../../../../shared/hooks/useFetch";
import { logError } from "../../../../shared/logging";
import adminApi, {
  DataCatalogueEntityType,
  FileTag,
  FilesViewDefinition,
  GridColumnDefinition,
} from "../../../api/adminApi";
import FilesView from "../../common/files/FilesView";
import EntityCardFilesNavigation from "./EntityCardFilesNavigation";
import { acceptedFileExtensions, dropAreaSubtitle, maxFileSize } from "./entityFilesUpload";

interface Props {
  entityType: DataCatalogueEntityType;
  entityId: string;
  entityName: string;
  objectType?: string;
  hasPermissionsToManage: boolean;
}

interface ViewState {
  view: FilesViewDefinition | undefined;
  tag: FileTag | undefined;
}

const EntityCardFiles = ({ entityType, entityId, entityName, objectType, hasPermissionsToManage }: Props) => {
  const [viewState, setViewState] = useState<ViewState>({ view: undefined, tag: undefined });
  const [defaultColumnDefinitions, setDefaultColumnDefinitions] = useState<GridColumnDefinition[]>([]);

  const fetchViews = useCallback(
    () => adminApi.getFilesViewsForEntityType(entityType, objectType),
    [entityType, objectType]
  );
  const fetchTags = useCallback(
    () => adminApi.getFileTagsForEntityType(entityType, objectType),
    [entityType, objectType]
  );

  const [viewsResp, viewsError] = useFetch(fetchViews, (data) => {
    setViewState({ view: data.predefinedViews[0], tag: undefined });
    setDefaultColumnDefinitions(data.predefinedViews[0]?.columns ?? []);
  });

  const [tags, tagsError] = useFetch(fetchTags);

  if (viewsError) {
    logError(viewsError, "[EntityCardFiles] getFilesViews");
    return <DataLoadingFailed title="Could not load files view structure" />;
  }

  if (tagsError) {
    logError(tagsError, "[EntityCardFiles] getFileTagSettings");
    return <DataLoadingFailed title="Could not load file tags" />;
  }

  if (!viewsResp || !tags) {
    return <InlineLoader />;
  }

  const { predefinedViews } = viewsResp;

  const handleSelectedViewChange = (viewName: string) => {
    const view = predefinedViews.find(({ name }) => name === viewName);
    if (view) {
      setViewState({ view, tag: undefined });
    }
  };

  const handleSelectedTagChange = (tagId: string) => {
    const tag = tags.find(({ catalogueId }) => catalogueId === tagId);
    if (tag) {
      setViewState({ view: undefined, tag });
    }
  };

  const uploadParams = {
    acceptedFileExtensions: acceptedFileExtensions,
    maxFileSize: maxFileSize,
    dropAreaSubtitle: dropAreaSubtitle,
    uploadFileApiCall: (file: File) =>
      adminApi.uploadEntityFile(entityType, entityId, objectType, file, viewState.tag?.catalogueId),
  };

  const entityInfo = { entityType, entityId, entityName, objectType };

  return (
    <Box display="flex" overflow="hidden" height="100%">
      <EntityCardFilesNavigation
        predefinedViews={predefinedViews}
        selectedView={viewState.view}
        onSelectedViewChange={handleSelectedViewChange}
        tags={tags}
        selectedTag={viewState.tag}
        onSelectedTagChange={handleSelectedTagChange}
      />
      <Divider flexItem orientation="vertical" />
      {(viewState.view || viewState.tag) && (
        <FilesView
          key={viewState.view?.name ?? viewState.tag?.catalogueId}
          viewName={viewState.view?.name}
          tagId={viewState.tag?.catalogueId}
          viewTitle={viewState.view?.title ?? viewState.tag?.name ?? ""}
          columnDefinitions={viewState.view?.columns ?? defaultColumnDefinitions}
          uploadParams={uploadParams}
          entityInfo={entityInfo}
          hasPermissionsToManage={hasPermissionsToManage}
        />
      )}
    </Box>
  );
};

export default EntityCardFiles;
