import ObjectIcon from "@mui/icons-material/DataObject";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Popover, Stack, TextField, Typography } from "@mui/material";
import { useRef, useState } from "react";
import { useNotificationContext } from "../../../../../shared/contexts/NotificationContext";
import { logError } from "../../../../../shared/logging";
import adminApi from "../../../../api/adminApi";
import EmailSnippets from "../design/EmailSnippets";

interface Props {
  settings: InvitationEmailSettings;
  disableSave?: boolean;
  onChange: (settings: InvitationEmailSettings) => void;
  onSave: () => void;
  onReset: () => void;
}

type InputSelection = [keyof InvitationEmailSettings | null, number | null, number | null];

export interface InvitationEmailSettings {
  subject: string;
  messageText: string;
}

const InvitationEmailEdit = ({ settings, disableSave, onChange, onSave, onReset }: Props) => {
  const { sendNotificationError } = useNotificationContext();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [isSaving, setSaving] = useState(false);
  const [isSubjectValid, setSubjectValid] = useState(true);
  const [isMessageTextValid, setMessageTextValid] = useState(true);
  const [inputSelection, setInputSelection] = useState<InputSelection>([null, 0, 0]);

  const subjectInputRef = useRef<HTMLInputElement>();
  const bodyInputRef = useRef<HTMLInputElement>();

  const handleSave = async () => {
    setSaving(true);
    try {
      const contactInvitationEmailSubject = settings.subject.trim();
      const contactInvitationEmailMessageText = settings.messageText.trim();

      if (!contactInvitationEmailSubject) {
        setSubjectValid(false);
      }
      if (!contactInvitationEmailMessageText) {
        setMessageTextValid(false);
      }

      if (!contactInvitationEmailSubject || !contactInvitationEmailMessageText) {
        return;
      }

      await adminApi.updateInvestorPortalSettings({
        contactInvitationEmailSubject,
        contactInvitationEmailMessageText,
      });

      onSave();
    } catch (error) {
      logError(error, "InvitationEmailEditor");
      sendNotificationError("Failed to save invitation email settings");
    } finally {
      setSaving(false);
    }
  };

  const handleReset = () => {
    setSubjectValid(true);
    setMessageTextValid(true);
    onReset();
  };

  const handleSnippetButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(e.currentTarget);

  const handleSnippetsClose = () => setAnchorEl(null);

  const handleSnippetSelected = (snippetValue: string) => {
    setAnchorEl(null);
    const [propName, start, end] = inputSelection;

    if (!propName || !start || !end) {
      return;
    }

    const text = settings[propName];
    const textStart = text.slice(0, start);
    const textEnd = text.slice(end);

    onChange({ ...settings, [propName]: textStart + snippetValue + textEnd });
  };

  const handleInputSelectionChange = (input: HTMLInputElement, propName: keyof InvitationEmailSettings) => {
    const { selectionStart, selectionEnd } = input;
    setInputSelection([propName, selectionStart, selectionEnd]);
  };

  return (
    <Stack spacing={3} maxWidth="45rem">
      <Box>
        <Typography color="text.secondary" mb={1}>
          You can add a Snippet to the Email Subject and Email Body. Select a field and add a Snippet.
        </Typography>
        <Button variant="outlined" color="secondary" startIcon={<ObjectIcon />} onClick={handleSnippetButtonClick}>
          Snippet
        </Button>
        <Popover
          open={!!anchorEl}
          onClose={handleSnippetsClose}
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        >
          <EmailSnippets onSelected={handleSnippetSelected} />
        </Popover>
        <TextField
          spellCheck
          inputRef={subjectInputRef}
          fullWidth
          variant="outlined"
          label="Subject"
          value={settings.subject}
          onChange={({ target }) => onChange({ ...settings, subject: target.value })}
          error={!isSubjectValid}
          sx={(theme) => ({ mt: theme.spacing(4) })}
          onSelect={() => subjectInputRef.current && handleInputSelectionChange(subjectInputRef.current, "subject")}
        />
        <Typography variant="subtitle1" mt={2} mb={1}>
          Email Body
        </Typography>
        <TextField
          spellCheck
          inputRef={bodyInputRef}
          fullWidth
          multiline
          minRows={8}
          value={settings.messageText}
          onChange={({ target }) => onChange({ ...settings, messageText: target.value })}
          error={!isMessageTextValid}
          onSelect={() => bodyInputRef.current && handleInputSelectionChange(bodyInputRef.current, "messageText")}
        />
      </Box>
      <Stack direction="row" spacing={1}>
        <LoadingButton
          variant="contained"
          color="primary"
          disabled={disableSave}
          loading={isSaving}
          onClick={handleSave}
        >
          Save
        </LoadingButton>
        <Button variant="text" color="secondary" autoFocus onClick={handleReset}>
          Reset Changes
        </Button>
      </Stack>
    </Stack>
  );
};

export default InvitationEmailEdit;
