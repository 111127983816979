import DownloadIcon from "@mui/icons-material/SaveAltRounded";
import {
  IconButton,
  Paper,
  Stack,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { withErrorHandling } from "../../../../../shared/api/axiosHelper";
import FileIcon from "../../../../../shared/components/FileIcon";
import TableWithActions from "../../../../../shared/components/TableWithActions";
import { useNotificationContext } from "../../../../../shared/contexts/NotificationContext";
import { logError } from "../../../../../shared/logging";
import { downloadFileFromUrl } from "../../../../../shared/services/downloadFile";
import { formatFileSize, getFileExtension } from "../../../../../shared/utilities/fileHelper";
import adminApi, { FileInfo } from "../../../../api/adminApi";

interface Props {
  importId: string;
  sourceFiles: FileInfo[];
}

const getFileDownloadInfo = withErrorHandling(adminApi.getDataImportSourceFileDownloadInfo);

const ImportSourceFilesTable = ({ importId, sourceFiles }: Props) => {
  const { sendNotificationError } = useNotificationContext();

  const handleDownloadFile = (fileInfo: FileInfo) => async () => {
    const [resp, error] = await getFileDownloadInfo(importId, fileInfo.catalogueId);
    if (error) {
      logError(error, "[ImportSourceFilesTable] handleDownloadFile");
      sendNotificationError("Could not obtain file download information.");
      return;
    }

    downloadFileFromUrl(resp.downloadUrl);
  };

  return (
    <Paper variant="outlined" sx={{ width: "100%" }}>
      <TableContainer>
        <TableWithActions
          sx={{
            "& .MuiTableRow-root": {
              "&:last-child": {
                td: {
                  borderBottom: 0,
                },
              },
            },
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell sx={{ width: 120 }}>Size</TableCell>
              <TableCell sx={{ width: 64 }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sourceFiles.map((fileInfo) => (
              <TableRow key={fileInfo.fileName}>
                <TableCell>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <FileIcon fileExtension={getFileExtension(fileInfo.fileName)} />
                    <Typography noWrap>{fileInfo.fileName}</Typography>
                  </Stack>
                </TableCell>
                <TableCell>{formatFileSize(fileInfo.fileSize)}</TableCell>
                <TableCell className="table-row-actions">
                  <IconButton onClick={handleDownloadFile(fileInfo)}>
                    <DownloadIcon color="secondary" />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </TableWithActions>
      </TableContainer>
    </Paper>
  );
};

export default ImportSourceFilesTable;
