import CachedIcon from "@mui/icons-material/CachedRounded";
import ExportIcon from "@mui/icons-material/ExitToAppOutlined";
import { Box, Button, Container, Stack } from "@mui/material";
import { useGridApiRef } from "@mui/x-data-grid-premium";
import { saveAs } from "file-saver";
import { useCallback, useState } from "react";
import DataLoadingFailed from "../../../../../shared/components/DataLoadingFailed";
import SearchField from "../../../../../shared/components/inputs/SearchField";
import useFetch from "../../../../../shared/hooks/useFetch";
import { logError } from "../../../../../shared/logging";
import { DescriptiveDate, lastSevenDays, today, yesterday } from "../../../../../shared/utilities/dateFilterHelper";
import adminApi, { EmailTrackingStatus } from "../../../../api/adminApi";
import {
  firstPageAction,
  getPagingParams,
  loadItemsAction,
  nextPageAction,
  searchItemsAction,
} from "../../../../state/paginatedState";
import DateRangeSelect from "../../../common/DateRangeSelect";
import RecordCounter from "../../../common/filters/RecordCounter";
import GeneralPageHeader from "../../../common/GeneralPageHeader";
import {
  clearFiltersAction,
  getInitialEmailsState,
  updateDatesFilterAction,
  updateStatusFilterAction,
} from "./emailsState";
import EmailsTable from "./EmailsTable";
import StatusSelect from "./filters/StatusSelect";

const emailTrackingStatusFilterMap: Record<string, EmailTrackingStatus[]> = {
  Sent: ["Sent"],
  Delivered: ["Delivered"],
  Opened: ["Opened"],
  Bounced: ["PermanentFailure"],
  "Delivered & Opened": ["Delivered", "Opened"],
};

const EmailsPage = () => {
  const apiRef = useGridApiRef();

  const [state, setState] = useState(getInitialEmailsState());

  const getEmails = useCallback(
    () =>
      adminApi.searchSentEmails({
        paging: getPagingParams(state.page),
        query: state.searchTerm || undefined,
        sentAtFrom: state.dateRange?.from,
        sentAtTo: state.dateRange?.to,
        statuses: emailTrackingStatusFilterMap[state.statusFilter],
      }),
    [state.dateRange?.from, state.dateRange?.to, state.statusFilter, state.page, state.searchTerm]
  );

  const [, error, { isFetching, fetch: fetchEmails }] = useFetch(getEmails, (data) => {
    setState(loadItemsAction(data));
  });

  if (error) {
    logError(error.message, "[EmailsPage]");
    return <DataLoadingFailed title="Loading emails failed" />;
  }

  const handleSearch = (searchTerm: string) => {
    const query = searchTerm.trim();
    if (query.length === 0 || query.length > 1) {
      apiRef.current.scrollToIndexes({ rowIndex: 0 });
      setState(searchItemsAction(query));
    }
  };

  const handleStatusChange = (filterValue: string) => {
    apiRef.current.scrollToIndexes({ rowIndex: 0 });
    setState(updateStatusFilterAction(filterValue));
  };

  const handleDatesChange = (dateRange: DescriptiveDate) => {
    apiRef.current.scrollToIndexes({ rowIndex: 0 });
    setState(updateDatesFilterAction(dateRange));
  };

  const showClearFilters = state.dateRange !== undefined || Boolean(state.statusFilter);

  const handleClearFilters = () => {
    apiRef.current.scrollToIndexes({ rowIndex: 0 });
    setState(clearFiltersAction());
  };

  const handleRowScrollEnd = () => {
    setTimeout(() => {
      setState(nextPageAction());
    }, 100);
  };

  const handleExportToCsvClick = async () => {
    const csv = await adminApi.getSentEmailsCsv({
      sentAtFrom: state.dateRange?.from,
      sentAtTo: state.dateRange?.to,
      query: state.searchTerm,
      statuses: emailTrackingStatusFilterMap[state.statusFilter],
    });

    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const fileName = "emails.csv";
    saveAs(blob, fileName);
  };

  const handleRefreshClick = () => {
    apiRef.current.scrollToIndexes({ rowIndex: 0 });
    if (state.page === 0) {
      fetchEmails();
    } else {
      setState(firstPageAction());
    }
  };

  return (
    <>
      <GeneralPageHeader title="Email Delivery Log" />
      <Container maxWidth={false} sx={{ height: "100%", py: 2.5 }}>
        <Box display="flex" flexDirection="column" width="100%" height="100%" pb={5}>
          <Stack spacing={2} direction="row" justifyContent="space-between" alignItems="center">
            <Stack direction="row" spacing={2} alignItems="center">
              <RecordCounter records={state.items.length} total={state.totalRecords} />
              <DateRangeSelect
                predefinedRanges={[today, yesterday, lastSevenDays]}
                customRangeTitle="Custom date range"
                dateRange={state.dateRange}
                onChange={handleDatesChange}
              />
              <StatusSelect filterValue={state.statusFilter} onChange={handleStatusChange} />
              {showClearFilters && (
                <Button sx={{ whiteSpace: "nowrap" }} variant="text" color="primary" onClick={handleClearFilters}>
                  Clear All
                </Button>
              )}
            </Stack>
            <Stack spacing={2} direction="row" alignItems="center">
              <SearchField onSearch={handleSearch} debounceTimeMs={300} />
              <Button
                color="secondary"
                variant="outlined"
                startIcon={<CachedIcon />}
                onClick={handleRefreshClick}
                disabled={isFetching}
              >
                Refresh
              </Button>
              <Button
                color="secondary"
                variant="outlined"
                startIcon={<ExportIcon />}
                onClick={handleExportToCsvClick}
                disabled={state.items.length === 0 || isFetching}
              >
                Export
              </Button>
            </Stack>
          </Stack>
          <EmailsTable
            apiRef={apiRef}
            isLoading={isFetching}
            emails={state.items}
            onRowScrollEnd={handleRowScrollEnd}
          />
        </Box>
      </Container>
    </>
  );
};

export default EmailsPage;
