import { GridColDef, GridRowModes, GridRowModesModel, GridSingleSelectColDef } from "@mui/x-data-grid-premium";
import { formatISO, isValid, parseISO } from "date-fns";
import { AnyObject } from "../../../../../../../shared/types";
import { ClientDictionaries } from "../../../../../../api/types/clientTypes";
import { DataImportFieldValueChange, ImportFieldDefinition } from "../../../../../../api/types/dataImportTypes";
import { EntityFieldConfiguration } from "../../../../../../api/types/objectTypes";
import GridEditMultiSelectCell from "../../../../../common/grid/GridEditMultiSelectCell";
import { fieldsEditSettings, getSelectOptionsFromDictionaries } from "../../../../../entityFields/helpers";
import DataImportChangeActionTag from "./DataImportChangeActionTag";
import DataImportChangesListActionsCell from "./DataImportChangesListActionsCell";
import DataImportCsvValueGridCell from "./DataImportCsvValueGridCell";
import { DataImportChangeRowModel } from "./dataImportChangesGridTypes";

const getOptionsForSelectFieldType = (
  configuration: EntityFieldConfiguration | undefined,
  clientDictionaries: ClientDictionaries
): { value: string; label: string }[] => {
  if (configuration?.$type === "Select") {
    const dictionaryType = configuration.fieldType;
    return getSelectOptionsFromDictionaries(dictionaryType, clientDictionaries).map(({ label }) => ({
      label,
      value: label,
    }));
  }

  if (configuration?.$type === "UserDefinedOptionsSelect") {
    return configuration.userDefinedOptions.map(({ label }) => ({ label, value: label }));
  }

  // TODO add lookups support #9531

  return [];
};

const getValueColumnDefinition = (
  importFieldDefinition: ImportFieldDefinition,
  clientDictionaries: ClientDictionaries,
  isGridEditable: boolean,
  highlightChanges: boolean
): GridColDef<DataImportChangeRowModel> => {
  const colDef: GridColDef<DataImportChangeRowModel> = {
    field: importFieldDefinition.name,
    headerName: importFieldDefinition.name,
    sortable: false,
    editable: isGridEditable,
    flex: 1,
    minWidth: importFieldDefinition.isObjectIdentifierField ? 200 : 150,
    cellClassName: ({ row }) => {
      const classNames = [];

      if (row.isExcluded) {
        classNames.push("excluded-row-cell");
      }

      const change = row.fieldValueChanges.find(({ key }) => key === importFieldDefinition.name);
      if (change?.validationError) {
        classNames.push("change-value-cell-error");
      }

      return classNames.join(" ");
    },
    valueGetter: (_, row) => {
      const newValue = row.fieldValueChanges.find(({ key }) => key === importFieldDefinition.name)?.newValue;
      if (typeof newValue === "string" && importFieldDefinition.type === "Date") {
        const result = parseISO(newValue);
        return isValid(result) ? result : undefined;
      }

      if (
        typeof newValue === "string" &&
        (importFieldDefinition.type === "Number" ||
          importFieldDefinition.type === "Money" ||
          importFieldDefinition.type === "Percent")
      ) {
        const result = parseFloat(newValue);
        return Number.isNaN(result) ? undefined : result;
      }

      if (typeof newValue === "string" && importFieldDefinition.type === "Checkbox") {
        return ["true", "1", "yes"].includes(newValue.toLowerCase());
      }

      if (
        typeof newValue === "string" &&
        (importFieldDefinition.type === "MultiSelect" || importFieldDefinition.type === "UserDefinedOptionsMultiSelect")
      ) {
        return newValue.split(fieldsEditSettings.multiselectItemsSeparator).filter(Boolean);
      }

      return newValue;
    },
    valueSetter: (value, row) => {
      let newValue = value;

      if (newValue instanceof Date) {
        newValue = formatISO(newValue, { representation: "date" });
      }

      if (typeof newValue === "number") {
        newValue = newValue.toString();
      }

      if (typeof newValue === "boolean") {
        newValue = newValue ? "TRUE" : "FALSE";
      }

      if (Array.isArray(newValue)) {
        newValue = newValue.join(fieldsEditSettings.multiselectItemsSeparator);
      }

      const fieldValueChanges: DataImportFieldValueChange[] = row.fieldValueChanges.map((change) =>
        change.key === importFieldDefinition.name ? { ...change, newValue } : change
      );

      return { ...row, fieldValueChanges };
    },
    renderCell: ({ row }) => (
      <DataImportCsvValueGridCell
        row={row}
        importFieldDefinition={importFieldDefinition}
        highlightChanges={highlightChanges}
      />
    ),
  };

  if (importFieldDefinition.type === "Date") {
    colDef.type = "date";
  } else if (
    importFieldDefinition.type === "Money" ||
    importFieldDefinition.type === "Number" ||
    importFieldDefinition.type === "Percent"
  ) {
    colDef.type = "number";
  } else if (importFieldDefinition.type === "Checkbox") {
    colDef.type = "boolean";
  } else if (importFieldDefinition.type === "Select" || importFieldDefinition.type === "UserDefinedOptionsSelect") {
    const valueOptions = getOptionsForSelectFieldType(importFieldDefinition.configuration, clientDictionaries);
    if (valueOptions.length > 0) {
      colDef.type = "singleSelect";
      (colDef as GridSingleSelectColDef<DataImportChangeRowModel>).valueOptions = valueOptions;
    }
  } else if (
    importFieldDefinition.type === "MultiSelect" ||
    importFieldDefinition.type === "UserDefinedOptionsMultiSelect"
  ) {
    colDef.renderEditCell = (params) => (
      <GridEditMultiSelectCell
        {...params}
        valueOptions={getOptionsForSelectFieldType(importFieldDefinition.configuration, clientDictionaries)}
      />
    );
  }

  return colDef;
};

export const getColumnDefinitions = (
  importFieldDefinitions: ImportFieldDefinition[],
  clientDictionaries: ClientDictionaries,
  isGridEditable: boolean,
  highlightChanges: boolean,
  rowModesModel: GridRowModesModel
): GridColDef<DataImportChangeRowModel>[] => [
  {
    field: "rowNumber",
    headerName: "#",
    sortable: false,
    width: 50,
    align: "right",
    headerAlign: "right",
    cellClassName: ({ row }) => (row.hasValidationErrors ? "row-number-cell-error" : "row-number-cell"),
  },
  {
    field: "action",
    headerName: "Status",
    sortable: false,
    width: 100,
    renderCell: ({ row }) => <DataImportChangeActionTag changeRequest={row} />,
  },
  ...importFieldDefinitions.map((field) =>
    getValueColumnDefinition(field, clientDictionaries, isGridEditable, highlightChanges)
  ),
  {
    field: "gridActions",
    headerName: "",
    sortable: false,
    resizable: false,
    width: 150,
    align: "right",
    renderCell: ({ row }) => (
      <DataImportChangesListActionsCell row={row} isInEditMode={rowModesModel[row.id]?.mode === GridRowModes.Edit} />
    ),
  },
];

export const createNewRow = (
  id: string,
  dataImportId: string,
  fileDataCatalogueId: string,
  importFieldDefinitions: ImportFieldDefinition[]
): DataImportChangeRowModel => ({
  isNew: true,
  id,
  dataImportId,
  fileDataCatalogueId,
  hasValidationErrors: false,
  isExcluded: false,
  fieldValueChanges: importFieldDefinitions.map(({ name }) => ({ key: name, fieldType: "Text" })),
});

export const getNewValuesObject = (row: DataImportChangeRowModel): AnyObject =>
  Object.fromEntries(row.fieldValueChanges.map((c) => [c.key, c.newValue ?? ""]));
