import { Stack, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-premium";
import UserAvatar from "../../../../../../shared/components/UserAvatar";
import { convertISODateTime } from "../../../../../../shared/utilities/dateUtils";
import { EmailTrackingItem } from "../../../../../api/adminApi";
import EmailStatus from "../../../../tables/EmailStatus";

interface RecipientProps {
  recipientName: string;
  email: string;
}

const Recipient = ({ recipientName, email }: RecipientProps) => (
  <Stack direction="row" alignItems="center" spacing={1.5}>
    <UserAvatar size="medium" userName={recipientName} />
    <Stack spacing={0.5}>
      <Typography variant="subtitle2">{recipientName}</Typography>
      <Typography variant="caption">{email}</Typography>
    </Stack>
  </Stack>
);

export const getColumns = (): GridColDef<EmailTrackingItem>[] => [
  {
    field: "recipientName",
    headerName: "Recipient",
    sortable: false,
    flex: 2,
    renderCell: ({ row }) => <Recipient recipientName={row.recipientName ?? ""} email={row.email ?? ""} />,
  },
  {
    field: "investor",
    headerName: "Investor",
    sortable: false,
    flex: 2,
  },
  {
    field: "subject",
    headerName: "Subject",
    sortable: false,
    flex: 2,
  },
  {
    field: "sentAt",
    headerName: "Sent",
    sortable: false,
    flex: 1,
    renderCell: ({ row }) => (
      <Typography variant="caption" color="text.secondary">
        {convertISODateTime(row.sentAt)}
      </Typography>
    ),
  },
  {
    field: "status",
    headerName: "Status",
    sortable: false,
    flex: 1,
    renderCell: ({ row }) => (
      <EmailStatus
        status={row.status}
        deliveryFailureMessage={row.deliveryFailureMessage}
        deliveryFailureMessageExplanation={row.deliveryFailureMessageExplanation}
      />
    ),
  },
];
