import { Typography } from "@mui/material";
import { DataGridPremium as DataGrid, GridColDef } from "@mui/x-data-grid-premium";
import CenteredWrapper from "../../../../../shared/components/CenteredWrapper";
import InlineLoader from "../../../../../shared/components/inlineLoader/InlineLoader";
import { Fund } from "../../../../api/adminApi";
import { getFundsListColumns } from "./FundsDefinitions";

interface Props {
  funds: Fund[];
  isLoading: boolean;
  onFundClick: (id: string) => void;
}

const FundsList = ({ funds, isLoading, onFundClick }: Props) => {
  const columns: GridColDef<Fund>[] = getFundsListColumns(onFundClick);
  return (
    <DataGrid<Fund>
      getRowId={(row) => row.id}
      loading={isLoading}
      slots={{
        loadingOverlay: () => <InlineLoader />,
        noRowsOverlay: () => (
          <CenteredWrapper>
            <Typography color="textSecondary">No funds</Typography>
          </CenteredWrapper>
        ),
      }}
      columns={columns}
      rowHeight={48}
      rows={funds}
      disableRowSelectionOnClick
      hideFooter
      disableColumnFilter
      disableColumnSelector
      disableColumnMenu
    />
  );
};

export default FundsList;
