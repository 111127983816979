import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import { IconButton, Stack, Typography } from "@mui/material";
import { PropsWithChildren } from "react";
import { Link } from "react-router-dom";

interface Props {
  title?: string;
  BackButton?: React.ReactNode;
  TitleComponent?: React.ReactNode;
  showDefaultBackButtonTo?: string;
  hideBorderLine?: boolean;
}

const GeneralPageHeader = ({
  title,
  children,
  BackButton,
  TitleComponent,
  showDefaultBackButtonTo,
  hideBorderLine,
}: PropsWithChildren<Props>) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      pl={4}
      pr={3}
      pt={0.5}
      width="100%"
      borderBottom={hideBorderLine ? 0 : 1}
      borderColor="divider"
    >
      <Stack alignItems="center" direction="row" spacing={1}>
        {BackButton}

        {showDefaultBackButtonTo && BackButton === undefined && (
          <IconButton component={Link} to={showDefaultBackButtonTo} color="secondary">
            <ArrowBackRoundedIcon />
          </IconButton>
        )}

        {TitleComponent}

        <Typography sx={(theme) => ({ height: theme.spacing(6.5), pt: 2.2 })} variant="h6">
          {title}&nbsp;
        </Typography>
      </Stack>

      <Stack direction="row" spacing={1}>
        {children}
      </Stack>
    </Stack>
  );
};

export default GeneralPageHeader;
