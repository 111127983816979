import { Stack, Typography } from "@mui/material";

interface Props {
  title: string;
  secondaryTitle?: string;
  typeName: string;
}

const EntityTitle = ({ title, secondaryTitle, typeName }: Props) => (
  <Stack spacing={0.5}>
    <Stack direction="row" spacing={1} alignItems="baseline">
      <Typography variant="h6">{title}</Typography>
      {secondaryTitle && (
        <Typography variant="subtitle1" color="text.secondary">
          {secondaryTitle}
        </Typography>
      )}
    </Stack>
    <Typography variant="caption" color="text.secondary">
      {typeName}
    </Typography>
  </Stack>
);

export default EntityTitle;
