import ExportIcon from "@mui/icons-material/ExitToApp";
import { Box, Button, Divider, Stack, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import saveAs from "file-saver";
import { useCallback, useMemo, useState } from "react";
import { withErrorHandling } from "../../../../../../shared/api/axiosHelper";
import CenteredWrapper from "../../../../../../shared/components/CenteredWrapper";
import DataLoadingFailed from "../../../../../../shared/components/DataLoadingFailed";
import HorizontalFill from "../../../../../../shared/components/HorizontalFill";
import InlineLoader from "../../../../../../shared/components/inlineLoader/InlineLoader";
import { useNotificationContext } from "../../../../../../shared/contexts/NotificationContext";
import useFetch from "../../../../../../shared/hooks/useFetch";
import adminApi from "../../../../../api/adminApi";
import {
  CreateTimeSeriesFieldRequest,
  TimeSeriesPeriodTableLayout,
  TimeSeriesPeriodView,
  UpdateTimeSeriesFieldRequest,
} from "../../../../../api/types/timeSeriesTypes";
import TimeSeriesFieldDetails from "./TimeSeriesFieldDetails";
import TimeSeriesPeriodsGrid from "./TimeSeriesPeriodsGrid";
import { createCsvForTimeSeriesPeriodTable } from "./csvFormatter";
import CustomizeTimeSeriesFields from "./customization/CustomizeTimeSeriesFields";
import { getColumnsPerView } from "./timeSeriesPeriodsGridDataProvider";

interface Props {
  companyId: string;
  companyName: string;
}

const saveTimeSeriesPeriodTableLayout = withErrorHandling(adminApi.saveTimeSeriesPeriodTableLayout);
const createTimeSeriesField = withErrorHandling(adminApi.createTimeSeriesField);
const updateTimeSeriesField = withErrorHandling(adminApi.updateTimeSeriesField);
const deleteTimeSeriesField = withErrorHandling(adminApi.deleteTimeSeriesField);

const TimeSeriesPeriods = ({ companyId, companyName }: Props) => {
  const { sendNotificationError } = useNotificationContext();

  const getTimeSeriesPeriodTableData = useCallback(
    () => adminApi.getTimeSeriesPeriodTableData("PortfolioCompany", companyId),
    [companyId]
  );

  const [tableData, tableDataError, { isFetching, fetch: fetchTableData }] = useFetch(getTimeSeriesPeriodTableData);

  const [periodView, setPeriodView] = useState<TimeSeriesPeriodView>("Annual");
  const [selectedFieldId, setSelectedFieldId] = useState<string | undefined>();

  const gridColumnsPerView = useMemo(
    () => (tableData ? getColumnsPerView(tableData.periodsPerView) : { Annual: [], Quarterly: [], Monthly: [] }),
    [tableData]
  );

  if (tableDataError) {
    return <DataLoadingFailed title="Failed to load time series data" />;
  }

  if (!tableData || isFetching) {
    return <InlineLoader />;
  }

  const handleSelectionChange = (ids: string[]) => setSelectedFieldId(ids[0]);

  const selectedField = selectedFieldId ? tableData.fields.find((field) => field.id === selectedFieldId) : undefined;

  const selectedFieldValues = selectedFieldId
    ? tableData.values.filter((value) => value.fieldId === selectedField?.id)
    : undefined;

  const handleSaveFieldsLayout = async (fieldsLayout: TimeSeriesPeriodTableLayout) => {
    const [, error] = await saveTimeSeriesPeriodTableLayout("PortfolioCompany", fieldsLayout);
    if (error) {
      sendNotificationError("Failed to update fields layout");
    } else {
      await fetchTableData();
    }
  };

  const handleCreateField = async (createFieldRequest: CreateTimeSeriesFieldRequest) => {
    const [, error] = await createTimeSeriesField("PortfolioCompany", createFieldRequest);
    if (error) {
      sendNotificationError("Failed to create field");
      return;
    } else {
      await fetchTableData();
    }
  };

  const handleUpdateField = async (fieldId: string, updateFieldRequest: UpdateTimeSeriesFieldRequest) => {
    const [, error] = await updateTimeSeriesField("PortfolioCompany", fieldId, updateFieldRequest);
    if (error) {
      sendNotificationError("Failed to update field");
      return;
    } else {
      await fetchTableData();
    }
  };

  const handleDeleteField = async (fieldId: string) => {
    const [, error] = await deleteTimeSeriesField("PortfolioCompany", fieldId);
    if (error) {
      sendNotificationError("Failed to delete field");
      return;
    } else {
      await fetchTableData();
    }
  };

  const isTimeSeriesDataEmpty = Object.values(tableData.periodsPerView).every((periods) => periods.length === 0);

  const gridColumns = gridColumnsPerView[periodView] ?? [];
  const gridRows = tableData.rowsPerView[periodView] ?? [];

  const handleExportToCsvClick = () => {
    const csv = createCsvForTimeSeriesPeriodTable(
      tableData.periodsPerView[periodView].map((period) => period.name),
      gridRows
    );

    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const fileName = `${companyName}_Time_Series_${periodView}.csv`;
    saveAs(blob, fileName);
  };

  return (
    <Stack width="100%" height="100%" spacing={1} p={2}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        {isTimeSeriesDataEmpty ? (
          <HorizontalFill />
        ) : (
          <ToggleButtonGroup color="primary" value={periodView} exclusive onChange={(_, value) => setPeriodView(value)}>
            <ToggleButton value="Annual">Annual</ToggleButton>
            <ToggleButton value="Quarterly">Quarterly</ToggleButton>
            <ToggleButton value="Monthly">Monthly</ToggleButton>
          </ToggleButtonGroup>
        )}
        <Stack direction="row" spacing={2}>
          <Button color="secondary" variant="outlined" startIcon={<ExportIcon />} onClick={handleExportToCsvClick}>
            Export to CSV
          </Button>
          <CustomizeTimeSeriesFields
            initialFields={tableData.fields}
            onSaveFieldsLayout={handleSaveFieldsLayout}
            onCreateField={handleCreateField}
            onUpdateField={handleUpdateField}
            onDeleteField={handleDeleteField}
          />
        </Stack>
      </Box>
      <Stack direction="row" spacing={1} width="100%" height="100%">
        {isTimeSeriesDataEmpty ? (
          <CenteredWrapper>
            <Typography color="text.secondary">No Time Series data avaiable for this company</Typography>
          </CenteredWrapper>
        ) : (
          <TimeSeriesPeriodsGrid rows={gridRows} columns={gridColumns} onSelectionChange={handleSelectionChange} />
        )}
        {selectedField && selectedFieldValues && (
          <>
            <Divider flexItem orientation="vertical" />
            <TimeSeriesFieldDetails field={selectedField} fieldValues={selectedFieldValues} />
          </>
        )}
      </Stack>
    </Stack>
  );
};

export default TimeSeriesPeriods;
