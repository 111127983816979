import ApprovalIcon from "@mui/icons-material/ApprovalRounded";
import CancelIconRounded from "@mui/icons-material/CancelRounded";
import CheckIcon from "@mui/icons-material/Check";
import CheckIconRounded from "@mui/icons-material/CheckCircleRounded";
import CancelIcon from "@mui/icons-material/Close";
import { GridColDef } from "@mui/x-data-grid-premium";
import InlineItemsList from "../../../../shared/components/InlineItemsList";
import { convertISODate } from "../../../../shared/utilities/dateUtils";
import { formatMoney } from "../../../../shared/utilities/formatters";
import { ApprovalRequest } from "../../../api/mock/approvalsTypes";
import ActionsMenuButton from "../../common/ActionsMenuButton";
import ApprovalStatusTag from "./ApprovalStatusTag";
import { useApprovalsGridActionsContext } from "./ApprovalsGridActionsContext";
import { formatApprovalType, oneOrMultipleText } from "./approvalsFormatters";

const ActionsCell = ({ row }: { row: ApprovalRequest }) => {
  const { onApproveClick, onRejectClick } = useApprovalsGridActionsContext();

  if (row.status === "Approved") {
    return <CheckIconRounded color="success" />;
  }

  if (row.status === "Rejected") {
    return <CancelIconRounded color="error" />;
  }

  return (
    <ActionsMenuButton
      icon={<ApprovalIcon />}
      disabled={!row.allowUpdateForCurrentUser}
      items={[
        {
          icon: <CheckIcon color="success" />,
          label: "Approve",
          onClick: () => onApproveClick([row.id]),
        },
        {
          icon: <CancelIcon color="error" />,
          label: "Reject",
          onClick: () => onRejectClick([row.id]),
        },
      ]}
    />
  );
};

export const getGenericColumnDefinitions = (): GridColDef<ApprovalRequest>[] => [
  {
    field: "actions",
    type: "actions",
    headerName: "",
    sortable: false,
    width: 32,
    align: "center",
    renderCell: ActionsCell,
  },
  {
    field: "name",
    headerName: "Name",
    sortable: false,
    flex: 1,
  },
  {
    field: "status",
    headerName: "Status",
    sortable: false,
    flex: 0.65,
    minWidth: 240,
    renderCell: ({ row }) => (
      <ApprovalStatusTag
        status={row.status}
        approvedStepsCount={row.approvedStepsCount}
        totalStepsCount={row.totalStepsCount}
      />
    ),
  },
  {
    field: "type",
    headerName: "Type",
    sortable: false,
    flex: 0.65,
    minWidth: 240,
    valueFormatter: (value) => formatApprovalType(value),
  },
];

export const getPaymentColumnDefinitions = (): GridColDef<ApprovalRequest>[] => [
  {
    field: "actions",
    type: "actions",
    headerName: "",
    sortable: false,
    width: 32,
    align: "center",
    renderCell: ActionsCell,
  },
  {
    field: "name",
    headerName: "Name",
    sortable: false,
    flex: 1,
    minWidth: 360,
  },
  {
    field: "status",
    headerName: "Status",
    sortable: false,
    flex: 0.65,
    minWidth: 240,
    renderCell: ({ row }) => (
      <ApprovalStatusTag
        status={row.status}
        approvedStepsCount={row.approvedStepsCount}
        totalStepsCount={row.totalStepsCount}
      />
    ),
  },
  {
    field: "dueDate",
    headerName: "Due Date",
    sortable: false,
    flex: 0.65,
    minWidth: 240,
    valueFormatter: (value) => convertISODate(value),
  },
  {
    field: "transactionNo",
    headerName: "Transaction",
    sortable: false,
    flex: 0.65,
    minWidth: 240,
  },
  {
    field: "transactionType",
    headerName: "Transaction Type",
    sortable: false,
    flex: 0.65,
    minWidth: 240,
  },
  {
    field: "entityNames",
    headerName: "Entity",
    sortable: false,
    flex: 0.65,
    minWidth: 240,
    renderCell: ({ row }) => (
      <InlineItemsList<string>
        displayCount={1}
        items={row.entityNames}
        propGetter={(value) => value}
        justify="flex-start"
      />
    ),
  },
  {
    field: "counterpartyNames",
    headerName: "Counterparty",
    sortable: false,
    flex: 0.65,
    minWidth: 240,
    valueFormatter: (value) => oneOrMultipleText(value),
  },
  {
    field: "counterpartyBankNames",
    headerName: "Counterparty Bank",
    sortable: false,
    flex: 0.65,
    minWidth: 240,
    valueFormatter: (value) => oneOrMultipleText(value),
  },
  {
    field: "counterpartyAccountNames",
    headerName: "Account Name",
    sortable: false,
    flex: 0.65,
    minWidth: 240,
    valueFormatter: (value) => oneOrMultipleText(value),
  },
  {
    field: "counterpartyAccountNumbers",
    headerName: "Account Number",
    sortable: false,
    flex: 0.65,
    minWidth: 240,
    valueFormatter: (value) => oneOrMultipleText(value),
  },
  {
    field: "totalAmount",
    headerName: "Amount",
    headerAlign: "right",
    align: "right",
    sortable: false,
    flex: 0.65,
    minWidth: 240,
    renderCell: ({ row }) => formatMoney(row.totalAmount, row.currencyCode),
  },
];
