import { Box, Tooltip } from "@mui/material";
import BadgeDetached from "../../../shared/components/BadgeDetached";
import CategoryTag from "./CategoryTag";
import MultilineTooltipTitle from "./MultilineTooltipTitle";

interface Props {
  categoryNames: string[];
  maxDisplayedCategories?: number;
}

const CategoryInlineTags = ({ categoryNames, maxDisplayedCategories = 2 }: Props) => (
  <Box display="flex" alignItems="center" gap={1}>
    <>
      {categoryNames.slice(0, maxDisplayedCategories).map((categoryName) => (
        <CategoryTag key={categoryName} categoryName={categoryName} />
      ))}
      {categoryNames.length > maxDisplayedCategories && (
        <Tooltip arrow title={<MultilineTooltipTitle lines={categoryNames.slice(maxDisplayedCategories)} />}>
          <Box>
            <BadgeDetached color="secondary" badgeContent={`+${categoryNames.length - maxDisplayedCategories}`} />
          </Box>
        </Tooltip>
      )}
    </>
  </Box>
);

export default CategoryInlineTags;
