import { Stack, Typography } from "@mui/material";
import {
  EntityFieldConfiguration,
  NumberEntityFieldConfiguration,
  PercentEntityFieldConfiguration,
} from "../../../../../api/types/objectTypes";
import FieldPrecisionSelect from "./FieldPrecisionSelect";

interface Props {
  fieldConfiguration: NumberEntityFieldConfiguration | PercentEntityFieldConfiguration;
  readOnly?: boolean;
  onChange: (fieldConfiguration: EntityFieldConfiguration) => void;
}

const NumberFieldConfigEditor = ({ fieldConfiguration, readOnly, onChange }: Props) => {
  return (
    <Stack spacing={1} pt={2}>
      <Typography variant="subtitle2">Formatting</Typography>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography color="text.secondary">Precision</Typography>
        <FieldPrecisionSelect
          maxPrecision={6}
          precision={fieldConfiguration.precision || 0}
          disabled={readOnly}
          onChange={(precision) => onChange({ ...fieldConfiguration, precision })}
          sx={{ width: "12rem" }}
        />
      </Stack>
    </Stack>
  );
};

export default NumberFieldConfigEditor;
