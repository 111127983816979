import { DwhShareType, PostDwhShareRequest } from "../../../api/types/dwhTypes";

export type AddShareFormFields = keyof AddShareForm;

export interface AddShareForm {
  type: DwhShareType;
  accountCode: string;
  description: string;
  errors: {
    accountCode?: string;
  };
}

export const initialAddShareForm = (): AddShareForm => ({
  type: "AllTables",
  accountCode: "",
  description: "",
  errors: {},
});

const validateAccountCode = (accountCode: string): string | undefined => {
  if (!accountCode) {
    return "Account Code cannot be empty";
  }
  if (!/^[A-Za-z\d_]+\.[A-Za-z\d_]+$/.test(accountCode)) {
    return "Account Code must be in format 'ORGNAME.ACCOUNTNAME'";
  }
  return undefined;
};

export const validateAddShareForm = (form: AddShareForm): AddShareForm => {
  const validationError = validateAccountCode(form.accountCode);
  return validationError !== undefined
    ? {
        ...form,
        errors: {
          accountCode: validationError,
        },
      }
    : { ...form, errors: {} };
};

export const mapAddShareFormToRequest = (form: AddShareForm): PostDwhShareRequest => ({
  type: form.type,
  accountCode: form.accountCode.toUpperCase(),
  description: form.description || undefined,
});

export const isAddShareFormValid = (form: AddShareForm): boolean => Object.keys(form.errors).length === 0;
