import { Stack, Typography } from "@mui/material";
import FileIcon from "../../../../../shared/components/FileIcon";
import { getFileExtension } from "../../../../../shared/utilities/fileHelper";
import { useFilesContext } from "../FilesContext";
import { FilesRowModel } from "../filesState";

interface Props {
  row: FilesRowModel;
  fileName: string;
}

const FileNameCell = ({ row, fileName }: Props) => {
  const { onPreviewFile } = useFilesContext();

  if (!fileName) {
    return null;
  }

  const handleClick = () => {
    if (row.uploadState === undefined) {
      onPreviewFile(row);
    }
  };

  return (
    <Stack direction="row" spacing={1} alignItems="center" width="100%">
      <FileIcon fileExtension={getFileExtension(fileName)} />
      <Typography
        noWrap
        onClick={handleClick}
        sx={(t) =>
          row.uploadState === undefined ? { cursor: "pointer", "&:hover": { color: t.palette.primary.main } } : {}
        }
      >
        {fileName}
      </Typography>
    </Stack>
  );
};

export default FileNameCell;
