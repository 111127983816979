import { createContext, PropsWithChildren, useContext } from "react";
import { ClientBranding, ClientType } from "../../shared/api/clientTypes";
import { AccessPermission } from "../../shared/api/types";
import { defined } from "../../shared/utilities/typeHelper";
import { MenuBadges } from "../api/adminApi";
import {
  AppDescriptor,
  ClientDictionaries,
  ClientSettings,
  CompanyType,
  ExpenseManagementPortalAppDescriptor,
} from "../api/types/clientTypes";

export interface Client {
  clientCode: string;
  permissions: AccessPermission[];
  branding: ClientBranding;
  organizationEmailDomain?: string;
  businessCentralApp?: AppDescriptor;
  investorPortalApp?: AppDescriptor;
  reportingPortalApp?: AppDescriptor;
  apiGatewayApp?: AppDescriptor;
  salesforceApp?: AppDescriptor;
  plaidApp?: AppDescriptor;
  yodleeApp?: AppDescriptor;
  saltEdgeApp?: AppDescriptor;
  mxApp?: AppDescriptor;
  qashqadeApp?: AppDescriptor;
  entityLevelAccess?: boolean;
  clientType: ClientType;
  clientTitle: string;
  expenseManagementPortalApp?: ExpenseManagementPortalAppDescriptor;
  dataBackupApp?: AppDescriptor;
  passthroughApp?: AppDescriptor;
  companyType: CompanyType;
}

interface Props {
  client: Client;
  clientSettings: ClientSettings;
  clientDictionaries: ClientDictionaries;
  menuBadges: MenuBadges | undefined;
  updateMenuBadges: () => void;
  onNewClientSelected: (clientCode: string) => void;
  updateUserData: () => void;
}

interface ContextValue extends Client {
  isFundAdmin: boolean;
  onNewClientSelected: (clientCode: string) => void;
  hasPermissions: (permissions: AccessPermission[]) => boolean;
  hasAnyPermission: (permissions: AccessPermission[]) => boolean;
  settings: ClientSettings;
  dictionaries: ClientDictionaries;
  menuBadges: MenuBadges | undefined;
  updateMenuBadges: () => void;
  updateUserData: () => void;
}

const ClientContext = createContext<ContextValue | undefined>(undefined);

export const ClientContextProvider = ({
  children,
  client,
  clientSettings,
  clientDictionaries,
  menuBadges,
  updateMenuBadges,
  onNewClientSelected,
  updateUserData,
}: PropsWithChildren<Props>) => (
  <ClientContext.Provider
    value={{
      ...client,
      settings: clientSettings,
      dictionaries: clientDictionaries,
      menuBadges,
      updateMenuBadges,
      hasPermissions: (permissions: AccessPermission[]) => permissions.every((p) => client.permissions.includes(p)),
      hasAnyPermission: (permissions: AccessPermission[]) => permissions.some((p) => client.permissions.includes(p)),
      onNewClientSelected,
      isFundAdmin: client.clientType === "FundAdmin",
      updateUserData,
    }}
  >
    {children}
  </ClientContext.Provider>
);

export const useClientContext = () => {
  const client = useContext(ClientContext);
  return defined(client);
};
