import CalendarIcon from "@mui/icons-material/CalendarTodayRounded";
import RenameIcon from "@mui/icons-material/DriveFileRenameOutline";
import PersonIcon from "@mui/icons-material/PersonOutlineRounded";
import TitleIcon from "@mui/icons-material/TitleRounded";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import ShowMoreLessButton from "../../../../../../shared/components/ShowMoreLessButton";
import UserAvatar from "../../../../../../shared/components/UserAvatar";
import useToggleState from "../../../../../../shared/hooks/useToggleState";
import { convertISODateTime } from "../../../../../../shared/utilities/dateUtils";
import FundraisingAttributeLine from "./FundraisingAttributeLine";
import { useFundraisingDetailsPageContext } from "./FundraisingDetailsPageContext";

const FundraisingDetailsSection = () => {
  const { fundraising, hasEditPermissions, onRename } = useFundraisingDetailsPageContext();

  const [expanded, toggleExpanded] = useToggleState(false);

  return (
    <Stack width="100%">
      <Typography variant="subtitle1" pt={2} pb={2}>
        Details
      </Typography>

      <FundraisingAttributeLine Icon={TitleIcon} label="Name">
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography noWrap>{fundraising.name}</Typography>
          {hasEditPermissions && (
            <IconButton color="secondary" onClick={onRename}>
              <RenameIcon />
            </IconButton>
          )}
        </Stack>
      </FundraisingAttributeLine>

      <FundraisingAttributeLine Icon={PersonIcon} label="Created by">
        <Stack direction="row" spacing={1} alignItems="center">
          <UserAvatar userName={fundraising.createdByUserName} size="small" />
          <Typography noWrap>{fundraising.createdByUserName}</Typography>
        </Stack>
      </FundraisingAttributeLine>

      {expanded && (
        <>
          <FundraisingAttributeLine Icon={CalendarIcon} label="Created at">
            <Typography>{convertISODateTime(fundraising.createdAt)}</Typography>
          </FundraisingAttributeLine>

          <FundraisingAttributeLine Icon={PersonIcon} label="Modified by">
            <Stack direction="row" spacing={1} alignItems="center">
              <UserAvatar userName={fundraising.updatedByUserName} size="small" />
              <Typography noWrap>{fundraising.updatedByUserName}</Typography>
            </Stack>
          </FundraisingAttributeLine>

          <FundraisingAttributeLine Icon={CalendarIcon} label="Modified at">
            <Typography>{convertISODateTime(fundraising.updatedAt)}</Typography>
          </FundraisingAttributeLine>
        </>
      )}

      <Box py={1}>
        <ShowMoreLessButton expanded={expanded} onClick={toggleExpanded} />
      </Box>
    </Stack>
  );
};

export default FundraisingDetailsSection;
