import { Stack } from "@mui/material";
import { ObjectClassDefinition, ObjectFieldValues } from "../../api/types/objectTypes";
import ScrollableColumns from "../common/layout/ScrollableColumns";
import { NotesContextProvider } from "../notes/NotesContext";
import CreatedAndModifiedTimestamps from "./CreatedAndModifiedTimestamps";
import EntityNotesSection from "./EntityNotesSection";
import ObjectCard from "./ObjectCard";
import ObjectLogoSection from "./ObjectLogoSection";
import { getNotesEntityType } from "./entityTypesHelper";

interface Props {
  objectData: ObjectFieldValues;
  objectDefinition: ObjectClassDefinition;
  hasPermissionsToManageObjectData: boolean;
  showNotes: boolean;
  hasPermissionsToManageNotes: boolean;
  showLogoSection?: boolean;
  onEntityUpdate: (entity: ObjectFieldValues) => void;
}

const ObjectMainInfo = ({
  objectData,
  objectDefinition,
  hasPermissionsToManageObjectData,
  showNotes,
  hasPermissionsToManageNotes,
  showLogoSection,
  onEntityUpdate,
}: Props) => {
  return (
    <ScrollableColumns gridProps={{ columnSpacing: 2.5 }}>
      <Stack spacing={2.5} width="100%">
        <ObjectCard
          entity={objectData}
          objectDefinition={objectDefinition}
          hasPermissionsToManageFields={hasPermissionsToManageObjectData}
          onEntityUpdate={onEntityUpdate}
        />
        {showLogoSection && <ObjectLogoSection entity={objectData} disabled={!hasPermissionsToManageObjectData} />}
        <CreatedAndModifiedTimestamps createdAt={objectData.createdAt} modifiedAt={objectData.updateAt} />
      </Stack>
      {showNotes && (
        <NotesContextProvider
          entityType={getNotesEntityType(objectDefinition.objectType)}
          entityId={objectData.id}
          objectType={objectData.objectType}
          hasPermissionsToManageNotes={hasPermissionsToManageNotes}
        >
          <EntityNotesSection />
        </NotesContextProvider>
      )}
    </ScrollableColumns>
  );
};

export default ObjectMainInfo;
