import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import CenteredWrapper from "../../../../../shared/components/CenteredWrapper";
import CloseIconButton from "../../../../../shared/components/CloseIconButton";
import InlineLoader from "../../../../../shared/components/inlineLoader/InlineLoader";
import { useNotificationContext } from "../../../../../shared/contexts/NotificationContext";
import { logError } from "../../../../../shared/logging";
import { ValidationResult, invalidResult, requiredValidator } from "../../../../../shared/utilities/validators";
import adminApi, { Investor } from "../../../../api/adminApi";

interface Props {
  open: boolean;
  onClose: () => void;
  investors: Investor[];
  onCreated: (investorId: string, name: string) => void;
}

const maxNameLength = 100;

const InvestorCreateDialog: React.FC<Props> = ({ open, onClose, investors, onCreated }) => {
  const { sendNotification, sendNotificationError } = useNotificationContext();
  const [name, setName] = useState("");
  const [validationResult, setValidationResult] = useState<ValidationResult>(invalidResult(""));
  const [isLoading, setIsLoading] = useState(false);

  const handleNameChange = (name: string) => {
    setName(name);
    setValidationResult(requiredValidator(name));
    const existingInvestor = investors.find((investor) => investor.title.toLowerCase() === name.toLowerCase());
    if (existingInvestor) {
      setValidationResult(invalidResult("Investor with this name already exists"));
    }
  };

  const handleCreate = async () => {
    setIsLoading(true);
    try {
      const response = await adminApi.createInvestor({ name });
      setIsLoading(false);
      const investor = response.data;
      if (investor) {
        sendNotification("Investor created successfully");
        await onCreated(investor.id, name);
      } else {
        sendNotificationError("Investor creation failed");
      }
    } catch (error) {
      logError(error, "Investors");
      sendNotificationError("Failed to create investor");
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    setName("");
    setValidationResult(invalidResult(""));
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle>
        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
          <Typography variant="h6">Add investor</Typography>
          <CloseIconButton onClick={handleClose} />
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack spacing={1}>
          <Typography variant="subtitle1">
            Main info<span style={{ color: "red" }}>*</span>
          </Typography>
          <Typography color="text.secondary">Add the required information to create a new investor.</Typography>
        </Stack>
        <TextField
          fullWidth
          label="Name"
          variant="outlined"
          placeholder="Type name ..."
          onChange={(e) => handleNameChange(e.target.value)}
          error={validationResult.error !== ""}
          helperText={validationResult.error}
          inputProps={{ maxLength: maxNameLength }}
          sx={{ mt: 2 }}
          InputProps={{
            endAdornment: (
              <Typography color="textSecondary" variant="caption">
                {maxNameLength - name.length}
              </Typography>
            ),
          }}
        />
        {isLoading && (
          <CenteredWrapper>
            <InlineLoader />
          </CenteredWrapper>
        )}
      </DialogContent>
      <DialogActions sx={{ justifyContent: "flex-end", px: 3, py: 2 }}>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <Button
          sx={{ ml: 1 }}
          onClick={handleCreate}
          variant="contained"
          color="primary"
          disabled={!validationResult.isValid || isLoading}
        >
          Create Investor
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InvestorCreateDialog;
