import { DataSelector, Parser } from "@json2csv/plainjs";
import { maxBy } from "../../../../../shared/utilities/arrayHelper";
import { convertISODateTimeShort } from "../../../../../shared/utilities/dateUtils";
import { DocumentInfo, EmailTrackingItem, MessageDeliveryInfo, MessageLevel } from "../../../../api/adminApi";

interface DocumentInfoModel {
  name: string;
  investorTitle?: string;
  publishingStatus: string;
  category: string;
  isViewed: boolean;
  viewedAt?: string;
  deliveries: MessageDeliveryInfo[];
}

const createEmailsCsvParser = (): Parser<EmailTrackingItem> =>
  new Parser<EmailTrackingItem>({
    fields: [
      {
        label: "Recipient Name",
        value: "recipientName",
      },
      {
        label: "Recipient Email",
        value: "email",
      },
      {
        label: "Investor",
        value: "investor",
      },
      {
        label: "Subject",
        value: "subject",
      },
      {
        label: "Sent Date",
        value: (row) => convertISODateTimeShort(row.sentAt),
      },
      {
        label: "Status",
        value: "status",
      },
    ],
  });

export const emailsToCsv = (emails: EmailTrackingItem[]): string => {
  const csvParser = createEmailsCsvParser();
  return csvParser.parse(emails);
};

const getRecipientFields = (length: number) => {
  const fields: DataSelector<DocumentInfoModel>[] = [];

  for (let i = 0; i < length; ++i) {
    fields.push({ label: `(${i + 1}) Recipient Name`, value: (row) => row.deliveries[i]?.recipient.name });
    fields.push({ label: `(${i + 1}) Recipient Email`, value: (row) => row.deliveries[i]?.recipient.email });
    fields.push({ label: `(${i + 1}) Recipient Viewed`, value: (row) => row.deliveries[i]?.isRead });
    fields.push({
      label: `(${i + 1}) Recipient Viewed At`,
      value: (row) => convertISODateTimeShort(row.deliveries[i]?.readDate ?? ""),
    });
  }

  return fields;
};

const createDocumentsCsvParser = (maxRecipients: number): Parser<DocumentInfoModel> =>
  new Parser<DocumentInfoModel>({
    fields: [
      {
        label: "Name",
        value: "name",
      },
      {
        label: "Investor",
        value: "investorTitle",
      },
      {
        label: "Status",
        value: "publishingStatus",
      },
      {
        label: "Category",
        value: "category",
      },
      {
        label: "Viewed",
        value: "isViewed",
      },
      {
        label: "Viewed At",
        value: (row) => (row.viewedAt ? convertISODateTimeShort(row.viewedAt) : ""),
      },
      ...getRecipientFields(maxRecipients),
    ],
  });

const getFundLevelRows = (documents: DocumentInfo[]): DocumentInfoModel[] =>
  documents.flatMap((doc) =>
    doc.investorDeliveries.map((delivery) => ({
      name: doc.name,
      investorTitle: delivery.investorTitle,
      category: doc.category,
      publishingStatus: doc.publishingStatus,
      isViewed: delivery.isViewed,
      viewedAt: delivery.viewedAt,
      deliveries: delivery.deliveries,
    }))
  );

export const documentsToCsv = (documents: DocumentInfo[], messageLevel: MessageLevel): string => {
  const rows = messageLevel === "Fund" ? getFundLevelRows(documents) : documents;

  const maxRecipients = maxBy(
    rows.map((doc) => doc.deliveries.length),
    (count) => count
  );

  const csvParser = createDocumentsCsvParser(maxRecipients);
  return csvParser.parse(rows);
};
