import { Stack, Typography, useTheme } from "@mui/material";
import { MalwareScanningResult } from "../../../../api/adminApi";
import VerifiedShieldIcon from "../../../../icons/VerifiedShieldIcon";

interface Props {
  scanResult: MalwareScanningResult;
}

const MalwareScanBadge = ({ scanResult }: Props) => {
  const { palette } = useTheme();

  if (scanResult === "Clean") {
    return (
      <Stack direction="row" spacing={0.5} alignItems="center">
        <VerifiedShieldIcon htmlColor={palette.success.light} />
        <Typography variant="caption" color="text.secondary">
          Scanned for malware
        </Typography>
      </Stack>
    );
  }

  if (scanResult === "Malicious") {
    return (
      <Stack direction="row" spacing={0.5} alignItems="center">
        <VerifiedShieldIcon color="error" />
        <Typography variant="caption" color="error">
          Malware detected
        </Typography>
      </Stack>
    );
  }

  return null;
};

export default MalwareScanBadge;
