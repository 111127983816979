import { GridColDef, GridGroupingColDefOverride, useGridApiRef } from "@mui/x-data-grid-premium";
import { GridApiPremium } from "@mui/x-data-grid-premium/models/gridApiPremium";
import { useMemo, useRef } from "react";
import { ApiError } from "../../../../../../shared/api/types";
import DataLoadingFailed from "../../../../../../shared/components/DataLoadingFailed";
import DataGrid from "../../../../../../shared/components/grid/DataGrid";
import InlineLoader from "../../../../../../shared/components/inlineLoader/InlineLoader";
import { OrganizationReportInfo } from "../../../../../api/biApi";
import GroupHeader from "../../../../common/grid/GroupHeader";
import { headerHeight } from "../CustomHeader";
import { useCheckedGridSx } from "../hooks/useCheckedGridSx";
import GroupCell from "./GroupCell";

interface Props {
  reports: OrganizationReportInfo[];
  loading: boolean;
  error: ApiError | undefined;
  getTreeDataPath: ((row: OrganizationReportInfo) => string[]) | undefined;
  Toolbar: React.ReactNode;
  selectedIds: string[];
  currentReport: OrganizationReportInfo | undefined;
  setSelectedIds: (ids: string[]) => void;
  getColumns: (apiRef: React.MutableRefObject<GridApiPremium>) => GridColDef[];
  getViewReportUrl: (clientCode: string, reportId: string) => string;
}

const OrganizationReportsGridWrapper = ({
  reports,
  error,
  loading,
  getColumns,
  getTreeDataPath,
  Toolbar,
  selectedIds,
  setSelectedIds,
  currentReport,
  getViewReportUrl,
}: Props) => {
  const apiRef = useGridApiRef();
  const getGridSx = useCheckedGridSx();
  const showToolbar = useMemo(() => selectedIds.length > 0, [selectedIds]);

  const groupingColDef = useMemo(
    (): GridGroupingColDefOverride => ({
      sortable: true,
      headerName: "Companies",
      width: 500,
      renderCell: (params) => <GroupCell params={params} getViewReportUrl={getViewReportUrl} />,
      renderHeader: (params) => <GroupHeader params={params} />,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const sx = useMemo(() => getGridSx(showToolbar), [getGridSx, showToolbar]);

  const columns: GridColDef[] = useMemo(() => getColumns(apiRef), [apiRef, getColumns]);

  const currentReportRef = useRef<OrganizationReportInfo>();
  currentReportRef.current = currentReport;

  if (error) {
    return <DataLoadingFailed text={error.message || "Failed to load reports"} />;
  }

  return (
    <DataGrid<OrganizationReportInfo>
      apiRef={apiRef}
      treeData
      getTreeDataPath={getTreeDataPath}
      columns={columns}
      rows={reports}
      getRowId={(row) => row.reportId}
      sx={sx}
      columnHeaderHeight={headerHeight}
      rowHeight={40}
      hideFooter
      defaultGroupingExpansionDepth={2}
      loading={loading}
      checkboxSelection
      groupingColDef={groupingColDef}
      disableRowSelectionOnClick
      disableColumnMenu
      disableColumnReorder
      slots={{
        loadingOverlay: InlineLoader,
        toolbar: () => (showToolbar ? Toolbar : null),
      }}
      getRowClassName={(params) => {
        if (apiRef.current.getRowNode(params.id)?.type === "group") {
          return "";
        }
        if (params.row.reportId === currentReportRef.current?.reportId) {
          return "Mui-hovered";
        }
        return "";
      }}
      onRowSelectionModelChange={(ids) => setSelectedIds(ids as string[])}
      sortingOrder={["asc", "desc"]}
      shiftActionsLeft
    />
  );
};

export default OrganizationReportsGridWrapper;
