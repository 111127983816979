import { Button, FormControlLabel, Stack, Switch } from "@mui/material";
import { useState } from "react";
import { useCrmConnectorContext } from "./CrmConectorContext";
import DeleteConnectionDialog from "./DeleteConnectionDialog";

interface Props {
  onDelete: () => void;
  onReAuthorize: () => void;
  onTestConnection: () => void;
  onDataSyncToggle: (enabled: boolean) => void;
}

const CrmActions = ({ onDelete, onReAuthorize, onTestConnection, onDataSyncToggle }: Props) => {
  const { connectorSettings } = useCrmConnectorContext();

  const [syncEnabled, setSyncEnabled] = useState(connectorSettings.syncEnabled);

  const [deleteConnectionPrompt, setDeleteConnectionPrompt] = useState(false);

  const handleDeleteClick = () => setDeleteConnectionPrompt(true);
  const handleDeleteCancel = () => setDeleteConnectionPrompt(false);
  const handleDeleteSuccess = () => {
    setDeleteConnectionPrompt(false);
    onDelete();
  };

  const handleDataSyncToggle = () => {
    setSyncEnabled((isEnabled) => !isEnabled);
    onDataSyncToggle(!syncEnabled);
  };

  return (
    <Stack direction="row" spacing={2.5} mt={3.5} px={3} py={2}>
      <Button variant="outlined" color="secondary" onClick={onTestConnection}>
        Test Connection
      </Button>
      <Button variant="outlined" color="secondary" onClick={onReAuthorize}>
        Re-Authorize
      </Button>
      <Button variant="outlined" color="secondary" onClick={handleDeleteClick}>
        Delete
      </Button>
      <FormControlLabel
        control={<Switch checked={syncEnabled} onChange={handleDataSyncToggle} />}
        label="Data Synchronization Enabled"
      />
      <DeleteConnectionDialog
        open={deleteConnectionPrompt}
        onSuccess={handleDeleteSuccess}
        onCancel={handleDeleteCancel}
      />
    </Stack>
  );
};

export default CrmActions;
