import AddIcon from "@mui/icons-material/Add";
import { Button, Divider, List, Stack, Typography } from "@mui/material";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import CloseIconButton from "../../../../../../../shared/components/CloseIconButton";
import { TimeSeriesPeriodTableField } from "../../../../../../api/types/timeSeriesTypes";
import DragAndDropList from "../../../../../common/DragAndDropList";
import TimeSeriesFieldListItem from "./TimeSeriesFieldListItem";

interface Props {
  fields: TimeSeriesPeriodTableField[];
  isDirty: boolean;
  onClose: () => void;
  onApply: () => void;
  onRequestFieldCreate: () => void;
  onRequestFieldEdit: (field: TimeSeriesPeriodTableField) => void;
  onFieldPositionChange: (fieldId: string, newIndex: number) => void;
  onFieldVisibilityChange: (fieldId: string, isVisible: boolean) => void;
}

const TimeSeriesFieldsLayoutEditor = ({
  fields,
  isDirty,
  onClose,
  onApply,
  onRequestFieldCreate,
  onRequestFieldEdit,
  onFieldPositionChange,
  onFieldVisibilityChange,
}: Props) => {
  const handleItemDrop = (dropIndex: number, draggedField: TimeSeriesPeriodTableField | undefined) => {
    if (draggedField) {
      const newIndex = dropIndex < 0 ? fields.length : dropIndex;
      onFieldPositionChange(draggedField.id, newIndex);
    }
  };

  return (
    <Stack sx={(t) => ({ width: t.spacing(52.5), maxHeight: "80vh" })} overflow="hidden" alignItems="flex-start">
      <Stack px={2.5} py={2} direction="row" justifyContent="space-between" alignItems="center" width="100%">
        <Typography flexGrow={1} variant="h6">
          Customize Fields
        </Typography>
        <CloseIconButton onClick={onClose} />
      </Stack>
      <Divider flexItem />
      {fields.length > 0 && (
        <List sx={{ width: "100%", px: 0.5, py: 1, overflow: "auto" }}>
          <DndProvider backend={HTML5Backend}>
            <DragAndDropList
              type="time_series_field"
              items={fields}
              onDrop={handleItemDrop}
              renderItem={(field) => (
                <TimeSeriesFieldListItem
                  field={field}
                  onChangeVisibility={onFieldVisibilityChange}
                  onRequestEdit={onRequestFieldEdit}
                />
              )}
            />
          </DndProvider>
        </List>
      )}
      <Stack direction="row" alignItems="center" px={2.5} py={1.5}>
        <Button variant="text" onClick={onRequestFieldCreate} startIcon={<AddIcon />}>
          Add New Field
        </Button>
      </Stack>
      <Divider flexItem />
      <Stack px={2} py={1.5} direction="row" justifyContent="flex-end">
        <Stack spacing={1.5} direction="row">
          <Button variant="text" color="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="contained" onClick={onApply} disabled={!isDirty}>
            Apply
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default TimeSeriesFieldsLayoutEditor;
