import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { useState } from "react";
import { withErrorHandling } from "../../../../../../shared/api/axiosHelper";
import DialogCloseButton from "../../../../../../shared/components/DialogeCloseButton";
import { useNotificationContext } from "../../../../../../shared/contexts/NotificationContext";
import { logError } from "../../../../../../shared/logging";
import adminApi from "../../../../../api/adminApi";
import { Fundraising } from "../../../../../api/types/fundraisingTypes";

interface Props {
  onClose: () => void;
  onCreated: (fundraising: Fundraising) => void;
  onUpdated: (fundraising: Fundraising) => void;
  fundraisingId?: string;
  fundraisingName?: string;
}

const createFundraising = withErrorHandling(adminApi.createFundraising);
const updateFundraising = withErrorHandling(adminApi.updateFundraising);

const EditFundraisingDialog = ({ onClose, onCreated, onUpdated, fundraisingId, fundraisingName }: Props) => {
  const { sendNotification, sendNotificationError } = useNotificationContext();
  const [saving, setSaving] = useState(false);
  const [name, setName] = useState(fundraisingName || "");
  const [isTouched, setTouched] = useState(false);

  const isValid = Boolean(name.trim());
  const isSaveDisabled = !isValid || (!!fundraisingName && name.trim() === fundraisingName.trim());

  const handleCreateFundraising = async () => {
    if (!isValid) {
      return;
    }

    setSaving(true);
    const [resp, error] = await createFundraising({ name: name.trim() });
    setSaving(false);

    if (error) {
      logError(error, "[EditFundraisingDialog]");
      sendNotificationError("Could not create fundraising");
    } else {
      sendNotification(`Fundraising "${name}" created successfully`);
      onCreated(resp);
    }
  };

  const handleUpdateFundraising = async (id: string) => {
    if (!isValid) {
      return;
    }

    setSaving(true);
    const [resp, error] = await updateFundraising(id, { name: name.trim() });
    setSaving(false);

    if (error) {
      sendNotificationError("Could not update fundraising");
    } else {
      sendNotification(`Fundraising "${name}" updated successfully`);
      onUpdated(resp);
    }
  };

  const handleSubmit = async () => {
    if (fundraisingId === undefined) {
      await handleCreateFundraising();
    } else {
      await handleUpdateFundraising(fundraisingId);
    }
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
    setTouched(true);
  };

  const isNew = !fundraisingId;
  const showValidationError = !isValid && isTouched;

  return (
    <Dialog open onClose={onClose} PaperProps={{ sx: { width: "32.5rem" } }}>
      <DialogTitle>{isNew ? "New Fundraising" : "Rename Fundraising"}</DialogTitle>
      <DialogCloseButton onClick={onClose} />
      <DialogContent>
        <TextField
          fullWidth
          autoFocus
          label="Name"
          value={name}
          onChange={handleNameChange}
          error={showValidationError}
          helperText={showValidationError ? "Name cannot be empty" : ""}
        />
      </DialogContent>
      <DialogActions sx={{ py: 2, px: 3, columnGap: 1 }}>
        <Button variant="text" color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton disabled={isSaveDisabled} variant="contained" loading={saving} onClick={handleSubmit}>
          {isNew ? "Create" : "Save"}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default EditFundraisingDialog;
