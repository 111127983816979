import DeleteIcon from "@mui/icons-material/DeleteOutlineRounded";
import EditIcon from "@mui/icons-material/EditOutlined";
import DownloadIcon from "@mui/icons-material/SaveAltRounded";
import { Button, Menu, MenuItem } from "@mui/material";
import { useRef, useState } from "react";
import CustomDataGridHeaderToolbar from "../../../../../common/grid/CustomDataGridHeaderToolbar";
import { useFundraisingDetailsPageContext } from "../FundraisingDetailsPageContext";
import { useFundraisingDocumentsContext } from "./FundraisingDocumentsContext";
import { deleteDocumentsAction, updateDocumentsCategoryAction } from "./fundraisingDocumentsState";

interface Props {
  selectedIds: string[];
  setSelectedIds: (ids: string[]) => void;
  disabled?: boolean;
}

const FundraisingDocumentsGridToolbar = ({ selectedIds, setSelectedIds, disabled }: Props) => {
  const { categories, isContentEditable } = useFundraisingDetailsPageContext();
  const { setDocumentsState, onDownloadMultipleFiles } = useFundraisingDocumentsContext();

  const [isCategorySelectorOpen, setCategorySelectorOpen] = useState(false);
  const changeCategoryButtonRef = useRef<HTMLButtonElement>(null);

  return (
    <CustomDataGridHeaderToolbar sx={{ height: "3.25rem" }} selection={[selectedIds, setSelectedIds]}>
      {isContentEditable && (
        <Button
          ref={changeCategoryButtonRef}
          disabled={disabled}
          onClick={() => setCategorySelectorOpen(true)}
          color="secondary"
          variant="text"
          startIcon={<EditIcon />}
        >
          Change Category
        </Button>
      )}

      <Button
        disabled={disabled}
        onClick={() => onDownloadMultipleFiles(selectedIds)}
        color="secondary"
        variant="text"
        startIcon={<DownloadIcon />}
      >
        Download
      </Button>

      {isContentEditable && (
        <Button
          disabled={disabled}
          onClick={() => setDocumentsState(deleteDocumentsAction(selectedIds))}
          color="error"
          variant="text"
          startIcon={<DeleteIcon />}
        >
          Delete
        </Button>
      )}

      <Menu
        open={isCategorySelectorOpen}
        onClose={() => setCategorySelectorOpen(false)}
        anchorEl={changeCategoryButtonRef.current}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        {categories.map((category) => (
          <MenuItem
            key={category.externalId}
            value={category.externalId}
            dense
            onClick={() =>
              setDocumentsState(updateDocumentsCategoryAction(selectedIds, category.externalId, category.name))
            }
          >
            {category.name}
          </MenuItem>
        ))}
      </Menu>
    </CustomDataGridHeaderToolbar>
  );
};

export default FundraisingDocumentsGridToolbar;
