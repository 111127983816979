import { NumberFormatFactor } from "../../../../../../../shared/utilities/formatters";
import {
  combineValidators,
  definedValidator,
  invalidResult,
  requiredValidator,
  uniqueValidator,
  validResult,
} from "../../../../../../../shared/utilities/validators";
import {
  CreateTimeSeriesFieldRequest,
  DateFormat,
  TimeSeriesField,
  TimeSeriesFieldType,
  UpdateTimeSeriesFieldRequest,
} from "../../../../../../api/types/timeSeriesTypes";

export interface FieldEditForm {
  fieldId: string | undefined;
  name: string;
  fieldType: TimeSeriesFieldType;
  precision: number | undefined;
  factor: NumberFormatFactor | undefined;
  dateFormat: DateFormat | undefined;
  currencyCode: string | undefined;
  valueOptions: string[] | undefined;
  errors: {
    name?: string;
    fieldType?: string;
    valueOptions?: string;
  };
  isValid: boolean;
}

export const getEmptyFieldEditForm = (): FieldEditForm => ({
  fieldId: undefined,
  name: "",
  fieldType: "Number",
  precision: undefined,
  factor: undefined,
  dateFormat: undefined,
  currencyCode: undefined,
  valueOptions: undefined,
  errors: {},
  isValid: false,
});

export const createFieldEditForm = (field: TimeSeriesField): FieldEditForm => ({
  fieldId: field.id,
  name: field.name,
  fieldType: field.type,
  precision: field.configuration.precision,
  factor: field.configuration.numberFormatFactor,
  dateFormat: field.configuration.dateFormat,
  currencyCode: field.configuration.currencyCode,
  valueOptions: field.configuration.valueOptions,
  errors: {},
  isValid: true,
});

export const mapFormToCreateFieldRequest = (form: FieldEditForm): CreateTimeSeriesFieldRequest => ({
  name: form.name.trim(),
  type: form.fieldType,
  configuration: {
    precision: form.precision,
    numberFormatFactor: form.factor,
    dateFormat: form.dateFormat,
    currencyCode: form.currencyCode,
    valueOptions: form.valueOptions?.map((o) => o.trim()).filter(Boolean),
  },
});

export const mapFormToUpdateFieldRequest = (form: FieldEditForm): UpdateTimeSeriesFieldRequest => ({
  name: form.name.trim(),
  configuration: {
    precision: form.precision,
    numberFormatFactor: form.factor,
    dateFormat: form.dateFormat,
    currencyCode: form.currencyCode,
    valueOptions: form.valueOptions?.map((o) => o.trim()).filter(Boolean),
  },
});

const validateOptions = (options: string[]) => {
  const sanitizedOptions = options.map((o) => o.trim()).filter(Boolean);
  if (sanitizedOptions.length === 0) {
    return invalidResult("At least one option is required");
  }

  const distinctOptions = new Set(sanitizedOptions);
  if (distinctOptions.size !== sanitizedOptions.length) {
    return invalidResult("Options must be unique");
  }

  return validResult();
};

export const validateFieldEditForm = (form: FieldEditForm, existingFieldNames: string[]): FieldEditForm => {
  const validateName = combineValidators(
    requiredValidator,
    uniqueValidator("Such field name already exists", existingFieldNames)
  );

  const validateFieldType = definedValidator<TimeSeriesFieldType>("Field type is required");

  const nameValidationResult = validateName(form.name.trim());
  const fieldTypeValidationResult = validateFieldType(form.fieldType);
  const valueOptionsValidationResult =
    form.fieldType === "Select" ? validateOptions(form.valueOptions ?? []) : validResult();

  return {
    ...form,
    errors: {
      name: nameValidationResult.error,
      fieldType: fieldTypeValidationResult.error,
      valueOptions: valueOptionsValidationResult.error,
    },
    isValid: nameValidationResult.isValid && fieldTypeValidationResult.isValid && valueOptionsValidationResult.isValid,
  };
};
