import { Stack, Typography } from "@mui/material";
import { convertISODate } from "../../../../../shared/utilities/dateUtils";
import { ApprovalRequest } from "../../../../api/mock/approvalsTypes";
import ApprovalStatusTag from "../ApprovalStatusTag";

interface Props {
  approvalRequest: ApprovalRequest;
}

const ApprovalDetailsTitle = ({ approvalRequest }: Props) => {
  return (
    <Stack py={1.5} spacing={0.5}>
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography variant="h6">{approvalRequest.name}</Typography>
        <ApprovalStatusTag status={approvalRequest.status} />
      </Stack>
      <Stack direction="row" spacing={1}>
        <Typography variant="caption">{approvalRequest.transactionType}</Typography>
        <Typography variant="caption">•</Typography>
        <Typography variant="caption">{convertISODate(approvalRequest.dueDate)}</Typography>
      </Stack>
    </Stack>
  );
};

export default ApprovalDetailsTitle;
