import { Chip, ListItemText, MenuItem, Select, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { PortalRole } from "../../../../api/adminApi";
import { investorPortalPortalRolesMap } from "./investorPortalPortalRolesMap";

interface Props {
  readonly: boolean;
  roles: PortalRole | PortalRole[] | undefined;
  onChange?: (roles: PortalRole[]) => void;
  multiple?: boolean;
}
const noneRole = "none";

const PortalRoleSelector = ({ roles, onChange, readonly, multiple }: Props) => {
  return (
    <Select
      sx={{
        boxShadow: "none",
        ".MuiSelect-select": readonly ? { px: "0 !important", cursor: "default" } : {},
        ".MuiOutlinedInput-notchedOutline": { border: 0 },
        "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
          border: 0,
        },
        "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
          border: 0,
        },
        ".MuiSvgIcon-root": {
          display: readonly ? "none" : "block",
        },
      }}
      readOnly={readonly}
      multiple={multiple}
      value={roles ? (roles.length === 0 ? noneRole : roles) : ""}
      displayEmpty
      onChange={(event) => {
        if (event.target.value === "" || event.target.value === noneRole) {
          onChange?.([]);
        } else {
          onChange?.(Array.isArray(event.target.value) ? event.target.value : [event.target.value as PortalRole]);
        }
      }}
      renderValue={
        roles === undefined
          ? () => (
              <Typography variant="caption" color="textSecondary">
                Select
              </Typography>
            )
          : undefined
      }
    >
      <MenuItem value={noneRole}>
        <ListItemText
          primary={<Chip sx={{ backgroundColor: "transparent", border: 1, borderColor: grey[200] }} label="None" />}
        />
      </MenuItem>
      {Object.entries(investorPortalPortalRolesMap).map(([role, definition]) => (
        <MenuItem key={role} value={role}>
          <ListItemText primary={<Chip sx={{ backgroundColor: definition.color }} label={definition.text} />} />
        </MenuItem>
      ))}
    </Select>
  );
};

export default PortalRoleSelector;
