import LocalPhoneRoundedIcon from "@mui/icons-material/LocalPhoneRounded";
import MailOutlineRoundedIcon from "@mui/icons-material/MailOutlineRounded";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import CenteredWrapper from "../../../../../shared/components/CenteredWrapper";
import CloseIconButton from "../../../../../shared/components/CloseIconButton";
import InlineLoader from "../../../../../shared/components/inlineLoader/InlineLoader";
import { useNotificationContext } from "../../../../../shared/contexts/NotificationContext";
import { logError } from "../../../../../shared/logging";
import {
  ValidationResult,
  emailValidator,
  invalidResult,
  requiredValidator,
  validResult,
} from "../../../../../shared/utilities/validators";
import adminApi, { Contact, CreateContactData } from "../../../../api/adminApi";

interface Props {
  open: boolean;
  onClose: () => void;
  onNewContactCreated: (contactId: string, contactData: CreateContactData) => Promise<void>;
  contacts: Contact[];
}

const maxNameLength = 100;

const ContactCreateDialog = ({ open, onClose, onNewContactCreated, contacts }: Props) => {
  const { sendNotification, sendNotificationError } = useNotificationContext();
  const [contactData, setContactData] = useState<CreateContactData>({ name: "", phone: "", email: undefined });
  const [nameValidationResult, setNameValidationResult] = useState<ValidationResult>(invalidResult(""));
  const [emailValidationResult, setEmailValidationResult] = useState<ValidationResult>(validResult());
  const [nameCollisionWarning, setNameCollisionWarning] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);

  const handleNameChange = (name: string) => {
    setContactData({ ...contactData, name });
    const validationResult = requiredValidator(name);
    setNameValidationResult(validationResult);
    if (validationResult.isValid) {
      const existingContact = contacts.find((c) => c.name.toLowerCase() === name.toLowerCase());
      setNameCollisionWarning(existingContact ? "A contact with this name already exists" : undefined);
    }
  };

  const handleEmailChange = (email: string) => {
    setContactData({ ...contactData, email });
    setEmailValidationResult(validResult());
  };

  const handleCreate = async () => {
    const email = contactData.email;
    if (email) {
      const emailValidationResult = emailValidator(email);
      setEmailValidationResult(emailValidationResult);
      if (!emailValidationResult.isValid) {
        return;
      }

      const existingContact = contacts.find((c) => c.email && email && c.email.toLowerCase() === email.toLowerCase());
      if (existingContact) {
        setEmailValidationResult(invalidResult("A contact with this email already exists"));
        return;
      }
    }

    setIsLoading(true);
    try {
      const response = await adminApi.createContact(contactData);
      setIsLoading(false);
      const contact = response.data;
      if (contact) {
        sendNotification("Contact created successfully");
        reset();
        await onNewContactCreated(contact.id, contactData);
      } else {
        sendNotificationError("Contact creation failed");
      }
    } catch (error) {
      logError(error, "Contact");
      sendNotificationError("Failed to create contact");
      setIsLoading(false);
    }
  };

  const reset = () => {
    setContactData({ name: "", phone: undefined, email: undefined });
    setNameValidationResult(invalidResult(""));
    setEmailValidationResult(validResult());
  };

  const handleClose = () => {
    reset();
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle>
        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
          <Typography variant="h6">Add contact</Typography>
          <CloseIconButton onClick={handleClose} />
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack spacing={1}>
          <Typography variant="subtitle1">
            Main info<span style={{ color: "red" }}>*</span>
          </Typography>
          <Typography color="text.secondary">Add the required information to create a new contact.</Typography>
        </Stack>
        <TextField
          fullWidth
          label="Full Name"
          variant="outlined"
          placeholder="Type full name ..."
          onChange={(e) => handleNameChange(e.target.value)}
          error={nameValidationResult.error !== ""}
          helperText={nameValidationResult.error || nameCollisionWarning}
          inputProps={{ maxLength: maxNameLength }}
          sx={{ mt: 2 }}
          InputProps={{
            endAdornment: (
              <Typography color="textSecondary" variant="caption">
                {maxNameLength - contactData.name.length}
              </Typography>
            ),
          }}
        />

        <Stack spacing={2} mt={4}>
          <Stack direction={"row"} spacing={1} mt={4} alignItems={"center"}>
            <Typography variant="subtitle1">Communication</Typography>
            <Typography variant="caption" color="text.secondary">
              (Optional)
            </Typography>
          </Stack>

          <TextField
            fullWidth
            label="Email"
            variant="outlined"
            onChange={(e) => handleEmailChange(e.target.value)}
            value={contactData.email ?? ""}
            error={emailValidationResult.error !== ""}
            helperText={emailValidationResult.error}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <MailOutlineRoundedIcon color="secondary" />
                </InputAdornment>
              ),
            }}
          />

          <TextField
            fullWidth
            label="Phone"
            variant="outlined"
            onChange={(e) => setContactData({ ...contactData, phone: e.target.value.replace(/[^\d()+\-\s]/g, "") })}
            value={contactData.phone ?? ""}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LocalPhoneRoundedIcon color="secondary" />
                </InputAdornment>
              ),
            }}
          />
        </Stack>

        {isLoading && (
          <CenteredWrapper>
            <InlineLoader />
          </CenteredWrapper>
        )}
      </DialogContent>
      <DialogActions sx={{ justifyContent: "flex-end", px: 3, py: 2 }}>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <Button
          sx={{ ml: 1 }}
          onClick={handleCreate}
          variant="contained"
          color="primary"
          disabled={!nameValidationResult.isValid || isLoading || !emailValidationResult.isValid}
        >
          Create Contact
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ContactCreateDialog;
