import { TabPanel } from "@mui/lab";
import { useState } from "react";
import { Location, useLocation } from "react-router-dom";
import GeneralPageHeader from "../../../common/GeneralPageHeader";
import PageTabs from "../../../common/PageTabs";
import InvestorPortalImpersonateButton from "../InvestorPortalImpersonateButton";
import ConsentsSection from "../consents/ConsentsSection";
import PortalDesignSettings from "../design/PortalDesignSettings";
import GeneralInvestorPortalSettings from "../general/GeneralInvestorPortalSettings";
import InvitationEmailSection from "../invitation-email/InvitationEmailSection";
import PortalPagesSettings from "../pages/PortalPagesSettings";
import PortalSupportInfo from "../support/PortalSupportInfo";
import WatermarkSettingsPage from "../watermark/WatermarkSettingsPage";

const tabs = [
  "general",
  "portal_design",
  "pages",
  "watermark",
  "user_consents",
  "support_info",
  "invitation_email",
] as const;

type Tabs = (typeof tabs)[number];

const labels = [
  "Main Info",
  "Portal Design",
  "Pages",
  "Watermark",
  "User Consents",
  "Support Info",
  "Invitation Email",
];

export interface NavigationState {
  tab: Tabs;
}

const getInitialSelectedTab = (location: Location<NavigationState>) => {
  const navToParam = new URLSearchParams(location.search).get("navTo");
  return (navToParam as Tabs) || location.state?.tab || "general";
};

const InvestorPortalSettingsPage = () => {
  const location = useLocation();
  const [selectedTab, setSelectedTab] = useState<Tabs>(getInitialSelectedTab(location));

  return (
    <>
      <GeneralPageHeader title="Portal Settings" hideBorderLine>
        <InvestorPortalImpersonateButton variant="outlined" color="secondary" />
      </GeneralPageHeader>
      <PageTabs tabs={tabs} labels={labels} value={selectedTab} onChange={setSelectedTab}>
        <TabPanel value="general" sx={{ height: "100%", pt: 2 }}>
          <GeneralInvestorPortalSettings />
        </TabPanel>
        <TabPanel value="portal_design" sx={{ height: "100%", pt: 2 }}>
          <PortalDesignSettings />
        </TabPanel>
        <TabPanel value="pages" sx={{ height: "100%", pt: 2 }}>
          <PortalPagesSettings />
        </TabPanel>
        <TabPanel value="watermark" className="no-margin" sx={{ height: "100%", p: 0, "&.no-margin": { m: 0 } }}>
          <WatermarkSettingsPage />
        </TabPanel>
        <TabPanel value="user_consents" sx={{ pt: 2, height: "100%" }}>
          <ConsentsSection />
        </TabPanel>
        <TabPanel value="support_info" sx={{ height: "100%", pt: 2 }}>
          <PortalSupportInfo />
        </TabPanel>
        <TabPanel value="invitation_email" sx={{ height: "100%", pt: 2 }}>
          <InvitationEmailSection />
        </TabPanel>
      </PageTabs>
    </>
  );
};

export default InvestorPortalSettingsPage;
