import { useCallback } from "react";
import DataLoadingFailed from "../../../../../../shared/components/DataLoadingFailed";
import InlineLoader from "../../../../../../shared/components/inlineLoader/InlineLoader";
import useFetch from "../../../../../../shared/hooks/useFetch";
import { logError } from "../../../../../../shared/logging";
import adminApi, { Category, Investor } from "../../../../../api/adminApi";
import InvestorCommunicationMatrix from "./InvestorCommunicationMatrix";

interface Props {
  investor: Investor;
  categories: Category[];
}

const InvestorCommunicationMatrixTab = ({ categories, investor }: Props) => {
  const fetchInvestorPermissionsResponse = useCallback(() => adminApi.getInvestorPermissions(investor.id), [investor]);
  const getContacts = useCallback(
    async () =>
      adminApi.searchContacts({ fieldIds: [], includeFunds: true, includeInvestors: true, includeInboxData: false }),
    []
  );

  const [investorPermissions, error] = useFetch(fetchInvestorPermissionsResponse);
  const [contacts, contactsLoadError] = useFetch(getContacts);

  if (error || contactsLoadError) {
    logError(error || contactsLoadError, "InvestorDetailsCommunicationMatrixPage");
    return <DataLoadingFailed bgColor="none" title="Loading investor permissions" />;
  }

  if (investorPermissions === undefined || contacts === undefined) {
    return <InlineLoader />;
  }

  const { permissions } = investorPermissions;

  return (
    <InvestorCommunicationMatrix
      investor={investor}
      categories={categories}
      permissions={permissions}
      contacts={contacts.items}
    />
  );
};

export default InvestorCommunicationMatrixTab;
