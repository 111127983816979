import { Typography } from "@mui/material";
import CenteredWrapper from "../../../../../../shared/components/CenteredWrapper";
import DataGrid from "../../../../../../shared/components/grid/DataGrid";
import { SentEmail } from "../../../../../api/adminApi";
import { getContactEmailsColumns } from "./contactEmailsTableDefinitions";

interface Props {
  emails: SentEmail[];
}

const ContactEmailsTable = ({ emails }: Props) => {
  return (
    <DataGrid<SentEmail>
      getRowId={(row) => row.id}
      slots={{
        noRowsOverlay: () => (
          <CenteredWrapper>
            <Typography color="textSecondary">No emails</Typography>
          </CenteredWrapper>
        ),
      }}
      columns={getContactEmailsColumns()}
      rows={emails}
      rowHeight={56}
      disableRowSelectionOnClick
      hideFooter
      disableColumnFilter
      disableColumnSelector
      disableColumnMenu
    />
  );
};

export default ContactEmailsTable;
