import { Box, Stack, Typography } from "@mui/material";
import { DocumentInfo } from "../../../../../../api/adminApi";
import CategoryInlineTags from "../../../../../common/CategoryInlineTags";

interface Props {
  document: DocumentInfo;
}

const DocumentPreviewHeader = ({ document }: Props) => (
  <Stack spacing={1} mb={2} ml={1.5}>
    <Box display="flex" justifyContent="space-between">
      <Typography variant="subtitle1">{decodeURIComponent(document.name)}</Typography>
      <CategoryInlineTags categoryNames={[document.category]} />
    </Box>
    <Typography>{document.investorTitle}</Typography>
  </Stack>
);

export default DocumentPreviewHeader;
