import ClockIcon from "@mui/icons-material/AccessTimeOutlined";
import { Stack, Typography } from "@mui/material";
import { convertISODate, formatDate } from "../../../../../../../shared/utilities/dateUtils";
import { MessageDeliveryRecipient } from "../../../../../../api/adminApi";
import { useDocumentCollectionContext } from "../../DocumentCollectionContext";

interface Props {
  recipient: MessageDeliveryRecipient;
}

export const getRecipientStatusText = (recipient: MessageDeliveryRecipient, sentInvites: Record<string, Date>) => {
  if (recipient.isRegistered) {
    return recipient.lastActivity ? `Last activity ${convertISODate(recipient.lastActivity)}` : "Registered";
  }

  if (!recipient.isInvited) {
    return "Not yet registered";
  }

  if (recipient.referenceId) {
    const storedInviteDate = sentInvites[recipient.referenceId];
    if (storedInviteDate) {
      return `Not yet registered - Invited ${formatDate(storedInviteDate)}`;
    }
  }

  if (recipient.invitedAt) {
    return `Not yet registered - Invited ${convertISODate(recipient.invitedAt)}`;
  }

  return "Not yet registered - Invited";
};

const ContactDeliveryStatus = ({ recipient }: Props) => {
  const { sentInvites } = useDocumentCollectionContext();

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <ClockIcon />
      <Typography color="text.secondary">{getRecipientStatusText(recipient, sentInvites)}</Typography>
    </Stack>
  );
};

export default ContactDeliveryStatus;
