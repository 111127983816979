import MoneyIcon from "@mui/icons-material/AttachMoney";
import DescriptionIcon from "@mui/icons-material/DescriptionOutlined";
import PersonIcon from "@mui/icons-material/PersonOutlineRounded";
import { Box, Button, Stack } from "@mui/material";
import SearchField from "../../../../../shared/components/inputs/SearchField";
import { MessagePublishStatus } from "../../../../api/adminApi";
import VisibilityIcon from "../../../../icons/VisibleIcon";
import MultiSelect, { MultiSelectOption } from "../../../common/MultiSelect";
import RecordCounter from "../../../common/filters/RecordCounter";
import { FundStructureDocumentsFilterValue } from "./fundStructureDocumentsState";

interface Props {
  categoryOptions: SelectOption[];
  fundOptions?: SelectOption[];
  investorOptions?: SelectOption[];
  filterState: FundStructureDocumentsFilterValue;
  onChange: (newFilterState: FundStructureDocumentsFilterValue) => void;
  onClear: () => void;
  isLoading?: boolean;
  recordsCount: number;
  totalRecordsCount: number;
}

interface SelectOption {
  value: string;
  label: string;
}

const FundStructureDocumentFilters = ({
  categoryOptions,
  fundOptions,
  investorOptions,
  filterState,
  onChange,
  onClear,
  isLoading,
  recordsCount,
  totalRecordsCount,
}: Props) => {
  const statusOptions: MultiSelectOption<MessagePublishStatus>[] = [
    { value: "Live", label: "Live" },
    { value: "Preview", label: "Preview" },
  ];

  const handleSearch = (searchTerm: string) => onChange({ ...filterState, searchTerm });

  const handleInvestorsChange = (investorIds: string[]) => onChange({ ...filterState, fundInvestorIds: investorIds });

  const handleFundsChange = (fundIds: string[]) => onChange({ ...filterState, fundIds });

  const handleCategoriesChange = (categoryIds: string[]) => onChange({ ...filterState, categoryIds });

  const handleStatusesChange = (statuses: MessagePublishStatus[]) => onChange({ ...filterState, statuses });

  const showClearButton =
    filterState.categoryIds.length +
      filterState.fundIds.length +
      filterState.fundInvestorIds.length +
      filterState.statuses.length >
    0;

  return (
    <Stack alignItems="center" direction="row" justifyContent="space-between" flexWrap="wrap" rowGap={2}>
      <Stack alignItems="center" direction="row" spacing={1.5} mr={1}>
        <Box width="8rem">
          <RecordCounter records={recordsCount} total={totalRecordsCount} />
        </Box>
        {investorOptions && (
          <MultiSelect
            sortValues
            placeholder="Investors"
            options={investorOptions}
            selectedValues={filterState.fundInvestorIds}
            onChange={handleInvestorsChange}
            StartIcon={PersonIcon}
            disabled={isLoading}
          />
        )}
        {fundOptions && (
          <MultiSelect
            sortValues
            placeholder="Funds"
            options={fundOptions}
            selectedValues={filterState.fundIds}
            onChange={handleFundsChange}
            StartIcon={MoneyIcon}
            disabled={isLoading}
          />
        )}
        <MultiSelect
          placeholder="Category"
          options={categoryOptions}
          selectedValues={filterState.categoryIds}
          onChange={handleCategoriesChange}
          StartIcon={DescriptionIcon}
          disabled={isLoading}
        />
        <MultiSelect
          placeholder="Status"
          options={statusOptions}
          selectedValues={filterState.statuses}
          onChange={handleStatusesChange}
          StartIcon={VisibilityIcon}
          disabled={isLoading}
        />
        {showClearButton && (
          <Button variant="text" onClick={onClear}>
            Clear All
          </Button>
        )}
      </Stack>
      <SearchField onSearch={handleSearch} debounceTimeMs={500} />
    </Stack>
  );
};

export default FundStructureDocumentFilters;
