import DataGrid from "../../../../../../shared/components/grid/DataGrid";
import { EmailTrackingItem } from "../../../../../api/adminApi";
import { getColumns } from "./emailsTableDataProvider";

interface Props {
  emails: EmailTrackingItem[];
}

const EmailsTable = ({ emails }: Props) => {
  return (
    <DataGrid<EmailTrackingItem>
      columns={getColumns()}
      rows={emails}
      rowHeight={56}
      disableRowSelectionOnClick
      hideFooter
      disableColumnFilter
      disableColumnSelector
      disableColumnMenu
    />
  );
};

export default EmailsTable;
