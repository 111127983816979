import GroupCollapseIcon from "@mui/icons-material/ArrowDropDownRounded";
import GroupExpandIcon from "@mui/icons-material/ArrowRightRounded";
import { Stack, Typography } from "@mui/material";
import CenteredWrapper from "../../../../../../shared/components/CenteredWrapper";
import DataGrid from "../../../../../../shared/components/grid/DataGrid";
import InlineLoader from "../../../../../../shared/components/inlineLoader/InlineLoader";
import { DocumentViews } from "../../../../../api/types/documentActivityTypes";
import DocumentViewsGridRowDetail from "../../../investor-portal/document-activity/DocumentViewsGridRowDetail";
import { columnDefinitions } from "./userDocumentActivityGridDataProvider";

interface Props {
  documentViews: DocumentViews[];
  loading: boolean;
  excludeColumns?: string[];
}

const UserDocumentActivitySection = ({ documentViews, loading, excludeColumns }: Props) => {
  const columnVisibilityModel = Object.fromEntries((excludeColumns ?? []).map((fieldId) => [fieldId, false]));

  return (
    <Stack spacing={2} height="100%">
      <Typography variant="subtitle2" pt={1}>
        All Activity
      </Typography>
      <DataGrid<DocumentViews>
        rows={documentViews}
        columns={columnDefinitions}
        columnHeaderHeight={36}
        rowHeight={52}
        loading={loading}
        getDetailPanelContent={({ row }) =>
          Object.values(row.pageViews).some((val) => val > 0) ? <DocumentViewsGridRowDetail row={row} /> : undefined
        }
        getDetailPanelHeight={() => 256}
        slots={{
          loadingOverlay: () => <InlineLoader />,
          noRowsOverlay: () => (
            <CenteredWrapper>
              <Typography color="text.secondary">No document activity</Typography>
            </CenteredWrapper>
          ),
          detailPanelExpandIcon: GroupExpandIcon,
          detailPanelCollapseIcon: GroupCollapseIcon,
        }}
        columnVisibilityModel={columnVisibilityModel}
        hideFooter
        disableColumnFilter
        disableColumnSelector
        disableColumnSorting
        disableColumnMenu
        disableColumnReorder
        disableRowSelectionOnClick
      />
    </Stack>
  );
};

export default UserDocumentActivitySection;
