import {
  GRID_TREE_DATA_GROUPING_FIELD,
  GridEventListener,
  GridRowSelectionModel,
  GridRowsProp,
} from "@mui/x-data-grid-premium";
import { GridApiPremium } from "@mui/x-data-grid-premium/models/gridApiPremium";
import { useEffect, useMemo } from "react";
import DataGrid from "../../../../../../shared/components/grid/DataGrid";
import {
  getVisibleRowIds,
  selectNexRow,
  selectPreviousRow,
} from "../../../../../../shared/utilities/dataGridApiHelper";
import { DocumentInfo } from "../../../../../api/adminApi";
import { useGridGroupsExpanded } from "../../../../common/grid/useGridGroupsExpanded";
import {
  FundLevelRowModel,
  getFundLevelColumns,
  getFundLevelGroupingColumn,
  getFundLevelRows,
} from "./documentsTableDataProvider";

interface Props {
  apiRef: React.MutableRefObject<GridApiPremium>;
  documents: DocumentInfo[];
  showPublishingStatus: boolean;
  selection: GridRowSelectionModel | undefined;
  onSelectionChange: (selection: GridRowSelectionModel) => void;
}

const DocumentsTableFundLevel = ({ documents, showPublishingStatus, selection, onSelectionChange, apiRef }: Props) => {
  const expandedGroupIds = useGridGroupsExpanded(apiRef);
  const rows: GridRowsProp<FundLevelRowModel> = useMemo(() => getFundLevelRows(documents), [documents]);

  useEffect(() => {
    const firstRowId = getVisibleRowIds(apiRef)[0];
    if (firstRowId) {
      setTimeout(() => {
        onSelectionChange([firstRowId]);
        apiRef.current.setCellFocus(firstRowId, GRID_TREE_DATA_GROUPING_FIELD);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCellKeyDown: GridEventListener<"cellKeyDown"> = (_, e) => {
    if (e.code !== "ArrowUp" && e.code !== "ArrowDown") {
      return;
    }

    if (e.code === "ArrowDown") {
      selectNexRow(apiRef);
    } else if (e.code === "ArrowUp") {
      selectPreviousRow(apiRef);
    }

    e.stopPropagation();
    e.preventDefault();
  };

  const handleRowClick: GridEventListener<"rowClick"> = ({ id }) => {
    onSelectionChange([id]);
  };

  const handleRowSelectionModelChange = (newSelection: GridRowSelectionModel) => {
    if (newSelection.length === 0 && selection && selection.length > 0) {
      onSelectionChange(selection); // MUI DataGrid sometimes resets selection to empty by itself, prevent this
    } else {
      onSelectionChange(newSelection);
    }
  };

  return (
    <DataGrid
      apiRef={apiRef}
      treeData
      getTreeDataPath={(row) => [row.group, row.investor]}
      groupingColDef={getFundLevelGroupingColumn()}
      columns={getFundLevelColumns()}
      rows={rows}
      rowHeight={40}
      rowSelectionModel={selection}
      onRowSelectionModelChange={handleRowSelectionModelChange}
      onCellKeyDown={handleCellKeyDown}
      onRowClick={handleRowClick}
      isGroupExpandedByDefault={(node) => expandedGroupIds.includes(node.id)}
      disableRowSelectionOnClick
      disableMultipleRowSelection
      hideFooter
      disableColumnFilter
      disableColumnSelector
      disableColumnMenu
      columnVisibilityModel={{
        publishingStatus: showPublishingStatus,
      }}
    />
  );
};

export default DocumentsTableFundLevel;
