import DownloadIcon from "@mui/icons-material/SaveAltRounded";
import { IconButton, Stack } from "@mui/material";
import { FundStructureDocument } from "../../../../api/adminApi";
import { useClientContext } from "../../../../context/ClientContext";
import { useUserContext } from "../../../../context/UserContext";
import AnalyticsIcon from "../../../../icons/AnalyticsIcon";
import { PortalFeature } from "../../../../utilities/featureManagement";

interface Props {
  row: FundStructureDocument;
  attachmentId: string | undefined;
  onNavigateToDocumentActivity?: (postMessageRequestId: string, attachmentId: string) => void;
  onDownloadClick: (row: FundStructureDocument) => void;
}

const FundStructureDocumentsGridRowActions = ({
  row,
  attachmentId,
  onNavigateToDocumentActivity,
  onDownloadClick,
}: Props) => {
  const { isPortalFeatureEnabled } = useUserContext();
  const { hasPermissions } = useClientContext();

  const hasAccessToDocumentActivity = hasPermissions(["ViewInvestorPortalDocumentViewsAnalytics"]);

  return (
    <Stack direction="row" spacing={0.5} width="100%" justifyContent="flex-end">
      {isPortalFeatureEnabled(PortalFeature.DocumentActivity) &&
        hasAccessToDocumentActivity &&
        onNavigateToDocumentActivity &&
        attachmentId && (
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              onNavigateToDocumentActivity(row.documentCollectionId, attachmentId);
            }}
          >
            <AnalyticsIcon />
          </IconButton>
        )}
      {row.attachmentIds.length > 0 && (
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            onDownloadClick(row);
          }}
        >
          <DownloadIcon />
        </IconButton>
      )}
    </Stack>
  );
};

export default FundStructureDocumentsGridRowActions;
