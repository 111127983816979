import UpIcon from "@mui/icons-material/ExpandLessRounded";
import DownIcon from "@mui/icons-material/ExpandMoreRounded";
import DownloadIcon from "@mui/icons-material/FileDownloadOutlined";
import { Box, Button, Divider, Paper, Stack, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { useCallback, useState } from "react";
import { withErrorHandling } from "../../../../../shared/api/axiosHelper";
import InlineLoader from "../../../../../shared/components/inlineLoader/InlineLoader";
import { useNotificationContext } from "../../../../../shared/contexts/NotificationContext";
import { logError } from "../../../../../shared/logging";
import { downloadFileFromUrl } from "../../../../../shared/services/downloadFile";
import adminApi, { FileInfo } from "../../../../api/adminApi";
import OutlinedIconButton from "../../../common/OutlinedIconButton";
import DocumentPreview from "../../../common/document-preview/DocumentPreview";

interface Props {
  files: FileInfo[];
  loading?: boolean;
}

const getDownloadUrlForMultipleFiles = withErrorHandling(adminApi.getDownloadUrlForMultipleFiles);

const ApprovalFilesPreview = ({ files, loading }: Props) => {
  const { sendNotificationError } = useNotificationContext();
  const [selectedFileIndex, setSelectedFileIndex] = useState<number>(0);

  const getSelectedFileDownloadInfo = useCallback(
    () => adminApi.getFileDownloadInfo(files[selectedFileIndex]?.catalogueId ?? ""),
    [files, selectedFileIndex]
  );

  if (loading) {
    return <InlineLoader />;
  }

  const handleDownloadAll = async () => {
    const catalogueIds = files.map((file) => file.catalogueId);
    const [downloadUrl, error] = await getDownloadUrlForMultipleFiles(catalogueIds, "files.zip");

    if (error || !downloadUrl) {
      sendNotificationError("Could not download multiple files as zip");
      logError(error, "[ApprovalFilesPreview] handleDownloadAll");
      return;
    }

    downloadFileFromUrl(downloadUrl);
  };

  const selectedFile = files[selectedFileIndex];

  return (
    <Box height="100%">
      <Box p={2.5} display="flex" justifyContent="space-between" alignItems="center" width="100%">
        <Typography variant="subtitle1" noWrap sx={{ width: "18rem" }}>
          {selectedFile?.fileName}
        </Typography>
        <Stack direction="row" spacing={1} alignItems="center" ml={1}>
          {files.length && (
            <Typography sx={{ whiteSpace: "nowrap" }}>
              {selectedFileIndex + 1} of {files.length}
            </Typography>
          )}
          <Stack direction="row" spacing={0.25}>
            <OutlinedIconButton
              disabled={selectedFileIndex < 1}
              onClick={() => setSelectedFileIndex((i) => Math.max(i - 1, 0))}
            >
              <UpIcon />
            </OutlinedIconButton>
            <OutlinedIconButton
              disabled={selectedFileIndex >= files.length - 1}
              onClick={() => setSelectedFileIndex((i) => Math.min(i + 1, files.length - 1))}
            >
              <DownIcon />
            </OutlinedIconButton>
          </Stack>
          <Button
            variant="outlined"
            color="secondary"
            fullWidth
            startIcon={<DownloadIcon />}
            onClick={handleDownloadAll}
            disabled={files.length === 0}
            sx={{ whiteSpace: "nowrap" }}
          >
            Download All
          </Button>
        </Stack>
      </Box>

      <Divider />

      {selectedFile && (
        <Box
          p={2.5}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          sx={{ bgcolor: grey[100] }}
          height="calc(100% - 5rem)"
        >
          <Paper elevation={1} sx={{ width: "100%", height: "85%" }}>
            <DocumentPreview getFileDownloadInfoCallback={getSelectedFileDownloadInfo} />
          </Paper>
        </Box>
      )}

      {!selectedFile && (
        <Box display="flex" alignItems="center" justifyContent="center">
          <Typography>No files to preview</Typography>
        </Box>
      )}
    </Box>
  );
};

export default ApprovalFilesPreview;
