import { Checkbox, Stack, Typography } from "@mui/material";
import { useGridApiContext } from "@mui/x-data-grid-premium";
import { useCallback } from "react";
import { AccessPermission } from "../../../../shared/api/types";
import AuthorizedBox from "../../../../shared/components/AuthorizedBox";
import ExpandCollapseAllButton from "../../../../shared/components/ExpandCollapseAllButton";
import { setAllNonGroupRowsSelection } from "../../../../shared/utilities/dataGridApiHelper";

interface Props {
  headerName: string | undefined;
  permissions: AccessPermission[];
  hideExpandCollapse?: boolean;
}

const GroupHeaderWithSelection = ({ permissions, headerName, hideExpandCollapse }: Props) => {
  const apiRef = useGridApiContext();

  const changeSelection = useCallback(
    (newState: boolean) => {
      setAllNonGroupRowsSelection(apiRef.current, newState);
    },
    [apiRef]
  );

  return (
    <Stack direction="row" alignItems="center">
      <AuthorizedBox permissions={permissions}>
        <Checkbox
          onChange={(_, value) => changeSelection(value)}
          onClick={(evt) => {
            evt.preventDefault();
            evt.stopPropagation();
            changeSelection(true);
          }}
        />
      </AuthorizedBox>
      {!hideExpandCollapse && <ExpandCollapseAllButton />}
      <Typography variant="subtitle2" sx={{ pl: 1 }}>
        {headerName}
      </Typography>
    </Stack>
  );
};

export default GroupHeaderWithSelection;
