import { Box, Stack, Typography } from "@mui/material";
import ImageUploaderWithPreview from "../../../../../shared/components/imageUploader/ImageUploaderWithPreview";
import ImagePlaceholderIcon from "../../../../../shared/icons/ImagePlaceholderIcon";

interface LogosSettings {
  logoTypeUrl: string;
  logoMarkUrl: string;
  faviconUrl: string;
  appBarColor: string;
}

interface Props {
  settings: LogosSettings;
  originalSettings: LogosSettings;
  isSaving: boolean;
  disabled: boolean;
  onImageChange: (key: keyof LogosSettings, file: File | undefined, url: string) => void;
}

const LogosEditor = ({ settings, originalSettings, isSaving, disabled, onImageChange }: Props) => {
  return (
    <Stack spacing={3} sx={(theme) => ({ maxWidth: theme.spacing(89) })}>
      <Stack spacing={1} mt={1}>
        <Typography variant="subtitle1">Logotype on Navigation Bar</Typography>
        <Typography color="text.secondary">PNG, SVG, JPG. Recommended size: 280x56 pixels.</Typography>
      </Stack>
      <Stack spacing={1}>
        <Typography variant="subtitle1">Light or Dark Background</Typography>
        <ImageUploaderWithPreview
          disabled={disabled}
          width={280}
          height={56}
          allowedImageTypes="image/png, image/jpeg, image/svg+xml"
          loading={isSaving}
          originalImageSrc={originalSettings.logoTypeUrl}
          imageSrc={settings.logoTypeUrl}
          onImageSelected={(file, url) => onImageChange("logoTypeUrl", file, url)}
          uploadButtonName="Upload Logotype"
          backgroundColor={settings.appBarColor}
          maxFileSizeBytes={0.5 * 1024 * 1024}
          buttonsPosition="bottom"
          Placeholder={
            <Stack p={2}>
              <Typography variant="h5" color="#C7CED4">
                Logotype
              </Typography>
            </Stack>
          }
        />
      </Stack>

      <Stack mt={2}>
        <Typography variant="subtitle1">Logomark</Typography>
        <Typography color="text.secondary">PNG, SVG, JPG. Recommended size: 56x56 pixels.</Typography>
        <Box mt={2}>
          <ImageUploaderWithPreview
            disabled={disabled}
            width={56}
            height={56}
            allowedImageTypes="image/png, image/jpeg, image/svg+xml"
            loading={isSaving}
            originalImageSrc={originalSettings.logoMarkUrl}
            imageSrc={settings.logoMarkUrl}
            onImageSelected={(file, url) => onImageChange("logoMarkUrl", file, url)}
            uploadButtonName="Upload Logomark"
            maxFileSizeBytes={0.5 * 1024 * 1024}
            buttonsPosition="right"
            border="dashed"
            Placeholder={
              <Stack p={2}>
                <ImagePlaceholderIcon />
              </Stack>
            }
          />
        </Box>
      </Stack>

      <Stack mt={2}>
        <Typography variant="subtitle1">Favicon</Typography>
        <Typography color="text.secondary">PNG, SVG, ICO. Recommended size: 16x16 pixels or 32x32 pixels.</Typography>
        <Box mt={2}>
          <ImageUploaderWithPreview
            disabled={disabled}
            width={32}
            height={32}
            allowedImageTypes="image/png, image/x-icon, image/svg+xml"
            loading={isSaving}
            originalImageSrc={originalSettings.faviconUrl}
            imageSrc={settings.faviconUrl}
            onImageSelected={(file, url) => onImageChange("faviconUrl", file, url)}
            uploadButtonName="Upload Favicon"
            maxFileSizeBytes={0.5 * 1024 * 1024}
            border="dashed"
            buttonsPosition="right"
            Placeholder={
              <Stack p="0.3rem">
                <ImagePlaceholderIcon />
              </Stack>
            }
          />
        </Box>
      </Stack>
    </Stack>
  );
};

export default LogosEditor;
