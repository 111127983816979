import { WatermarkSegment } from "../../../../api/adminApi";

export const getDetailsControlLabel = (segment: WatermarkSegment) => {
  switch (segment) {
    case WatermarkSegment.ContactName:
      return "Contact Name";
    case WatermarkSegment.ContactEmail:
      return "Contact Email";
    case WatermarkSegment.InvestorName:
      return "Investor Name";
    case WatermarkSegment.DateAndTime:
      return "Date and Time";
    default:
      return "";
  }
};
