import { InvestorPortalFeature } from "../../../../api/adminApi";

interface SettingsValues {
  inbox: ToggleValue;
  dashboards: ToggleValue;
  portfolio: ToggleValue;
  fundraising: ToggleValue;
  accountSettingsView: ToggleValue;
  accountSettingsEdit: boolean;
  bankAccountDetailsView: ToggleValue;
  bankAccountDetailsEdit: boolean;
  membership: ToggleValue;
}

export type ToggleValue = "off" | "preview" | "live";

export interface PagesSettingsState {
  all: boolean;
  values: SettingsValues;
  disabled: Record<keyof SettingsValues, boolean>;
  liveFeatures: InvestorPortalFeature[];
  previewFeatures: InvestorPortalFeature[];
}

type Action =
  | {
      type: "TOGGLE_ALL";
    }
  | {
      type: "TOGGLE_FIELD";
      payload: Partial<SettingsValues>;
    }
  | {
      type: "RESET";
      payload: {
        liveFeatures: InvestorPortalFeature[];
        previewFeatures: InvestorPortalFeature[];
      };
    };

const getAllLiveState = (): PagesSettingsState => ({
  all: true,
  values: {
    inbox: "live",
    dashboards: "live",
    portfolio: "live",
    fundraising: "live",
    accountSettingsView: "live",
    accountSettingsEdit: true,
    bankAccountDetailsView: "live",
    bankAccountDetailsEdit: true,
    membership: "live",
  },
  disabled: {
    inbox: true,
    dashboards: true,
    portfolio: true,
    fundraising: true,
    accountSettingsView: true,
    accountSettingsEdit: true,
    bankAccountDetailsView: true,
    bankAccountDetailsEdit: true,
    membership: true,
  },
  liveFeatures: ["All"],
  previewFeatures: [],
});

const getFeatures = (values: SettingsValues) => {
  const liveFeatures: InvestorPortalFeature[] = [];
  const previewFeatures: InvestorPortalFeature[] = [];

  const addFeatureToResult = (
    feature: InvestorPortalFeature,
    value: ToggleValue,
    editFeature?: InvestorPortalFeature,
    editFeatureValue?: boolean
  ) => {
    if (value === "live") {
      liveFeatures.push(feature);
      if (editFeature !== undefined && editFeatureValue === true) {
        liveFeatures.push(editFeature);
      }
    } else if (value === "preview") {
      previewFeatures.push(feature);
      if (editFeature !== undefined && editFeatureValue === true) {
        previewFeatures.push(editFeature);
      }
    }
  };

  addFeatureToResult("Inbox", values.inbox);
  addFeatureToResult("Dashboards", values.dashboards);
  addFeatureToResult("Fundraising", values.fundraising);
  addFeatureToResult("PortfolioCompanies", values.portfolio);
  addFeatureToResult(
    "AccountSettingsViewer",
    values.accountSettingsView,
    "AccountSettingsEditor",
    values.accountSettingsEdit
  );
  addFeatureToResult(
    "BankAccountsDetailsViewer",
    values.bankAccountDetailsView,
    "BankAccountsDetailsEditor",
    values.bankAccountDetailsEdit
  );
  addFeatureToResult("Membership", values.membership);

  return { liveFeatures, previewFeatures };
};

const getPartialState = (payloadValues: SettingsValues): PagesSettingsState => {
  const values = {
    ...payloadValues,
    accountSettingsEdit: payloadValues.accountSettingsView !== "off" && payloadValues.accountSettingsEdit,
    bankAccountDetailsEdit: payloadValues.bankAccountDetailsView !== "off" && payloadValues.bankAccountDetailsEdit,
  };

  const { liveFeatures, previewFeatures } = getFeatures(values);

  return {
    all: false,
    values,
    disabled: {
      inbox: false,
      dashboards: false,
      portfolio: false,
      fundraising: false,
      accountSettingsView: false,
      accountSettingsEdit: values.accountSettingsView === "off",
      bankAccountDetailsView: false,
      bankAccountDetailsEdit: values.bankAccountDetailsView === "off",
      membership: false,
    },
    liveFeatures,
    previewFeatures,
  };
};

export const getInitialState = (
  enabledFeatures: InvestorPortalFeature[],
  enabledPreviewOnlyFeatures: InvestorPortalFeature[]
): PagesSettingsState => {
  if (enabledFeatures.includes("All")) {
    return getAllLiveState();
  }

  const getInitialToggleValue = (feature: InvestorPortalFeature) => {
    if (enabledFeatures.includes(feature)) {
      return "live";
    }
    if (enabledPreviewOnlyFeatures.includes(feature)) {
      return "preview";
    }
    return "off";
  };

  const getInitialEditValue = (feature: InvestorPortalFeature) =>
    enabledFeatures.includes(feature) || enabledPreviewOnlyFeatures.includes(feature);

  const values: SettingsValues = {
    inbox: getInitialToggleValue("Inbox"),
    dashboards: getInitialToggleValue("Dashboards"),
    portfolio: getInitialToggleValue("PortfolioCompanies"),
    fundraising: getInitialToggleValue("Fundraising"),
    accountSettingsView: getInitialToggleValue("AccountSettingsViewer"),
    accountSettingsEdit: getInitialEditValue("AccountSettingsEditor"),
    bankAccountDetailsView: getInitialToggleValue("BankAccountsDetailsViewer"),
    bankAccountDetailsEdit: getInitialEditValue("BankAccountsDetailsEditor"),
    membership: getInitialToggleValue("Membership"),
  };

  return getPartialState(values);
};

export const reducer = (state: PagesSettingsState, action: Action): PagesSettingsState => {
  switch (action.type) {
    case "TOGGLE_ALL": {
      return state.all ? getPartialState(state.values) : getAllLiveState();
    }
    case "TOGGLE_FIELD": {
      return getPartialState({ ...state.values, ...action.payload });
    }
    case "RESET": {
      return getInitialState(action.payload.liveFeatures, action.payload.previewFeatures);
    }
    default: {
      return state;
    }
  }
};
