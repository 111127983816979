import GroupCollapseIcon from "@mui/icons-material/ArrowDropDownRounded";
import GroupExpandIcon from "@mui/icons-material/ArrowRightRounded";
import { Typography } from "@mui/material";
import { GridApiPremium } from "@mui/x-data-grid-premium/models/gridApiPremium";
import CenteredWrapper from "../../../../../shared/components/CenteredWrapper";
import DataGrid from "../../../../../shared/components/grid/DataGrid";
import InlineLoader from "../../../../../shared/components/inlineLoader/InlineLoader";
import { DocumentViews } from "../../../../api/types/documentActivityTypes";
import { nextPageAction } from "../../../../state/paginatedState";
import { useDocumentActivityPageContext } from "./DocumentActivityPageContext";
import { columnDefinitions } from "./documentViewsGridDataProvider";
import DocumentViewsGridRowDetail from "./DocumentViewsGridRowDetail";

interface Props {
  isLoading: boolean;
  apiRef?: React.MutableRefObject<GridApiPremium>;
  rows: DocumentViews[];
}

const DocumentViewsGrid = ({ isLoading, apiRef, rows }: Props) => {
  const { setPageState } = useDocumentActivityPageContext();

  const handleRowScrollEnd = () => {
    setTimeout(() => setPageState(nextPageAction()), 100);
  };

  return (
    <DataGrid<DocumentViews>
      apiRef={apiRef}
      rows={rows}
      columns={columnDefinitions}
      loading={isLoading}
      columnHeaderHeight={36}
      rowHeight={52}
      onRowsScrollEnd={handleRowScrollEnd}
      getDetailPanelContent={({ row }) =>
        Object.values(row.pageViews).some((val) => val > 0) ? <DocumentViewsGridRowDetail row={row} /> : undefined
      }
      getDetailPanelHeight={() => 320}
      slots={{
        loadingOverlay: () => <InlineLoader />,
        noRowsOverlay: () => (
          <CenteredWrapper>
            <Typography color="text.secondary">No document activity</Typography>
          </CenteredWrapper>
        ),
        detailPanelExpandIcon: GroupExpandIcon,
        detailPanelCollapseIcon: GroupCollapseIcon,
      }}
      hideFooter
      disableColumnFilter
      disableColumnSelector
      disableColumnSorting
      disableColumnMenu
      disableColumnReorder
      disableRowSelectionOnClick
    />
  );
};

export default DocumentViewsGrid;
