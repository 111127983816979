import { Divider, Stack } from "@mui/material";
import DataLoadingFailed from "../../../../../shared/components/DataLoadingFailed";
import InlineLoader from "../../../../../shared/components/inlineLoader/InlineLoader";
import useFetch from "../../../../../shared/hooks/useFetch";
import { logError } from "../../../../../shared/logging";
import adminApi from "../../../../api/adminApi";
import PortalDesignEditor from "./PortalDesignEditor";
import SignInPageEditor from "./SignInPageEditor";

const PortalDesignSettings = () => {
  const [portalSettingsResponse, portalSettingsError] = useFetch(adminApi.getInvestorPortalSettings);
  const [signOnSettings, signOnSettingsError] = useFetch(adminApi.getSignOnSettings);

  if (portalSettingsError) {
    logError(portalSettingsError, "getInvestorPortalSettings");
    return <DataLoadingFailed title="Failed to load settings" />;
  }

  if (signOnSettingsError) {
    logError(portalSettingsError, "getInvestorPortalSettings");
  }

  if (!portalSettingsResponse) {
    return <InlineLoader />;
  }

  const { settings } = portalSettingsResponse;

  return (
    <Stack spacing={3} px={1} pb={4}>
      <PortalDesignEditor
        initialSettings={{
          appBarColor: settings.appBarColor,
          appBarTextColor: settings.appBarTextColor,
          mainColor: settings.mainColor,
          bannerUrl: settings.bannerUrl,
          bannerFontColor: settings.bannerFontColor,
          logoTypeUrl: settings.logoTypeUrl,
          logoMarkUrl: settings.logoMarkUrl,
          faviconUrl: settings.faviconUrl,
        }}
      />
      <Divider />
      {signOnSettingsError ? (
        <DataLoadingFailed title="Failed to load sign-on settings" />
      ) : signOnSettings ? (
        <SignInPageEditor currentSettings={signOnSettings} mainColor={settings.mainColor} />
      ) : (
        <InlineLoader />
      )}
    </Stack>
  );
};

export default PortalDesignSettings;
