import { ListItemText, MenuItem, Select, SxProps, Theme } from "@mui/material";

interface Props {
  precision: number;
  maxPrecision: number;
  disabled?: boolean;
  onChange: (precision: number) => void;
  sx?: SxProps<Theme>;
}

const getDecimalPrecisionLabel = (precision: number) => (precision === 0 ? "1" : `1.${"0".repeat(precision)}`);

const FieldPrecisionSelect = ({ precision, maxPrecision, disabled, onChange, sx }: Props) => {
  return (
    <Select
      disabled={disabled}
      value={precision}
      onChange={(e) => onChange(e.target.value as number)}
      renderValue={(value) => getDecimalPrecisionLabel(value)}
      sx={sx}
    >
      {Array(maxPrecision + 1)
        .fill(0)
        .map((_, index) => (
          <MenuItem key={`precision-${index}`} value={index}>
            <ListItemText primary={getDecimalPrecisionLabel(index)} />
          </MenuItem>
        ))}
    </Select>
  );
};

export default FieldPrecisionSelect;
