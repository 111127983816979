import { useGridApiContext } from "@mui/x-data-grid-premium";
import CollapseAllIcon from "../icons/CollapseAllIcon";
import ExpandAllIcon from "../icons/ExpandAllIcon";
import ExpandCollapseButton from "./ExpandCollapseButton";
import { isAnyGroupExpanded, setAllGroupsExpansion } from "../utilities/dataGridApiHelper";

const ExpandCollapseAllButton = () => {
  const apiRef = useGridApiContext();

  const anyExpanded = isAnyGroupExpanded(apiRef);

  const handleToggle = () => setAllGroupsExpansion(apiRef, !anyExpanded);

  return (
    <ExpandCollapseButton
      expanded={anyExpanded}
      onToggle={handleToggle}
      ExpandIcon={ExpandAllIcon}
      CollapseIcon={CollapseAllIcon}
    />
  );
};

export default ExpandCollapseAllButton;
