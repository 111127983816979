import MoneyIcon from "@mui/icons-material/AttachMoney";
import PersonOutlineRoundedIcon from "@mui/icons-material/PersonOutlineRounded";
import SupervisorAccountOutlinedIcon from "@mui/icons-material/SupervisorAccountOutlined";
import { Button, Stack } from "@mui/material";
import { PropsWithChildren, useEffect, useMemo, useState } from "react";
import SearchField from "../../../../../shared/components/inputs/SearchField";
import useDebounce from "../../../../../shared/hooks/useDebounce";
import { distinct } from "../../../../../shared/utilities/arrayHelper";
import { PortalRole } from "../../../../api/adminApi";
import MultiSelect from "../../../common/MultiSelect";
import {
  CommunicationMatrixEntityType,
  CommunicationMatrixModel,
  isEmptyInvestorContact,
} from "./matrixColumnDefinitions";
import { investorPortalPortalRolesMap } from "./investorPortalPortalRolesMap";

interface Props {
  entityType: CommunicationMatrixEntityType;
  permissions: CommunicationMatrixModel[];
  onFilterChange: (items: CommunicationMatrixModel[]) => void;
}

export interface FilterState {
  search: string;
  fundNames: string[];
  investorNames: string[];
  roleNames: string[];
}

const emptyRoleName = "None";
const getRoleNames = (roles: PortalRole[]) =>
  roles.length > 0 ? roles.map((role) => investorPortalPortalRolesMap[role].text) : emptyRoleName;

const CommunicationMatrixFilters = ({
  permissions,
  entityType,
  onFilterChange,
  children,
}: PropsWithChildren<Props>) => {
  const [filters, setFilters] = useState<FilterState>({
    search: "",
    fundNames: [],
    investorNames: [],
    roleNames: [],
  });

  const updateFiltersState = (update: Partial<FilterState>) => setFilters({ ...filters, ...update });

  useEffect(() => {
    const filteredPermissions = getFilteredItems(permissions, filters);
    onFilterChange(filteredPermissions);
  }, [filters, onFilterChange, permissions]);

  const onSearch = useDebounce((search: string) => updateFiltersState({ search: search.toLowerCase() }), 300);

  const clearFilters = () => updateFiltersState({ fundNames: [], roleNames: [], investorNames: [] });

  const updatePortalRolesFilters = (rolesNames: string[]) => updateFiltersState({ roleNames: rolesNames });

  const fundNames = useMemo(() => distinct(permissions.map((permission) => permission.fundName)), [permissions]);

  const investorNames = useMemo(
    () => distinct(permissions.map((permission) => permission.investorName)),
    [permissions]
  );

  const availableRoleNames = useMemo(
    () =>
      distinct(
        permissions
          .filter((m) => !isEmptyInvestorContact(m))
          .map((permission) => getRoleNames(permission.roles))
          .flatMap((r) => r)
      ),
    [permissions]
  );

  return (
    <Stack alignItems={"center"} direction={"row"} justifyContent={"space-between"}>
      <Stack direction={"row"} spacing={1.5}>
        {entityType !== "Fund" && (
          <MultiSelect
            sortValues
            placeholder="Funds"
            options={fundNames.map((fundName) => ({ value: fundName, label: fundName }))}
            selectedValues={filters.fundNames}
            onChange={(fundNames) => updateFiltersState({ fundNames })}
            StartIcon={MoneyIcon}
          />
        )}
        {(entityType === "Fund" || entityType === "Contact") && (
          <MultiSelect
            sortValues
            placeholder="Investors"
            options={investorNames.map((investorName) => ({ value: investorName, label: investorName }))}
            selectedValues={filters.investorNames}
            onChange={(investorNames) => updateFiltersState({ investorNames })}
            StartIcon={PersonOutlineRoundedIcon}
          />
        )}
        {availableRoleNames.length > 0 && (
          <MultiSelect
            sortValues
            placeholder="Role"
            options={availableRoleNames.map((roleName) => ({ value: roleName, label: roleName }))}
            selectedValues={filters.roleNames}
            onChange={updatePortalRolesFilters}
            StartIcon={SupervisorAccountOutlinedIcon}
          />
        )}

        {(filters.fundNames.length > 0 || filters.roleNames.length > 0 || filters.investorNames.length > 0) && (
          <Button onClick={clearFilters}>Clear All</Button>
        )}
      </Stack>
      <Stack direction={"row"} spacing={1.5}>
        <SearchField onSearch={onSearch} />
        {children}
      </Stack>
    </Stack>
  );
};

export default CommunicationMatrixFilters;

const getFilteredItems = (permissions: CommunicationMatrixModel[], filters: FilterState) => {
  const { search, fundNames, investorNames, roleNames } = filters;

  return permissions.filter((permission) => {
    const showBySearch =
      permission.investorName.toLowerCase().includes(search) ||
      permission.fundName.toLowerCase().includes(search) ||
      permission.contactName.toLowerCase().includes(search) ||
      (permission.contactEmail ?? "").toLowerCase().includes(search);

    const showByFund = !fundNames.length || fundNames.includes(permission.fundName);
    const showByInvestor = !investorNames.length || investorNames.includes(permission.investorName);
    const showByRole =
      roleNames.length === 0 || roleNames.some((name) => getRoleNames(permission.roles).includes(name));

    return showBySearch && showByFund && showByRole && showByInvestor;
  });
};
