import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Stack,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { withErrorHandling } from "../../../../../../shared/api/axiosHelper";
import DialogCloseButton from "../../../../../../shared/components/DialogeCloseButton";
import { useNotificationContext } from "../../../../../../shared/contexts/NotificationContext";
import adminApi from "../../../../../api/adminApi";
import { Fundraising } from "../../../../../api/types/fundraisingTypes";
import FundraisingStatusUpdateIcon from "../FundraisingStatusUpdateIcon";
import { FundraisingStatusTransition } from "../fundraisingsPageTypes";

interface Props {
  onClose: () => void;
  onUpdated: (fundraising: Fundraising) => void;
  fundraisingId: string;
  statusTransition: FundraisingStatusTransition;
}

const updateFundraising = withErrorHandling(adminApi.updateFundraising);

const ChangeFundraisingStatusDialog = ({ onClose, onUpdated, fundraisingId, statusTransition }: Props) => {
  const { sendNotification, sendNotificationError } = useNotificationContext();
  const [saving, setSaving] = useState(false);
  const [confirmed, setConfirmed] = useState(false);

  const isSubmitDisabled = statusTransition.requiresAdditionalConfirmation && !confirmed;

  const handleSubmit = async () => {
    if (isSubmitDisabled) {
      return;
    }

    setSaving(true);
    const [resp, error] = await updateFundraising(fundraisingId, { status: statusTransition.to });
    setSaving(false);

    if (error) {
      sendNotificationError("Could not update status");
    } else {
      sendNotification("Status successfully updated");
      onUpdated(resp);
    }
  };

  return (
    <Dialog open onClose={onClose} PaperProps={{ sx: { width: "32.5rem" } }}>
      <DialogTitle>{statusTransition.label}</DialogTitle>
      <DialogCloseButton onClick={onClose} />
      <DialogContent>
        <Stack alignItems="center" spacing={2}>
          <FundraisingStatusUpdateIcon status={statusTransition.to} />
          <Stack alignItems="center" spacing={1}>
            <Typography noWrap variant="h6">
              {statusTransition.label}
            </Typography>
            {statusTransition.detailLines.map((line, index) => (
              <Typography key={index} color="text.secondary" textAlign="center">
                {line}
              </Typography>
            ))}
          </Stack>
          {statusTransition.requiresAdditionalConfirmation && (
            <Box mt={3}>
              <FormControlLabel
                control={<Checkbox checked={confirmed} onChange={(e) => setConfirmed(e.target.checked)} />}
                label="Confirm"
              />
            </Box>
          )}
        </Stack>
      </DialogContent>
      <DialogActions sx={{ py: 2, px: 3, columnGap: 1 }}>
        <Button variant="text" color="secondary" autoFocus onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton variant="contained" loading={saving} onClick={handleSubmit} disabled={isSubmitDisabled}>
          {statusTransition.dialogActionLabel}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default ChangeFundraisingStatusDialog;
