import DeleteIcon from "@mui/icons-material/DeleteOutlineRounded";
import RenameIcon from "@mui/icons-material/DriveFileRenameOutline";
import DownloadIcon from "@mui/icons-material/SaveAltRounded";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import { ChangeEventHandler, KeyboardEventHandler, useState } from "react";
import { withErrorHandling } from "../../../../../shared/api/axiosHelper";
import FileIcon from "../../../../../shared/components/FileIcon";
import TypographyMultilineEllipsis from "../../../../../shared/components/TypographyMultilineEllipsis";
import { useNotificationContext } from "../../../../../shared/contexts/NotificationContext";
import { getFileExtension } from "../../../../../shared/utilities/fileHelper";
import { combineValidators, fileNameValidator, requiredValidator } from "../../../../../shared/utilities/validators";
import adminApi, { FileDetails, FileInfo } from "../../../../api/adminApi";
import ActionsMenuButton from "../../ActionsMenuButton";
import { useFilesContext } from "../FilesContext";
import MalwareScanBadge from "./MalwareScanBadge";

interface Props {
  fileDetails: FileDetails;
  onFileInfoUpdated: (newFileInfo: FileInfo) => void;
}

const renameFile = withErrorHandling(adminApi.renameFile);
const renameEntityFile = withErrorHandling(adminApi.renameEntityFile);

const validateFileName = combineValidators(requiredValidator, fileNameValidator());

interface RenamingState {
  status: "none" | "edit" | "submit";
  fileNameWithoutExt: string;
  isFileNameValid: boolean;
  validationError?: string;
}

const getInitialRenamingState = (initialFileName: string): RenamingState => ({
  status: "none",
  fileNameWithoutExt: initialFileName.substring(0, initialFileName.length - getFileExtension(initialFileName).length),
  isFileNameValid: true,
});

const FileDetailsHeaderSection = ({ fileDetails, onFileInfoUpdated }: Props) => {
  const { onDownloadFile, onDeleteFile, hasPermissionsToManage, dispatchFiles, entityInfo } = useFilesContext();
  const { sendNotificationError } = useNotificationContext();
  const [renamingState, setRenamingState] = useState<RenamingState>(getInitialRenamingState(fileDetails.fileName));

  const fileExtension = getFileExtension(fileDetails.fileName);

  const onStopRenaming = () => {
    setRenamingState(getInitialRenamingState(fileDetails.fileName));
  };

  const handleFileNameInputKeyDown: KeyboardEventHandler = (e) => {
    e.stopPropagation();

    if (e.code === "Escape") {
      e.preventDefault();
      onStopRenaming();
    }
  };

  const handleFileNameChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const fileNameWithoutExt = e.target.value;
    const { isValid, error } = validateFileName(fileNameWithoutExt);

    setRenamingState((state) => ({
      ...state,
      fileNameWithoutExt,
      isFileNameValid: isValid,
      validationError: error,
    }));
  };

  const handleApplyRenaming = async () => {
    const newFileName = renamingState.fileNameWithoutExt + fileExtension;
    const { isValid, error } = validateFileName(newFileName);
    if (!isValid) {
      setRenamingState((state) => ({ ...state, isFileNameValid: false, validationError: error }));
      return;
    }

    setRenamingState((state) => ({ ...state, status: "submit" }));

    const [newFileInfo, respError] = entityInfo
      ? await renameEntityFile(
          entityInfo.entityType,
          entityInfo.entityId,
          entityInfo.objectType,
          fileDetails.catalogueId,
          newFileName
        )
      : await renameFile(fileDetails.catalogueId, newFileName);

    if (respError) {
      sendNotificationError("Failed to rename the file");
      onStopRenaming();
      return;
    }

    dispatchFiles({ type: "rename_file", catalogueId: fileDetails.catalogueId, newFileName: newFileInfo.fileName });
    onFileInfoUpdated(newFileInfo);
    onStopRenaming();
  };

  return (
    <Stack spacing={1} width="100%">
      {renamingState.status !== "none" && (
        <Stack pb={1} spacing={1} width="100%">
          <TextField
            variant="outlined"
            fullWidth
            value={renamingState.fileNameWithoutExt}
            onChange={handleFileNameChange}
            onKeyDown={handleFileNameInputKeyDown}
            error={!renamingState.isFileNameValid}
            helperText={renamingState.validationError}
            InputProps={{
              endAdornment: <Typography color="text.secondary">{fileExtension}</Typography>,
            }}
          />
          <Stack direction="row" spacing={1}>
            <Button variant="text" color="secondary" onClick={onStopRenaming}>
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              color="primary"
              disabled={!renamingState.isFileNameValid}
              loading={renamingState.status === "submit"}
              onClick={handleApplyRenaming}
            >
              Apply
            </LoadingButton>
          </Stack>
        </Stack>
      )}
      {renamingState.status === "none" && (
        <Stack pb={1} direction="row" spacing={0.5} justifyContent="space-between" alignItems="center" width="100%">
          <Stack direction="row" spacing={1} alignItems="center" width="90%">
            <FileIcon fileExtension={fileExtension} />
            <TypographyMultilineEllipsis
              maxLines={3}
              typographyProps={{ variant: "subtitle1", sx: { lineBreak: "anywhere" } }}
            >
              {fileDetails.fileName}
            </TypographyMultilineEllipsis>
          </Stack>
          <ActionsMenuButton
            items={[
              {
                disabled: !hasPermissionsToManage,
                label: "Rename",
                icon: <RenameIcon />,
                onClick: () => setRenamingState((state) => ({ ...state, status: "edit" })),
              },
              {
                disabled: !hasPermissionsToManage,
                label: "Delete",
                icon: <DeleteIcon color="error" />,
                onClick: () => onDeleteFile(fileDetails),
              },
            ]}
          />
        </Stack>
      )}
      <Button
        variant="outlined"
        color="secondary"
        fullWidth
        startIcon={<DownloadIcon />}
        onClick={() => onDownloadFile(fileDetails)}
      >
        Download
      </Button>
      <Box display="flex" width="100%" justifyContent="center">
        <MalwareScanBadge scanResult={fileDetails.malwareScanResult} />
      </Box>
    </Stack>
  );
};

export default FileDetailsHeaderSection;
