import ApprovalIcon from "@mui/icons-material/ApprovalRounded";
import { sumBy } from "../../../../shared/utilities/arrayHelper";
import approvalsApi from "../../../api/mock/approvalsMockApi";
import { approvalTypes } from "../../../api/mock/approvalsTypes";
import ViewsIcon from "../../../icons/ViewsIcon";
import VerticalNavigationMenuItem from "../../common/navigation/VerticalNavigationMenuItem";
import VerticalNavigationSection from "../../common/navigation/VerticalNavigationSection";
import VerticalNavigationWrapper from "../../common/navigation/VerticalNavigationWrapper";
import { NavigationView, formatViewTitle } from "./approvalsPageHelper";

interface Props {
  selectedView: NavigationView;
  onSelectedViewChange: (view: NavigationView) => void;
}

const ApprovalsNavigation = ({ selectedView, onSelectedViewChange }: Props) => {
  const counters = approvalsApi.getApprovalsCountersByType();
  const total = sumBy(Object.values(counters), (counter) => counter);

  return (
    <VerticalNavigationWrapper>
      <VerticalNavigationSection title="Menu">
        <VerticalNavigationMenuItem
          text={formatViewTitle("All")}
          selected={selectedView === "All"}
          onClick={() => onSelectedViewChange("All")}
          Icon={<ApprovalIcon color={selectedView === "All" ? "primary" : "secondary"} />}
          badgeCount={total}
        />
      </VerticalNavigationSection>
      <VerticalNavigationSection title="Type">
        {approvalTypes.map((approvalType) => (
          <VerticalNavigationMenuItem
            key={approvalType}
            text={formatViewTitle(approvalType)}
            selected={selectedView === approvalType}
            onClick={() => onSelectedViewChange(approvalType)}
            Icon={<ViewsIcon color={selectedView === approvalType ? "primary" : "secondary"} />}
            badgeCount={counters[approvalType]}
          />
        ))}
      </VerticalNavigationSection>
    </VerticalNavigationWrapper>
  );
};

export default ApprovalsNavigation;
