import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { Box, Checkbox, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-premium";
import { GridApiPremium } from "@mui/x-data-grid-premium/models/gridApiPremium";
import { numberComparerBy } from "../../../../../shared/utilities/arrayHelper";
import {
  Category,
  Contact,
  ContactPermissions,
  InvestorContactPermissions,
  PortalRole,
} from "../../../../api/adminApi";
import ActionsMenuButton, { MenuItemPropsWithIcon } from "../../../common/ActionsMenuButton";
import { GroupedRowCell } from "../../../common/grid/GroupedRowCell";
import createCheckboxColumn from "../../../common/grid/createCheckboxColumn";
import PortalRoleSelector from "./PortalRoleSelector";

export type CommunicationMatrixEntityType = "Investor" | "Contact" | "Fund";

export interface ContactPermissionsChange {
  contact: Contact;
  original: CommunicationMatrixModel | undefined;
  modified: CommunicationMatrixModel;
}

export interface BulkPermissionsState {
  roles: PortalRole[] | undefined;
  categories: Record<string, boolean | undefined>;
  isPrimary: boolean | undefined;
  emailNotification: boolean | undefined;
}

export const investorPortalRolesReverseMap: Record<string, PortalRole> = {
  Admin: "LP_Admin",
  Member: "LP_Member",
};

export type CommunicationMatrixModel = ContactPermissions & InvestorContactPermissions & { investorId?: string };

export interface InvestorBase {
  id: string;
  name: string;
}

export const getId = (investorName: string, fundInvestorId: string, contactId: string) =>
  `${investorName}-${fundInvestorId}-${contactId}`;
export const getModelId = (model: CommunicationMatrixModel) =>
  getId(model.investorName, model.fundInvestorId, model.contactId);

export const EmptyId = "00000000-0000-0000-0000-000000000000";
export const isEmptyFundInvestor = (model: CommunicationMatrixModel) => model.fundInvestorId === EmptyId;
export const isEmptyInvestorContact = (model: CommunicationMatrixModel) => model.contactId === EmptyId;

interface CommunicationMatrixColumnsParams {
  messageCategories: Category[];
  excludedColumns?: string[];
  onChange: (model: CommunicationMatrixModel) => void;
  onRemove: (ids: string[]) => void;
  readOnly: boolean;
  rowEditingDisabled: (model: CommunicationMatrixModel) => boolean;
  childRowActionsDisabled?: boolean;
  groupRowActionsDisabled?: boolean;
  apiRef?: React.MutableRefObject<GridApiPremium>;
}

export const getCommunicationMatrixColumns = ({
  messageCategories,
  excludedColumns,
  onChange,
  onRemove,
  readOnly,
  rowEditingDisabled,
  childRowActionsDisabled,
  groupRowActionsDisabled,
  apiRef,
}: CommunicationMatrixColumnsParams): GridColDef<CommunicationMatrixModel>[] => {
  const onCategoryChange = (model: CommunicationMatrixModel, category: Category, checked: boolean) => {
    const categories = model.categories.filter((categoryId) => categoryId !== category.externalId);
    if (checked) {
      categories.push(category.externalId);
    }
    onChange({ ...model, categories });
  };

  const categoriesColumns: GridColDef<CommunicationMatrixModel>[] = [
    {
      sortable: false,
      resizable: false,
      field: "divider",
      width: 1,
      renderCell: ({ rowNode }) =>
        GroupedRowCell(rowNode, <Box sx={{ borderRight: 1, borderColor: "divider", height: "100%" }} />),
      headerName: "",
    },
  ];

  messageCategories.sort(numberComparerBy((c) => c.sortOrder)).forEach((category) => {
    categoriesColumns.push({
      sortable: false,
      field: category.id,
      flex: 1,
      minWidth: 100,
      maxWidth: 300,
      headerAlign: "center",
      align: "center",
      renderCell: ({ row, rowNode }) =>
        rowNode.depth !== 0 && (
          <Checkbox
            size="small"
            checked={row.categories.some((categoryId) => categoryId === category.externalId)}
            onChange={(e) => onCategoryChange(row, category, e.target.checked)}
            disabled={readOnly || rowEditingDisabled(row)}
          />
        ),
      renderHeader: () => renderMultilineHeader(category.name),
    });
  });

  const mainColumns: GridColDef<CommunicationMatrixModel>[] = [
    {
      sortable: false,
      resizable: false,
      field: "roles",
      width: 120,
      renderHeader: () => renderMultilineHeader("Portal Role"),
      renderCell: ({ row, rowNode }) =>
        GroupedRowCell(
          rowNode,
          <PortalRoleSelector
            readonly={readOnly || rowEditingDisabled(row)}
            onChange={(roles) => onChange({ ...row, roles })}
            roles={row.roles}
          />
        ),
    },
    {
      sortable: false,
      resizable: false,
      field: "emailNotification",
      width: 90,
      renderCell: ({ row, rowNode }) =>
        GroupedRowCell(
          rowNode,
          <Checkbox
            size="small"
            checked={row.emailNotification}
            disabled={readOnly || rowEditingDisabled(row)}
            onChange={(e) => onChange({ ...row, emailNotification: e.target.checked })}
          />
        ),
      renderHeader: () => renderMultilineHeader("Email Notification"),
    },
    {
      sortable: false,
      resizable: false,
      field: "isPrimary",
      width: 80,
      renderCell: ({ row, rowNode }) =>
        GroupedRowCell(
          rowNode,
          <Checkbox
            size="small"
            checked={row.isPrimary}
            disabled={readOnly || rowEditingDisabled(row)}
            onChange={(_e, checked) => onChange({ ...row, isPrimary: checked })}
          />
        ),
      renderHeader: () => renderMultilineHeader("Primary Contact"),
    },
  ];

  const columns = mainColumns.filter((col) => !(excludedColumns ?? []).includes(col.field)).concat(categoriesColumns);

  columns.push({
    field: "actions",
    headerName: "",
    cellClassName: "grid-row-actions",
    width: 30,
    align: "right",
    renderCell: ({ rowNode }) => {
      const getModelIdsForActions = (): string[] => {
        if (rowNode.type === "group") {
          return groupRowActionsDisabled ? [] : (rowNode.children as string[]);
        }
        if (rowNode.type === "leaf" && rowNode.depth === 0) {
          return groupRowActionsDisabled ? [] : [rowNode.id as string];
        }
        return childRowActionsDisabled ? [] : [rowNode.id as string];
      };

      const ids = getModelIdsForActions();
      if (ids.length === 0) {
        return null;
      }

      const menuItems: MenuItemPropsWithIcon[] = [
        {
          icon: <CloseRoundedIcon color="error" />,
          label: "Remove",
          disabled: readOnly,
          onClick: () => onRemove(ids),
        },
      ];

      return <ActionsMenuButton items={menuItems} />;
    },
  });

  return apiRef
    ? [createCheckboxColumn(apiRef, { groupsSelectionOnly: childRowActionsDisabled })].concat(columns)
    : columns;
};

const renderMultilineHeader = (label: string) => {
  return (
    <Typography fontWeight={500} sx={{ whiteSpace: "normal" }}>
      {label}
    </Typography>
  );
};
