import { DataCatalogueEntityType } from "../../api/adminApi";
import { NotesEntityType } from "../../api/types/notesTypes";

export const getNotesEntityType = (objectType: string): NotesEntityType => {
  switch (objectType) {
    case "Investor":
      return "Investor";
    case "Contact":
      return "Contact";
    case "Fund":
      return "Fund";
    case "Vendor":
      return "Vendor";
    case "PortfolioCompany":
      return "PortfolioCompany";
    case "FundInvestment":
      return "InvesteeFund";
    default:
      return "PlatformObject";
  }
};

export const getDataCatalogueEntityType = (objectType: string): DataCatalogueEntityType => {
  switch (objectType) {
    case "Investor":
      return "Investor";
    case "Contact":
      return "Contact";
    case "Fund":
      return "Fund";
    case "Vendor":
      return "Vendor";
    case "PortfolioCompany":
      return "PortfolioCompany";
    case "FundInvestment":
      return "InvesteeFund";
    default:
      return "PlatformObject";
  }
};
