import { sessionStorageService } from "../../../shared/services/storage";
import { defined } from "../../../shared/utilities/typeHelper";
import { ApprovalRequestStep, BankInfo, StoredApprovalRequest } from "./approvalsTypes";

const entityBanks: BankInfo[] = [
  {
    bankName: "JP Morgan Chase",
    accountName: "Aquileia Fund I LP",
    abaNumber: "833515173",
    accountNumber: "86359933",
  },
  {
    bankName: "JP Morgan Chase",
    accountName: "Aquileia Fund II LP",
    abaNumber: "229692671",
    accountNumber: "41144408",
  },
  {
    bankName: "JP Morgan Chase",
    accountName: "Aquileia Fund III LP",
    abaNumber: "144435777",
    accountNumber: "22070482",
  },
];

const counterpartyBanks: BankInfo[] = [
  {
    bankName: "Bank of America",
    accountName: "Patras Fund X LP",
    abaNumber: "534893246",
    accountNumber: "52074640",
  },
  {
    bankName: "Citigroup",
    accountName: "KPMG",
    abaNumber: "752254104",
    accountNumber: "98765432",
  },
  {
    bankName: "Commerzbank",
    accountName: "Brekke Inc",
    abaNumber: "COBADEFFXXX",
    accountNumber: "DE093820984098412300",
  },
  {
    bankName: "Wells Fargo",
    accountName: "Aquileia Capital Partners",
    abaNumber: "917445826",
    accountNumber: "62084641",
  },
  {
    bankName: "HSBC",
    accountName: "Charles Thompson Foundation",
    abaNumber: "605897425",
    accountNumber: "50149875",
  },
  {
    bankName: "Bank of America",
    accountName: "Annela School Fund",
    abaNumber: "459016687",
    accountNumber: "99746802",
  },
];

const storedApprovals: StoredApprovalRequest[] = [
  {
    id: "approval-01",
    type: "Payments",
    deriveNameFrom: "Counterparty",
    dueDate: "2024-04-30T00:00:00.000Z",
    transactionNo: "FTAKE00002",
    transactionType: "Fund Takedown",
    requestedByUserName: "Jane Cooper",
    requestedAt: "2024-04-05T10:28:00.000Z",
    currencyCode: "USD",
    payments: [
      {
        id: "payment-01-01",
        counterpartyName: defined(counterpartyBanks[0]).accountName,
        counterpartyBank: defined(counterpartyBanks[0]),
        entityId: "0",
        entityType: "Fund",
        entityName: defined(entityBanks[0]).accountName,
        entityBank: defined(entityBanks[0]),
        amount: 5000,
        currencyCode: "USD",
      },
      {
        id: "payment-01-02",
        counterpartyName: defined(counterpartyBanks[0]).accountName,
        counterpartyBank: defined(counterpartyBanks[0]),
        entityId: "1",
        entityType: "Fund",
        entityName: defined(entityBanks[1]).accountName,
        entityBank: defined(entityBanks[1]),
        amount: 5000,
        currencyCode: "USD",
      },
      {
        id: "payment-01-03",
        counterpartyName: defined(counterpartyBanks[0]).accountName,
        counterpartyBank: defined(counterpartyBanks[0]),
        entityId: "1",
        entityType: "Fund",
        entityName: defined(entityBanks[2]).accountName,
        entityBank: defined(entityBanks[2]),
        amount: 5000,
        currencyCode: "USD",
      },
    ],
    steps: [
      {
        id: "step-01-01",
        allowUpdateForCurrentUser: true,
        status: "Pending",
      },
      {
        id: "step-01-02",
        allowUpdateForCurrentUser: false,
        status: "Pending",
      },
    ],
  },
  {
    id: "approval-02",
    type: "Payments",
    deriveNameFrom: "Counterparty",
    dueDate: "2024-04-25T00:00:00.000Z",
    transactionNo: "PINV0006",
    transactionType: "Purchase Invoice",
    requestedByUserName: "John Smith",
    requestedAt: "2024-04-11T18:56:00.000Z",
    currencyCode: "USD",
    payments: [
      {
        id: "payment-02-01",
        counterpartyName: defined(counterpartyBanks[1]).accountName,
        counterpartyBank: defined(counterpartyBanks[1]),
        entityId: "0",
        entityType: "Fund",
        entityName: defined(entityBanks[0]).accountName,
        entityBank: defined(entityBanks[0]),
        amount: 2000,
        currencyCode: "USD",
      },
      {
        id: "payment-02-02",
        counterpartyName: defined(counterpartyBanks[1]).accountName,
        counterpartyBank: defined(counterpartyBanks[1]),
        entityId: "1",
        entityType: "Fund",
        entityName: defined(entityBanks[1]).accountName,
        entityBank: defined(entityBanks[1]),
        amount: 2000,
        currencyCode: "USD",
      },
      {
        id: "payment-02-03",
        counterpartyName: defined(counterpartyBanks[1]).accountName,
        counterpartyBank: defined(counterpartyBanks[1]),
        entityId: "1",
        entityType: "Fund",
        entityName: defined(entityBanks[2]).accountName,
        entityBank: defined(entityBanks[2]),
        amount: 2000,
        currencyCode: "USD",
      },
    ],
    steps: [
      {
        id: "step-02-01",
        allowUpdateForCurrentUser: false,
        status: "Approved",
        updatedAt: "2024-04-12T09:12:00.000Z",
        updatedByUserName: "Mark Williamson",
      },
      {
        id: "step-02-02",
        allowUpdateForCurrentUser: true,
        status: "Pending",
      },
      {
        id: "step-02-03",
        allowUpdateForCurrentUser: false,
        status: "Pending",
      },
    ],
  },
  {
    id: "approval-03",
    type: "Payments",
    deriveNameFrom: "Counterparty",
    dueDate: "2024-04-29T00:00:00.000Z",
    transactionNo: "PPREXP00009",
    transactionType: "Operating Expense",
    requestedByUserName: "Alice Johnson",
    requestedAt: "2024-04-06T16:35:00.000Z",
    currencyCode: "EUR",
    payments: [
      {
        id: "payment-03-01",
        counterpartyName: defined(counterpartyBanks[2]).accountName,
        counterpartyBank: defined(counterpartyBanks[2]),
        entityId: "0",
        entityType: "Fund",
        entityName: defined(entityBanks[0]).accountName,
        entityBank: defined(entityBanks[0]),
        amount: 11000,
        currencyCode: "EUR",
      },
    ],
    steps: [
      {
        id: "step-03-01",
        allowUpdateForCurrentUser: false,
        status: "Approved",
        updatedAt: "2024-04-08T15:44:00.000Z",
        updatedByUserName: "Mark Williamson",
      },
      {
        id: "step-03-02",
        allowUpdateForCurrentUser: true,
        status: "Rejected",
        updatedAt: "2024-04-09T08:55:00.000Z",
        updatedByUserName: "Eric Brown",
        comment: "Wrong Transaction No.",
      },
    ],
  },
  {
    id: "approval-04",
    type: "Payments",
    deriveNameFrom: "Counterparty",
    dueDate: "2024-04-29T00:00:00.000Z",
    transactionNo: "POPREXP00009",
    transactionType: "Operating Expense",
    requestedByUserName: "Alice Johnson",
    requestedAt: "2024-04-10T14:01:00.000Z",
    currencyCode: "EUR",
    payments: [
      {
        id: "payment-04-01",
        counterpartyName: defined(counterpartyBanks[2]).accountName,
        counterpartyBank: defined(counterpartyBanks[2]),
        entityId: "0",
        entityType: "Fund",
        entityName: defined(entityBanks[0]).accountName,
        entityBank: defined(entityBanks[0]),
        amount: 11000,
        currencyCode: "EUR",
      },
    ],
    steps: [
      {
        id: "step-04-01",
        allowUpdateForCurrentUser: false,
        status: "Approved",
        updatedAt: "2024-04-08T13:28:00.000Z",
        updatedByUserName: "Mark Williamson",
      },
      {
        id: "step-04-02",
        allowUpdateForCurrentUser: true,
        status: "Pending",
      },
    ],
  },
  {
    id: "approval-05",
    type: "Payments",
    deriveNameFrom: "Entity",
    dueDate: "2024-05-31T00:00:00.000Z",
    transactionNo: "ICCD00019",
    transactionType: "Investor CC/Distribution",
    requestedByUserName: "Jane Cooper",
    requestedAt: "2024-04-15T11:37:00.000Z",
    currencyCode: "USD",
    payments: [
      {
        id: "payment-05-01",
        counterpartyName: defined(counterpartyBanks[3]).accountName,
        counterpartyBank: defined(counterpartyBanks[3]),
        entityId: "0",
        entityType: "Fund",
        entityName: defined(entityBanks[0]).accountName,
        entityBank: defined(entityBanks[0]),
        amount: 350000,
        currencyCode: "USD",
      },
      {
        id: "payment-05-02",
        counterpartyName: defined(counterpartyBanks[3]).accountName,
        counterpartyBank: defined(counterpartyBanks[3]),
        entityId: "1",
        entityType: "Fund",
        entityName: defined(entityBanks[1]).accountName,
        entityBank: defined(entityBanks[1]),
        amount: 350000,
        currencyCode: "USD",
      },
      {
        id: "payment-05-03",
        counterpartyName: defined(counterpartyBanks[3]).accountName,
        counterpartyBank: defined(counterpartyBanks[3]),
        entityId: "2",
        entityType: "Fund",
        entityName: defined(entityBanks[2]).accountName,
        entityBank: defined(entityBanks[2]),
        amount: 350000,
        currencyCode: "USD",
      },
    ],
    steps: [
      {
        id: "step-05-01",
        allowUpdateForCurrentUser: true,
        status: "Approved",
        updatedAt: "2024-04-16T12:06:00.000Z",
        updatedByUserName: "Mark Williamson",
      },
      {
        id: "step-05-02",
        allowUpdateForCurrentUser: true,
        status: "Approved",
        updatedAt: "2024-04-17T18:27:00.000Z",
        updatedByUserName: "Eric Brown",
      },
      {
        id: "step-05-03",
        allowUpdateForCurrentUser: true,
        status: "Approved",
        updatedAt: "2024-04-18T05:00:00.000Z",
        updatedByUserName: "Bastian Müller",
      },
    ],
  },
];

const sessionStorageKey = "approval-requests";
type StoredApprovalStepsMap = Record<string, ApprovalRequestStep[]>;

const getStoredApprovalStepsMap = (): StoredApprovalStepsMap =>
  sessionStorageService.getItemWithFallback<StoredApprovalStepsMap>(sessionStorageKey, {});

export const saveStepsToStorage = (update: Record<string, ApprovalRequestStep>): void => {
  const storedApprovalStepsMap = getStoredApprovalStepsMap();

  for (const approvalId in update) {
    const storedSteps = storedApprovalStepsMap[approvalId] ?? [];
    const updatedStep = update[approvalId];
    if (!updatedStep) {
      continue;
    }

    const index = storedSteps.findIndex((s) => s.id === updatedStep.id);
    if (index !== -1) {
      storedSteps[index] = updatedStep;
    } else {
      storedSteps.push(updatedStep);
    }

    storedApprovalStepsMap[approvalId] = storedSteps;
  }

  sessionStorageService.setItem(sessionStorageKey, storedApprovalStepsMap);
};

export const getStoredApprovals = (): StoredApprovalRequest[] => {
  const result = [...storedApprovals];

  const storedApprovalStepsMap = getStoredApprovalStepsMap();
  for (const approval of result) {
    const storedSteps = storedApprovalStepsMap[approval.id] ?? [];
    approval.steps = approval.steps.map((step) => storedSteps.find((s) => s.id === step.id) ?? step);
  }

  return result;
};
