import { ApprovalType } from "../../../api/mock/approvalsTypes";

export type NavigationView = "All" | ApprovalType;

export const approvalsFilters = ["my", "pending", "all"] as const;
export type ApprovalsFilter = (typeof approvalsFilters)[number];

const approvalTypeLabelsMap = new Map<ApprovalType, string>([
  ["Payments", "Payments"],
  ["InvestorRequests", "Investor Requests"],
  ["DocumentCollections", "Document Collections"],
]);

export const formatViewTitle = (view: NavigationView) => {
  if (view === "All") {
    return "Approvals";
  }

  return approvalTypeLabelsMap.get(view) ?? view;
};
