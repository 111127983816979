import { blue, green } from "@mui/material/colors";
import { PortalRole } from "../../../../api/adminApi";

export const investorPortalPortalRolesMap: Record<PortalRole, InvestorPortalPortalRolesDescriptor> = {
  LP_Admin: {
    text: "Admin",
    color: blue[50],
  },
  LP_Member: {
    text: "Member",
    color: green[50],
  },
};

interface InvestorPortalPortalRolesDescriptor {
  text: string;
  color: string;
}
