import DeleteIcon from "@mui/icons-material/DeleteOutlineRounded";
import { Button, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { withErrorHandling } from "../../../shared/api/axiosHelper";
import ImageUploaderWithPreview from "../../../shared/components/imageUploader/ImageUploaderWithPreview";
import { useNotificationContext } from "../../../shared/contexts/NotificationContext";
import ImagePlaceholderIcon from "../../../shared/icons/ImagePlaceholderIcon";
import adminApi from "../../api/adminApi";
import { ObjectFieldValues } from "../../api/types/objectTypes";
import EntitySection from "./EntitySection";
import EntitySectionHeader from "./EntitySectionHeader";

interface Props {
  entity: ObjectFieldValues;
  disabled?: boolean;
}

const updateObjectLogo = withErrorHandling(adminApi.updateObjectLogo);
const removeObjectLogo = withErrorHandling(adminApi.removeObjectLogo);

const ObjectLogoSection = ({ entity, disabled }: Props) => {
  const { sendNotificationError } = useNotificationContext();
  const [logoUrl, setLogoUrl] = useState(entity.logoUrl);
  const [isSavingLogo, setSavingLogo] = useState(false);

  const handleImageSelected = async (file: File | undefined) => {
    if (file === undefined) {
      return;
    }

    setSavingLogo(true);
    const [resp, error] = await updateObjectLogo(entity.objectType, entity.id, file);
    if (error) {
      sendNotificationError("Failed to upload logo");
    } else {
      setLogoUrl(resp.logoUrl);
    }
    setSavingLogo(false);
  };

  const handleDeleteClick = async () => {
    setSavingLogo(true);
    const [resp, error] = await removeObjectLogo(entity.objectType, entity.id);
    if (error) {
      sendNotificationError("Failed to remove logo");
    } else {
      setLogoUrl(resp.logoUrl);
    }
    setSavingLogo(false);
  };

  return (
    <EntitySection>
      <EntitySectionHeader title="Company Logo" />
      <Stack width="100%" px={2.5} pb={2} spacing={1.5}>
        <Typography color="text.secondary">PNG, SVG, JPG. Recommended size: 56x56 pixels.</Typography>
        <Stack direction="row" spacing={1} alignItems="center">
          <ImageUploaderWithPreview
            disabled={disabled}
            width={56}
            height={56}
            allowedImageTypes="image/png, image/jpeg, image/svg+xml"
            loading={isSavingLogo}
            imageSrc={logoUrl}
            onImageSelected={handleImageSelected}
            uploadButtonName="Upload Logomark"
            showUploadButtonIcon
            maxFileSizeBytes={0.5 * 1024 * 1024}
            buttonsPosition="right"
            border="dashed"
            Placeholder={
              <Stack p={2}>
                <ImagePlaceholderIcon />
              </Stack>
            }
          />
          <Button
            disabled={disabled || isSavingLogo}
            variant="outlined"
            color="error"
            startIcon={<DeleteIcon fontSize="small" />}
            onClick={handleDeleteClick}
          >
            Delete
          </Button>
        </Stack>
      </Stack>
    </EntitySection>
  );
};

export default ObjectLogoSection;
