import { useCallback } from "react";
import { GridRenderCellParams, useGridApiContext } from "@mui/x-data-grid-premium";
import { Checkbox, Stack, Typography } from "@mui/material";
import AuthorizedBox from "../../../../../../../shared/components/AuthorizedBox";
import { ExpandCollapseButton } from "../../../../../../../shared/reporting/components/reports/ExpandCollapseButton";
import { AccessPermission } from "../../../../../../../shared/api/types";

export default function GroupName({
  params,
  permissions,
}: {
  params: GridRenderCellParams;
  permissions: AccessPermission[];
}) {
  const apiRef = useGridApiContext();
  const expanded = params.rowNode.type === "group" && params.rowNode.childrenExpanded === true;

  const childRows = apiRef.current.getRowGroupChildren({ groupId: params.id });
  const selectedChildren = childRows.filter((child) => apiRef.current.isRowSelected(child)).length;
  const allChildrenSelected = childRows.length === selectedChildren;
  const selected = allChildrenSelected || apiRef.current.isRowSelected(params.id);

  const changeSelection = useCallback(
    (newState: boolean) => {
      apiRef.current.selectRows(childRows, newState);
    },
    [apiRef, childRows]
  );

  return (
    <Stack direction="row" alignItems="center">
      <AuthorizedBox permissions={permissions}>
        <Checkbox
          indeterminate={selectedChildren > 0 && !allChildrenSelected}
          checked={selected}
          onChange={(_, checked) => changeSelection(checked)}
        />
      </AuthorizedBox>
      <ExpandCollapseButton
        expanded={expanded}
        onClick={(value) => {
          apiRef.current?.setRowChildrenExpansion(params.id, value);
        }}
      />
      <Typography variant="subtitle2" pl={1}>
        {params.value}
      </Typography>
    </Stack>
  );
}
