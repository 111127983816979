import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { getErrorMessage, withErrorHandling } from "../../../../shared/api/axiosHelper";
import DialogCloseButton from "../../../../shared/components/DialogeCloseButton";
import { logError } from "../../../../shared/logging";
import adminApi from "../../../api/adminApi";
import { DwhShareType } from "../../../api/types/dwhTypes";
import {
  AddShareForm,
  AddShareFormFields,
  initialAddShareForm,
  isAddShareFormValid,
  mapAddShareFormToRequest,
  validateAddShareForm,
} from "./addShareForm";

interface Props {
  open: boolean;
  onCancel: () => void;
  onShareCreated: (accountCode: string) => void;
}

const createDwhShare = withErrorHandling(adminApi.postDwhShare);

const AddShareDialog = ({ open, onCancel, onShareCreated }: Props) => {
  const [saving, setSaving] = useState(false);

  const [form, setForm] = useState<AddShareForm>(initialAddShareForm());

  const handleEdit =
    (field: AddShareFormFields): React.ChangeEventHandler<HTMLInputElement> =>
    ({ target }) =>
      setForm((currentForm) =>
        validateAddShareForm({
          ...currentForm,
          [field]: target.value,
        })
      );

  const handleTypeChange = (event: SelectChangeEvent<string>) => {
    setForm((currentForm) => ({
      ...currentForm,
      type: event.target.value as DwhShareType,
    }));
  };

  const handleCancel = () => {
    onCancel();
    setSaving(false);

    setTimeout(() => {
      setForm(initialAddShareForm());
    });
  };

  const handleSave = async () => {
    const validatedForm = validateAddShareForm(form);
    if (!isAddShareFormValid(validatedForm)) {
      setForm(validatedForm);
      return;
    }

    setSaving(true);
    const [, error] = await createDwhShare(mapAddShareFormToRequest(validatedForm));
    setSaving(false);

    if (error) {
      logError(error, "[AddShareDialog] handleSave");
      setForm({ ...form, errors: { accountCode: getErrorMessage(error) || "Server error" } });
      return;
    }

    onShareCreated(form.accountCode.toUpperCase());
  };

  return (
    <Dialog open={open} onClose={handleCancel} maxWidth="xs" fullWidth>
      <DialogTitle>Share Data</DialogTitle>
      <DialogCloseButton onClick={handleCancel} />
      <Divider />
      <DialogContent sx={(theme) => ({ px: theme.spacing(4) })}>
        <Typography variant="subtitle2" mb={1}>
          Type
        </Typography>
        <Select fullWidth variant="outlined" value={form.type} onChange={handleTypeChange}>
          <MenuItem key="AllTables" value="AllTables">
            All Tables
          </MenuItem>
          <MenuItem key="Qashqade" value="Qashqade">
            Qashqade
          </MenuItem>
          <MenuItem key="Cascata" value="Cascata">
            Cascata
          </MenuItem>
        </Select>
        <Typography color="text.secondary" mb={1} mt={3}>
          Paste the Snowflake account code for data sharing in the field below.
        </Typography>
        <Typography variant="subtitle2" mb={1}>
          Account Code
        </Typography>
        <TextField
          autoFocus
          fullWidth
          variant="outlined"
          placeholder="XXXXXXX.XXXXXXX"
          value={form.accountCode}
          onChange={handleEdit("accountCode")}
          error={!!form.errors.accountCode}
          helperText={form.errors.accountCode}
        />
        <Stack direction="row" spacing={1} mt={3} mb={1}>
          <Typography variant="subtitle2">Description</Typography>
          <Typography color="text.secondary">(optional)</Typography>
        </Stack>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Add text..."
          value={form.description}
          onChange={handleEdit("description")}
          spellCheck
        />
      </DialogContent>
      <Divider />
      <DialogActions sx={{ py: 2, px: 3, columnGap: 1 }}>
        <Button variant="text" color="secondary" onClick={handleCancel}>
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          color="primary"
          loading={saving}
          onClick={handleSave}
          disabled={!isAddShareFormValid(form)}
        >
          Share
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default AddShareDialog;
