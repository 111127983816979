import StatusChip from "../../../../shared/components/StatusChip";
import { ApprovalRequestStatus } from "../../../api/mock/approvalsTypes";

interface Props {
  status: ApprovalRequestStatus;
  approvedStepsCount?: number;
  totalStepsCount?: number;
}

const ApprovalStatusTag = ({ status, approvedStepsCount, totalStepsCount }: Props) => {
  switch (status) {
    case "Pending": {
      const label = totalStepsCount ? `Pending ${approvedStepsCount}/${totalStepsCount}` : "Pending";
      return <StatusChip label={label} withDot color={(t) => t.palette.warning.main} />;
    }
    case "Approved":
      return <StatusChip label="Approved" withDot color={(t) => t.palette.success.main} />;
    case "Rejected":
      return <StatusChip label="Rejected" withDot color={(t) => t.palette.error.main} />;
    default:
      return null;
  }
};

export default ApprovalStatusTag;
