import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useNotificationContext } from "../../../../../../../shared/contexts/NotificationContext";
import { useState } from "react";
import { useLocalization } from "../../../../../../hooks/useLocalization";
import adminApi, { UpdateInvoiceRequest } from "../../../../../../api/adminApi";
import DocumentSuccess from "../../../../../../../shared/icons/DocumentSuccess";
import CloseIcon from "@mui/icons-material/Close";
import { withErrorHandling } from "../../../../../../../shared/api/axiosHelper";

interface Props {
  open: boolean;
  updateRequest: UpdateInvoiceRequest;
  onCancel: () => void;
  onSaved: () => void;
}

const updateInvoice = withErrorHandling(adminApi.updateInvoice);
const postInvoice = withErrorHandling(adminApi.postInvoice);

const CreateTransactionDialog = ({ onCancel, open, updateRequest, onSaved }: Props) => {
  const { sendNotification, sendNotificationError } = useNotificationContext();
  const {
    invoices: { create_transaction_dialog: locale },
  } = useLocalization();
  const [isSaving, setSaving] = useState(false);

  const handleSubmit = async () => {
    setSaving(true);

    const [, error] = await updateInvoice(updateRequest);

    if (error) {
      sendNotificationError(locale.transaction_creating_error);
      onCancel();
    } else {
      const [, error] = await postInvoice(updateRequest.invoiceId);

      if (error) {
        sendNotificationError(locale.transaction_creating_error);
        onCancel();
      } else {
        sendNotification(locale.transaction_created);
        onSaved();
      }
    }
    setSaving(false);
  };

  return (
    <Dialog open={open} onClose={onCancel} maxWidth="sm">
      <DialogTitle>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="h6" noWrap>
            {locale.title}
          </Typography>
          <IconButton onClick={onCancel}>
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ p: 3 }}>
        <Box textAlign="center" pt={3}>
          <Stack spacing={2} alignItems="center">
            <DocumentSuccess />
            <Stack spacing={1}>
              <Typography variant="h6">{locale.description_primary}</Typography>
              <Typography color="secondary">{locale.description_secondary}</Typography>
            </Stack>
          </Stack>
        </Box>
      </DialogContent>
      <DialogActions sx={{ px: 3, py: 2 }}>
        <Button variant="text" color="secondary" onClick={onCancel}>
          {locale.cancel}
        </Button>
        <LoadingButton variant="contained" color="primary" loading={isSaving} onClick={handleSubmit}>
          {locale.submit}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default CreateTransactionDialog;
