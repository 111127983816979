import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import DesktopWindowsRoundedIcon from "@mui/icons-material/DesktopWindowsRounded";
import ForwardToInboxRoundedIcon from "@mui/icons-material/ForwardToInboxRounded";
import { Button, Stack } from "@mui/material";
import saveAs from "file-saver";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import DataGrid from "../../../../../shared/components/grid/DataGrid";
import InlineLoader from "../../../../../shared/components/inlineLoader/InlineLoader";
import { useNotificationContext } from "../../../../../shared/contexts/NotificationContext";
import { logError } from "../../../../../shared/logging";
import adminApi, { Contact, ContactWithFieldValues } from "../../../../api/adminApi";
import { useClientContext } from "../../../../context/ClientContext";
import { getInvestorPortalImpersonationUrl } from "../../../../utilities/navigationHelper";
import ActionsMenuButton from "../../../common/ActionsMenuButton";
import EmptyMemberIcon from "../../../common/EmptyMemberIcon";
import { useFilterContext } from "../../../common/filters/FilterContext";
import FiltersPanel from "../../../common/filters/FiltersPanel";
import { filterRecords } from "../../../common/filters/filterState";
import CustomDataGridHeaderToolbar, {
  useCustomDataGridHeaderToolbar,
} from "../../../common/grid/CustomDataGridHeaderToolbar";
import { getContactsGridColumns } from "./contactsGridDefinitions";

interface Props {
  allRows: ContactWithFieldValues[];
  excludedColumns?: string[];
  isLoading: boolean;
  onInviteContacts: (contactIds: string[]) => void;
  onDeleteContact: (contact: Contact) => void;
}

const isAllowedToSendInvitation = (contact: Contact) => contact.hasAccess && !contact.registrationDate;

const headerHeight = 52;

const ContactsGrid = ({ allRows, excludedColumns, isLoading, onInviteContacts, onDeleteContact }: Props) => {
  const navigate = useNavigate();
  const { getGridSx } = useCustomDataGridHeaderToolbar();

  const { clientCode, hasAnyPermission } = useClientContext();
  const { sendNotificationError } = useNotificationContext();
  const { filterState } = useFilterContext<ContactWithFieldValues>();

  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const hasAccessToSendInvitation = hasAnyPermission(["ManageFundStructure"]);
  const canInspectInvestorPortal = hasAnyPermission(["ViewInvestorPortalOnBehalfOfInvestor"]);
  const hasAccessToManage = hasAnyPermission(["ManageFundStructure"]);

  const rows = filterRecords(filterState, allRows);

  const handleContactNavigate = (contact: Contact, tab: "main" | "documents" | "emails") => {
    navigate(`./${contact.id}/${tab}`);
  };

  const handleImpersonate = (email: string) => {
    const investorPortalUrl = getInvestorPortalImpersonationUrl(clientCode, "", email);
    window.open(investorPortalUrl, "_blank");
  };

  const handleExportToCsv = async () => {
    try {
      const csvContent = await adminApi.exportContactsToCsv(selectedIds);
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      saveAs(blob, "Contacts.csv");
    } catch (error) {
      logError(error, "[ContactsGrid] exportToCsv");
      sendNotificationError("Failed to export contacts");
    }
  };

  const columns = getContactsGridColumns(excludedColumns ?? [], filterState, handleContactNavigate);

  columns.push({
    field: "actions",
    headerName: "",
    width: 30,
    cellClassName: "grid-row-actions",
    align: "right",
    renderCell: ({ row }) => (
      <ActionsMenuButton
        items={[
          {
            icon: <DesktopWindowsRoundedIcon sx={{ color: "action.active" }} />,
            label: "Impersonate",
            onClick: () => row.email && handleImpersonate(row.email),
            disabled: !canInspectInvestorPortal || !row.email,
          },
          {
            icon: <DeleteOutlineIcon color="error" />,
            label: "Delete",
            onClick: () => onDeleteContact(row),
            disabled: !hasAccessToManage,
          },
        ]}
      />
    ),
  });

  const showToolbar = Boolean(selectedIds.length);

  const doNotAllowToSendInvitation = rows.some(
    (contact) => selectedIds.includes(contact.id) && !isAllowedToSendInvitation(contact)
  );

  return (
    <Stack spacing={2} height="100%">
      <FiltersPanel
        totalCount={allRows.length}
        recordCount={rows.length}
        isLoading={isLoading}
        onExportToCsv={handleExportToCsv}
      />
      <DataGrid<ContactWithFieldValues>
        checkboxSelection
        rowSelectionModel={selectedIds}
        sx={() => getGridSx(showToolbar)}
        columns={columns}
        columnHeaderHeight={headerHeight}
        rows={rows}
        loading={isLoading}
        slots={{
          loadingOverlay: () => <InlineLoader />,
          noRowsOverlay: () => <EmptyMemberIcon title="No contacts" description="There are no contacts." />,
          toolbar: () => {
            return showToolbar ? (
              <CustomDataGridHeaderToolbar sx={{ height: headerHeight }} selection={[selectedIds, setSelectedIds]}>
                <Button
                  disabled={isLoading || !hasAccessToSendInvitation || doNotAllowToSendInvitation}
                  onClick={() => onInviteContacts(selectedIds)}
                  color="secondary"
                  variant="text"
                  startIcon={<ForwardToInboxRoundedIcon />}
                >
                  Send Invitation
                </Button>
              </CustomDataGridHeaderToolbar>
            ) : null;
          },
        }}
        hideFooter
        disableColumnFilter
        disableColumnSelector
        disableColumnMenu
        disableRowSelectionOnClick
        onRowSelectionModelChange={(newSelection) => setSelectedIds(newSelection as string[])}
        getRowClassName={({ row }) => (isAllowedToSendInvitation(row) ? "" : "grid-row-restricted")}
        shiftActionsLeft
      />
    </Stack>
  );
};

export default ContactsGrid;
