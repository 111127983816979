import { definedValidator, requiredValidator } from "../../../../../../shared/utilities/validators";
import {
  GlAccount,
  InvoiceDeal,
  InvoiceEntry,
  InvoiceLine,
  InvoiceProcessingStatus,
} from "../../../../../api/adminApi";

export interface InvoiceDetailsLineError {
  description?: string;
  account?: string;
  amount?: string;
}

export interface InvoiceDetailsConfidenceWarning {
  amount?: boolean;
  dueDate?: boolean;
  postDate?: boolean;
  lines: InvoiceDetailsLineWarning[];
}

export interface InvoiceDetailsLineWarning {
  id: number;
  description?: boolean;
  amount?: boolean;
}

export interface InvoiceBaseData {
  readonly businessCentralTransactionNo?: string;
  readonly transactionTypeCode?: string;
  readonly vendor?: string;
  readonly dueDate?: string;
  readonly entity?: string;
  readonly status?: InvoiceProcessingStatus;
}
export interface InvoiceDetailsPageState {
  form: InvoiceDetailsState;
  readonly vendors: InvoiceEntry[];
  readonly entities: InvoiceEntry[];
  readonly deals: InvoiceDeal[];
  readonly initialData: InvoiceBaseData;
  readonly glAccounts: GlAccount[];
}
export interface InvoiceAccount {
  no?: string;
  name?: string;
}
export interface InvoiceDetailsLine extends Omit<InvoiceLine, "glAccountName" | "glAccountNo"> {
  account?: InvoiceAccount;
}

export interface InvoiceDetailsState {
  vendor?: InvoiceEntry;
  entity?: InvoiceEntry;
  dueDate?: string;
  amount?: number;
  currencyCode?: string;
  postDate?: string;
  invoiceNo: string;
  lines: InvoiceDetailsLine[];
  isValid: boolean;
  isDirty: boolean;
  errors: {
    vendor?: string;
    entity?: string;
    dueDate?: string;
    currencyCode?: string;
    postDate?: string;
    amount?: string;
    invoiceNo?: string;
  };
  confidenceWarnings: InvoiceDetailsConfidenceWarning;
}
export const getInitialState = (): InvoiceDetailsPageState => ({
  form: {
    invoiceNo: "",
    isValid: false,
    isDirty: false,
    lines: [],
    errors: {},
    confidenceWarnings: {
      lines: [],
    },
  },
  vendors: [],
  deals: [],
  entities: [],
  glAccounts: [],
  initialData: {},
});

export const validateForm = (form: InvoiceDetailsState): InvoiceDetailsState => {
  const validateEntity = definedValidator<InvoiceEntry>("Entity must be selected");
  const validateVendor = definedValidator<InvoiceEntry>("Vendor must be selected");
  const validateIssueDate = definedValidator<string>("Issue date must be selected");
  const validateAmount = definedValidator<number>("Amount must be entered");
  const validateCurrencyCode = definedValidator<string>("Currency Code must be selected");
  const validateDueDate = definedValidator<string>("Due date must be selected");

  const validateEntityResult = validateEntity(form.entity);
  const validateVendorResult = validateVendor(form.vendor);
  const validateIssueDateResult = validateIssueDate(form.postDate);
  const validateAmountResult = validateAmount(form.amount);
  const validateCurrencyCodeResult = validateCurrencyCode(form.currencyCode);
  const validateDueDateResult = validateDueDate(form.dueDate);
  const validateInvoiceNoResult = requiredValidator(form.invoiceNo);

  const isValid =
    validateIssueDateResult.isValid &&
    validateEntityResult.isValid &&
    validateAmountResult.isValid &&
    validateVendorResult.isValid &&
    validateCurrencyCodeResult.isValid &&
    validateDueDateResult.isValid &&
    validateInvoiceNoResult.isValid;

  return {
    ...form,
    isValid,
    errors: {
      entity: validateEntityResult.error,
      vendor: validateVendorResult.error,
      postDate: validateIssueDateResult.error,
      amount: validateAmountResult.error,
      currencyCode: validateCurrencyCodeResult.error,
      dueDate: validateDueDateResult.error,
      invoiceNo: validateInvoiceNoResult.error,
    },
  };
};

export const buildSaveInvoicePayload = (invoiceFormState: InvoiceDetailsPageState) => {
  return {
    lines: invoiceFormState.form.lines.map((line) => ({
      currencyCode: line.currencyCode,
      amount: line.amount,
      description: line.description,
      glAccountName: line.account?.name,
      glAccountNo: line.account?.no,
      id: line.id,
      dealId: line.dealId,
    })),
    amount: invoiceFormState.form.amount,
    dueDate: invoiceFormState.form.dueDate,
    vendorId: invoiceFormState.form.vendor?.id,
    entityId: invoiceFormState.form.entity?.id,
    postDate: invoiceFormState.form.postDate,
    currencyCode: invoiceFormState.form.currencyCode,
    invoiceNo: invoiceFormState.form.invoiceNo,
  };
};

export const isFieldLowConfident = (confidence: number | undefined) => confidence !== undefined && confidence < 0.3;
