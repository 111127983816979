import { Box, Divider, Stack, Typography } from "@mui/material";
import { GlAccount, InvoiceDeal, InvoiceEntry } from "../../../../../api/adminApi";
import { DictionaryItem } from "../../../../../api/types/clientTypes";
import InvoiceAutocomplete from "../shared/InvoiceAutocomplete";
import InvoiceCurrency from "../shared/InvoiceCurrency";
import InvoiceDatePicker from "../shared/InvoiceDatePicker";
import InvoiceSelect from "../shared/InvoiceSelect";
import InvoiceTextField from "../shared/InvoiceTextField";
import InvoiceSummaryData from "./InvoiceSummaryData";
import InvoiceLinesGrid from "./grid/InvoiceLinesGrid";
import { InvoiceDetailsLine, InvoiceDetailsState } from "./invoiceDetailsState";

interface Props {
  form: InvoiceDetailsState;
  entities: InvoiceEntry[];
  vendors: InvoiceEntry[];
  glAccounts: GlAccount[];
  deals: InvoiceDeal[];
  currencies: DictionaryItem[];
  readonly?: boolean;
  onChange: (form: InvoiceDetailsState) => void;
  onLineAdd: () => void;
  onLineDelete: (lineId: number) => void;
  onLineChange: (line: InvoiceDetailsLine) => void;
}

const InvoiceFieldsContainer = ({
  form,
  onChange,
  entities,
  vendors,
  currencies,
  onLineAdd,
  onLineDelete,
  glAccounts,
  deals,
  onLineChange,
  readonly,
}: Props) => {
  const onFieldChange = (newValue: Partial<InvoiceDetailsState>) => {
    onChange({ ...form, ...newValue });
  };

  return (
    <Box position={"relative"} height={"100%"}>
      <Stack
        spacing={3}
        sx={{ position: "absolute", height: "100%", width: "100%", overflowY: "scroll", py: 2.5, pl: 3, pr: 1 }}
      >
        <InvoiceSummaryData
          amount={form.amount}
          currencyCode={form.currencyCode}
          dueDate={form.dueDate}
          vendor={form.vendor?.caption}
        />
        <Stack spacing={3} width={"60%"}>
          <Stack spacing={2.5}>
            <Typography variant="subtitle2">Payment</Typography>
            <InvoiceAutocomplete
              label="Vendor"
              value={form.vendor}
              placeholder="Type a vendor name..."
              onChange={(newVendor) => onFieldChange({ vendor: newVendor })}
              validationError={form.errors.vendor}
              options={vendors}
              disabled={readonly}
            />
            <Stack spacing={1} direction="row" flex={1}>
              <InvoiceCurrency
                label="Invoice Amount"
                value={form.amount}
                placeholder="Type amount..."
                onChange={(newAmount) =>
                  onFieldChange({
                    amount: newAmount,
                    confidenceWarnings: { ...form.confidenceWarnings, amount: undefined },
                  })
                }
                validationError={form.errors.amount}
                isLowConfident={form.confidenceWarnings.amount}
                disabled={readonly}
              />
              <InvoiceSelect
                value={form.currencyCode}
                onChange={(newCurrency) => onFieldChange({ currencyCode: newCurrency })}
                values={currencies}
                sx={{ minWidth: "74px", width: "fit-content" }}
                validationError={form.errors.currencyCode}
                disabled={readonly}
              />
            </Stack>
            <InvoiceDatePicker
              label="Due Date"
              value={form.dueDate}
              onChange={(newDueDate) =>
                onFieldChange({
                  dueDate: newDueDate,
                  confidenceWarnings: { ...form.confidenceWarnings, dueDate: undefined },
                })
              }
              validationError={form.errors.dueDate}
              isLowConfident={form.confidenceWarnings.dueDate}
              disabled={readonly}
            />
          </Stack>
          <Stack>
            <Divider sx={{ mr: 3.5 }} />
          </Stack>
          <Stack spacing={2.5}>
            <Typography variant="subtitle2">Invoice Details</Typography>
            <InvoiceAutocomplete
              label="Bill To"
              value={form.entity}
              placeholder="Type an entity name..."
              onChange={(newEntity) => onFieldChange({ entity: newEntity })}
              validationError={form.errors.entity}
              options={entities}
              disabled={readonly}
            />
            <InvoiceDatePicker
              label="Invoice Date"
              value={form.postDate}
              onChange={(newIssueDate) =>
                onFieldChange({
                  postDate: newIssueDate,
                  confidenceWarnings: { ...form.confidenceWarnings, postDate: undefined },
                })
              }
              validationError={form.errors.postDate}
              isLowConfident={form.confidenceWarnings.postDate}
              disabled={readonly}
            />
            <InvoiceTextField
              label="Invoice Number"
              value={form.invoiceNo}
              placeholder="Type an invoice number..."
              onChange={(newInvoiceNumber) => onFieldChange({ invoiceNo: newInvoiceNumber })}
              validationError={form.errors.invoiceNo}
              disabled={readonly}
            />
          </Stack>
        </Stack>
        <Stack spacing={1} height={"auto"}>
          <Typography variant="subtitle2">Lines</Typography>
          <InvoiceLinesGrid
            lines={form.lines}
            confidenceWarnings={form.confidenceWarnings}
            onLineAdd={onLineAdd}
            onLineDelete={onLineDelete}
            onLineChange={onLineChange}
            glAccounts={glAccounts}
            deals={deals}
            disabled={readonly}
          />
        </Stack>
      </Stack>
    </Box>
  );
};

export default InvoiceFieldsContainer;
