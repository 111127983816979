import { GridRowId } from "@mui/x-data-grid-premium";
import { GridApiPremium } from "@mui/x-data-grid-premium/models/gridApiPremium";
import { useEffect, useState } from "react";

export const useGridGroupsCollapsed = (
  apiRef: React.MutableRefObject<GridApiPremium>,
  initiallyCollapsedGroupIds?: GridRowId[]
) => {
  const [collapsedGroupIds, setCollapsedGroupIds] = useState<GridRowId[]>(initiallyCollapsedGroupIds || []);

  useEffect(() => {
    return apiRef.current.subscribeEvent("rowExpansionChange", ({ id, childrenExpanded }) => {
      setCollapsedGroupIds((ids) => (childrenExpanded ? ids.filter((nodeId) => nodeId !== id) : [...ids, id]));
    });
  }, [apiRef]);

  return collapsedGroupIds;
};
