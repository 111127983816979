import { Button, Checkbox, Divider, Drawer, Stack, StackProps, Typography } from "@mui/material";
import deepEqual from "fast-deep-equal";
import { PropsWithChildren, useState } from "react";
import CloseIconButton from "../../../../../shared/components/CloseIconButton";
import { numberComparerBy } from "../../../../../shared/utilities/arrayHelper";
import { Category } from "../../../../api/adminApi";
import PortalRoleSelector from "./PortalRoleSelector";
import { BulkPermissionsState } from "./matrixColumnDefinitions";

interface Props {
  recordsCount: number;
  categories: Category[];
  initialPermissionsState: BulkPermissionsState;
  onClose: () => void;
  onSubmit: (permissions: BulkPermissionsState) => void;
}

const commonBarStackProps: StackProps = {
  direction: "row",
  alignItems: "center",
  py: 2,
  px: 3,
  sx: {
    borderColor: "divider",
  },
};

const MatrixBulkEdit = ({ recordsCount, categories, initialPermissionsState, onClose, onSubmit }: Props) => {
  const [permissions, setPermissions] = useState<BulkPermissionsState>(initialPermissionsState);
  const handleChange = (change: Partial<BulkPermissionsState>) => setPermissions((prev) => ({ ...prev, ...change }));

  const { categories: categoriesState, roles, emailNotification } = permissions;
  const categoryEntries = Object.entries(categoriesState).filter(([, value]) => value);

  const handleCategoryPermissionChange = (category: Category, checked: boolean) => {
    handleChange({ categories: { ...categoriesState, [category.externalId]: checked } });
  };

  const handleSelectAllCategories = () => {
    const allCategoriesSelected = categoryEntries.length === categories.length;
    const newCategoriesState = categories.reduce(
      (acc, category) => ({ ...acc, [category.externalId]: !allCategoriesSelected }),
      {}
    );
    handleChange({ categories: newCategoriesState });
  };

  const handleSubmit = () => {
    onSubmit(permissions);
    onClose();
  };

  return (
    <Drawer
      anchor="right"
      open
      sx={(theme) => ({
        "& .MuiDrawer-paper": {
          p: 0,
          width: theme.spacing(44),
        },
      })}
    >
      <Stack {...commonBarStackProps} justifyContent={"space-between"} borderBottom={1}>
        <Typography variant={"h6"}>{`Bulk Edit ${recordsCount} records`}</Typography>
        <CloseIconButton onClick={onClose} />
      </Stack>
      <Stack spacing={1} flexGrow={1} py={2.5} px={3} height="100%" overflow={"scroll"}>
        <Typography variant="subtitle1" color={"secondary"}>
          Notices
        </Typography>
        <RowItem text="Portal Role">
          <PortalRoleSelector readonly={false} roles={roles} onChange={(roles) => handleChange({ roles })} />
        </RowItem>
        <RowItem text="Email Notification">
          <Checkbox
            indeterminate={emailNotification === undefined}
            checked={!!emailNotification}
            onChange={(_e, checked) => handleChange({ emailNotification: checked })}
          />
        </RowItem>
        <Divider />
        <RowItem text="Access" color="secondary">
          <Checkbox
            checked={categoryEntries.length === categories.length}
            onChange={handleSelectAllCategories}
            indeterminate={categoryEntries.length > 0 && categoryEntries.length !== categories.length}
          />
        </RowItem>
        <Stack spacing={1}>
          {categories.sort(numberComparerBy((c) => c.sortOrder)).map((category) => (
            <RowItem text={category.name} key={category.id}>
              <Checkbox
                checked={!!categoriesState[category.externalId]}
                indeterminate={categoriesState[category.externalId] === undefined}
                onChange={(_e, checked) => handleCategoryPermissionChange(category, checked)}
              />
            </RowItem>
          ))}
        </Stack>
      </Stack>
      <Stack {...commonBarStackProps} spacing={1} borderTop={1}>
        <Button
          disabled={deepEqual(permissions, initialPermissionsState)}
          onClick={handleSubmit}
          variant="contained"
          color="primary"
        >
          Apply
        </Button>
        <Button onClick={onClose} variant="text" color="secondary">
          Cancel
        </Button>
      </Stack>
    </Drawer>
  );
};

export default MatrixBulkEdit;

interface PermissionItemProps {
  text: string;
  color?: "primary" | "secondary";
}

const RowItem = ({ text, color, children }: PropsWithChildren<PermissionItemProps>) => (
  <Stack justifyContent={"space-between"} direction={"row"} alignItems={"center"}>
    <Typography variant="subtitle1" color={color}>
      {text}
    </Typography>
    {children}
  </Stack>
);
