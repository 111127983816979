import { Divider } from "@mui/material";
import { useCallback } from "react";
import DataLoadingFailed from "../../../../../shared/components/DataLoadingFailed";
import InlineLoader from "../../../../../shared/components/inlineLoader/InlineLoader";
import useFetch from "../../../../../shared/hooks/useFetch";
import adminApi, { FileInfo } from "../../../../api/adminApi";
import { useFilesContext } from "../FilesContext";
import { extractFileTags, getFileInformationAttributes, getFileRelationsAttributes } from "../fileDetailsHelper";
import FileAttributesSection from "./FileAttributesSection";
import FileDetailsHeaderSection from "./FileDetailsHeaderSection";
import FileTagsSection from "./FileTagsSection";

const SectionDivider = () => <Divider sx={{ borderStyle: "dotted" }} />;

interface Props {
  catalogueId: string;
}

const SingleFileDetailsPanel = ({ catalogueId }: Props) => {
  const { entityInfo } = useFilesContext();
  const getFileDetails = useCallback(
    () =>
      entityInfo
        ? adminApi.getEntityFileDetails(entityInfo.entityType, entityInfo.entityId, entityInfo.objectType, catalogueId)
        : adminApi.getFileDetails(catalogueId),
    [catalogueId, entityInfo]
  );

  const [fileDetails, error, { setData: setFileDetails }] = useFetch(getFileDetails);

  if (error) {
    return <DataLoadingFailed title="Could not load file details" />;
  }

  if (!fileDetails) {
    return <InlineLoader />;
  }

  const handleFileInfoUpdated = (newFileInfo: FileInfo) => setFileDetails({ ...fileDetails, ...newFileInfo });

  return (
    <>
      <FileDetailsHeaderSection fileDetails={fileDetails} onFileInfoUpdated={handleFileInfoUpdated} />
      <SectionDivider />
      <FileTagsSection fileTags={extractFileTags(fileDetails)} />
      <SectionDivider />
      <FileAttributesSection title="Document Properties" attributes={getFileRelationsAttributes(fileDetails)} />
      <SectionDivider />
      <FileAttributesSection title="Information" attributes={getFileInformationAttributes(fileDetails)} />
    </>
  );
};

export default SingleFileDetailsPanel;
