import { GridColDef } from "@mui/x-data-grid-premium";
import DataGrid from "../../../../../../shared/components/grid/DataGrid";
import InlineLoader from "../../../../../../shared/components/inlineLoader/InlineLoader";
import { TimeSeriesPeriodTableRow } from "../../../../../api/types/timeSeriesTypes";

interface Props {
  rows: TimeSeriesPeriodTableRow[];
  columns: GridColDef<TimeSeriesPeriodTableRow>[];
  onSelectionChange: (ids: string[]) => void;
}

const TimeSeriesPeriodsGrid = ({ rows, columns, onSelectionChange }: Props) => {
  return (
    <DataGrid<TimeSeriesPeriodTableRow>
      sx={{
        ".MuiDataGrid-row": {
          "&:hover": {
            cursor: "pointer",
          },
          ".MuiDataGrid-cell": {
            ":first-of-type": {
              cursor: "pointer",
            },
          },
        },
      }}
      rowHeight={40}
      rows={rows}
      columns={columns}
      initialState={{ pinnedColumns: { left: ["name"] } }}
      slots={{
        loadingOverlay: () => <InlineLoader />,
      }}
      onRowSelectionModelChange={(selection) => onSelectionChange(selection as string[])}
      hideFooter
      disableColumnFilter
      disableColumnSelector
      disableColumnMenu
      disableColumnReorder
      disableMultipleRowSelection
    />
  );
};

export default TimeSeriesPeriodsGrid;
