import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { Category } from "../../../../api/adminApi";

interface Props {
  value: string | undefined;
  categories: Category[];
  onChange: (value: string | undefined) => void;
  disabled?: boolean;
  label?: string;
}

function CategorySelector({ categories, onChange, label, value, disabled }: Props) {
  const handleOnChange = (selectedValue: string | undefined) => {
    onChange(selectedValue && selectedValue !== "all" ? selectedValue : undefined);
  };

  const allCategoriesItemLabel = "All Categories";

  return (
    <FormControl>
      <InputLabel id="category-select-label">{label}</InputLabel>
      <Select
        sx={(theme) => ({ width: theme.spacing(24) })}
        labelId="category-select-label"
        label={label}
        placeholder={allCategoriesItemLabel}
        disabled={disabled}
        value={value || "all"}
        onChange={(e) => handleOnChange(e.target.value)}
        renderValue={(value) =>
          value ? categories.find((c) => c.id === value)?.name || allCategoriesItemLabel : allCategoriesItemLabel
        }
        displayEmpty
      >
        <MenuItem value="all">{allCategoriesItemLabel}</MenuItem>
        {categories.map((category) => (
          <MenuItem key={category.id} value={category.id}>
            {category.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default CategorySelector;
