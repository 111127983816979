import { parseISO, isValid, isAfter, isBefore } from "date-fns";
import { DescriptiveDate } from "../../../../shared/utilities/dateFilterHelper";
import { DocumentCollection } from "../../../api/adminApi";
import { createSearchFilter } from "./searchFilter";

export interface DocumentCollectionsFilterState {
  allItems: DocumentCollection[];
  filteredItems: DocumentCollection[];
  searchTerm: string;
  dateRange?: DescriptiveDate | undefined;
  allFunds: string[];
  selectedFunds: string[];
}

type Action =
  | {
      type: "RESET";
      items: DocumentCollection[];
    }
  | {
      type: "SEARCH";
      searchTerm: string;
    }
  | {
      type: "FILTER_FUNDS";
      selectedFunds: string[];
    }
  | {
      type: "FILTER_DATES";
      dateRange: DescriptiveDate;
    }
  | {
      type: "CLEAR_FILTERS";
    };

const applySearch = createSearchFilter<DocumentCollection>(3, (c) => [c.name, c.fundName, ...c.categories]);

const isInDateRange = (dateString: string, dateRange: DescriptiveDate) => {
  const date = parseISO(dateString);
  if (!isValid(date)) {
    return false;
  }

  if (dateRange.from && isBefore(date, parseISO(dateRange.from))) {
    return false;
  }

  if (dateRange.to && isAfter(date, parseISO(dateRange.to))) {
    return false;
  }

  return true;
};

const applyFilters = (newState: DocumentCollectionsFilterState): DocumentCollectionsFilterState => {
  const { dateRange, selectedFunds, searchTerm } = newState;

  let filteredItems = dateRange
    ? newState.allItems.filter((item) => isInDateRange(item.publishDate, dateRange))
    : newState.allItems;

  filteredItems =
    selectedFunds.length > 0
      ? filteredItems.filter((item) => item.fundName && selectedFunds.includes(item.fundName))
      : filteredItems;

  filteredItems = applySearch(filteredItems, searchTerm);

  return { ...newState, filteredItems };
};

const getAllFunds = (items: DocumentCollection[]) =>
  [
    ...new Set(
      items
        .map((item) => item.fundName)
        .filter(Boolean)
        .sort()
    ),
  ] as string[];

export const getInitialState = (): DocumentCollectionsFilterState => ({
  allItems: [],
  filteredItems: [],
  searchTerm: "",
  allFunds: [],
  selectedFunds: [],
});

export const reducer = (state: DocumentCollectionsFilterState, action: Action): DocumentCollectionsFilterState => {
  switch (action.type) {
    case "RESET": {
      return {
        ...getInitialState(),
        allItems: action.items,
        filteredItems: action.items,
        allFunds: getAllFunds(action.items),
      };
    }
    case "SEARCH": {
      return applyFilters({ ...state, searchTerm: action.searchTerm });
    }
    case "FILTER_FUNDS": {
      return applyFilters({ ...state, selectedFunds: action.selectedFunds });
    }
    case "FILTER_DATES": {
      return applyFilters({ ...state, dateRange: action.dateRange });
    }
    case "CLEAR_FILTERS": {
      return {
        ...state,
        dateRange: undefined,
        selectedFunds: [],
        filteredItems: applySearch(state.allItems, state.searchTerm),
      };
    }
    default: {
      return state;
    }
  }
};
