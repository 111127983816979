import { Link, Stack, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-premium";
import InlineItemsList from "../../../../../shared/components/InlineItemsList";
import StaticCircularProgress from "../../../../../shared/components/StaticCircularProgress";
import TypographyTooltipEllipsis from "../../../../../shared/components/TypographyTooltipEllipsis";
import { distinct } from "../../../../../shared/utilities/arrayHelper";
import { convertISODateTime } from "../../../../../shared/utilities/dateUtils";
import { formatDurationInSeconds } from "../../../../../shared/utilities/formatters";
import { Category, FundStructureDocument } from "../../../../api/adminApi";
import CategoryTag from "../../../common/CategoryTag";
import DocumentCollectionStatus from "../../insights/DocumentCollectionStatus";
import FundStructureDocumentsGridRowActions from "./FundStructureDocumentsGridRowActions";

const getViewsInfo = (row: FundStructureDocument) => {
  const entry = Object.entries(row.attachmentsWithViews ?? {})?.find(
    ([, value]) => value.totalViews || value.totalDownloads
  );

  return entry ? { attachmentId: entry[0], viewsInfo: entry[1] } : undefined;
};

export const getFundStructureDocumentsGridColumns = (
  onDocumentPreview: (doc: FundStructureDocument) => void,
  onDocumentDownload: (doc: FundStructureDocument) => void,
  onDocCollectionClick: (doc: FundStructureDocument) => void,
  categories: Category[],
  excludeColumns: string[],
  onNavigateToDocumentActivity?: (postMessageRequestId: string, attachmentId: string) => void
): GridColDef<FundStructureDocument>[] => {
  const columnDefs: GridColDef<FundStructureDocument>[] = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      renderCell: ({ row }) => <CellWithAction name={row.name} row={row} onActionClick={onDocumentPreview} />,
    },
    {
      field: "investorName",
      headerName: "Investor",
      flex: 1,
      renderCell: ({ row }) => <TypographyTooltipEllipsis text={row.investorName} />,
    },
    {
      field: "fundNames",
      headerName: "Fund",
      flex: 1,
      renderCell: ({ row }) =>
        row.fundNames.length === 0 ? (
          "-"
        ) : (
          <InlineItemsList<string>
            displayCount={1}
            items={distinct(row.fundNames)}
            propGetter={(prop) => prop}
            justify="flex-start"
          />
        ),
    },
    {
      field: "categoryId",
      headerName: "Category",
      width: 250,
      renderCell: ({ row }) => {
        const category = categories.find((c) => c.externalId === row.categoryId);
        return category ? <CategoryTag categoryName={category.name} /> : null;
      },
    },
    {
      field: "documentCollectionName",
      headerName: "Document Collection",
      flex: 1,
      renderCell: ({ row }) => (
        <CellWithAction name={row.documentCollectionName} row={row} onActionClick={onDocCollectionClick} />
      ),
    },
    {
      field: "status",
      headerName: "Status",
      width: 100,
      renderCell: ({ row }) => <DocumentCollectionStatus status={row.status} />,
    },
    {
      field: "sentAt",
      headerName: "Published Live",
      width: 160,
      valueFormatter: (value: string) => convertISODateTime(value),
    },
    {
      field: "views",
      headerName: "Views",
      type: "number",
      width: 100,
      valueGetter: (_, row) => getViewsInfo(row)?.viewsInfo.totalViews || null,
    },
    {
      field: "downloads",
      headerName: "Downloads",
      type: "number",
      width: 100,
      valueGetter: (_, row) => getViewsInfo(row)?.viewsInfo.totalDownloads || null,
    },
    {
      field: "totalViewTimeSeconds",
      headerName: "Total Time Spent",
      headerAlign: "right",
      align: "right",
      width: 140,
      valueGetter: (_, row) => {
        const totalViewTimeSeconds = getViewsInfo(row)?.viewsInfo.totalViewTimeSeconds;
        return totalViewTimeSeconds ? formatDurationInSeconds(totalViewTimeSeconds) : null;
      },
    },
    {
      field: "totalCompletionPercentage",
      headerName: "Total Completion",
      type: "number",
      width: 160,
      headerAlign: "center",
      align: "center",
      renderCell: ({ row }) => {
        const completionPercentage = getViewsInfo(row)?.viewsInfo.totalCompletionPercentage;
        if (!completionPercentage) {
          return null;
        }

        return (
          <Stack direction="row" spacing={0.5} alignItems="center">
            <Typography>{completionPercentage + "%"}</Typography>
            <StaticCircularProgress size={20} value={completionPercentage} />
          </Stack>
        );
      },
    },
    {
      field: "actions",
      headerName: "",
      width: 90,
      cellClassName: "grid-row-actions",
      align: "right",
      renderCell: ({ row }) => (
        <FundStructureDocumentsGridRowActions
          row={row}
          attachmentId={getViewsInfo(row)?.attachmentId}
          onNavigateToDocumentActivity={onNavigateToDocumentActivity}
          onDownloadClick={onDocumentDownload}
        />
      ),
    },
  ];

  return columnDefs.filter((c) => !excludeColumns.includes(c.field));
};

const CellWithAction = ({
  row,
  onActionClick: onDocCollectionClick,
  name,
}: {
  name: string;
  row: FundStructureDocument;
  onActionClick: (doc: FundStructureDocument) => void;
}) => {
  if (!row.documentCollectionOriginatorId) {
    return <Typography>{name}</Typography>;
  }
  return (
    <Link
      className="highlighted-action"
      sx={{
        textDecoration: "none",
        width: "100%",
        "&:hover": {
          color: "primary.main",
          backgroundColor: "transparent",
          textDecoration: "underline",
          cursor: "pointer",
        },
      }}
      color="text.primary"
      onClick={(e) => {
        e.stopPropagation();
        onDocCollectionClick(row);
      }}
    >
      <TypographyTooltipEllipsis text={name} />
    </Link>
  );
};
