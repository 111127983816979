import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Stack, Tab } from "@mui/material";
import { SyntheticEvent, useCallback, useState } from "react";
import adminApi, {
  DocumentInfo,
  InvestorDeliveryInfo,
  MessageDeliveryInfo,
  MessageLevel,
} from "../../../../../../api/adminApi";
import DocumentPreview from "../../../../../common/document-preview/DocumentPreview";
import DocumentDetailsInfo from "./DocumentDetailsInfo";
import DocumentPreviewHeader from "./DocumentPreviewHeader";

type TabId = "preview" | "details";

interface Props {
  hidePreviewTab?: boolean;
  hideDetailsTab?: boolean;
  messageLevel: MessageLevel;
  document: DocumentInfo;
  investor?: InvestorDeliveryInfo;
}

const getContactDeliveries = ({ messageLevel, document, investor }: Props): MessageDeliveryInfo[] => {
  const allDeliveries = messageLevel === "Fund" ? investor?.deliveries ?? [] : document.deliveries;
  return allDeliveries.filter((d) => d.recipient.type === "InvestorContact");
};

const DocumentDetails = ({ hidePreviewTab, hideDetailsTab, messageLevel, document, investor }: Props) => {
  const fetchFileInfo = useCallback(
    () => adminApi.getDocCollectionFileDownloadInfo(document.postMessageRequestId, document.id),
    [document.id, document.postMessageRequestId]
  );

  const [selectedTab, setSelectedTab] = useState<TabId>("preview");

  const handleTabChange = (_: SyntheticEvent, value: TabId) => setSelectedTab(value);

  const contactDeliveries = getContactDeliveries({ messageLevel, document, investor });

  const currentTab = hidePreviewTab ? "details" : hideDetailsTab ? "preview" : selectedTab;

  return (
    <Stack height="100%">
      <DocumentPreviewHeader document={document} />
      <TabContext value={currentTab}>
        <TabList onChange={handleTabChange}>
          {!hidePreviewTab && <Tab label="Preview" value="preview" />}
          {!hideDetailsTab && <Tab label="Details" value="details" />}
        </TabList>
        <TabPanel value="preview" sx={{ height: "100%", p: 0 }}>
          <DocumentPreview getFileDownloadInfoCallback={fetchFileInfo} />
        </TabPanel>
        <TabPanel value="details" sx={{ height: "100%", p: 0 }}>
          <DocumentDetailsInfo contactDeliveries={contactDeliveries} messageRequestId={document.postMessageRequestId} />
        </TabPanel>
      </TabContext>
    </Stack>
  );
};

export default DocumentDetails;
