import TuneIcon from "@mui/icons-material/TuneRounded";
import { Button, Popover } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import {
  CreateTimeSeriesFieldRequest,
  TimeSeriesPeriodTableField,
  TimeSeriesPeriodTableLayout,
  UpdateTimeSeriesFieldRequest,
} from "../../../../../../api/types/timeSeriesTypes";
import useConfirmationDialog from "../../../../../../hooks/useConfirmationDialog";
import DeleteDialogIcon from "../../../../../../icons/fieldTypes/DeleteDialogIcon";
import TimeSeriesFieldEditor from "./TimeSeriesFieldEditor";
import TimeSeriesFieldsLayoutEditor from "./TimeSeriesFieldsLayoutEditor";
import {
  FieldEditForm,
  createFieldEditForm,
  getEmptyFieldEditForm,
  mapFormToCreateFieldRequest,
  mapFormToUpdateFieldRequest,
  validateFieldEditForm,
} from "./timeSeriesFieldEditForm";

interface Props {
  initialFields: TimeSeriesPeriodTableField[];
  onSaveFieldsLayout: (fieldsLayout: TimeSeriesPeriodTableLayout) => void;
  onCreateField: (createFieldRequest: CreateTimeSeriesFieldRequest) => void;
  onUpdateField: (fieldId: string, updateFieldRequest: UpdateTimeSeriesFieldRequest) => void;
  onDeleteField: (fieldId: string) => void;
}

type EditMode = "none" | "field" | "layout";

const CustomizeTimeSeriesFieldsButton = ({
  initialFields,
  onSaveFieldsLayout,
  onCreateField,
  onUpdateField,
  onDeleteField,
}: Props) => {
  const { ConfirmationDialogComponent, setConfirmDialogData } = useConfirmationDialog<string>();

  const [editMode, setEditMode] = useState<EditMode>("none");
  const [fieldEditForm, setFieldEditForm] = useState<FieldEditForm>(getEmptyFieldEditForm());
  const [fields, setFields] = useState<TimeSeriesPeriodTableField[]>(initialFields);
  const [isLayoutDirty, setLayoutDirty] = useState(false);

  useEffect(() => {
    setFields(initialFields);
  }, [initialFields]);

  const buttonRef = useRef<HTMLButtonElement>(null);

  const handleCustomizeClick = () => setEditMode("layout");

  const handleLayoutEditorClose = () => {
    setEditMode("none");
    setFields(initialFields);
    setLayoutDirty(false);
  };

  const handleSaveLayout = () => {
    onSaveFieldsLayout({
      orderedFields: fields.map((field) => ({ fieldId: field.id, isVisible: field.isVisible })),
    });

    setLayoutDirty(false);
  };

  const handleFieldPositionChange = (fieldId: string, newIndex: number) => {
    setFields((currenFields) => {
      let newFields = [...currenFields];
      const field = newFields.find((field) => field.id === fieldId);
      if (field) {
        newFields = newFields.filter((field) => field.id !== fieldId);
        newFields.splice(newIndex, 0, field);
      }

      return newFields;
    });

    setLayoutDirty(true);
  };

  const handleFieldVisibilityChange = (fieldId: string, isVisible: boolean) => {
    setFields((currenFields) => currenFields.map((field) => (field.id === fieldId ? { ...field, isVisible } : field)));
    setLayoutDirty(true);
  };

  const handleFieldEditorClose = () => {
    setEditMode("layout");
    setTimeout(() => setFieldEditForm(getEmptyFieldEditForm()), 250);
  };

  const handleRequestFieldCreate = () => {
    setFieldEditForm(getEmptyFieldEditForm());
    setEditMode("field");
  };

  const handleRequestFieldEdit = (field: TimeSeriesPeriodTableField) => {
    setFieldEditForm(createFieldEditForm(field));
    setEditMode("field");
  };

  const handleFormChange = (newForm: FieldEditForm) => {
    setFieldEditForm(
      validateFieldEditForm(newForm, fieldEditForm.fieldId === undefined ? initialFields.map((f) => f.name) : [])
    );
  };

  const handleDeleteField = () => {
    if (fieldEditForm.fieldId) {
      setConfirmDialogData({
        title: `Delete '${fieldEditForm.name}'?`,
        description: "You are about to delete the field",
        ids: fieldEditForm.fieldId,
        confirmButtonLabel: "Delete",
        confirmButtonColor: "error",
        icon: <DeleteDialogIcon />,
        confirmCallback: () => {
          if (fieldEditForm.fieldId) {
            onDeleteField(fieldEditForm.fieldId);
          }
        },
      });
    }
  };

  const handleSubmitField = () => {
    if (fieldEditForm.fieldId === undefined) {
      const createFieldRequest = mapFormToCreateFieldRequest(fieldEditForm);
      onCreateField(createFieldRequest);
    } else {
      const fieldToUpdate = initialFields.find((f) => f.id === fieldEditForm.fieldId);
      if (fieldToUpdate) {
        const updateFieldRequest = mapFormToUpdateFieldRequest(fieldEditForm);
        onUpdateField(fieldEditForm.fieldId, updateFieldRequest);
      }
    }
  };

  return (
    <>
      <Button
        ref={buttonRef}
        variant="outlined"
        color="secondary"
        startIcon={<TuneIcon />}
        onClick={handleCustomizeClick}
        disabled={editMode !== "none"}
      >
        Customize
      </Button>
      <Popover
        open={editMode === "layout"}
        onClose={handleLayoutEditorClose}
        anchorEl={buttonRef.current}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <TimeSeriesFieldsLayoutEditor
          fields={fields}
          isDirty={isLayoutDirty}
          onClose={handleLayoutEditorClose}
          onApply={handleSaveLayout}
          onFieldPositionChange={handleFieldPositionChange}
          onFieldVisibilityChange={handleFieldVisibilityChange}
          onRequestFieldCreate={handleRequestFieldCreate}
          onRequestFieldEdit={handleRequestFieldEdit}
        />
      </Popover>
      <Popover
        open={editMode === "field"}
        onClose={handleFieldEditorClose}
        anchorEl={buttonRef.current}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <TimeSeriesFieldEditor
          fieldEditForm={fieldEditForm}
          onFormChange={handleFormChange}
          onClose={handleFieldEditorClose}
          onSubmit={handleSubmitField}
          onDelete={handleDeleteField}
        />
      </Popover>
      {ConfirmationDialogComponent}
    </>
  );
};

export default CustomizeTimeSeriesFieldsButton;
