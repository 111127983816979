import { GridColDef } from "@mui/x-data-grid-premium";
import {
  NamedTimePeriod,
  TimeSeriesPeriodTableRow,
  TimeSeriesPeriodView,
} from "../../../../../api/types/timeSeriesTypes";
import TimeSeriesFieldValueText from "./TimeSeriesFieldValueText";

const nameColumn: GridColDef<TimeSeriesPeriodTableRow> = {
  field: "name",
  headerName: "Name",
  sortable: false,
  width: 240,
  type: "string",
};

const mapPeriodNameToColumn = (periodName: string): GridColDef<TimeSeriesPeriodTableRow> => ({
  field: periodName,
  headerName: periodName,
  sortable: false,
  width: 192,
  headerAlign: "right",
  align: "right",
  renderCell: ({ row }) => (
    <TimeSeriesFieldValueText
      fieldType={row.fieldType}
      fieldConfig={row.fieldConfiguration}
      fieldValue={row.periodValues[periodName]}
    />
  ),
});

export const getColumnsPerView = (
  periodsPerView: Record<TimeSeriesPeriodView, NamedTimePeriod[]>
): Record<TimeSeriesPeriodView, GridColDef<TimeSeriesPeriodTableRow>[]> => {
  return Object.fromEntries(
    Object.entries(periodsPerView).map(([periodView, periods]) => [
      periodView,
      [nameColumn, ...periods.map((period) => mapPeriodNameToColumn(period.name))],
    ])
  ) as Record<TimeSeriesPeriodView, GridColDef<TimeSeriesPeriodTableRow>[]>;
};
