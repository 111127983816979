import {
  Button,
  Divider,
  FilledInputProps,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { SelectInputProps } from "@mui/material/Select/SelectInput";
import { useState } from "react";
import CloseIconButton from "../../../../../../../shared/components/CloseIconButton";
import HorizontalFill from "../../../../../../../shared/components/HorizontalFill";
import { TimeSeriesFieldType, allTimeSeriesFieldTypes } from "../../../../../../api/types/timeSeriesTypes";
import TimeSeriesFieldTypeIcon from "../TimeSeriesFieldTypeIcon";
import TimeSeriesFieldConfigEditor from "./TimeSeriesFieldConfigEditor";
import { FieldEditForm } from "./timeSeriesFieldEditForm";

interface Props {
  fieldEditForm: FieldEditForm;
  onFormChange: (newForm: FieldEditForm) => void;
  onClose: () => void;
  onSubmit: () => void;
  onDelete: () => void;
}

const getFieldTypeName = (fieldType: TimeSeriesFieldType) => {
  switch (fieldType) {
    case "Number":
      return "Number";
    case "Money":
      return "Money";
    case "Percent":
      return "Percent";
    case "Text":
      return "Text";
    case "Date":
      return "Date";
    case "Select":
      return "Select";
    default:
      return fieldType;
  }
};

const TimeSeriesFieldEditor = ({ fieldEditForm, onFormChange, onClose, onSubmit, onDelete }: Props) => {
  const [isDirty, setDirty] = useState(false);

  const isEdit = fieldEditForm.fieldId !== undefined;

  const handleNameChange: FilledInputProps["onChange"] = (e) => {
    const name = e.target.value;
    setDirty(true);
    onFormChange({ ...fieldEditForm, name });
  };

  const handleFieldTypeChange: SelectInputProps<TimeSeriesFieldType>["onChange"] = (e) => {
    const fieldType = e.target.value as TimeSeriesFieldType;
    setDirty(true);
    onFormChange({ ...fieldEditForm, fieldType });
  };

  const handleConfigFormChange = (newForm: FieldEditForm) => {
    setDirty(true);
    onFormChange(newForm);
  };

  return (
    <Stack sx={(t) => ({ width: t.spacing(52.5) })} alignItems="flex-start">
      <Stack px={2.5} py={2} direction="row" justifyContent="space-between" alignItems="center" width="100%">
        <Typography flexGrow={1} variant="h6">
          {isEdit ? "Edit Field" : "Add Field"}
        </Typography>
        <CloseIconButton onClick={onClose} />
      </Stack>
      <Stack spacing={2} width="100%" px={2.5} pb={2}>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Type field name ..."
          value={fieldEditForm.name}
          onChange={handleNameChange}
          error={!!fieldEditForm.errors.name}
          helperText={fieldEditForm.errors.name}
        />
        <Select
          disabled={isEdit}
          fullWidth
          variant="outlined"
          value={fieldEditForm.fieldType}
          onChange={handleFieldTypeChange}
          renderValue={(fieldType) => (
            <Stack alignItems="center" direction="row" spacing={1} width="100%">
              <TimeSeriesFieldTypeIcon fieldType={fieldType} />
              <Typography>{getFieldTypeName(fieldType)}</Typography>
            </Stack>
          )}
        >
          {allTimeSeriesFieldTypes.map((fieldType) => (
            <MenuItem key={fieldType} value={fieldType}>
              <ListItemIcon>
                <TimeSeriesFieldTypeIcon fieldType={fieldType} />
              </ListItemIcon>
              <ListItemText>{getFieldTypeName(fieldType)}</ListItemText>
            </MenuItem>
          ))}
        </Select>
        <TimeSeriesFieldConfigEditor fieldEditForm={fieldEditForm} onFormChange={handleConfigFormChange} />
      </Stack>
      <Divider flexItem />
      <Stack width="100%" px={2} py={2} direction="row" justifyContent="space-between">
        {isEdit ? (
          <Button variant="outlined" color="error" onClick={onDelete}>
            Delete
          </Button>
        ) : (
          <HorizontalFill />
        )}
        <Stack spacing={1.5} direction="row">
          <Button variant="text" color="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="contained" onClick={onSubmit} disabled={!isDirty || !fieldEditForm.isValid}>
            {isEdit ? "Save" : "Add"}
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default TimeSeriesFieldEditor;
