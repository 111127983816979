import { Stack, Typography } from "@mui/material";
import ExpandableListWithBadge from "../../../../../../shared/components/ExpandableListWithBadge";
import FundraisingConfigurationCard from "./FundraisingConfigurationCard";
import { useFundraisingDetailsPageContext } from "./FundraisingDetailsPageContext";

const FundraisingConfigurationSection = () => {
  const { fundraising, isContentEditable, onTabChange } = useFundraisingDetailsPageContext();

  return (
    <Stack width="100%" spacing={1}>
      <Typography variant="subtitle1" pt={2} pb={1}>
        Configuration
      </Typography>

      <FundraisingConfigurationCard
        checked={fundraising.funds.length > 0}
        title="Access"
        actionLabel={isContentEditable ? "Edit" : "View"}
        onActionClick={() => onTabChange("access")}
      >
        <Typography variant="body2" color="text.secondary">
          Select one or more funds to target investor contacts. The list of contacts is dynamic, including those who
          match your fund selection now and in the future.
        </Typography>
        {fundraising.funds.length > 0 && (
          <ExpandableListWithBadge title="Funds" items={fundraising.funds.map((f) => f.name)} maxVisibleItems={3} />
        )}
      </FundraisingConfigurationCard>

      <FundraisingConfigurationCard
        checked={Boolean(fundraising.content?.title)}
        title="Content Layout"
        actionLabel={isContentEditable ? "Edit" : "View"}
        onActionClick={() => onTabChange("content")}
      >
        <Typography variant="body2" color="text.secondary">
          Review and adjust the appearance of your fundraising page.
        </Typography>
      </FundraisingConfigurationCard>

      <FundraisingConfigurationCard
        checked={fundraising.files.length > 0}
        title="Documents"
        actionLabel={isContentEditable ? "Add" : "View"}
        onActionClick={() => onTabChange("documents")}
      >
        <Typography variant="body2" color="text.secondary">
          Upload documents and other materials. Select the appropriate category for each file to manage permissions.
        </Typography>
      </FundraisingConfigurationCard>
    </Stack>
  );
};

export default FundraisingConfigurationSection;
