import { generatePath, useMatch } from "react-router-dom";
import { useClientContext } from "../../context/ClientContext";
import { useUserContext } from "../../context/UserContext";
import { pageRoutes } from "../../routes";
import { PortalFeature } from "../../utilities/featureManagement";
import MenuLinkItem from "../common/MenuLinkItem";
import SubMenuList from "./SubMenuList";

const InvestorRelationsMenu = () => {
  const { clientCode, hasAnyPermission, menuBadges } = useClientContext();
  const { isPortalFeatureEnabled } = useUserContext();

  const routePattern = `/:clientCode/${pageRoutes.investorRelations}/:page/*`;
  const routeMatch = useMatch(routePattern);
  const currentPage = routeMatch?.params["page"];

  const buildFullPath = (page: string) => generatePath(routePattern, { clientCode, page });

  const hasAccessToDocumentCollections = hasAnyPermission(["ViewDocumentCollections", "ManageFundStructure"]);
  const hasAccessToFundStructure = hasAnyPermission(["ViewFundStructure", "ManageFundStructure"]);
  const hasAccessToInvestorPortalSettings = hasAnyPermission([
    "ViewInvestorPortalSettings",
    "ManageInvestorPortalSettings",
  ]);
  const hasAccessToFundraising =
    hasAccessToInvestorPortalSettings && hasAnyPermission(["ViewFundraisings", "ManageFundraisings"]);
  const hasAccessToDocumentActivity =
    hasAccessToFundStructure && hasAnyPermission(["ViewInvestorPortalDocumentViewsAnalytics"]);
  const hasAccessToInvestorRequests = hasAnyPermission(["ViewInvestorRequests"]);

  return (
    <SubMenuList>
      {hasAccessToFundStructure && (
        <MenuLinkItem
          subItem
          active={currentPage === pageRoutes.funds}
          title="Funds"
          href={buildFullPath(pageRoutes.funds)}
        />
      )}
      {hasAccessToFundStructure && (
        <MenuLinkItem
          subItem
          active={currentPage === pageRoutes.investors}
          title="Investors"
          href={buildFullPath(pageRoutes.investors)}
        />
      )}
      {hasAccessToFundStructure && (
        <MenuLinkItem
          subItem
          active={currentPage === pageRoutes.contacts}
          title="Contacts"
          href={buildFullPath(pageRoutes.contacts)}
        />
      )}
      {hasAccessToInvestorRequests && (
        <MenuLinkItem
          subItem
          active={currentPage === pageRoutes.requests}
          title="Requests"
          href={buildFullPath(pageRoutes.requests)}
          badgeCount={menuBadges?.pendingInvestorChangeRequestCount}
        />
      )}
      {hasAccessToDocumentCollections && (
        <MenuLinkItem
          subItem
          active={currentPage === pageRoutes.documentCollections}
          title="Document Collections"
          href={buildFullPath(pageRoutes.documentCollections)}
        />
      )}
      {isPortalFeatureEnabled(PortalFeature.Fundraising) && hasAccessToFundraising && (
        <MenuLinkItem
          subItem
          active={currentPage === pageRoutes.fundraisings}
          title="Fundraising"
          href={buildFullPath(pageRoutes.fundraisings)}
        />
      )}
      {isPortalFeatureEnabled(PortalFeature.DocumentActivity) && hasAccessToDocumentActivity && (
        <MenuLinkItem
          subItem
          active={currentPage === pageRoutes.documentActivity}
          title="Document Activity"
          href={buildFullPath(pageRoutes.documentActivity)}
        />
      )}
      {hasAccessToInvestorPortalSettings && (
        <MenuLinkItem
          subItem
          active={currentPage === pageRoutes.investorEmails}
          title="Email Delivery Log"
          href={buildFullPath(pageRoutes.investorEmails)}
        />
      )}
      {hasAccessToInvestorPortalSettings && (
        <MenuLinkItem
          subItem
          active={currentPage === pageRoutes.investorPortalSettings}
          title="Investor Portal"
          href={buildFullPath(pageRoutes.investorPortalSettings)}
        />
      )}
    </SubMenuList>
  );
};

export default InvestorRelationsMenu;
