import {
  combineValidators,
  fileNameValidator,
  requiredValidator,
  uniqueValidator,
} from "../../../../../shared/utilities/validators";
import { DataCatalogueEntityType, FileTag } from "../../../../api/adminApi";

export interface TagSettingsDialogState {
  openDialog: "add" | "edit" | "delete" | undefined;
  form: TagFormState;
}

export interface TagFormState {
  name: string;
  entityType: DataCatalogueEntityType | "";
  catalogueId: string | undefined;
  isValid: boolean;
  errors: {
    name?: string;
    entityType?: string;
  };
}

export const getInitialState = (): TagSettingsDialogState => ({
  openDialog: undefined,
  form: { name: "", entityType: "", catalogueId: undefined, errors: {}, isValid: false },
});

export const validateForm = (form: TagFormState, tagGroups: Map<string, FileTag[]>): TagFormState => {
  const existingTagNames = form.entityType ? tagGroups?.get(form.entityType)?.map((t) => t.name) ?? [] : [];
  const validateName = combineValidators(
    requiredValidator,
    fileNameValidator("Tag name"),
    uniqueValidator("Such tag name already exists", existingTagNames)
  );
  const validateEntityType = requiredValidator;
  const nameValidationResult = validateName(form.name);
  const entityTypeValidationResult = validateEntityType(form.entityType);
  const isValid = nameValidationResult.isValid && entityTypeValidationResult.isValid;
  return {
    ...form,
    isValid,
    errors: {
      name: nameValidationResult.error,
      entityType: entityTypeValidationResult.error,
    },
  };
};
