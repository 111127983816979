import {
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";
import NoAccessIcon from "../../../../../../shared/icons/NoAccessIcon";
import { useImportCsvDataContext } from "../ImportCsvDataContext";
import {
  changeObjectTypeSelectionAction,
  changeTargetOptionSelectionAction,
  getObjectTypeOptions,
} from "../importCsvDataPageState";
import ImportCsvTargetCard from "./ImportCsvTargetCard";

const ImportCsvDataTargetSelectionStep = () => {
  const { pageState, setPageState } = useImportCsvDataContext();

  const handleChangeObjectType = (e: SelectChangeEvent) => {
    const selectedObjectType = e.target.value;
    setPageState(changeObjectTypeSelectionAction(selectedObjectType));
  };

  const handleToggleDataTypeTarget = (key: string) => (newSelected: boolean) => {
    setPageState(changeTargetOptionSelectionAction(key, newSelected));
  };

  if (pageState.targetOptions.length === 0) {
    return (
      <Container sx={{ height: "100%" }}>
        <Stack py={2.5} spacing={3} mx="auto" width="24rem">
          <NoAccessIcon />
          <Typography variant="subtitle2">
            You do not have enough permissions to any available CSV data import target.
          </Typography>
        </Stack>
      </Container>
    );
  }

  return (
    <Container sx={{ height: "100%" }}>
      <Stack py={2.5} spacing={3} mx="auto" width="25rem">
        <Typography color="text.secondary">Select the object type and data you wish to import.</Typography>
        <FormControl>
          <InputLabel id="object-type-select-label">Object Type</InputLabel>
          <Select
            fullWidth
            labelId="object-type-select-label"
            label="Object Type"
            value={pageState.selectedObjectType || ""}
            onChange={handleChangeObjectType}
          >
            {getObjectTypeOptions(pageState).map(({ value, label }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {pageState.selectedObjectType && (
          <Stack spacing={1} flex={1}>
            <Typography variant="subtitle2">Data</Typography>
            {pageState.targetOptions
              .filter(({ objectType }) => objectType === pageState.selectedObjectType)
              .map((target) => (
                <ImportCsvTargetCard
                  key={target.key}
                  target={target}
                  onToggleSelected={handleToggleDataTypeTarget(target.key)}
                />
              ))}
          </Stack>
        )}
      </Stack>
    </Container>
  );
};

export default ImportCsvDataTargetSelectionStep;
