import { createContext, PropsWithChildren, useContext } from "react";
import { defined } from "../../../../../shared/utilities/typeHelper";
import { DocumentViews } from "../../../../api/types/documentActivityTypes";
import { PaginatedItemsState } from "../../../../state/paginatedState";

interface Props {
  pageState: PaginatedItemsState<DocumentViews>;
  setPageState: React.Dispatch<React.SetStateAction<PaginatedItemsState<DocumentViews>>>;
  showFilePreview: (documentView: DocumentViews) => void;
}

type ContextValue = Props;

const DocumentActivityPageContext = createContext<ContextValue | undefined>(undefined);

export const DocumentActivityPageContextProvider = ({
  pageState,
  setPageState,
  showFilePreview,
  children,
}: PropsWithChildren<Props>) => {
  return (
    <DocumentActivityPageContext.Provider value={{ pageState, setPageState, showFilePreview }}>
      {children}
    </DocumentActivityPageContext.Provider>
  );
};

export const useDocumentActivityPageContext = () =>
  defined(useContext(DocumentActivityPageContext), "Attempt to use DocumentActivityPageContext without provider");
