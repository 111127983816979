import CheckBoxRoundedIcon from "@mui/icons-material/CheckBoxRounded";
import IndeterminateCheckBoxRoundedIcon from "@mui/icons-material/IndeterminateCheckBoxRounded";
import { Checkbox, Divider, Typography, useTheme } from "@mui/material";
import { SxProps, Theme } from "@mui/system";
import { GridToolbarContainer } from "@mui/x-data-grid-premium";
import { PropsWithChildren } from "react";

export const useCustomDataGridHeaderToolbar = () => {
  const theme = useTheme();

  const getGridSx = (showToolbar: boolean) => ({
    ".grid-row-restricted": {
      ".MuiCheckbox-root .MuiSvgIcon-root": {
        color: theme.palette.text.disabled,
      },
    },
    ".MuiDataGrid-columnHeader": showToolbar
      ? {
          display: "none",
          position: "absolute",
          zIndex: 0,
        }
      : {},
  });

  return { getGridSx };
};

interface Props {
  fullSelection?: boolean;
  selection?: [string[], (ids: string[]) => void];
  sx?: Partial<SxProps<Theme>>;
}

const CustomDataGridHeaderToolbar = ({ sx, fullSelection, selection, children }: PropsWithChildren<Props>) => {
  const combinedSx: SxProps<Theme> = {
    zIndex: 1,
    py: 0,
    px: 0.6,
    ...(sx || {}),
  };

  return (
    <GridToolbarContainer sx={combinedSx}>
      {selection && (
        <>
          <Checkbox
            checkedIcon={fullSelection ? <CheckBoxRoundedIcon /> : <IndeterminateCheckBoxRoundedIcon />}
            checked={selection[0]?.length > 0}
            onClick={() => selection[1]([])}
          />
          <Typography variant="subtitle2">{selection[0]?.length} selected</Typography>
          <Divider orientation="vertical" variant="middle" flexItem sx={{ ml: 2, mr: 1 }} />
        </>
      )}
      {children}
    </GridToolbarContainer>
  );
};

export default CustomDataGridHeaderToolbar;
