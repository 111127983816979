import DownloadIcon from "@mui/icons-material/SaveAltRounded";
import { Button, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { withErrorHandling } from "../../../../../../shared/api/axiosHelper";
import FileIcon from "../../../../../../shared/components/FileIcon";
import PreviewFileDialog from "../../../../../../shared/components/previewFile/PreviewFileDialog";
import { useNotificationContext } from "../../../../../../shared/contexts/NotificationContext";
import { logError } from "../../../../../../shared/logging";
import { downloadFileFromUrl } from "../../../../../../shared/services/downloadFile";
import { distinct } from "../../../../../../shared/utilities/arrayHelper";
import { getFileExtension } from "../../../../../../shared/utilities/fileHelper";
import { defined } from "../../../../../../shared/utilities/typeHelper";
import adminApi from "../../../../../api/adminApi";
import VisibleIcon from "../../../../../icons/VisibleIcon";
import CategoryTag from "../../../../common/CategoryTag";
import GeneralPageHeader from "../../../../common/GeneralPageHeader";

interface PageTitleProps {
  fileName: string;
  categoryName: string;
  fundNames: string[];
}

const getFundInfo = (fundNames: string[]): string => {
  switch (fundNames.length) {
    case 0:
      return "";
    case 1:
      return defined(fundNames[0]);
    case 2:
      return `${fundNames[0]}, ${fundNames[1]}`;
    default:
      return `${fundNames[0]} and ${fundNames.length - 1} more funds`;
  }
};

const PageTitle = ({ fileName, categoryName, fundNames }: PageTitleProps) => {
  const fundInfo = getFundInfo(distinct(fundNames.sort()));

  return (
    <Stack py={1.5} spacing={0.5}>
      <Stack direction="row" spacing={1} alignItems="center">
        <FileIcon fileExtension={getFileExtension(fileName)} />
        <Typography variant="h6" noWrap maxWidth="32rem">
          {fileName}
        </Typography>
        <CategoryTag categoryName={categoryName} />
      </Stack>
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography variant="caption">Document Activity</Typography>
        {fundInfo && <Typography variant="caption">•</Typography>}
        {fundInfo && <Typography variant="caption">{fundInfo}</Typography>}
      </Stack>
    </Stack>
  );
};

interface Props {
  backPath: string;
  fileName: string;
  categoryName: string;
  fundNames: string[];
  postMessageRequestId: string;
  attachmentId: string;
}

const getDocCollectionFileDownloadInfo = withErrorHandling(adminApi.getDocCollectionFileDownloadInfo);

interface DocumentPreviewState {
  url: string;
  fileName: string;
}

const FundStructureDocumentActivityPageHeader = ({
  backPath,
  fileName,
  categoryName,
  fundNames,
  postMessageRequestId,
  attachmentId,
}: Props) => {
  const { sendNotificationError } = useNotificationContext();

  const [documentPreviewState, setDocumentPreviewState] = useState<DocumentPreviewState>();

  const handleViewDocument = async () => {
    const [downloadInfo, error] = await getDocCollectionFileDownloadInfo(postMessageRequestId, attachmentId);
    if (error) {
      logError(error, "[FundStructureDocumentActivityPageHeader] getDocCollectionFileDownloadInfo");
      sendNotificationError("Could not download document");
      return;
    }

    setDocumentPreviewState({ url: downloadInfo.downloadUrl, fileName: downloadInfo.fileName });
  };

  const handleDownloadDocument = async () => {
    const [downloadInfo, error] = await getDocCollectionFileDownloadInfo(postMessageRequestId, attachmentId);
    if (error) {
      logError(error, "[FundStructureDocumentActivityPageHeader] getDocCollectionFileDownloadInfo");
      sendNotificationError("Could not download document");
      return;
    }

    downloadFileFromUrl(downloadInfo.downloadUrl);
  };

  return (
    <>
      <GeneralPageHeader
        TitleComponent={<PageTitle fileName={fileName} categoryName={categoryName} fundNames={fundNames} />}
        showDefaultBackButtonTo={backPath}
      >
        <Stack direction="row" spacing={1}>
          <Button variant="outlined" color="secondary" onClick={handleViewDocument} startIcon={<VisibleIcon />}>
            View
          </Button>
          <Button variant="outlined" color="secondary" onClick={handleDownloadDocument} startIcon={<DownloadIcon />}>
            Download
          </Button>
        </Stack>
      </GeneralPageHeader>
      {documentPreviewState && (
        <PreviewFileDialog
          url={documentPreviewState.url}
          fileName={documentPreviewState.fileName}
          onClose={() => setDocumentPreviewState(undefined)}
        />
      )}
    </>
  );
};

export default FundStructureDocumentActivityPageHeader;
