import { Stack, Typography } from "@mui/material";

interface Props {
  onSelected: (value: string) => void;
}

interface EmailSnippet {
  value: string;
  displayName: string;
}

const snippets: EmailSnippet[] = [
  { value: "{{FundName}}", displayName: "Fund Name" },
  { value: "{{InvestorName}}", displayName: "Investor Name" },
  { value: "{{RecipientName}}", displayName: "Contact Name" },
];

const EmailSnippets = ({ onSelected }: Props) => {
  const handleClick = (value: string) => () => onSelected(value);

  return (
    <Stack spacing={2} p={2}>
      {snippets.map(({ value, displayName }) => (
        <Stack key={value} direction="row" onClick={handleClick(value)} sx={{ cursor: "pointer" }}>
          <Typography fontWeight={700} width="10rem">
            {value}
          </Typography>
          <Typography width="10rem">{displayName}</Typography>
        </Stack>
      ))}
    </Stack>
  );
};
export default EmailSnippets;
