import { GridRowId } from "@mui/x-data-grid-premium";
import { GridApiPremium } from "@mui/x-data-grid-premium/models/gridApiPremium";
import { useEffect, useState } from "react";

export const useGridGroupsExpanded = (
  apiRef: React.MutableRefObject<GridApiPremium>,
  initiallyExpandedGroupIds?: GridRowId[]
) => {
  const [expandedGroupIds, setExpandedGroupIds] = useState<GridRowId[]>(initiallyExpandedGroupIds || []);

  useEffect(() => {
    return apiRef.current.subscribeEvent("rowExpansionChange", ({ id, childrenExpanded }) => {
      setExpandedGroupIds((ids) => (childrenExpanded ? [...ids, id] : ids.filter((nodeId) => nodeId !== id)));
    });
  }, [apiRef]);

  return expandedGroupIds;
};
