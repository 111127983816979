import { Stack, TableBody, TableCell, TableCellProps, TableHead, TableRow, Typography } from "@mui/material";
import { PropsWithChildren } from "react";
import RoundedTable from "../../../../../shared/components/RoundedTable";
import { formatMoney } from "../../../../../shared/utilities/formatters";
import { ApprovalRequest } from "../../../../api/mock/approvalsTypes";
import ObjectPageLink from "../../../entityFields/ObjectPageLink";

interface Props {
  approvalRequest: ApprovalRequest;
}

const Cell = ({ children, ...props }: PropsWithChildren<TableCellProps>) => (
  <TableCell sx={{ minWidth: 180 }} {...props}>
    {children}
  </TableCell>
);

const ApprovalPaymentsSection = ({ approvalRequest }: Props) => {
  return (
    <Stack width="100%" spacing={2} maxWidth="75vw">
      <Typography variant="subtitle1">Payments</Typography>
      <RoundedTable>
        <TableHead>
          <TableRow>
            <Cell>Counterparty</Cell>
            <Cell>Counterparty Bank</Cell>
            <Cell>ABA / SWIFT</Cell>
            <Cell>Account Name</Cell>
            <Cell>Account Number / IBAN</Cell>
            <Cell>Entity</Cell>
            <Cell>Entity Bank</Cell>
            <Cell>ABA / SWIFT</Cell>
            <Cell>Account Name</Cell>
            <Cell>Account Number / IBAN</Cell>
            <Cell align="right">Amount</Cell>
          </TableRow>
        </TableHead>
        <TableBody>
          {approvalRequest.payments.map((payment) => (
            <TableRow key={payment.id}>
              <Cell>{payment.counterpartyName}</Cell>
              <Cell>{payment.counterpartyBank.bankName}</Cell>
              <Cell>{payment.counterpartyBank.abaNumber}</Cell>
              <Cell>{payment.counterpartyBank.accountName}</Cell>
              <Cell>{payment.counterpartyBank.accountNumber}</Cell>
              <Cell>
                <ObjectPageLink objectType="Fund" objectId={payment.entityId}>
                  {payment.entityName}
                </ObjectPageLink>
              </Cell>
              <Cell>{payment.entityBank.bankName}</Cell>
              <Cell>{payment.entityBank.abaNumber}</Cell>
              <Cell>{payment.entityBank.accountName}</Cell>
              <Cell>{payment.entityBank.accountNumber}</Cell>
              <Cell align="right">{formatMoney(payment.amount, payment.currencyCode)}</Cell>
            </TableRow>
          ))}
        </TableBody>
      </RoundedTable>
    </Stack>
  );
};

export default ApprovalPaymentsSection;
