import { Stack, Typography } from "@mui/material";
import { GridRenderCellParams, useGridApiContext } from "@mui/x-data-grid-premium";
import { ExpandCollapseButton } from "../../../../../../shared/reporting/components/reports/ExpandCollapseButton";
import { PropsWithChildren } from "react";
import { OrganizationReportInfo } from "../../../../../api/biApi";

interface Props {
  expanded: boolean;
  params: GridRenderCellParams<OrganizationReportInfo, string, unknown>;
}
const GroupNameCore = ({ expanded, params, children }: PropsWithChildren<Props>) => {
  const apiRef = useGridApiContext();
  return (
    <Stack direction={"row"} alignItems={"center"} gap={1} pl={params.rowNode.depth * 2}>
      <ExpandCollapseButton
        expanded={expanded}
        onClick={(value) => apiRef.current.setRowChildrenExpansion(params.rowNode.id, value)}
      />
      {children}
      <Typography variant="subtitle2">{params.value}</Typography>
    </Stack>
  );
};

export default GroupNameCore;
