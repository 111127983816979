import { createErrorApiResponse } from "../../../../../shared/api/axiosHelper";
import { ApiResponse } from "../../../../../shared/api/types";
import adminApi, { FileDownloadInfo } from "../../../../api/adminApi";
import { DocumentViews } from "../../../../api/types/documentActivityTypes";

export const getDocumentViewFileDownloadInfo = async (
  documentView: DocumentViews
): Promise<ApiResponse<FileDownloadInfo>> => {
  const {
    postMessageRequestId,
    fundraisingId,
    documentInfo: { attachmentId, fundraisingDocumentId },
  } = documentView;

  if (postMessageRequestId && attachmentId) {
    return adminApi.getDocCollectionFileDownloadInfo(postMessageRequestId, attachmentId);
  }

  if (fundraisingId && fundraisingDocumentId) {
    return adminApi.getFundraisingFileDownloadInfo(fundraisingId, fundraisingDocumentId);
  }

  return Promise.resolve(
    createErrorApiResponse({
      message: "Invalid document view: not enough data for file download",
      type: "General",
    })
  );
};
