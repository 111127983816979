import { useCallback, useState } from "react";
import { Navigate, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { createApiResponse } from "../../../../../../shared/api/axiosHelper";
import DataLoadingFailed from "../../../../../../shared/components/DataLoadingFailed";
import InlineLoader from "../../../../../../shared/components/inlineLoader/InlineLoader";
import useFetch from "../../../../../../shared/hooks/useFetch";
import { logError } from "../../../../../../shared/logging";
import { numberComparerBy, stringComparerBy } from "../../../../../../shared/utilities/arrayHelper";
import { parseSearchParamWithFallback } from "../../../../../../shared/utilities/searchParamsHelper";
import adminApi from "../../../../../api/adminApi";
import { Fundraising } from "../../../../../api/types/fundraisingTypes";
import { useClientContext } from "../../../../../context/ClientContext";
import PageTabs from "../../../../common/PageTabs";
import FundraisingDialogs from "../dialogs/FundraisingDialogs";
import { DetailsPageTab, DialogState, FundraisingStatusTransition, detailsPageTabs } from "../fundraisingsPageTypes";
import FundraisingAccessConfig from "./FundraisingAccessConfig";
import FundraisingContentConfig from "./FundraisingContentConfig";
import { FundraisingDetailsPageContextProvider } from "./FundraisingDetailsPageContext";
import FundraisingDetailsPageHeader from "./FundraisingDetailsPageHeader";
import FundraisingDetailsTabPanel from "./FundraisingDetailsTabPanel";
import FundraisingOverview from "./FundraisingOverview";
import FundraisingDocuments from "./fundraising-documents/FundraisingDocuments";

const FundraisingDetailsPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { hasPermissions } = useClientContext();
  const [dialogState, setDialogState] = useState<DialogState>({});

  const [selectedTab, setSelectedTab] = useState<DetailsPageTab>(
    parseSearchParamWithFallback(searchParams, "tab", detailsPageTabs)
  );

  const getFundraisingDetails = useCallback(
    async () => (id ? adminApi.getFundraisingById(id) : Promise.resolve(createApiResponse(undefined))),
    [id]
  );

  const [fundraising, fetchFundraisingError, { isFetching: isFetchingFundraising, setData: setFundraising }] =
    useFetch(getFundraisingDetails);

  const getFunds = useCallback(async () => adminApi.searchFunds({ fieldIds: [], includeInvestorCount: false }), []);

  const [funds, fetchFundsError, { isFetching: isFetchingFunds }] = useFetch(getFunds);

  const [accessCategories, fetchCategoriesError, { isFetching: isFetchingCategories }] = useFetch(
    adminApi.getAccessCategories
  );

  if (!id) {
    return <Navigate to="/" />;
  }

  const fetchError = fetchFundraisingError || fetchFundsError || fetchCategoriesError;
  const isLoading = isFetchingFundraising || isFetchingFunds || isFetchingCategories;

  if (fetchError) {
    logError(fetchError, "[FundraisingDetailsPage] fetch");
    return <DataLoadingFailed title="Failed to load fundraising details" />;
  }

  if (isLoading || fundraising === undefined || funds === undefined || accessCategories === undefined) {
    return <InlineLoader />;
  }

  const handleTabChange = (tab: DetailsPageTab) => {
    setSelectedTab(tab);
    setSearchParams({ tab });
  };

  const handleRename = () => {
    if (fundraising) {
      setDialogState({
        openDialog: "rename",
        fundraisingId: fundraising.id,
        fundraisingName: fundraising.name,
      });
    }
  };

  const handleStatusChange = (statusTransition: FundraisingStatusTransition) => {
    setDialogState({
      openDialog: "change_status",
      statusTransition,
      fundraisingId: fundraising.id,
      fundraisingName: fundraising.name,
    });
  };

  const handleConfirmSave = (onSaveConfirmed: () => void) => {
    if (fundraising.status !== "Live") {
      onSaveConfirmed();
      return;
    }

    setDialogState({
      openDialog: "confirm_save",
      onSaveConfirmed,
      fundraisingId: fundraising.id,
      fundraisingName: fundraising.name,
    });
  };

  const handleDelete = () => {
    setDialogState({
      openDialog: "delete",
      fundraisingId: fundraising.id,
      fundraisingName: fundraising.name,
    });
  };

  const handleDialogClose = () => setDialogState({});

  const handleDeleted = () => {
    handleDialogClose();
    navigate("..");
  };

  const handleUpdated = (updatedFundraising: Fundraising) => {
    handleDialogClose();
    setFundraising(updatedFundraising);
  };

  const hasEditPermissions = hasPermissions(["ManageFundraisings"]);

  const fundraisingCategories = accessCategories
    .filter((c) => c.type === "Fundraising")
    .sort(numberComparerBy((c) => c.sortOrder));

  const fundOptions = funds.items.map((f) => ({ value: f.id, label: f.name })).sort(stringComparerBy((f) => f.label));

  return (
    <FundraisingDetailsPageContextProvider
      fundraising={fundraising}
      hasEditPermissions={hasEditPermissions}
      isContentEditable={hasEditPermissions && fundraising.status !== "Completed"}
      categories={fundraisingCategories}
      fundOptions={fundOptions}
      onRename={handleRename}
      onStatusChange={handleStatusChange}
      onConfirmSave={handleConfirmSave}
      onDelete={handleDelete}
      onTabChange={handleTabChange}
      onUpdated={handleUpdated}
    >
      <FundraisingDetailsPageHeader />
      <PageTabs
        tabs={detailsPageTabs}
        labels={["Overview", "Access", "Content Layout", "Documents"]}
        value={selectedTab}
        onChange={handleTabChange}
      >
        <FundraisingDetailsTabPanel value="overview" containerWidth="lg">
          <FundraisingOverview />
        </FundraisingDetailsTabPanel>
        <FundraisingDetailsTabPanel value="access" containerWidth="lg">
          <FundraisingAccessConfig />
        </FundraisingDetailsTabPanel>
        <FundraisingDetailsTabPanel value="content" containerWidth="lg">
          <FundraisingContentConfig />
        </FundraisingDetailsTabPanel>
        <FundraisingDetailsTabPanel value="documents" containerWidth="xl">
          <FundraisingDocuments />
        </FundraisingDetailsTabPanel>
      </PageTabs>

      <FundraisingDialogs
        dialogState={dialogState}
        onClose={handleDialogClose}
        onCreated={() => handleDialogClose()}
        onUpdated={handleUpdated}
        onDeleted={handleDeleted}
      />
    </FundraisingDetailsPageContextProvider>
  );
};

export default FundraisingDetailsPage;
