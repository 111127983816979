import { Stack, Typography } from "@mui/material";
import { GridColDef, GridGroupingColDefOverride } from "@mui/x-data-grid-premium";
import { convertISODate } from "../../../../../../shared/utilities/dateUtils";
import { FundraisingAccessItem } from "../../../../../api/types/fundraisingTypes";
import CategoryInlineTags from "../../../../common/CategoryInlineTags";
import GroupHeader from "../../../../common/grid/GroupHeader";

export const applySearchFilter = (searchValue: string, allRows: FundraisingAccessItem[]) => {
  const searchTerm = searchValue.toLowerCase().trim();
  if (searchTerm.length === 0) {
    return allRows;
  }

  return allRows.filter(
    (row) =>
      row.investorName.toLowerCase().includes(searchTerm) ||
      row.contactName.toLowerCase().includes(searchTerm) ||
      (row.contactEmail ?? "").toLowerCase().includes(searchTerm)
  );
};

export const groupingColumnDefinition: GridGroupingColDefOverride<FundraisingAccessItem> = {
  leafField: "contactName",
  renderHeader: (params) => <GroupHeader params={params} />,
  flex: 1,
};

export const columnDefinitions: GridColDef<FundraisingAccessItem>[] = [
  {
    field: "investorName",
    headerName: "",
    renderCell: ({ rowNode, field, value }) => {
      if (rowNode.type === "group" && field === rowNode.groupingField) {
        return "";
      }

      return (
        <Typography variant="subtitle2" noWrap>
          {value}
        </Typography>
      );
    },
  },
  {
    field: "contactName",
    headerName: "Name",
    renderCell: ({ row, rowNode }) => {
      if (rowNode.type === "group") {
        return "";
      }

      return (
        <Stack spacing={0.5} width="100%" pl={5}>
          <Typography variant="subtitle2" noWrap>
            {row.contactName}
          </Typography>
          <Typography variant="caption" color="text.secondary" noWrap>
            {row.contactEmail}
          </Typography>
        </Stack>
      );
    },
  },
  {
    field: "registrationDate",
    headerName: "Registration Status",
    width: 160,
    renderCell: ({ row, rowNode }) => {
      if (rowNode.type === "group") {
        return "";
      }

      return row.registrationDate ? (
        <Stack spacing={0.5} width="100%">
          <Typography>Registered</Typography>
          <Typography variant="caption" color="text.secondary" noWrap>
            {convertISODate(row.registrationDate)}
          </Typography>
        </Stack>
      ) : (
        <Typography>Not Registered</Typography>
      );
    },
  },
  {
    field: "categories",
    headerName: "Categories",
    minWidth: 240,
    renderCell: ({ row, rowNode }) => {
      if (rowNode.type === "group") {
        return "";
      }

      return <CategoryInlineTags categoryNames={row.categoryNames} maxDisplayedCategories={1} />;
    },
  },
];
