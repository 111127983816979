import { Typography } from "@mui/material";
import TypographyTooltipEllipsis from "../../../../../../shared/components/TypographyTooltipEllipsis";
import { convertISODate, convertISODateShort } from "../../../../../../shared/utilities/dateUtils";
import { formatMoney, formatNumber, formatPercentage } from "../../../../../../shared/utilities/formatters";
import {
  TimeSeriesFieldConfiguration,
  TimeSeriesFieldType,
  TimeSeriesFieldValue,
} from "../../../../../api/types/timeSeriesTypes";

interface Props {
  fieldType: TimeSeriesFieldType;
  fieldConfig: TimeSeriesFieldConfiguration;
  fieldValue: TimeSeriesFieldValue;
}

const TimeSeriesFieldValueText = ({ fieldType, fieldConfig, fieldValue }: Props) => {
  switch (fieldType) {
    case "Text": {
      return fieldValue ? <TypographyTooltipEllipsis text={fieldValue.toString()} /> : null;
    }
    case "Number": {
      return typeof fieldValue === "number" ? (
        <Typography>
          {formatNumber(fieldValue, {
            maximumFractionDigits: fieldConfig.precision,
            factor: fieldConfig.numberFormatFactor,
          })}
        </Typography>
      ) : null;
    }
    case "Money": {
      return typeof fieldValue === "number" ? (
        <Typography>
          {formatMoney(fieldValue, fieldConfig.currencyCode ?? "USD", {
            maximumFractionDigits: fieldConfig.precision,
            factor: fieldConfig.numberFormatFactor,
          })}
        </Typography>
      ) : null;
    }
    case "Percent": {
      return typeof fieldValue === "number" ? (
        <Typography>{formatPercentage(fieldValue, { maximumFractionDigits: fieldConfig.precision })}</Typography>
      ) : null;
    }
    case "Date": {
      return typeof fieldValue === "string" ? (
        <Typography>
          {fieldConfig.dateFormat === "Long" ? convertISODate(fieldValue) : convertISODateShort(fieldValue)}
        </Typography>
      ) : null;
    }
    case "Select": {
      if (!fieldValue) {
        return null;
      }
      const option = fieldConfig.valueOptions?.find((option) => option === fieldValue.toString());
      const resolvedValue = option || fieldValue.toString();
      return <TypographyTooltipEllipsis text={resolvedValue} />;
    }
    default: {
      return fieldValue !== undefined ? <TypographyTooltipEllipsis text={fieldValue as string} /> : null;
    }
  }
};

export default TimeSeriesFieldValueText;
