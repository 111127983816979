import ExportIcon from "@mui/icons-material/ExitToAppOutlined";
import { Box, Button, Grid, Stack } from "@mui/material";
import { GridRowSelectionModel, useGridApiRef } from "@mui/x-data-grid-premium";
import saveAs from "file-saver";
import { useMemo, useState } from "react";
import SearchField from "../../../../../../shared/components/inputs/SearchField";
import { setAllGroupsExpansion } from "../../../../../../shared/utilities/dataGridApiHelper";
import { DocumentInfo, InvestorDeliveryInfo } from "../../../../../api/adminApi";
import { createNestedSearchFilter } from "../../searchFilter";
import { documentsToCsv } from "../csv-formatters";
import DocumentsFilter, { DocumentsFilterValue } from "./DocumentsFilter";
import DocumentsTableFundLevel from "./DocumentsTableFundLevel";
import DocumentPreviewContainer from "./document-preview/DocumentPreviewContainer";
import { getSelectedItemsForFundLevel } from "./selectionHelper";

interface Props {
  documents: DocumentInfo[];
  showPublishingStatus: boolean;
}

const applySearch = createNestedSearchFilter<DocumentInfo, InvestorDeliveryInfo, "investorDeliveries">(
  3,
  (c) => [c.name, c.category],
  "investorDeliveries",
  (d) => [d.investorTitle]
);

const applyFilter = (documents: DocumentInfo[], filterValue: DocumentsFilterValue) => {
  if (filterValue === "All") {
    return documents;
  }

  const targetIsViewed = filterValue === "Viewed";
  return documents.reduce<DocumentInfo[]>((result, doc) => {
    const investorDeliveries = doc.investorDeliveries.filter((i) => !!i.isViewed === targetIsViewed);
    if (investorDeliveries.length > 0) {
      result.push({ ...doc, investorDeliveries });
    }

    return result;
  }, []);
};

const getFilterValueCounts = (documents: DocumentInfo[]) => {
  let allCount = 0;
  let viewedCount = 0;
  let notViewedCount = 0;

  for (const doc of documents) {
    for (const delivery of doc.investorDeliveries) {
      if (delivery.isViewed) {
        viewedCount++;
      } else {
        notViewedCount++;
      }
      allCount++;
    }
  }

  return { allCount, viewedCount, notViewedCount };
};

const DocumentsViewFundLevel = ({ documents, showPublishingStatus }: Props) => {
  const gridApiRef = useGridApiRef();

  const [filteredItems, setFilteredItems] = useState<DocumentInfo[]>(documents);
  const [filterValue, setFilterValue] = useState<DocumentsFilterValue>("All");
  const [currentSearchTerm, setCurrentSearchTerm] = useState("");
  const [selection, setSelection] = useState<GridRowSelectionModel>();

  const { allCount, viewedCount, notViewedCount } = useMemo(() => getFilterValueCounts(documents), [documents]);

  const updateFilteredItems = (documents: DocumentInfo[], searchTerm: string, filterValue: DocumentsFilterValue) => {
    const result = applyFilter(applySearch(documents, searchTerm), filterValue);
    setFilteredItems(result);
    if (filterValue !== "All" || searchTerm.length >= 3) {
      setAllGroupsExpansion(gridApiRef, true);
    }
  };

  const handleSearch = (searchTerm: string) => {
    updateFilteredItems(documents, searchTerm, filterValue);
    setCurrentSearchTerm(searchTerm);
  };

  const handleSelectionChange = (newSelection: GridRowSelectionModel) => setSelection(newSelection);

  const handleFilterChange = (newValue: DocumentsFilterValue) => {
    updateFilteredItems(documents, currentSearchTerm, newValue);
    setFilterValue(newValue);
  };

  const handleExportToCsvClick = () => {
    const csv = documentsToCsv(filteredItems, "Fund");
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const fileName = "document_views.csv";
    saveAs(blob, fileName);
  };

  const { selectedDocument, selectedInvestor } = getSelectedItemsForFundLevel(selection, documents);

  return (
    <Grid container height="100%">
      <Grid item xs={6} height="100%" sx={{ pb: 8 }}>
        <Box
          mt={2}
          mb={1}
          mr={1}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          flexWrap="wrap"
          rowGap={1}
        >
          <DocumentsFilter
            allCount={allCount}
            viewedCount={viewedCount}
            notViewedCount={notViewedCount}
            value={filterValue}
            onChange={handleFilterChange}
          />
          <Stack direction="row" spacing={1} alignItems="center">
            <SearchField onSearch={handleSearch} />
            <Button
              color="secondary"
              variant="outlined"
              onClick={handleExportToCsvClick}
              startIcon={<ExportIcon />}
              disabled={filteredItems.length === 0}
            >
              Export
            </Button>
          </Stack>
        </Box>
        <DocumentsTableFundLevel
          apiRef={gridApiRef}
          documents={filteredItems}
          showPublishingStatus={showPublishingStatus}
          selection={selection}
          onSelectionChange={handleSelectionChange}
        />
      </Grid>
      <Grid item xs={6} height="100%">
        <DocumentPreviewContainer
          messageLevel="Fund"
          documents={selectedDocument ? [selectedDocument] : []}
          investor={selectedInvestor}
        />
      </Grid>
    </Grid>
  );
};

export default DocumentsViewFundLevel;
