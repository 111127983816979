import AccessTimeIcon from "@mui/icons-material/AccessTimeRounded";
import TagIcon from "@mui/icons-material/LabelRounded";
import SettingsIcon from "@mui/icons-material/SettingsRounded";
import { SvgIconProps } from "@mui/material";
import { stringComparerBy } from "../../../../shared/utilities/arrayHelper";
import { FileTag, FilesViewDefinition } from "../../../api/adminApi";
import FilesIcon from "../../../icons/FilesIcon";
import ViewsIcon from "../../../icons/ViewsIcon";
import VerticalNavigationMenuItem from "../navigation/VerticalNavigationMenuItem";
import VerticalNavigationSection from "../navigation/VerticalNavigationSection";
import VerticalNavigationWrapper from "../navigation/VerticalNavigationWrapper";
import FilesViewIcon from "./FilesViewIcon";
import { getEntityTitle, groupTagsByEntityType, orderedEntityTypesForTags } from "./fileDetailsHelper";

interface Props {
  predefinedViews: FilesViewDefinition[];
  selectedView: FilesViewDefinition | undefined;
  onSelectedViewChange: (viewName: string) => void;
  tags: FileTag[];
  selectedTag: FileTag | undefined;
  onSelectedTagChange: (tagId: string) => void;
  showSettings: boolean;
  onShowSettings: () => void;
}

const predefinedViewsIconMap = new Map<string, (p: SvgIconProps) => React.ReactNode>([
  ["AllFiles", FilesIcon],
  ["RecentlyUploaded", AccessTimeIcon],
]);

export const getViewIcon = (viewName: string) => predefinedViewsIconMap.get(viewName) ?? ViewsIcon;

const FilesNavigation = ({
  predefinedViews,
  selectedView,
  onSelectedViewChange,
  tags,
  selectedTag,
  onSelectedTagChange,
  showSettings,
  onShowSettings,
}: Props) => {
  const tagGroups = groupTagsByEntityType(tags);

  const handleViewItemClick = (viewName: string) => () => onSelectedViewChange(viewName);

  const handleTagItemClick = (tagId: string) => () => onSelectedTagChange(tagId);

  return (
    <VerticalNavigationWrapper>
      <VerticalNavigationSection title="Menu">
        {predefinedViews.map((view) => {
          const isSelected = selectedView?.name === view.name;
          return (
            <VerticalNavigationMenuItem
              key={view.name}
              text={view.title}
              Icon={<FilesViewIcon viewName={view.name} color={isSelected ? "primary" : "secondary"} />}
              selected={isSelected}
              onClick={handleViewItemClick(view.name)}
            />
          );
        })}
        <VerticalNavigationMenuItem
          key="tag_settings"
          text="Tag Settings"
          Icon={<SettingsIcon color={showSettings ? "primary" : "secondary"} />}
          selected={showSettings}
          onClick={onShowSettings}
        />
      </VerticalNavigationSection>

      {orderedEntityTypesForTags.map((entityType) => {
        const entityTags = tagGroups.get(entityType) ?? [];
        if (entityTags.length === 0) {
          return null;
        }

        return (
          <VerticalNavigationSection key={entityType} title={getEntityTitle(entityType)}>
            {entityTags.sort(stringComparerBy((t) => t.name)).map((tag) => {
              const isSelected = selectedTag?.catalogueId === tag.catalogueId;
              return (
                <VerticalNavigationMenuItem
                  key={tag.catalogueId}
                  text={tag.name}
                  Icon={<TagIcon color={isSelected ? "primary" : "secondary"} />}
                  selected={isSelected}
                  onClick={handleTagItemClick(tag.catalogueId)}
                />
              );
            })}
          </VerticalNavigationSection>
        );
      })}
    </VerticalNavigationWrapper>
  );
};

export default FilesNavigation;
