import { IconButton, Stack, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-premium";
import { useNavigate } from "react-router-dom";
import FileIcon from "../../../../../shared/components/FileIcon";
import InlineItemsList from "../../../../../shared/components/InlineItemsList";
import StaticCircularProgress from "../../../../../shared/components/StaticCircularProgress";
import { distinct } from "../../../../../shared/utilities/arrayHelper";
import { convertISODateTime } from "../../../../../shared/utilities/dateUtils";
import { getFileExtension } from "../../../../../shared/utilities/fileHelper";
import { formatDurationInSeconds } from "../../../../../shared/utilities/formatters";
import { DocumentViews } from "../../../../api/types/documentActivityTypes";
import { useClientContext } from "../../../../context/ClientContext";
import AnalyticsIcon from "../../../../icons/AnalyticsIcon";
import { pageRoutes } from "../../../../routes";
import CategoryInlineTags from "../../../common/CategoryInlineTags";
import { useDocumentActivityPageContext } from "./DocumentActivityPageContext";

const FileNameCell = ({ row }: { row: DocumentViews }) => {
  const { showFilePreview } = useDocumentActivityPageContext();

  return (
    <Stack direction="row" spacing={1} alignItems="center" width="100%">
      <FileIcon fileExtension={getFileExtension(row.documentInfo.fileName)} />
      <Typography
        noWrap
        sx={(t) => ({ cursor: "pointer", "&:hover": { color: t.palette.primary.main } })}
        onClick={() => showFilePreview(row)}
      >
        {row.documentInfo.fileName}
      </Typography>
    </Stack>
  );
};

const UserCell = ({ row }: { row: DocumentViews }) => {
  const { clientCode } = useClientContext();
  const navigate = useNavigate();

  const contactId = row.userInfo.contactId;
  const canNavigateToContact = Boolean(contactId);

  const handleContainerClick = () => {
    if (canNavigateToContact) {
      navigate(`/${clientCode}/${pageRoutes.investorRelations}/${pageRoutes.contacts}/${contactId}/main`);
    }
  };

  return (
    <Stack
      spacing={0.5}
      width="100%"
      onClick={handleContainerClick}
      sx={(t) => (canNavigateToContact ? { ":hover": { color: t.palette.primary.main, cursor: "pointer" } } : {})}
    >
      <Typography variant="subtitle2" noWrap>
        {row.userInfo.userName}
      </Typography>
      <Typography variant="caption" color="text.secondary" noWrap>
        {row.userInfo.userEmail}
      </Typography>
    </Stack>
  );
};

const ActionsCell = ({ row }: { row: DocumentViews }) => {
  const { clientCode } = useClientContext();
  const navigate = useNavigate();

  const {
    postMessageRequestId,
    userInfo: { contactId },
    documentInfo: { attachmentId },
  } = row;

  const canNavigateToDocumentActivity = Boolean(contactId) && Boolean(attachmentId) && Boolean(attachmentId);

  if (!canNavigateToDocumentActivity) {
    return null;
  }

  const handleNavigateToDocumentActivity = () => {
    navigate(
      `/${clientCode}/${pageRoutes.investorRelations}/${pageRoutes.contacts}/${contactId}/${pageRoutes.messages}/${postMessageRequestId}/${pageRoutes.attachments}/${attachmentId}/${pageRoutes.activity}`
    );
  };

  return (
    <IconButton
      onClick={(e) => {
        e.stopPropagation();
        handleNavigateToDocumentActivity();
      }}
    >
      <AnalyticsIcon />
    </IconButton>
  );
};

export const columnDefinitions: GridColDef<DocumentViews>[] = [
  {
    field: "userInfo",
    headerName: "Contact",
    flex: 1,
    cellClassName: "highlighted-action",
    renderCell: ({ row }) => <UserCell row={row} />,
  },
  {
    field: "documentInfo.investorNames",
    headerName: "Investor",
    flex: 1,
    renderCell: ({ row }) => {
      if (row.documentInfo.investorNames.length === 0) {
        return "-";
      }

      return (
        <InlineItemsList<string>
          displayCount={1}
          items={distinct(row.documentInfo.investorNames)}
          propGetter={(name) => name}
          justify="flex-start"
        />
      );
    },
  },
  {
    field: "documentInfo.fundNames",
    headerName: "Fund",
    flex: 1,
    renderCell: ({ row }) => {
      if (row.documentInfo.fundNames.length === 0) {
        return "-";
      }

      return (
        <InlineItemsList<string>
          displayCount={1}
          items={distinct(row.documentInfo.fundNames)}
          propGetter={(name) => name}
          justify="flex-start"
        />
      );
    },
  },
  {
    field: "documentInfo.categories",
    headerName: "Category",
    flex: 1,
    minWidth: 120,
    renderCell: ({ row }) => <CategoryInlineTags categoryNames={row.documentInfo.categories} />,
  },
  {
    field: "documentInfo",
    headerName: "Document",
    flex: 1.5,
    cellClassName: "highlighted-action",
    renderCell: ({ row }) => <FileNameCell row={row} />,
  },
  {
    field: "sessionStart",
    headerName: "Viewed at",
    width: 160,
    valueFormatter: (value: string) => (value ? convertISODateTime(value) : ""),
  },
  {
    field: "totalViewDuration",
    headerName: "View Duration",
    width: 120,
    headerAlign: "right",
    align: "right",
    valueFormatter: (value: number) => (value ? formatDurationInSeconds(value) : ""),
  },
  {
    field: "completionPercentage",
    headerName: "Total Completion",
    type: "number",
    width: 160,
    headerAlign: "center",
    align: "center",
    renderCell: ({ row }) => {
      if (!row.completionPercentage) {
        return null;
      }

      return (
        <Stack direction="row" spacing={0.5} alignItems="center">
          <Typography>{row.completionPercentage + "%"}</Typography>
          <StaticCircularProgress size={20} value={row.completionPercentage} />
        </Stack>
      );
    },
  },
  {
    field: "downloadTimes",
    headerName: "Downloaded at",
    width: 160,
    renderCell: ({ row }) => {
      const lastDownloadTime = row.downloadTimes[row.downloadTimes.length - 1];
      return lastDownloadTime ? convertISODateTime(lastDownloadTime) : null;
    },
  },
  {
    field: "actions",
    headerName: "",
    width: 50,
    cellClassName: "grid-row-actions",
    align: "center",
    renderCell: ({ row }) => <ActionsCell row={row} />,
  },
];
