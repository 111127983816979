import { Avatar, Checkbox, Paper, Stack, Typography } from "@mui/material";
import { makeLighterBackgroundFromColor } from "../../../../../../shared/utilities/colorHelper";
import { ImportCsvTargetOption } from "../importCsvDataPageState";
import ImportCsvTargetIcon from "./ImportCsvTargetIcon";

interface Props {
  target: ImportCsvTargetOption;
  onToggleSelected: (newSelected: boolean) => void;
}

const getDescription = (targetOption: ImportCsvTargetOption) => {
  switch (targetOption.target) {
    case "PlatformObjects": {
      return "Create and update platform objects";
    }
    case "TimeSeriesFields": {
      return "Create and update time series fields";
    }
    default: {
      return "";
    }
  }
};

const ImportCsvTargetCard = ({ target, onToggleSelected }: Props) => {
  const description = getDescription(target);

  return (
    <Paper variant="outlined" sx={{ p: 1 }}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack direction="row" alignItems="center" spacing={1.5}>
          <Avatar
            variant="rounded"
            sx={(t) => ({
              width: 32,
              height: 32,
              color: target.selected ? t.palette.primary.main : t.palette.secondary.main,
              bgcolor: makeLighterBackgroundFromColor(
                target.selected ? t.palette.primary.main : t.palette.secondary.main
              ),
            })}
            color={target.selected ? "primary" : "secondary"}
          >
            <ImportCsvTargetIcon target={target.target} />
          </Avatar>
          <Stack spacing={0.5}>
            <Typography variant="subtitle2">{target.label}</Typography>
            {description && <Typography color="text.secondary">{description}</Typography>}
          </Stack>
        </Stack>
        <Checkbox checked={target.selected} onChange={(_, checked) => onToggleSelected(checked)} />
      </Stack>
    </Paper>
  );
};

export default ImportCsvTargetCard;
