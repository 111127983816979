import { Stack } from "@mui/material";
import { useGridApiRef } from "@mui/x-data-grid-premium";
import saveAs from "file-saver";
import objectHash from "object-hash";
import { useCallback, useReducer, useState } from "react";
import { withErrorHandling } from "../../../../../shared/api/axiosHelper";
import DataLoadingFailed from "../../../../../shared/components/DataLoadingFailed";
import PreviewFileDialog from "../../../../../shared/components/previewFile/PreviewFileDialog";
import { useNotificationContext } from "../../../../../shared/contexts/NotificationContext";
import useFetch from "../../../../../shared/hooks/useFetch";
import { logError } from "../../../../../shared/logging";
import adminApi, { FileDownloadInfo } from "../../../../api/adminApi";
import { DocumentViews } from "../../../../api/types/documentActivityTypes";
import { useClientContext } from "../../../../context/ClientContext";
import {
  firstPageAction,
  getInitialPaginatedItemsState,
  getPagingParams,
  loadItemsAction,
} from "../../../../state/paginatedState";
import storage from "../../../../storage/storage";
import GeneralPageHeader from "../../../common/GeneralPageHeader";
import { FilterContextProvider } from "../../../common/filters/FilterContext";
import FiltersPanel from "../../../common/filters/FiltersPanel";
import { createReducer, getInitialState } from "../../../common/filters/filterState";
import { emptySearchFilterDefinition } from "../../../common/filters/handlers/filterHandlers";
import { DocumentActivityPageContextProvider } from "./DocumentActivityPageContext";
import DocumentViewsGrid from "./DocumentViewsGrid";
import { getCsvToExport } from "./csvFormatter";
import { getDocumentViewFileDownloadInfo } from "./documentViewFileHelper";
import { filterDefinitions, getRequestFilters } from "./documentViewsFilterDefinitions";
import { getSelectOptionsForDocumentViews } from "./documentViewsSelectOptionsProvider";

interface DialogState {
  previewFileInfo?: FileDownloadInfo;
}

const getFileDownloadInfo = withErrorHandling(getDocumentViewFileDownloadInfo);

const DocumentActivityPage = () => {
  const { clientCode, clientTitle } = useClientContext();
  const gridApiRef = useGridApiRef();
  const { sendNotification, sendNotificationError } = useNotificationContext();

  const [dialogState, setDialogState] = useState<DialogState>({});
  const [pageState, setPageState] = useState(getInitialPaginatedItemsState<DocumentViews>());

  const [filterState, dispatchFilters] = useReducer(
    createReducer<DocumentViews>(),
    getInitialState(`${clientCode}_document_activity_filter_v1`, filterDefinitions, emptySearchFilterDefinition(), [])
  );

  const getDocumentViews = useCallback(() => {
    return adminApi.searchDocumentViews({
      paging: getPagingParams(pageState.page),
      ...getRequestFilters(filterState),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageState.page, objectHash(getRequestFilters(filterState))]);

  const [, fetchError, { isFetching, fetch: fetchDocumentViews }] = useFetch(getDocumentViews, (resp) => {
    setPageState(loadItemsAction(resp));
  });

  if (fetchError) {
    logError(fetchError, "[DocumentActivityPage]");
    storage.clearFilterState(`${clientCode}_document_activity_filter_v1`);
    return <DataLoadingFailed title="Could not load document activity" />;
  }

  const handleRefresh = () => {
    if (pageState.page === 0) {
      fetchDocumentViews();
    } else {
      setPageState(firstPageAction());
    }
  };

  const handleExportToCsv = () => {
    const csv = getCsvToExport(pageState.items);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const fileName = `${clientTitle} Document Activity.csv`;
    saveAs(blob, fileName);
  };

  const handleShowFilePreview = async (documentView: DocumentViews) => {
    const [previewFileInfo, error] = await getFileDownloadInfo(documentView);
    if (error?.type === "NotFound") {
      sendNotification(
        "The file you are trying to view is no longer available. It was previously accessible, but it has now been removed."
      );
      return;
    } else if (error) {
      sendNotificationError("Could not get file download information");
      logError(error, `[getDocumentViewFileDownloadInfo] ${documentView.id}`);
      return;
    }

    setDialogState({ previewFileInfo });
  };

  const onHideFilePreview = () => setDialogState({});

  return (
    <DocumentActivityPageContextProvider
      pageState={pageState}
      setPageState={setPageState}
      showFilePreview={handleShowFilePreview}
    >
      <GeneralPageHeader title="Document Activity" />
      <Stack spacing={2.5} py={2.5} px={3} height="100%">
        <FilterContextProvider
          filterState={filterState}
          dispatchFilters={dispatchFilters}
          getSelectOptions={getSelectOptionsForDocumentViews}
        >
          <FiltersPanel
            totalCount={pageState.totalRecords}
            recordCount={pageState.items.length}
            isLoading={isFetching}
            onRefresh={handleRefresh}
            onExportToCsv={handleExportToCsv}
            withoutSearch
          />
        </FilterContextProvider>
        <DocumentViewsGrid isLoading={isFetching} apiRef={gridApiRef} rows={pageState.items} />
      </Stack>
      {dialogState.previewFileInfo?.downloadUrl && (
        <PreviewFileDialog
          url={dialogState.previewFileInfo.downloadUrl}
          fileName={dialogState.previewFileInfo.fileName ?? ""}
          onClose={onHideFilePreview}
        />
      )}
    </DocumentActivityPageContextProvider>
  );
};

export default DocumentActivityPage;
